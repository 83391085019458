import React, { useState, useEffect } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Col, Row } from 'react-bootstrap';
import EditCourse from '../Course_Components/editCourseForm'
import CreateCourseForm from '../Course_Components/createCourseForm'
import EditCourseVersion from '../Course_Components/EditCourseVersion';
import AddCourseVersion from '../Course_Components/AddCourseVersion';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ErrorPopUp from '../Utility/ErrorPopUp';
import SuccessPopUp from '../Utility/SuccessPopUp';
import * as apiUtility from "../../config/APIUtils";
import '../Admin_Components/newCourseTab.css';

function NewCourseTab() {

    var training_providers = JSON.parse(sessionStorage.getItem("training_providers"));
    const [allCourses, setAllCourses] = useState([]);
    const [selectedCrs, setSelectedCrs] = useState("");
    const [selectedCrsVersion, setSelectedCrsVersion] = useState("");
    const [parentCrsForVersion, setParentCrsForVersion] = useState("");
    const [addCourseOpen, setAddCourseOpen] = useState(false);
    const [editCourseModel, setEditCourseModel] = useState(false);
    const [addCrsVersionModel, setAddCrsVersionModel] = useState(false);
    const [editCrsVersionModel, setEditCrsVersionModel] = useState(false);
    const [parentCrsToAddVer, setParentCrsToAddVer] = useState("");
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [dataForSuccessPopup, setDataForSuccessPopup] = useState('');
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [dataForErrorPopup, setDataForErrorPopup] = useState('');
    
    var mapOfTpIdName = {};
    if(training_providers !== undefined && training_providers !== null){
        training_providers?.forEach(element => {
            mapOfTpIdName[element.tp_id] = element.provider_name;
        });
    }else{
        mapOfTpIdName["1000"] = "Lambton College";
    }
    

    useEffect(async () => {
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "course/view-all-courses";
        await apiUtility.fetchAsyncAPIDataGET(apiRequest)
            .then(res => {
               setAllCourses(res.data);
            })
            .catch(err => {
                console.log(err);
            })

    }, [])


    const handleShow = (msg) => {
        if (msg) {
            setAddCourseOpen(true);
        }
    };

    const handleEditCrs = (msg) => {
        if (msg) {
            setEditCourseModel(true);
        }
    };

    const handleAddCrsVersion = (msg) => {
        if (msg) {
            setAddCrsVersionModel(true);
        }
    };


    const handleEditCrsVersion = (msg) => {
        if (msg) {
            setEditCrsVersionModel(true);
        }
    };

    const refreshGrid = async () => {
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "course/view-all-courses";
        await apiUtility.fetchAsyncAPIDataGET(apiRequest)
            .then(res => {
                setAllCourses(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    var cols = [{
        dataField: 'courseID',
        text: 'Course ID',
        sort: true,
        formatter: (rowContent, row) => {

            return (
                <div style={{ cursor: "pointer" }} onClick={(e) => {
                    console.log(row.courseID);
                    setSelectedCrs(row); handleEditCrs(true);

                }}> {row.courseID} </div>
            )
        }
    },
    {
        dataField: 'title',
        text: 'Title',
        sort: true,
        formatter: (rowContent, row) => {

            return (
                <div style={{ cursor: "pointer" }} onClick={(e) => {
                    setSelectedCrs(row); handleEditCrs(true);

                }}> {row.title} </div>
            )
        }
    },
    {
        dataField: 'description',
        text: 'Description',
        formatter: (rowContent, row) => {

            return (
                <div style={{ cursor: "pointer" }} onClick={(e) => {
                    setSelectedCrs(row); handleEditCrs(true);

                }}> {row.description} </div>
            )
        }
    },
        , {
        dataField: 'typeForView',
        text: 'Applicable Industry',
        sort: true,
        formatter: (rowContent, row) => {
            return (
                <div style={{ cursor: "pointer" }} onClick={(e) => {
                    setSelectedCrs(row); handleEditCrs(true);
                }}> {row.typeForView} </div>
            )
        }
    }
        , {
        dataField: 'last_modified_date',
        text: 'Last updated',
        sort: true,
        formatter: (rowContent, row) => {

            return (
                <div style={{ cursor: "pointer" }} onClick={(e) => {
                    setSelectedCrs(row); handleEditCrs(true);

                }}> {row.last_modified_date} </div>
            )
        }
    },

    {
        dataField: 'validity_duration',
        text: 'Expiry',
        sort: true,
        formatter: (rowContent, row) => {

            return (
                <div style={{ cursor: "pointer" }} onClick={(e) => {
                    setSelectedCrs(row); handleEditCrs(true);

                }}> {row.validity_duration_string} </div>
            )
        }
    },
        , {
        dataField: 'link',
        text: 'Versions     ',
        formatter: (rowContent, row) => {
            // //console.log(rowContent)


            return (
                <Button class='showVerBtnClass' onClick={async (e) => {
                    // changeUserCrsClicked(row)
                    if (e.target.innerHTML == "-") {
                        e.target.innerHTML = "+";
                        console.log(e.target.parentNode.parentNode.nextElementSibling)
                        e.target.parentNode.parentNode.nextElementSibling.remove();
                        return
                    }
                    e.target.innerHTML = "-";
                    var crsVerCols = ['Version Code', 'Version Reference Number', 'Title', 'Description', 'Training Duration', 'Training Provider','Direct Registration', 'Status', 'Last Modified'];
                    var tbl = document.createElement('table');
                    // tbl.style.width = '145%';
                    tbl.setAttribute('border', '1');
                    var thead = document.createElement('thead');
                    var tbdy = document.createElement('tbody');
                    var parentCrs = row.courseID


                    var button = document.createElement("button");
                    button.innerHTML = "Add Course Version";
                    button.setAttribute("class", "btn btn-primary");
                    button.addEventListener("click", function (e) {
                        setParentCrsToAddVer(parentCrs);
                        handleAddCrsVersion(true);

                    });
                    var trForAddCrsVer = document.createElement("tr");
                    var tdForAddCrsVer = document.createElement("td");
                    tdForAddCrsVer.setAttribute("colspan", "9");
                    tdForAddCrsVer.appendChild(button);

                    trForAddCrsVer.appendChild(tdForAddCrsVer);
                    tbl.appendChild(trForAddCrsVer);

                    var tr = document.createElement('tr');
                    for (let i = 0; i < crsVerCols.length; i++) {
                        let th = document.createElement('th');
                        th.style.padding = '10px';
                        th.innerHTML = crsVerCols[i];
                        tr.appendChild(th);
                    }
                    thead.appendChild(tr);
                    tbl.appendChild(thead);

                    var apiRequest = apiUtility.apiInputRequest;
                    apiRequest.endPoint = "course/get-all-course-version_for_course/" + parentCrs;
                    var res = await apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
                        
                        var allCourses = res.data;
                        for (let i = 0; i < allCourses.length; i++) {
                            tr = document.createElement('tr');

                            let th = document.createElement('td');
                            // th.innerHTML = allCourses[i].course_version_id;
                            // th.style.padding = '10px';
                            // tr.appendChild(th);
                            th.innerHTML = allCourses[i].tp_course_code;
                            th.style.padding = '10px';
                            tr.appendChild(th);

                            th = document.createElement('td');
                            th.innerHTML = allCourses[i].version_number;
                            th.style.padding = '10px';
                            tr.appendChild(th);

                            th = document.createElement('td');
                            th.innerHTML = allCourses[i].title === undefined ? "" : allCourses[i].title;
                            th.style.padding = '10px';
                            tr.appendChild(th);

                            th = document.createElement('td');
                            th.innerHTML = allCourses[i].description.substring(0, 40);
                            th.style.padding = '10px';
                            tr.appendChild(th);

                            th = document.createElement('td');
                            th.innerHTML = allCourses[i].training_duration;
                            th.style.padding = '10px';
                            tr.appendChild(th);

                            // th = document.createElement('td');
                            // th.innerHTML = allCourses[i].equivalent_course_id;
                            // th.style.padding = '10px';
                            // tr.appendChild(th);

                            th = document.createElement('td');
                            th.innerHTML = allCourses[i].training_provider_id === undefined ? "" : mapOfTpIdName[allCourses[i].training_provider_id];
                            th.style.padding = '10px';
                            tr.appendChild(th);

                            th = document.createElement('td');
                            th.innerHTML = (allCourses[i].direct_registration === undefined || allCourses[i].direct_registration === false) ? "No" : "Yes";
                            th.style.padding = '10px';
                            tr.appendChild(th);

                            th = document.createElement('td');
                            th.innerHTML = (allCourses[i].is_valid === "true" || allCourses[i].is_valid === true) ? "Active" : "Inactive";
                            th.style.padding = '10px';
                            tr.appendChild(th);

                            th = document.createElement('td');
                            th.innerHTML = new Date(allCourses[i].last_modified_date).toLocaleDateString();
                            th.style.padding = '10px';
                            tr.appendChild(th);

                            th = document.createElement('td');
                            th.style.padding = '10px';
                            tr.appendChild(th)
                            tr.addEventListener("click", function (e) {
                                e.preventDefault();
                                setParentCrsForVersion(parentCrs);
                                setSelectedCrsVersion(allCourses[i].course_version_id);
                                handleEditCrsVersion(true);
                            });
                            tr.classList.add('tr_crs_version');
                            tbdy.appendChild(tr);
                        }
                        tbl.appendChild(tbdy);
                        var trOfClicked = e.target.parentNode.parentNode;
                        var div = document.createElement("div");
                        div.appendChild(tbl);
                        var tr = document.createElement('tr');
                        var td = document.createElement('td');
                        td.setAttribute("colspan", "6");
                        td.appendChild(div);
                        tr.appendChild(td);
                        trOfClicked.parentNode.insertBefore(tr, trOfClicked.nextSibling)
                        document.getElementById("formGridCourseSelectEdit").dispatchEvent(new Event('change'));
                        
                    }).catch((error) => {
                            console.error(error);
                            // setshowSpinner(false);
                          });
                    if (res) {
                        var allCourses = res.data;
                        for (let i = 0; i < allCourses.length; i++) {
                            tr = document.createElement('tr');

                            let th = document.createElement('td');
                            // th.innerHTML = allCourses[i].course_version_id;
                            // th.style.padding = '10px';
                            // tr.appendChild(th);
                            th.innerHTML = allCourses[i].tp_course_code;
                            th.style.padding = '10px';
                            tr.appendChild(th);

                            th = document.createElement('td');
                            th.innerHTML = allCourses[i].version_number;
                            th.style.padding = '10px';
                            tr.appendChild(th);

                            th = document.createElement('td');
                            th.innerHTML = allCourses[i].title === undefined ? "" : allCourses[i].title;
                            th.style.padding = '10px';
                            tr.appendChild(th);

                            th = document.createElement('td');
                            th.innerHTML = allCourses[i].description.substring(0, 40);
                            th.style.padding = '10px';
                            tr.appendChild(th);

                            th = document.createElement('td');
                            th.innerHTML = allCourses[i].training_duration;
                            th.style.padding = '10px';
                            tr.appendChild(th);

                            // th = document.createElement('td');
                            // th.innerHTML = allCourses[i].equivalent_course_id;
                            // th.style.padding = '10px';
                            // tr.appendChild(th);

                            th = document.createElement('td');
                            th.innerHTML = allCourses[i].training_provider_id === undefined ? "" : mapOfTpIdName[allCourses[i].training_provider_id];
                            th.style.padding = '10px';
                            tr.appendChild(th);

                            th = document.createElement('td');
                            th.innerHTML = (allCourses[i].direct_registration === undefined || allCourses[i].direct_registration === false) ? "No" : "Yes";
                            th.style.padding = '10px';
                            tr.appendChild(th);

                            th = document.createElement('td');
                            th.innerHTML = (allCourses[i].is_valid === "true" || allCourses[i].is_valid === true) ? "Active" : "Inactive";
                            th.style.padding = '10px';
                            tr.appendChild(th);

                            th = document.createElement('td');
                            th.innerHTML = new Date(allCourses[i].last_modified_date).toLocaleDateString();
                            th.style.padding = '10px';
                            tr.appendChild(th);
                            th = document.createElement('td');

                            th.style.padding = '10px';
                            tr.appendChild(th)
                            tr.addEventListener("click", function (e) {
                                e.preventDefault();
                                setParentCrsForVersion(parentCrs);
                                setSelectedCrsVersion(allCourses[i].course_version_id);
                                handleEditCrsVersion(true);
                            });
                            tr.classList.add('tr_crs_version');
                            tbdy.appendChild(tr);
                        }
                        tbl.appendChild(tbdy);
                        var trOfClicked = e.target.parentNode.parentNode;
                        var div = document.createElement("div");
                        div.appendChild(tbl);
                        var tr = document.createElement('tr');
                        var td = document.createElement('td');
                        td.setAttribute("colspan", "6");
                        td.appendChild(div);
                        tr.appendChild(td);


                        trOfClicked.parentNode.insertBefore(tr, trOfClicked.nextSibling)
                        document.getElementById("formGridCourseSelectEdit").dispatchEvent(new Event('change'));
                    }
                }}>+</Button>
            )
        }
    }

    ]


    var handleCallBack = (msg) => {
        setAddCourseOpen(false);
        setEditCourseModel(false);
        setAddCrsVersionModel(false);
        setEditCrsVersionModel(false);
        var isError = (msg[0] === -1) ? true : false;
        if (!isError) {
            setDataForSuccessPopup(msg[1]);
            setShowSuccessPopup(true);
        } else {
            setDataForErrorPopup(msg[1]);
            setShowErrorPopup(true);
        }
        refreshGrid();
    }

    return (
        <div>
            <Row>
                <Col></Col>
                <Col className='text-center'><h3>Manage Courses</h3></Col>
                <Col></Col>
            </Row>
            <Row>
                <Col></Col>
                <Col className='text-center'>
                    <Button className="btn btn-primary" onClick={() => handleShow(true)} >Add New Course
                    </Button>
                </Col>
                <Col></Col>
            </Row>
            <br></br>
            <Row>
                <BootstrapTable striped hover keyField='courseID' data={allCourses} columns={cols} pagination={paginationFactory({
                    sizePerPage: 10,
                    onPageChange: function (page, sizePerPage) {
                        var showVerBtnArr = document.getElementsByClassName("showVerBtnClass");
                        for (let i = 0; i < showVerBtnArr.length; i++) {
                            if (showVerBtnArr[i].innerHTML == "-") {
                                showVerBtnArr[i].innerHTML = "+";
                                showVerBtnArr[i].parentNode.parentNode.nextElementSibling.remove();
                            }
                        }

                        //console.log("page changed");
                    }
                })} />
            </Row>
            <CreateCourseForm show={addCourseOpen} handleCallBack={handleCallBack} onHide={() => { refreshGrid(); setAddCourseOpen(false); }} />
            <EditCourse show={editCourseModel} courseID={selectedCrs} handleCallBack={handleCallBack} onHide={() => { refreshGrid(); setEditCourseModel(false); }} />
            <AddCourseVersion show={addCrsVersionModel} handleCallBack={handleCallBack} selectedCrs={parentCrsToAddVer} isPrntCrsDisabled={true} onHide={() => { setAddCrsVersionModel(false); }} />
            <EditCourseVersion show={editCrsVersionModel} handleCallBack={handleCallBack} crsVerID={selectedCrsVersion} parentCrs={parentCrsForVersion} onHide={() => { setEditCrsVersionModel(false); }} />

            <ErrorPopUp show={showErrorPopup} data={dataForErrorPopup} onHide={() => { setShowErrorPopup(false) }} />
            <SuccessPopUp show={showSuccessPopup} data={dataForSuccessPopup} onHide={() => { setShowSuccessPopup(false) }} />
            <br></br>

        </div>
    )
}

export default NewCourseTab