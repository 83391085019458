import Header from './header/Header';
import Footer from './footer/Footer';
import React, {Component} from 'react'
import { Container } from 'react-bootstrap';

class Layout extends Component {
    render() {
        return (
            <Container fluid>
                {this.props.data !=='/login' && <Header/>}
                {this.props.children}
                {this.props.data !=='/login' && <Footer />}
            </Container>
        )
    }
}

export default Layout;
