import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import BootstrapTable from 'react-bootstrap-table-next';
import Spinner from 'react-bootstrap/Spinner';
import * as apiUtility from "../../config/APIUtils";
import { Divider, IconButton, Paper, Tooltip } from "@mui/material";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import ErrorPopUp from '../Utility/ErrorPopUp';
import { RiFileExcel2Line } from "react-icons/ri";
import { BsFiletypePdf } from "react-icons/bs";

function OrganizationTemplateSpecificComponent(props) {
    const [orgStaffSummData, setOrgStaffSummData] = useState([]);
    const [showSpinner, setshowSpinner] = useState(false);
    const toggleshowSpinner = () => setshowSpinner(!showSpinner);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [dataForErrorPopup, setDataForErrorPopup] = useState('');

    var orgId = props.org_id;
    var filterObj = props.filterBy;
    var homeFlag = props.homeFlag;
    var staffFlag = props.staffFlag;
    useEffect(async () => {
        setshowSpinner(true)
        var body = {
            "orgId": orgId,
            "firstName": filterObj.firstName,
            "lastName": filterObj.lastName,
            "homeFlag": homeFlag,
            "staffFlag": staffFlag
        }
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "audit-report/org-template-specific";
        apiRequest.data = body;
        var res = await apiUtility.sendAPIDataPOST(apiRequest).then(res => {
            var data = res.data;
            data.forEach((item, index) => {
                data[index].completion_date = new Date(item.completion_date).toLocaleString();
            })
            setOrgStaffSummData(res.data)
            setshowSpinner(false)
        }).catch((error) => {
            console.error(error);
            setshowSpinner(false);
        });


    }, [orgId])

    var orgStaffSummCols = [{
        dataField: 'orgId',
        sort: true,
        text: 'Org ID'
    }, {
        dataField: 'home_name',
        sort: true,
        text: 'Home'
    }, {
        dataField: 'home_status',
        sort: true,
        text: 'Home Status'
    }, {
        dataField: 'role_id',
        sort: true,
        text: 'Role ID'
    }, {
        dataField: 'role_name',
        sort: true,
        text: 'Role Name'
    }, {
        dataField: 'user_id',
        sort: true,
        text: 'User ID'
    }, {
        dataField: 'first_name',
        sort: true,
        text: 'First Name'
    }, {
        dataField: 'last_name',
        sort: true,
        text: 'Last Name'
    }
        , {
        dataField: 'status',
        sort: true,
        text: 'Status'
    },
        , {
        dataField: 'is_complaint',
        sort: true,
        text: 'Is Compliant ?'
    },

    ];

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: "xlsx",
            bookSST: false,
            type: "binary",
        };
        const wbout = XLSX.write(workbook, wopts);

        // The application/octet-stream MIME type is used for unknown binary files.
        // It preserves the file contents, but requires the receiver to determine file type,
        // for example, from the filename extension.
        const blob = new Blob([s2ab(wbout)], {
            type: "application/octet-stream",
        });
        return blob;
    };
    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; i++) {
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    //Excel
    const exportInExcel = async () => {
        try {
            var rowsCount = 6;
            const title = [{ A: 'Ontario Long Term Care Orientation Co-operative Webportal - Template Specific Summary for Organization ID : ' + String(orgId) }];
            let reportView = [{ A: 'Name', B: 'User ID', C: 'Role', D: 'Date' }];
            reportView.push({
                A: sessionStorage.getItem('fullName'),
                B: sessionStorage.getItem('userId'),
                C: (sessionStorage.getItem('userType') !== null)?sessionStorage.getItem('userType').toUpperCase():"User",
                D: new Date().toLocaleString()
            });
            reportView.push("");
            reportView.push("");

            let reportHeaders = { A: 'Organization ID', B: 'Home Name', C: 'Home Status', D: 'Role ID', E: 'Role Name', F: 'User ID', G: 'First Name', H: 'Last Name', I: 'Status', J: 'Is Compliant?' };
            reportView.push(reportHeaders);
            // orgStaffTemplateData.forEach(element => {
            orgStaffSummData.forEach(element => {
                rowsCount += 1;
                var tempObj = {
                    A: element.orgId,
                    B: element.home_name,
                    C: element.home_status,
                    D: element.role_id,
                    E: element.role_name,
                    F: element.user_id,
                    G: element.first_name,
                    H: element.last_name,
                    I: element.status,
                    J: element.is_complaint
                };
                reportView.push(tempObj);
            });
            const finalData = [...title, ...reportView];
            const worksheet = XLSX.utils.json_to_sheet(finalData, {
                skipHeader: true,
            });
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Template Specific Summary");
            const workbookblob = workbook2blob(workbook);
            var headerIndexes = [];
            finalData.forEach((data, index) =>
                data["A"] === "Organization ID" ? headerIndexes.push(index) : null
            );
            const dataInfo = {
                titleCell: "A2",
                titleRange: "A1:F1",
                tbodyRange: `A7:J${finalData.length}`,
                uHeadRange: `A2:D2`,
                uBodyRange: `A3:D3`,
                theadRange:
                    headerIndexes?.length >= 1
                        ? `A${headerIndexes[0] + 1}:J${headerIndexes[0] + 1}`
                        : null,
                theadRange1:
                    headerIndexes?.length >= 2
                        ? `A${headerIndexes[1] + 1}:I${headerIndexes[1] + 1}`
                        : null,
                tFirstColumnRange:
                    headerIndexes?.length >= 1
                        ? `A${headerIndexes[0] + 1}:A${rowsCount + headerIndexes[0] + 1}`
                        : null,
                tLastColumnRange:
                    headerIndexes?.length >= 1
                        ? `K${headerIndexes[0] + 1}:I${rowsCount + headerIndexes[0] + 1}`
                        : null,

                tFirstColumnRange1:
                    headerIndexes?.length >= 1
                        ? `A${headerIndexes[1] + 1}:A${rowsCount + headerIndexes[1] + 1}`
                        : null,
                tLastColumnRange1:
                    headerIndexes?.length >= 1
                        ? `H${headerIndexes[0] + 1}:I${rowsCount + headerIndexes[1] + 1}`
                        : null,
            };
            XlsxPopulate.fromDataAsync(workbookblob).then((workbook) => {
                workbook.sheets().forEach((sheet) => {
                    sheet.column("A").width(15);
                    sheet.column("B").width(30);
                    sheet.column("C").width(20);
                    sheet.column("D").width(20);
                    sheet.column("E").width(30);
                    sheet.column("F").width(20);
                    sheet.column("G").width(30);
                    sheet.column("H").width(30);
                    sheet.column("I").width(20);
                    sheet.column("J").width(20);
                    sheet.range(dataInfo.titleRange).merged(true).style({
                        bold: true,
                        fontFamily: "Arial",
                        horizontalAlignment: "center",
                        verticalAlignment: "center",
                    });
                    sheet.range(dataInfo.uHeadRange).style({
                        fill: "A6A6A6",
                        bold: true,
                        horizontalAlignment: "center",
                    });
                    sheet.range(dataInfo.uBodyRange).style({
                        bold: true,
                        horizontalAlignment: "center",
                    });
                    if (dataInfo.tbodyRange) {
                        sheet.range(dataInfo.tbodyRange).style({
                            horizontalAlignment: "left",
                        });
                    }
                    sheet.range(dataInfo.theadRange).style({
                        fill: "A6A6A6",
                        bold: true,
                        horizontalAlignment: "center",
                    });
                    if (dataInfo.tFirstColumnRange) {
                        sheet.range(dataInfo.tFirstColumnRange).style({
                            bold: true,
                        });
                    }

                });

                return workbook.outputAsync().then((workbookBlob) => {
                    const downloadAnchorNode = document.createElement("a");
                    var dateVar = new Date();
                    var fileName = "LCPT_OrgTemplateSpecificSummary-" + dateVar.getDate() + dateVar.getMonth() + dateVar.getFullYear() + "_" + dateVar.getHours() + dateVar.getMinutes() + ".xlsx";
                    downloadAnchorNode.setAttribute("href", URL.createObjectURL(workbookBlob));
                    downloadAnchorNode.setAttribute("download", fileName);
                    downloadAnchorNode.click();
                    downloadAnchorNode.remove();
                    // setDataForSuccessPopup("Downloaded Successfully");
                    // setShowSuccessPopup(true);
                });
            });
        } catch (error) {
            setDataForErrorPopup("Something went wrong while processing excel!");
            setShowErrorPopup(true);
        }
    }


    return (
        <div>
            {showSpinner ? <div style={{ paddingLeft: "50%", paddingTop: "10%", position: "absolute" }}>
                <Spinner show={showSpinner} animation="border" size="lg" variant='primary' />

            </div> : <div></div>}
            <Paper sx={{ p: 1, borderRadius: "1.5px" }}>
                <BootstrapTable id='organizationStaffSpecificTable' keyField='id' data={orgStaffSummData} columns={orgStaffSummCols} striped hover />
                <Row>
                    <Col xs={0} md={8}>

                    </Col>
                    <Col xs={12} md={4} className="text-center">
                        <Paper component="form" variant="outlined"
                            sx={{ float: "right", display: 'flex', marginBottom: "4px", alignItems: 'center', width: 50 }}
                        >

                            <Tooltip variant="contained" title="Export to Excel" disableRipple>
                                <IconButton color="primary" onClick={exportInExcel} sx={{ p: '10px' }} aria-label="directions">
                                    <RiFileExcel2Line />
                                </IconButton>
                            </Tooltip>
                        </Paper>
                    </Col>
                </Row>
            </Paper>
            <ErrorPopUp show={showErrorPopup} data={dataForErrorPopup} onHide={() => { setShowErrorPopup(false) }} />
        </div>


    )
}

export default OrganizationTemplateSpecificComponent