import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import { Button } from 'react-bootstrap'
import VerifyCredentials from './VerifyCredentials';
import filterFactory, { textFilter, selectFilter, dateFilter } from 'react-bootstrap-table2-filter';
import { BASE_URL_FRONTEND } from '../../config/Url-config';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ErrorPopUp from '../Utility/ErrorPopUp';
import SuccessPopUp from '../Utility/SuccessPopUp';
import SpinnerBox from '../Utility/SpinnerBox';
import * as apiUtility from "../../config/APIUtils";


function AdminUserEditor() {

    const [adminUserEditorData, setAdminUserEditorData] = useState([]);
    const [userCrsRow, setUserCrsRow] = useState({});
    const [verifyCredModal, setVerifyCredModal] = useState(false);
    const [isEditBtnClicked, setIsEditBtnClicked] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [dataForSuccessPopup, setDataForSuccessPopup] = useState('');
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [dataForErrorPopup, setDataForErrorPopup] = useState('');
    const [spinnerBox, setSpinnerBox] = useState(false);
    const accessToken = sessionStorage.getItem('authToken');
    const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    var changeUserCrsClicked = (row) => {
        setIsEditBtnClicked(false);
        //console.log(row)
        var userCrsRow = {
            mongoID: row._id,
            userId: row.user_id,
            courseId: row.course_id,
            url: row.badging_document_url,
            status: row.verification,
            validityDate: row.completion_date,
            validityDateOG: row.completion_date_OG,
            courseVersionId: row.course_version_id
        }
        setUserCrsRow(userCrsRow)
        setIsEditBtnClicked(true);
        setVerifyCredModal(true);
    }
    // var tab = JSON.parse(localStorage.getItem("activeTab"));
    // console.log("Tab: ",tab);
    useEffect(async () => {
        setSpinnerBox(true);
        if (sessionStorage.getItem("userType") !== 'admin') {
            return window.location.href = BASE_URL_FRONTEND;

        }
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "admin-data/user-crs";
        //apiRequest.data = tempOrgObj;
        var res = await apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
            setAdminUserEditorData(res.data)
                setSpinnerBox(false);      }).catch((error) => {
                console.error(error);
                setSpinnerBox(false);
              });
        if(res){
            setAdminUserEditorData(res.data)
                setSpinnerBox(false);
        }
        // var getAdmUsrCrsURL = BASE_API_URL + "admin-data/user-crs";
        // axios.get(getAdmUsrCrsURL,{headers})
        //     .then(res => {
        //         //console.log(res.data);
        //         setAdminUserEditorData(res.data)
        //         setSpinnerBox(false);
        //     })
        //     .catch(err => {
        //         setSpinnerBox(false);
        //         //console.log(err);
        //     })

    }, [userCrsRow])

    // useEffect(() => {
    //     if (tab === 'verifyCredentials') {
    //         //return window.location.href = BASE_URL_FRONTEND;
    //         alert("load now");
    //     }
    // }, [tab])

    var refreshGridData = async () => {
        setSpinnerBox(true);
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "admin-data/user-crs";
        //apiRequest.data = tempOrgObj;
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
            setAdminUserEditorData(res.data)
            setSpinnerBox(false);        }).catch((error) => {
                console.error(error);
                setSpinnerBox(false);
              });
        // if(res){
        //     setAdminUserEditorData(res.data)
        //         setSpinnerBox(false);
        // }
        // else{
        //     setSpinnerBox(false);
        // }
        // var getAdmUsrCrsURL = BASE_API_URL + "admin-data/user-crs";
        // axios.get(getAdmUsrCrsURL,{headers})
        //     .then(res => {
        //         setAdminUserEditorData(res.data)
        //         setSpinnerBox(false);
        //     })
        //     .catch(err => {
        //         setSpinnerBox(false);
        //         //console.log(err);
        //     })
    }

    var handleCallBack = (msg) => {
        setVerifyCredModal(false);
        var isError = (msg[0] === -1)? true : false;
        if(!isError){
            setDataForSuccessPopup(msg[1]);
            setShowSuccessPopup(true);
        }else{
            setDataForErrorPopup(msg[1]);
            setShowErrorPopup(true);
        }
        refreshGridData();
    }

    const selectOptions = {
        "Denied": 'Denied',
        "Validated": 'Validated',
        "Expired":"Expired",
        "Approved":"Approved"
      };

    const options = {
        sizePerPage: 10,
        hideSizePerPage: false,
        hidePageListOnlyOnePage: false,
    };

    var adminUserEditorCols = [{
        dataField: 'user_id',
        text: 'User ID',
        sort: true,
        filter: textFilter(),
        
    }, {
        dataField: 'course_id',
        text: 'Course ID',
        sort: true,
        filter: textFilter()
    }
        , {
        dataField: 'tp_course_code',
        text: 'Course Version Code',
        sort: true,
        filter: textFilter()
    }

        , {
        dataField: 'badging_document_url',
        text: 'Badging Document URL',
        sort: true,
        filter: textFilter()
    }
        //     , {
        //     dataField: 'status',
        //     text: 'Is Valid'
        // }
        , {
        dataField: 'verification',
        text: 'Status',
        sort: true,
        formatter: cell => selectOptions[cell],
        filter: selectFilter({
            options: selectOptions,
            placeholder: 'Select Status' 
          })
    }

        , {
        dataField: 'completion_date',
        text: 'Completed On',
        sort: true,
        filter: textFilter(),
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
            var val1 = (rowA.completion_date_OG === "")?"":new Date(a);
            var val2 = (rowB.completion_date_OG === "")?"":new Date(b);
            if (order === 'asc') {
              return val1 - val2;
            }
            return val2 - val1; // desc
          }
    }

        , {
        dataField: 'link',
        text: 'Verify Details',
        formatter: (rowContent, row) => {
            // //console.log(rowContent)

            return (
                 (row.verification !== "Approved" && row.verification !== "Validated")?
                    <Button variant="primary" onClick={(e) => {changeUserCrsClicked(row)}}> Verify </Button>
                    :   ""
                
            )
        }
    }
    ];

   
    return (
        <div>
            {isEditBtnClicked && <VerifyCredentials data={userCrsRow} handleCallBack={handleCallBack} show={verifyCredModal} onHide={() => {refreshGridData(); setVerifyCredModal(false);}}/>}
            
            <div>
                <br></br>
                <h3 style={{ textAlign: 'center'}}>Verify User Credentials</h3>
                <br></br>
                <BootstrapTable id='organizationStaffSpecificTable' keyField='id' data={adminUserEditorData} 
                        columns={adminUserEditorCols} 
                        pagination={paginationFactory(options)} 
                        filter={filterFactory()}
                        filterPosition="top"
                        striped
                        hover
                        condensed 
                        />
            </div>
            {/* <VerifyCredentials userCrsRowData={userCrsRow} style={{ display: 'none' }} /> */}
            <SpinnerBox show={spinnerBox} onHide={() => { setSpinnerBox(false)}}/> 
            <ErrorPopUp show={showErrorPopup} data={dataForErrorPopup} onHide={() => { setShowErrorPopup(false)}}/> 
            <SuccessPopUp show={showSuccessPopup} data={dataForSuccessPopup} onHide={() => { setShowSuccessPopup(false)}}/> 
        </div>
    )
}

export default AdminUserEditor