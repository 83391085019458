import React, { useEffect, useRef, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as apiUtility from '../../config/APIUtils';
import { IdleTimerProvider } from 'react-idle-timer';
import { Button } from 'react-bootstrap';


function SessionTimeoutContainer(){
    const idleTimerRef = useRef(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [timer, setTimer] = useState(0);
    
    useEffect(() => {
      //console.log("Timer : ", timer, modalIsOpen);
        if(timer > 0){
            setTimeout(()=> { setTimer(timer => timer -1)}, 1000)
        }else if(timer <= 0 && modalIsOpen){
          logOut();
        }
    }, [timer]);

    const onIdle = () => {
        console.log("User idle - Session timeout");
        setTimer(30);
        setModalIsOpen(true);
    }


    const logOut = () => {
          apiUtility.logOut();
          setModalIsOpen(false);
          window.location = ("/login")
    }

    const stayActive = () => {
        //console.log("Stay active");
        setModalIsOpen(false);
    }

    const onActive = (event) => {
        //console.log("User is active");
    }

    const handleClose = () => {
        setModalIsOpen(false);
      };
    


    return (
        <div>
          {/* 30 mins timeout */}
            <IdleTimerProvider ref={idleTimerRef} timeout={1800000}
                onIdle={onIdle}
                onActive={onActive}
                ></IdleTimerProvider>
                {/* <Modal
                    isOpen={modalIsOpen}
                    onHide={()=> setModalIsOpen(false)}>
                    <h2>You've been idle for a while! </h2>
                    <p>You will be logged out soon</p>
                    <div>
                        <Button onClick={logOut}>Log me Out</Button>
                        <Button onClick={stayActive}>Keep me signed in</Button>
                    </div>
                </Modal> */}
                <Dialog
                    open={modalIsOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{ sx: { borderRadius: "1.5px" } }}
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Session Timeout"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You're being timed out due to inactivity. <br></br>
                        Please choose to stay signed in or to logoff. Otherwise you will logged off automatically in {timer} seconds.
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    

                        <Button onClick={logOut} autoFocus>Log Out</Button>
                        <Button variant="success" onClick={stayActive}>Keep me signed in</Button>
                    </DialogActions>
                </Dialog>
        </div>)
}

export default SessionTimeoutContainer;