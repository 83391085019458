import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import StaffRoleCourseChecklistComponent from './StaffRoleCourseChecklistComponent';
import SuccessPopUp from '../Utility/SuccessPopUp';
import { MdOutlineMailOutline } from "react-icons/md";
import * as apiUtility from "../../config/APIUtils";
import ErrorPopUp from '../Utility/ErrorPopUp';
import { Col, Row } from 'react-bootstrap';
import { DataGrid } from '@mui/x-data-grid';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { FiCheckCircle, FiXCircle } from "react-icons/fi";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { Divider, Grid, IconButton, InputBase, LinearProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Tooltip, Typography } from '@mui/material';
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { RiFileExcel2Line } from "react-icons/ri";
import { styled } from '@mui/system';

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
    '.MuiDataGrid-columnHeadersInner, .MuiDataGrid-footerContainer, .css-rtrcn9-MuiTablePagination-root': {
        backgroundColor: '#007bff', // Header and cell background color\
        fontSize: '1pc',
        color: "white"
    }
    // ,
    // '.MuiDataGrid-cell--withRenderer, .MuiDataGrid-cell MuiDataGrid-cell--textLeft, .MuiDataGrid-withBorderColor': {
    //     display: "block",
    //     textAlign:"center",
    //     width: "100%",
    //     }

}));

export default function StaffComponent(props) {

    var params = "";
    const handleCloseStaff = () => setStaffShow(false);
    const [staffList, setStaffList] = useState([]);
    const [staffListAll, setStaffListAll] = useState([]);
    const [showStaff, setStaffShow] = useState(false);
    const [showAssignRole, setShowAssignRole] = useState(false);
    const handleCloseAssignRole = () => setShowAssignRole(false);
    const handleShowAssignRole = () => setShowAssignRole(true);
    const [staffDetail, setStaffDetail] = useState({});
    const [positionDetail, setPositionDetail] = useState({});
    const [roleDetails, setRoleDetails] = useState([]);
    const [homeId, sethomeId] = useState({});
    const [staffUserId, setStaffUserId] = useState();
    const [staffHomeId, setStaffHomeId] = useState();
    const [staffFullName, setStaffFullName] = useState();
    const [checkListStaffModal, setStaffCourseCheckListModal] = useState(false);
    const handleCloseCourseCheckListStaffModal = () => setStaffCourseCheckListModal(false);
    const [homeName, setHomeName] = useState('');
    const [errors, setErrors] = useState(false)
    const [ageError, setAgeError] = useState(false)
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [homeDefaultPay, setHomeDefaultPay] = useState('');
    const [dataForSuccessPopup, setDataForSuccessPopup] = useState('');
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [dataForErrorPopup, setDataForErrorPopup] = useState('');
    const [hideRoles, setHideRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 25,
    });
    const [sortModel, setSortModel] = React.useState([{
        field: "",
        sort: "asc",
    }]);
    //const [queryOptions, setQueryOptions] = React.useState({});
    const [totalRows, setTotalRows] = useState(0);
    const [searchText, setSearchText] = useState("");
    if (props) {
        params = props.id
    }

    useEffect(() => {
        setSearchText("");
        fetchDataFromServer();
    }, [paginationModel.page, paginationModel.pageSize, params, sortModel])

    async function getRoleDetailByHome() {
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/showHomeCheckList/" + params;
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
            console.log(res)
            if (res.data != 'Something went wrong!' && res.data != 'No Role Found!' && res.data!='No Member Found!'){
                setRoleDetails(res.data);
                console.log("ROle in staff",res.data);
            }
                
        }).catch((error) => {
            console.error(error);
        });
    }

    const handleShowStaff = () => {
        getRoleDetailByHome();
        setStaffShow(true);
        if(sessionStorage.getItem('defaultPay')){
            setHomeDefaultPay(JSON.parse(sessionStorage.getItem('defaultPay')));
            console.log(sessionStorage.getItem('defaultPay'),homeDefaultPay)
        }
    }

    const calAge = (dateString) => {
        const today = new Date();
        const birthDate = new Date(dateString);
        let age = today.getFullYear() - birthDate.getFullYear()
        const m = today.getMonth() - birthDate.getMonth()
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--
        }
        return age;
    };


    function addStaffText(event, id) {
        var newStaffDetailObj = {}
        if (id === 'st1')
            newStaffDetailObj.user_id = event.target.value;
        else if (id === 'st2')
            newStaffDetailObj.fname = (event.target.value)
        else if (id === 'st5')
            newStaffDetailObj.lname = (event.target.value)
        else if (id === 'st3') {

            //console.log("calAge", calAge(event.target.value))
            if (calAge((event.target.value)) < 10) {
                setAgeError(true)

            }
            else {
                newStaffDetailObj.dob = String(((event.target.value)));
                setAgeError(false)
            }
        }
        else if (id === 'st4') {
            newStaffDetailObj.role_id = (roleDetails.find(item => item.role_name === event.target.value)).role_id;
            newStaffDetailObj.role_name = event.target.value;
        }
        else if (id === 'st6')
            newStaffDetailObj.email = (event.target.value)
        else if(id==='st8'){
            newStaffDetailObj.coverage_status = event.target.checked?'Pending':'Inactive';
            newStaffDetailObj.coverage_activation_date = event.target.checked?new Date():'N/A';
        }
        newStaffDetailObj.emp_status = 'Active'
        newStaffDetailObj.home_id = String(params);
        if(!staffDetail.coverage_status && !newStaffDetailObj.coverage_status){
            newStaffDetailObj.coverage_status = homeDefaultPay==false?'Inactive':'Pending';
            newStaffDetailObj.coverage_activation_date = homeDefaultPay==false?'N/A':new Date();        }
        setStaffDetail(staffDetail => ({
            ...staffDetail, ...newStaffDetailObj
        }));
    }
    async function saveStaffDetail() {
        console.log(staffDetail)
        if ((staffDetail.user_id || staffDetail.email) && staffDetail.role_name && staffDetail.dob && staffDetail.fname && staffDetail.lname) {
            setErrors(false);
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.data = staffDetail;
            apiRequest.endPoint = "orgnization/addNewStaff";
            console.log(staffDetail)
            var res = await apiUtility.sendAPIDataPOST(apiRequest).then(res => {

                //console.log(res)
                if (res.data == 'Role for User already present!') {
                    setDataForErrorPopup("Role for User already present!");
                    setShowErrorPopup(true);
                } else if (res.data == 'Something went wrong!') {
                    setDataForErrorPopup("Invalid User Details!");
                    setShowErrorPopup(true);
                } else {
                    setDataForSuccessPopup("New Staff Member was added");
                    setShowSuccessPopup(true);
                    //getStaffData();
                    fetchData(paginationModel.page, paginationModel.pageSize, searchText, sortModel, "")
                    handleCloseStaff();
                    setStaffDetail({});
                }

            }).catch((error) => {
                console.error(error);
                // setshowSpinner(false);
            });
        }
        else {
            setErrors(true)
        }
    }


    async function saveAssignRoleDetail() {
        if (positionDetail) {
            setShowAssignRole(false)
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.data = positionDetail;
            apiRequest.endPoint = "orgnization/addNewStaff";
            console.log(positionDetail)
            await apiUtility.sendAPIDataPOST(apiRequest).then(res => {
                fetchData(paginationModel.page, paginationModel.pageSize, searchText, sortModel, "")
                setDataForSuccessPopup("New role added successfully");
                setShowSuccessPopup(true);
            }).catch((error) => {
                console.error(error);
                setDataForErrorPopup("Something went wrong!");
                setShowErrorPopup(true);
                // setshowSpinner(false);
            });
        }
    }
    function addStaffRole(event, user_id, home_id, dob,defaultPay) {
        ////console.log(roleDetails[event.target.value],user_id,home_id,dob)
        let newPositionDetailObj = {};
        newPositionDetailObj = { ...positionDetail };
        newPositionDetailObj.newRoleForExistingStaff = true;
        if(defaultPay!=''){
            newPositionDetailObj.coverage_status = event.target.checked==true?"Pending":"Inactive";
            newPositionDetailObj.coverage_activation_date = event.target.checked==true?new Date():"N/A";
        }
        else{
            newPositionDetailObj.role_id = roleDetails[event.target.value].role_id
            newPositionDetailObj.role_name = roleDetails[event.target.value].role_name
            if(positionDetail.coverage_status!=false && positionDetail.coverage_status!=true){
                newPositionDetailObj.coverage_status = homeDefaultPay==true?"Pending":"Inactive";
                newPositionDetailObj.coverage_activation_date = homeDefaultPay==true?new Date():"N/A";
            }
        }
        
       
        setPositionDetail(newPositionDetailObj)

    }

    function openAssignRole(data) {
        //console.log("Data: ", data);
        let newPositionDetailObj = {};
        //console.log("dataffweff : ",data)
        newPositionDetailObj = { ...positionDetail };
        newPositionDetailObj.user_name = data.fullName
        newPositionDetailObj.user_id = data.user_id
        newPositionDetailObj.home_id = String(params)
        newPositionDetailObj.dob = data.dob
        var roleArray = [];
        for (let role of data.role_arr) {
            roleArray.push(role);
        }
        setHideRoles(roleArray);
        getRoleDetailByHome();
        setPositionDetail(newPositionDetailObj)
        handleShowAssignRole()
    }

    //params.row.user_id, params.row.home_id,params.row.firstName,params.row.lastName, params.row.fullName
    function handleStaffCourseRoleCheckList(userId, homeId, firstName,lastName,fullName) {
        setStaffHomeId(homeId);
        setStaffUserId(userId);
        setStaffFullName(firstName+" "+lastName);
        setStaffCourseCheckListModal(true);
    }

    async function resendEmail(obj, val) {
        setShowSuccessPopup(false);
        //console.log("OBJ ", obj, val);
        var body = {
            "user_id": obj.user_id,
            "home_id": obj.home_id,
            "role_id": val.role_id,
            "fullName": obj.fullName,
            "role_name": val.role_name
        }
        if (val.emp_status === "Hold") {
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.data = body;
            apiRequest.endPoint = "orgnization/resendStaffEmail";
            var res = await apiUtility.sendAPIDataPOST(apiRequest).then(res => {
                setDataForSuccessPopup(res.data);
                setShowSuccessPopup(true);
            }).catch((error) => {
                console.error(error);
                setDataForErrorPopup("Error occured while fetching user data.");
                setShowErrorPopup(true);
            });
        }
    }

    const clearFormatting = () => {
        setLoading(true);
        setSearchText("");
        fetchData(paginationModel.page, paginationModel.pageSize, "", "", "")
        setLoading(false);
    }

    const handleSearch = (e) => {
        e.preventDefault();
        setLoading(true);
        //console.log("event", e.target.value)
        // if(searchText !== ""){
        // const filteredData = staffListAll.filter(value => {
        //     const searchStr = e.target.value.toLowerCase();
        //     const nameMatches = value.fullName.toLowerCase().includes(searchStr);
        //     return nameMatches;
        // });
       
        fetchData(paginationModel.page, paginationModel.pageSize, searchText, "", "")
        setPaginationModel({...paginationModel,  page: 0, pageSize: 25}) 
        setLoading(false);
        //setStaffList(filteredData)
        //console.log("RowCount Search :", filteredData.length);
        // setTotalRows(filteredData.length);
        // setData(filteredData);
        // }
    }

    const captureKeyDown = (e) => {
        if (e.keyCode == 13) {
            e.preventDefault();
            handleSearch(e);
        }
    }

    // const handleSort = () => {
    //     if (staffListAscSort) {
    //         const sortedList = [...staffList].sort((a, b) => {
    //             if (a.fullName < b.fullName) {
    //                 return -1;
    //             }
    //             if (a.fullName > b.fullName) {
    //                 return 1;
    //             }
    //             return 0;
    //         });
    //         setStaffList(sortedList);
    //         setStaffListAscSort(false)

    //     }
    //     else {
    //         const sortedList = [...staffList].sort((a, b) => {
    //             if (a.fullName > b.fullName) {
    //                 return -1;
    //             }
    //             if (a.fullName < b.fullName) {
    //                 return 1;
    //             }
    //             return 0;
    //         });
    //         setStaffList(sortedList);
    //         setStaffListAscSort(true)
    //     }

    // };

    const fetchData = async (page, pageSize, searchBy, sortModel, searchColumn) => {
        // Perform server-side logic here (filtering, sorting, etc.)
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.data = {
            "page": page,
            "pageSize": pageSize,
            "homeId": params,
            "search": searchBy,
            "sortBy": sortModel,
            "searchColumn": searchColumn
        }
        //console.log("Sending Data : ",apiRequest.data);
        apiRequest.endPoint = "orgnization/getStaffList";
         apiUtility.sendAPIDataPOST(apiRequest).then(res => {
            var result = res.data;

            //console.log("Response Data : ",result.data);
            setStaffList(result.data)
            sethomeId(params)
            setTotalRows(result.totalCount);
            setHomeName(result.homeName);
            setHomeDefaultPay(result.default_to_pay);

            //console.log(homeDefaultPay,result.default_to_pay)
        }).catch(e => {
            console.log("Error: ", e.response.data);
            //setDataForErrorPopup(e.response.data);
            //setShowErrorPopup(true);
        })
    };

    const fetchDataFromServer = async () => {
        setLoading(true);
        await fetchData(paginationModel.page, paginationModel.pageSize, searchText, sortModel, "");
        setLoading(false);
    };

    const columns = [
        // Define your columns here
        {
            field: '_id',
            headerAlign: 'center',
            flex: 1,
            filterable: true,
            renderHeader: () => (
                <Typography sx={{ alignItems: "center" }} variant="subtitle1">
                    {'  ID'}
                </Typography>
            ),
            renderCell: (params) => (
                <div style={{ "display": "flex", "alignItems": "left", "justifyContent": "center", "padding": "0px", cursor: 'pointer' }} onClick={() => { handleStaffCourseRoleCheckList(params.row.user_id, params.row.home_id,params.row.firstName,params.row.lastName, params.row.fullName) }}>
                    <Tooltip title="View Details" disableRipple>
                        <IconButton sx={{ "cursor": "pointer" }} style={{ "display": "flex", "alignItems": "left", "justifyContent": "center", "padding": "0px", cursor: 'pointer' }} color="primary">
                            <OpenInNewOutlinedIcon />&nbsp;
                        </IconButton>
                    </Tooltip>
                    <Typography sx={{ alignItems: "center" }} variant="subtitle1">
                        {params.row._id}
                    </Typography>
                </div>
            )
        },
        {
            field: 'firstName',
            headerAlign: 'left',
            filterable: true,
            flex: 1,
            renderHeader: () => (
                <Typography variant="subtitle1">
                    {'First Name'}
                </Typography>
            ),
            renderCell: (params) => (
                <Typography variant="subtitle1" color="primary" align="center" >{params.row.firstName}&nbsp;</Typography>
            ),
        },
        {
            field: 'lastName',
            headerAlign: 'left',
            filterable: true,
            flex: 1,
            renderHeader: () => (
                <Typography variant="subtitle1">
                    {'Last Name'}
                </Typography>
            ),
            renderCell: (params) => (
                <Typography variant="subtitle1" color="primary" align="center" >{params.row.lastName}&nbsp;</Typography>
            ),
        },
        {
            field: 'role_arr',
            renderHeader: () => (
                <Typography variant="subtitle1">
                    {'Role'}
                </Typography>
            ),
            headerAlign: 'center',
            width: 620,
            filterable: true,
            sortable: true,
            renderCell: (params) => (
                <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                    <Grid container>
                        <Grid item md={12}>


                            <List sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                                position: 'relative',
                                '& ul': { padding: 0 },
                            }}>
                                {params.row.role_arr.map((val, _id) => {
                                    return (
                                        <ListItem sx={{ "padding": "0px" }} dense>
                                            <ListItemButton role={undefined} dense>
                                                <Grid container>
                                                    <Grid item md={4}>
                                                        <ListItemIcon sx={{ "padding": "0px" }}>
                                                            {(val.emp_status === "Active") ?
                                                                <IconButton color="primary" disableRipple sx={{ "padding": "0px" }}>
                                                                    <FiCheckCircle />&nbsp;<Typography variant="caption" display="block" align="left" color="primary" >{val.emp_status}</Typography>
                                                                </IconButton>
                                                                :
                                                                <IconButton disableRipple sx={{ "padding": "0px" }}>
                                                                    <FiXCircle />&nbsp;<Typography variant="caption" display="block" align="left" sx={{ color: 'text.secondary' }} >{val.emp_status}</Typography>
                                                                </IconButton>
                                                            }
                                                        </ListItemIcon>
                                                    </Grid>
                                                    <Grid item md={8} sx={{ "display": "flex", "alignItems": "center", "justifyContent": "center", "padding": "0px" }}>
                                                        <ListItemText id={val.role_id + "_" + params.row.fullName} >
                                                            {(val.emp_status == "Hold" || val.emp_status == "Archive") ?
                                                                <Typography variant="subtitle2" display="block" align="left" sx={{ color: 'text.secondary' }} ><span style={{ color: "grey" }}>{val.role_name}</span></Typography>
                                                                :
                                                                (val.emp_status == "Active" && val.isCompliant == false) ?
                                                                    <Typography variant="subtitle2" display="block" align="left" sx={{ color: 'text.primary' }}><span style={{ color: "red" }}>{val.role_name}</span></Typography>
                                                                    :
                                                                    <Typography variant="subtitle2" display="block" align="left" sx={{ color: 'text.primary' }}><span style={{ color: "#1976d2" }}>{val.role_name}</span></Typography>

                                                            }

                                                        </ListItemText>
                                                        <Tooltip title="Resend email" disableRipple><IconButton color="warning">
                                                            {(val.emp_status == "Hold") ?
                                                                <MdOutlineMailOutline onClick={() => resendEmail(params.row, val)} />
                                                                :
                                                                ""
                                                            }
                                                        </IconButton></Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </ListItemButton>
                                        </ListItem>)
                                })}
                            </List>
                        </Grid>
                        <Grid item md={12}>
                            <List sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                                position: 'relative',
                                paddingLeft: 2.5,
                                cursor: "pointer",
                                '& ul': { padding: 0 },
                            }}>
                                <Typography variant="caption" onClick={() => openAssignRole(params.row)} >+&nbsp; Assign New Role</Typography>
                            </List>
                        </Grid>
                    </Grid>
                </div>
            ),
        },
        {
            field: 'dateOfBirth',
            renderHeader: () => (
                <Typography variant="subtitle1">
                    {'Date of Birth'}
                </Typography>
            ),
            headerAlign: 'center',
            flex: 1,
            filterable: false,
            sortable: false,
            renderCell: (params) => (

                (<Typography variant="subtitle1" align="center">{(params.row.dateOfBirth !== "") ? ((([year, month, day]) => `${parseInt(month)}/${parseInt(day)}/${year}`)(params.row.dateOfBirth.split('-'))) : params.row.dob} </Typography>)
            )

        }
    ];

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: "xlsx",
            bookSST: false,
            type: "binary",
        };
        const wbout = XLSX.write(workbook, wopts);

        // The application/octet-stream MIME type is used for unknown binary files.
        // It preserves the file contents, but requires the receiver to determine file type,
        // for example, from the filename extension.
        const blob = new Blob([s2ab(wbout)], {
            type: "application/octet-stream",
        });
        return blob;
    };
    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; i++) {
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    async function getStaffData() {
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/getAllStaffList/" + params;
        await apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
            setStaffListAll(res.data)
        }).catch((error) => {
            setStaffListAll([]);
            setDataForErrorPopup("Somethinh went wrong!");
            setShowErrorPopup(true);
        });
    }


    //Excel
    const exportInExcel = async () => {
        try {
            var rowsCount = 6;
            var allStaffData = [];
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "orgnization/getAllStaffList/" + params;
            await apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
                allStaffData = res.data;
            }).catch((error) => {
                setStaffListAll([]);
                setDataForErrorPopup("Somethinh went wrong!");
                setShowErrorPopup(true);
                return;
            });
            const title = [{ A: 'Ontario Long Term Care Orientation Co-operative Webportal - Staff Report for ' + String(homeName) }];
            let reportView = [{ A: 'Name', B: 'User ID', C: 'Role', D: 'Date' }];
            reportView.push({
                A: sessionStorage.getItem('fullName'),
                B: sessionStorage.getItem('userId'),
                C: (sessionStorage.getItem('userType') !== null)?sessionStorage.getItem('userType').toUpperCase():"User",
                D: new Date().toLocaleString()
            });
            reportView.push("");
            reportView.push("");
            let reportHeaders = { A: 'User ID', B: 'First Name', C: 'Last Name', D: 'Role Status', E: 'Role Name', F: 'Date of Birth' };
            reportView.push(reportHeaders);
            allStaffData.forEach(element => {
                element.role_arr.forEach(data => {
                    rowsCount += 1;
                    var tempObj = {
                        A: element.user_id,
                        B: element.firstName,
                        C: element.lastName,
                        D: data.emp_status,
                        E: data.role_name,
                        F: element.dateOfBirth,
                    };
                    reportView.push(tempObj);
                });
            });
            const finalData = [...title, ...reportView];
            const worksheet = XLSX.utils.json_to_sheet(finalData, {
                skipHeader: true,
            });
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Staff Details");
            const workbookblob = workbook2blob(workbook);
            var headerIndexes = [];
            finalData.forEach((data, index) =>
                data["A"] === "User ID" ? headerIndexes.push(index) : null
            );
            const dataInfo = {
                titleCell: "A2",
                titleRange: "A1:G1",
                tbodyRange: `A7:F${finalData.length}`,
                uHeadRange: `A2:D2`,
                uBodyRange: `A3:D3`,
                theadRange:
                    headerIndexes?.length >= 1
                        ? `A${headerIndexes[0] + 1}:F${headerIndexes[0] + 1}`
                        : null,
                theadRange1:
                    headerIndexes?.length >= 2
                        ? `A${headerIndexes[1] + 1}:F${headerIndexes[1] + 1}`
                        : null,
                tFirstColumnRange:
                    headerIndexes?.length >= 1
                        ? `A${headerIndexes[0] + 1}:A${rowsCount + headerIndexes[0] + 1}`
                        : null,
                tLastColumnRange:
                    headerIndexes?.length >= 1
                        ? `K${headerIndexes[0] + 1}:G${rowsCount + headerIndexes[0] + 1}`
                        : null,

                tFirstColumnRange1:
                    headerIndexes?.length >= 1
                        ? `A${headerIndexes[1] + 1}:A${rowsCount + headerIndexes[1] + 1}`
                        : null,
                tLastColumnRange1:
                    headerIndexes?.length >= 1
                        ? `H${headerIndexes[0] + 1}:F${rowsCount + headerIndexes[1] + 1}`
                        : null,
            };
            XlsxPopulate.fromDataAsync(workbookblob).then((workbook) => {
                workbook.sheets().forEach((sheet) => {
                    sheet.column("A").width(15);
                    sheet.column("B").width(30);
                    sheet.column("C").width(30);
                    sheet.column("D").width(30);
                    sheet.column("E").width(40);
                    sheet.column("F").width(20);
                    sheet.range(dataInfo.titleRange).merged(true).style({
                        bold: true,
                        fontFamily: "Arial",
                        horizontalAlignment: "center",
                        verticalAlignment: "center",
                    });
                    sheet.range(dataInfo.uHeadRange).style({
                        fill: "A6A6A6",
                        bold: true,
                        horizontalAlignment: "center",
                    });
                    sheet.range(dataInfo.uBodyRange).style({
                        bold: true,
                        horizontalAlignment: "center",
                    });
                    if (dataInfo.tbodyRange) {
                        sheet.range(dataInfo.tbodyRange).style({
                            horizontalAlignment: "left",
                        });
                    }
                    sheet.range(dataInfo.theadRange).style({
                        fill: "A6A6A6",
                        bold: true,
                        horizontalAlignment: "center",
                    });
                    if (dataInfo.tFirstColumnRange) {
                        sheet.range(dataInfo.tFirstColumnRange).style({
                            bold: true,
                        });
                    }

                });

                return workbook.outputAsync().then((workbookBlob) => {
                    const downloadAnchorNode = document.createElement("a");
                    var dateVar = new Date();
                    var fileName = "LCPT_StaffDetails-" + dateVar.getDate() + dateVar.getMonth() + dateVar.getFullYear() + "_" + dateVar.getHours() + dateVar.getMinutes() + ".xlsx";
                    downloadAnchorNode.setAttribute("href", URL.createObjectURL(workbookBlob));
                    downloadAnchorNode.setAttribute("download", fileName);
                    downloadAnchorNode.click();
                    downloadAnchorNode.remove();
                    setDataForSuccessPopup("Downloaded Successfully");
                    setShowSuccessPopup(true);
                });
            });
        } catch (error) {
            setDataForErrorPopup("Something went wrong while processing excel!");
            setShowErrorPopup(true);
        }
    }
    
    const onFilterChange = async (filterModel) => {
        const re = /^[0-9\b]+$/;
        //setQueryOptions({ filterModel: { ...filterModel } });
        //console.log("FILTER Model : ", filterModel, filterModel.items.length);
        if (filterModel.items !== undefined && filterModel.items.length > 0 && filterModel.items[0].value !== undefined) {
            for(let item of filterModel.items){
                //console.log("Item Model : ", item);
                if(item.field === "_id" && !re.test(item.value) && item.value !== ""){
                    setDataForErrorPopup("Invalid search value!");
                    setShowErrorPopup(true);
                    continue;
                }
                if(item.field === "_id" && item.value !== "" && item.value.length < 4){
                    continue;
                }
                if(item.value === "")
                    await fetchData(paginationModel.page, paginationModel.pageSize, "", sortModel, "");    
                else
                    await fetchData(paginationModel.page, paginationModel.pageSize, item.value, sortModel, item.field);
            };
          }
    };



    return (
        <div className='org-container' disableEnforceFocus>
            <Row>
                <Col xs={12} md={5}>
                    <Typography variant="Subtitle2" display="block" align="left" sx={{ marginTop: "4px", color: 'text.secondary' }} >Staff Members for Home : {String(homeName)}</Typography>
                    {/* <Button variant='warning' style={{margin:"1%"}} onClick={exportInExcel}>Export to Excel</Button> */}
                </Col>

                <Col xs={12} md={5}>
                    <Paper component="form" variant="outlined"
                        sx={{ float: "right", display: 'flex', marginBottom: "4px", alignItems: 'center', width: 450 }}
                    >

                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search by Firstname or Lastname"
                            inputProps={{ 'aria-label': 'search by last or first' }}
                            value={searchText}
                            onChange={(e) => {
                                setSearchText(e.target.value);
                            }}
                            onKeyDown={captureKeyDown}
                        />
                        <Tooltip variant="contained" title="Search" disableRipple>
                            <IconButton type="button" onClick={handleSearch} sx={{ p: '10px' }} aria-label="search">
                                <ManageSearchIcon />
                            </IconButton>
                        </Tooltip>
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <Tooltip variant="contained" title="Reset Grid" disableRipple>
                            <IconButton color="primary" onClick={clearFormatting} sx={{ p: '10px' }} aria-label="directions">
                                <ClearAllIcon />
                            </IconButton>
                        </Tooltip>
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <Tooltip variant="contained" title="Export to Excel" disableRipple>
                            <IconButton color="primary" onClick={exportInExcel} sx={{ p: '10px' }} aria-label="directions">
                                <RiFileExcel2Line />
                            </IconButton>
                        </Tooltip>

                    </Paper>
                </Col>
                <Col xs={12} md={2}>
                    <Button style={{ float: "left", marginTop: "4px", marginRight: "1%", marginBottom: "1%" }} variant="warning" onClick={handleShowStaff}>Add Staff</Button>
                </Col>
            </Row>

            <CustomDataGrid
                loading={loading}
                rowCount={totalRows}
                rows={staffList}
                columns={columns}
                slots={{
                    loadingOverlay: LinearProgress
                }}
                //pageSize={pageSize}
                pageSizeOptions={[10, 25, 50, 100]}
                paginationMode="server"

                componentsProps={{ panel: { disablePortal: true } }}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                }}

                sortingMode="server"
                onSortModelChange={setSortModel}
                rowSelectionModel={rowSelectionModel}
                filterMode="server"
                onFilterModelChange={onFilterChange}
                getRowId={(row) => row._id}

                sx={{ p: 0 }}
                //autoPageSize={true} // Set automatic page size
                autoHeight={true} // Set automatic height
                getRowHeight={() => 'auto'}

            />

            <Modal show={showAssignRole} onHide={handleCloseAssignRole} id="addHomeInOrg">
                <Modal.Header closeButton>
                    <Modal.Title>Assign Position</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Form.Group as={Col} className="mb-2" controlId="formBasicUsername">
                                <Form.Label>Staff Name</Form.Label>
                                <Form.Control type="input" value={positionDetail.user_name} readOnly disabled />
                            </Form.Group>
                        </Row>
                        <br></br>
                        <Row>
                            <Form.Group as={Col} className="mb-2" controlId="formBasicUserId">
                                <Form.Label>Staff User Id </Form.Label>
                                <Form.Control type="input"
                                    value={positionDetail.user_id} readOnly disabled
                                />
                            </Form.Group>
                            <Form.Group as={Col} className="mb-2" controlId="formBasicRole">
                                <Form.Label>Role Name</Form.Label>
                                <Form.Select aria-label="Default select example"
                                    onChange={(e) => {
                                        addStaffRole(e, positionDetail.user_id, positionDetail.home_id, positionDetail.dob,'');
                                    }}
                                >
                                    <option value={-1}>Select Role</option>
                                    {roleDetails?.map((item, _id) => {
                                        return (hideRoles.some(d => (d.role_id === item.role_id)||item.archived==="True"||item.archived===true)) ? <option value={_id} disabled>{item.role_name} </option> : <option value={_id}>{item.role_name} </option>

                                    })}
                                </Form.Select>
                                <input style={{margin:"2%",transform:"scale(1.3)"}} type="checkbox" defaultChecked={sessionStorage.getItem('defaultPay')?JSON.parse(sessionStorage.getItem('defaultPay')):homeDefaultPay} onChange={(e) => {
                                        addStaffRole(e, positionDetail.user_id, positionDetail.home_id, positionDetail.dob,'homeDefaultPay');
                                    }}></input>
                            <Form.Label>Pay for training</Form.Label>

                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAssignRole}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={saveAssignRoleDetail}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* <Modal show={showPosition} onHide={handleClosePosition}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Position</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-2 col-xs-6" controlId="formBasicEmail">
                            <Form.Label>Position Name</Form.Label>
                            <Form.Control type="text"
                                onChange={(e) => {
                                    addPositionText(e, 'pos1');
                                }}
                            />
                            <Form.Label>Position ID</Form.Label>
                            <Form.Control type="text"
                                onChange={(e) => {
                                    addPositionText(e, 'pos2');
                                }}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseStaff}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={savePositionDetail}  >
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal> */}
            <Modal show={showStaff} onHide={handleCloseStaff} className="custom-map-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Add New Staff Member</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            {/* <Form.Group as={Col} className="mb-2" controlId="formBasicUserId">
                                <Form.Label>User ID</Form.Label>
                                <Form.Control type="input" placeholder='Please enter User Id'
                                    onChange={(e) => {
                                        addStaffText(e, 'st1');
                                    }}
                                />

                            </Form.Group>
                            <Form.Group as={Col} className="mb-2" controlId="formBasicEmail">
                                <Form.Label>User Email</Form.Label>
                                <Form.Control type="input" placeholder='Please enter User Email'
                                    onChange={(e) => {
                                        addStaffText(e, 'st6');
                                    }}
                                />
                            </Form.Group> */}
                            <Paper component="form" variant="outlined" elevation={3}
                                sx={{ display: 'flex', marginBottom: "4px", alignItems: 'center' }}
                            >
                                <Form.Group as={Col} className="mb-2" controlId="formBasicUserId">
                                    <Form.Label>User ID</Form.Label>
                                    <Form.Control type="input" placeholder='Please enter User Id'
                                        onChange={(e) => {
                                            addStaffText(e, 'st1');
                                        }}
                                    />

                                </Form.Group>
                                <Divider orientation="vertical" sx={{ color: "#1976d2", borderRadius: 2 }} variant="middle" flexItem>OR</Divider>

                                <Form.Group as={Col} className="mb-2" controlId="formBasicEmail">
                                    <Form.Label>User Email</Form.Label>
                                    <Form.Control type="input" placeholder='Please enter User Email'
                                        onChange={(e) => {
                                            addStaffText(e, 'st6');
                                        }}
                                    />
                                </Form.Group>
                            </Paper>
                        </Row>
                        <br></br>
                        <Row>
                            <Form.Group as={Col} controlId="firstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="input" placeholder="Please enter user's First Name"
                                    onChange={(e) => {
                                        addStaffText(e, 'st2');
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="lastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="input" placeholder="Please enter user's Last Name"
                                    onChange={(e) => {
                                        addStaffText(e, 'st5');
                                    }}
                                />
                            </Form.Group>
                        </Row>
                        <br></br>
                        <Row>
                            <Form.Group as={Col} controlId="dob">
                                <Form.Label>Date of Birth</Form.Label>
                                <Form.Control type="date"
                                    onChange={(e) => {
                                        addStaffText(e, 'st3');
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="role">
                                <Form.Label>Role</Form.Label>
                                <Form.Select aria-label="Default select example" onChange={(e) => {
                                    addStaffText(e, 'st4');
                                }} >
                                    <option value={""}> -- Please select a Role for User -- </option>
                                    {roleDetails.map((item, _id) => {
                                        return (item.archived==="False"||item.archived===false) ? 
                                        <option value={item.role_name}>
                                            {item.role_name}
                                        </option>:null;
                                    })}
                                </Form.Select>
                                
                                
                                <input style={{margin:"2%",transform:"scale(1.3)"}} type="checkbox" defaultChecked={sessionStorage.getItem('defaultPay')?JSON.parse(sessionStorage.getItem('defaultPay')):homeDefaultPay} onChange={(e) => {
                                    addStaffText(e, 'st8');
                                }}></input>
                            <Form.Label>Pay for training</Form.Label>
                            </Form.Group>

                            
                        </Row>
                    </Form>
                    <div>
                        {errors ? (
                            <p className="text-danger">*All fields are mandatory</p>

                        ) : (
                            <p className="text-danger"></p>

                        )}
                    </div>
                    <div>
                        {ageError ? (
                            <p className="text-danger">*Age must be minimum 10 years</p>

                        ) : (
                            <p className="text-danger"></p>

                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseStaff}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={saveStaffDetail} >
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={checkListStaffModal} onHide={handleCloseCourseCheckListStaffModal} >
                <Modal.Body>
                    <StaffRoleCourseChecklistComponent homeId={staffHomeId} userId={staffUserId} fullName={staffFullName} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCourseCheckListStaffModal}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
            <SuccessPopUp show={showSuccessPopup} data={dataForSuccessPopup} onHide={() => { setShowSuccessPopup(false) }} />
            <ErrorPopUp show={showErrorPopup} data={dataForErrorPopup} onHide={() => { setShowErrorPopup(false) }} />

        </div>);
}
