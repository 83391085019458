import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Modal } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { IconButton, Tooltip } from '@mui/material';
import * as apiUtility from '../../config/APIUtils';
import SuccessPopUp from '../Utility/SuccessPopUp';
import ErrorPopUp from '../Utility/ErrorPopUp';
import { FiCheckCircle, FiXCircle } from 'react-icons/fi';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import RemoveShoppingCartOutlinedIcon from '@mui/icons-material/RemoveShoppingCartOutlined';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import DeleteIcon from '@mui/icons-material/Delete';
import { Card, Button, CircularProgress, Typography, List, ListItem, Table, Box, Divider, LinearProgress, ListItemDecorator, CardActions, Accordion, AccordionSummary, AccordionDetails } from '@mui/joy';
import CardContent from '@mui/joy/CardContent';
import LaunchIcon from '@mui/icons-material/Launch';
import Check from '@mui/icons-material/Check';
import HistoryIcon from '@mui/icons-material/History';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { useDispatch } from 'react-redux';
import { handleCartUpdate, storeTrainingCart } from '../../config/UserSlice';


function UserTrainingCart(props) {
    var paramId = useParams().id;
    var propsId = props.userId;
    var params = propsId;
    if (!propsId) {
        params = paramId;
    }
    var homeTypes = JSON.parse(sessionStorage.getItem("home_types"));
    var propsCourseList = props.cartCourseList;
    const dispatch = useDispatch();

    // const userTrainingCartSelector = useSelector((state) => state.slice.trainingCart);
    // const userTrainingCartSelector2 = useSelector(storeTrainingCart);
    //console.log("Training Cart from : ", useSelector(storeTrainingCart));
    

    const [showSuccess, setShowSuccess] = useState(false);
    const [successMsg, setsuccessMsg] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const [waitBlock, setWaitBlock] = useState(false);
    const [pendingCourseList, setPendingCourseList] = useState([]);
    const [approveCourseList, setApproveCourseList] = useState([]);
    const [rejectedCourseList, setRejectedCourseList] = useState([]);
    const [activeApproveCourseList, setActiveApproveCourseList] = useState([]);
    const [approvedCount, setApprovedCOunt] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [submissionCountBox, setSubmissionCountBox] = useState(false);
    const [submisionCountMsg, setSubmisionCountMsg] = useState("Submitted");

    const URL_POST_VALIDATE_CRS_GUIDELINE = "user/validate-user-course-submission";
    const URL_UPDATE_TRAINING_CART = "user/update-pending-course-cart";
    const URL_DELETE_REJCTD_FROM_CART = "user/delete-rejected-course-cart";

    var training_providers = JSON.parse(sessionStorage.getItem("training_providers"));
    var mapOfHomeType = {};
    homeTypes.forEach(element => {
        mapOfHomeType[element.id] = element.value;
    });

    var mapOfTpIdName = {};
    if (training_providers !== undefined && training_providers !== null) {
        training_providers?.forEach(element => {
            mapOfTpIdName[element.tp_id] = element.provider_name;
        });
    } else {
        mapOfTpIdName["1001"] = "Lambton College";
    }

    useEffect(() => {
        if (propsCourseList != undefined) {
            setPendingCourseList((propsCourseList["pending"] != undefined && propsCourseList["pending"].length > 0) ? propsCourseList["pending"] : []);
            setActiveApproveCourseList((propsCourseList["active-approved"] != undefined && propsCourseList["active-approved"].length > 0) ? propsCourseList["active-approved"] : []);
            setApproveCourseList((propsCourseList["approved"] != undefined && propsCourseList["approved"].length > 0) ? propsCourseList["approved"] : []);
            setRejectedCourseList((propsCourseList["rejected"] != undefined && propsCourseList["rejected"].length > 0) ? propsCourseList["rejected"] : []);
        }
        //console.log("Props Course list : ",propsCourseList);
       
    }, [props.count]);

    const addCourseToPending = (rowData) => {
        delete rowData.reason;
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = URL_UPDATE_TRAINING_CART;
        apiRequest.data = {
            "userId": params,
            "courseObj": rowData,
            "isRejected": true,
            "isRemovePending": false
        }
        apiUtility.sendAPIDataPOST(apiRequest).then(response => {
            if (response.data.alreadyExist) {
                setErrorMsg("Course is already added to Cart.");
                setShowErrorMsg(true);
            } else {
                let obj = {
                    type: "addedFromRejected",
                    data: rowData
                }
                dispatch(handleCartUpdate(obj));
                props.handleCourseCartCallback(rowData, true, false, false);
                setsuccessMsg("Added Successfully");
                setShowSuccess(true);
            }
        });
    }

    const deleteFromRejected = (rowData) => {
        delete rowData.reason;
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = URL_DELETE_REJCTD_FROM_CART;
        apiRequest.data = {
            "userId": params,
            "courseVersionId": rowData.courseVersionId
        }
        apiUtility.sendAPIDataPOST(apiRequest).then(response => {
            if (response.data.status) {
                let obj = {
                    type: "deletedFromRejected",
                    data: rowData
                }
                dispatch(handleCartUpdate(obj));
                props.handleCourseCartCallback(rowData, false, false, true);
                setsuccessMsg("Course Removed");
                setShowSuccess(true);
            }
        });
    }

    const removeFromPendingCart = (rowData) => {
        delete rowData.reason;
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = URL_UPDATE_TRAINING_CART;
        apiRequest.data = {
            "userId": params,
            "courseObj": rowData,
            "isRejected": false,
            "isRemovePending": true
        }
        apiUtility.sendAPIDataPOST(apiRequest).then(response => {
            if (response.data.alreadyExist) {
                setErrorMsg("Processing error");
                setShowErrorMsg(true);
            } else {
                let obj = {
                    type: "isRemovedFromPending",
                    data: rowData
                }
                dispatch(handleCartUpdate(obj));
                props.handleCourseCartCallback(rowData, false, true, false);
                setsuccessMsg("Removed");
                setShowSuccess(true);
            }
        });
    }

    const validateCourseVersion = () => {
        setWaitBlock(true);
        if (pendingCourseList == undefined || pendingCourseList.length == 0) {
            setErrorMsg("No pending courses available");
            setShowErrorMsg(true);
            setWaitBlock(false);
        } else {
            setTotalCount(pendingCourseList.length);
            let totalLength = pendingCourseList.length;
            let apiRequest = apiUtility.apiInputRequest;
            apiRequest.data = { "userId": params, "cartCourseArr": pendingCourseList }
            apiRequest.endPoint = URL_POST_VALIDATE_CRS_GUIDELINE;
            apiUtility.sendAPIDataPOST(apiRequest).then(response => {
                setTimeout(
                    () => {
                        // let cart = [];
                        let appCount = 0;
                        response.data.responseMap.forEach(element => {
                            if (element.status){
                                appCount++;
                            }
                        });
                        setApprovedCOunt(appCount);
                        // setActiveApproveCourseList(cart);
                        // console.log("Active approved  : ",cart);
                        props.updatedCartAfterSubmission(response.data.responseMap);
                        setWaitBlock(false);
                        //set("Processed Successfully");
                        setSubmissionCountBox(true);
                        if(appCount === 0){
                            setSubmisionCountMsg('All registration requests rejected!')
                        }else if(appCount === totalLength){
                            setSubmisionCountMsg('All registration requests successfully submitted.')
                        }else{
                            setSubmisionCountMsg(appCount + ' approved out of total '+totalLength+ ' courses.')
                        }
                    }, 1000);
            }).catch(() => {
                setErrorMsg("Error while processing cart");
                setShowErrorMsg(true);
                setWaitBlock(false);
            });
        }
    }

    return (
        <div>
            <Container fluid>
                <Row>
                    <Col>
                        <Row>
                            <Card variant="plain" style={{ "border-radius": "1.5px" }}>
                                <CardContent>
                                    <Typography level="title-lg"><AccessTimeIcon color="primary" style={{ "fontSize": "1.5pc" }} /> &nbsp;Pending </Typography>
                                    <br></br>
                                    <Row>
                                        <Typography level="body-md">
                                            <Table borderAxis="x" size="md" style={{ "border-radius": "1.5px" }} stickyHeader variant="plain">
                                                <thead>
                                                    <tr color='primary'>
                                                        <th color='primary'>Course Code</th>
                                                        <th color='primary'>Version Code&nbsp;</th>
                                                        <th>Version No</th>
                                                        <th>Training Provider</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(pendingCourseList.length > 0) ?
                                                        pendingCourseList.map((row) => (
                                                            <tr key={row.courseId}>
                                                                <td>{row.courseId}</td>
                                                                <td>{row.tp_course_code}</td>
                                                                <td>{row.versionNo}</td>
                                                                <td>{mapOfTpIdName[parseInt(row.tp_id)]}</td>
                                                                <td><Tooltip variant="contained" title="Remove from Cart">
                                                                    <IconButton color="primary" onClick={() => { removeFromPendingCart(row) }} aria-label="directions-down">
                                                                        <RemoveShoppingCartOutlinedIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                </td>
                                                            </tr>
                                                        )) :
                                                        <tr>
                                                            <td colSpan={"5"} style={{ textAlign: 'center' }}>
                                                                <Typography variant='caption' color='disabled'>
                                                                    <ProductionQuantityLimitsIcon color='disabled' /> No course data available
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </Table>
                                            {(waitBlock) ?
                                                <div>
                                                    <LinearProgress />
                                                </div>
                                                : ""}
                                        </Typography>

                                    </Row>
                                </CardContent>
                                <CardActions sx={{ display: 'flex', gap: 4.5, '& > button': { flex: 1 } }}>
                                    <br></br>
                                    <List size={'xs'}>
                                        <ListItem>
                                            <ListItemDecorator>
                                                <Check color='success' />
                                            </ListItemDecorator>User should be associated with home for added course.
                                        </ListItem>
                                        <ListItem>
                                            <ListItemDecorator>
                                                <Check color='success' />
                                            </ListItemDecorator>Training provider should offer direct registration.
                                        </ListItem>
                                        <ListItem>
                                            <ListItemDecorator>
                                                <Check color='success' />
                                            </ListItemDecorator>Home policy guidelines will be applied for every submissions.
                                        </ListItem>
                                    </List>

                                    <Button style={{ "border-radius": "1.5px", "float": "right" }} onClick={() => validateCourseVersion()}>Submit</Button>
                                    <br></br>
                                </CardActions>
                            </Card>
                        </Row>
                        <br></br>

                        <Row>
                            <Col xs={12} md={6}>
                                <Card variant="soft" >
                                    <CardContent>
                                        <Typography level="title-lg"><FiCheckCircle style={{ "color": "green", "fontSize": "1.5pc" }} /> &nbsp;Approved</Typography>
                                        <br></br>
                                        {activeApproveCourseList.map((row) => (
                                            <Card orientation="horizontal" variant="outlined" sx={{ maxWidth: '100%', gap: 1.5 }}>
                                                <Typography fontSize="md" fontWeight="md" color="success">{row.courseId}<br></br>
                                                    <Typography level="body-xs">{row.tp_course_code}</Typography>
                                                    <br></br>

                                                </Typography>
                                                <br></br>
                                                <Divider />
                                                <Box sx={{ orientation: 'horizontal' }}>
                                                    <Typography level="body-sm" >
                                                        <List size={'xs'}>
                                                            <ListItem><b>Training Provider</b> &nbsp;- {mapOfTpIdName[parseInt(row.tp_id)]}</ListItem>
                                                            <ListItem><b>Training Duration</b> &nbsp;- {row.trainDuration}</ListItem>
                                                            <ListItem><b>Last Submitted</b> &nbsp;&nbsp;&nbsp;&nbsp;- {new Date(row.submitted_date).toLocaleDateString() + " " + new Date(row.submitted_date).toLocaleTimeString()}</ListItem>
                                                        </List>
                                                    </Typography>
                                                </Box>
                                            </Card>
                                        ))}
                                        <br></br>
                                        <Accordion defaultExpanded={false}>
                                            <AccordionSummary expandIcon={<GridExpandMoreIcon />} aria-controls={"primary-contact-content"}>
                                                <Typography fontSize="sm">
                                                    <><HistoryIcon color="primary" style={{ "fontSize": "1.5pc" }} /> &nbsp;History</>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <br></br>
                                                {approveCourseList.map((row) => (
                                                    <Card orientation="horizontal" variant="outlined" sx={{ maxWidth: '100%', gap: 1.5 }}>
                                                        <Typography fontSize="md" fontWeight="md" color="success">{row.courseId}<br></br>
                                                            <Typography level="body-xs">{row.tp_course_code}</Typography>
                                                            <br></br>
                                                        </Typography>
                                                        <br></br>
                                                        <Divider />
                                                        <Box sx={{ orientation: 'horizontal' }}>
                                                            <Typography level="body-sm" >
                                                                <List size={'xs'}>
                                                                    <ListItem><b>Training Provider</b> &nbsp;- {mapOfTpIdName[parseInt(row.tp_id)]}</ListItem>
                                                                    <ListItem><b>Training Duration</b> &nbsp;- {row.trainDuration}</ListItem>
                                                                    <ListItem><b>Last Submitted</b> &nbsp;&nbsp;&nbsp;&nbsp;- {new Date(row.submitted_date).toLocaleDateString() + " " + new Date(row.submitted_date).toLocaleTimeString()}</ListItem>
                                                                </List>
                                                            </Typography>
                                                        </Box>
                                                    </Card>
                                                ))}
                                            </AccordionDetails>
                                        </Accordion>
                                    </CardContent>
                                </Card>
                            </Col>
                            <Col xs={12} md={6}>
                                <Card variant="soft">
                                    <CardContent>
                                        <Typography level="title-lg"><FiXCircle style={{ "color": "red", "fontSize": "1.5pc" }} /> &nbsp;Rejected</Typography>
                                        <br></br>
                                        {rejectedCourseList.map((row) => (
                                            <Card orientation="horizontal" variant="outlined" sx={{ maxWidth: '100%', gap: 1.5 }}>
                                                <Typography fontSize="md" fontWeight="md" color="danger">{row.courseId}<br></br>
                                                    <Typography level="body-xs">{row.tp_course_code}</Typography>
                                                    <br></br>
                                                    <br></br>
                                                    <Tooltip variant="contained" title="Add to cart">
                                                        <IconButton color='primary' onClick={() => { addCourseToPending(row) }} aria-label="directions-down">
                                                            <AddShoppingCartIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip variant="contained" title="Visit">
                                                        <IconButton color='primary' onClick={() => { window.open(row.training_url, "_blank") }} aria-label="directions-down">
                                                            <LaunchIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip variant="contained" title="Remove">
                                                        <IconButton onClick={() => { deleteFromRejected(row) }} aria-label="directions-down">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>


                                                </Typography>
                                                <br></br>

                                                <Divider />
                                                <Box sx={{ orientation: 'horizontal', width: '60%', maxWidth: '60%' }}>
                                                    <Typography level="body-sm" >
                                                        <List size={'xs'}>
                                                            <ListItem><b>Training Provider</b> &nbsp;- {mapOfTpIdName[parseInt(row.tp_id)]}</ListItem>
                                                            <ListItem><b>Last Submitted</b> &nbsp;&nbsp;&nbsp;&nbsp;- {new Date(row.submitted_date).toLocaleDateString() + " " + new Date(row.submitted_date).toLocaleTimeString()}</ListItem>
                                                        </List>
                                                        <br></br>
                                                    </Typography>

                                                    <Typography level="body-xs">
                                                        <List size={'xs'}><ListItem ><b>Failure Reasons</b></ListItem>
                                                            {row?.reason?.map((rw) => (
                                                                <List marker="circle" size={'xs'}>
                                                                    <ListItem> <Typography level="body-xs">{rw}</Typography></ListItem>
                                                                </List>
                                                            ))}
                                                        </List>
                                                    </Typography>
                                                </Box>
                                            </Card>
                                        ))}
                                    </CardContent>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br></br>
                <br></br>
            </Container>
            <SuccessPopUp show={showSuccess} data={successMsg} onHide={() => { setShowSuccess(false) }} />
            <ErrorPopUp show={showErrorMsg} data={errorMsg} onHide={() => { setShowErrorMsg(false) }} />
            <Modal show={submissionCountBox} centered style={{ "border-radius": "0px", "width": "20pc", "top": "0%", "left": "40%" }} onHide={() => setSubmissionCountBox(false)} closeButton>
                <Modal.Body className='text-center'>
                            <CircularProgress size="lg" 
                            color={(approvedCount < totalCount )?"danger":"primary"}
                            sx=
                            {{  "--CircularProgress-size": "100px",
                                "--CircularProgress-trackThickness": "10px",
                                "--CircularProgress-progressThickness": "4px" }}
                            determinate value={((approvedCount ===  totalCount)?(approvedCount):(totalCount - approvedCount)) / (totalCount) * 100}>
                                    {((approvedCount ===  totalCount)?(approvedCount):(totalCount - approvedCount)) } / {totalCount}
                            </CircularProgress>
                            <br></br>
                            <br></br>
                            {(approvedCount > 0 && approvedCount < totalCount)?
                                <>
                                    <Typography gutterBottom color={"danger"} level='body-sm'>{(totalCount - approvedCount) +" registration request rejected" }</Typography>
                                    <Typography gutterBottom color={"primary"} level='body-sm'>{approvedCount + " registration request successfully submitted"}</Typography>
                                </>
                                
                                :
                                <Typography gutterBottom color={(approvedCount === 0 )?"danger":"primary"} level='body-md'>{submisionCountMsg}</Typography> 
                            }
                            
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default UserTrainingCart;



