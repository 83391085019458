
import './organisation.css';
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { Link } from 'react-router-dom'
import { BASE_API_URL } from '../../config/Url-config';
import { BASE_URL_FRONTEND } from '../../config/Url-config';
import StaffComponent from './StaffComponent';
import HomeCheckListComponent from './HomeCheckListComponent';
import Modal from 'react-bootstrap/Modal'
import { Form, Row, Col, Button, Container, FormText } from 'react-bootstrap';
import * as apiUtility from "../../config/APIUtils";


export default function HomeDetailComponent(props) {
    var nparams = useParams().id
    
    var paramId = props.homeIdForAdmin;
    var propsId = props.homeIdForAdmin;
    var params = propsId;
    if (props.id) {
        params = props.id
    }
    if(propsId)
    paramId = propsId
   
    const [id, setId] = useState('')

    const [showStaffModal, setShowStaffModal] = useState(false);
    const handleCloseStaff = () => setShowStaffModal(false);
    // const handleShowStaff = () => setShowStaffModal(true);
    const [showCheckListModal, setShowCheckListModal] = useState(false);
    const handleCloseCheckList = () => setShowCheckListModal(false);
    const [homeDetails, setHomeDetails] = useState({ id: params });
    const [homeList, setHomeList] = useState([])
    const [errors, setErrors] = useState(false)
    const [isHomeAdmin, setIsHomeAdmin] = useState(true);
    const [missingHome, setMissingHome] = useState(false);
    const accessToken = sessionStorage.getItem('authToken');
    const headers = {
        Authorization: `Bearer ${accessToken}`,
      };


    useEffect(() => {
        if (sessionStorage.getItem("OtherOrgId") || sessionStorage.getItem("userType") == "admin") {
            setIsHomeAdmin(false)
        }
        else {
            setIsHomeAdmin(true);
        }
        // if (!sessionStorage.getItem("homeId") && sessionStorage.getItem("userType") != 'admin') {
        //     alert("Sorry.Access Not Permitted")
        //     return window.location.href = BASE_URL_FRONTEND;

        // }
        // if (sessionStorage.getItem("allHomeArr") && sessionStorage.getItem("homeId") && sessionStorage.getItem("userType") != 'admin') {
        //     var flag = false;
        //     for (var i = 0; i < JSON.parse(sessionStorage.getItem("allHomeArr")).length; i++) {
        //         if (sessionStorage.getItem("homeId") == JSON.parse(sessionStorage.getItem("allHomeArr"))[i]) {
        //             //sessionStorage.setItem("homeId",String(params))
        //             flag = true;
        //             break;
        //         }
        //     }
        //     if (!flag) {
        //         alert("Sorry.Access Not Permitted")
        //         return window.location.href = BASE_URL_FRONTEND;
        //     }
        // }
        if (!sessionStorage.getItem("allHomeArr") && sessionStorage.getItem("userType") != 'admin') {
            alert("Sorry.Access Not Permitted")
            return window.location.href = BASE_URL_FRONTEND;

        }

        if (!params)
            params = String(nparams)
        //console.log(String(params))
        if (sessionStorage.getItem("allHomeArr") && sessionStorage.getItem("userType") != 'admin') {
            var flag = false;
            for (var i = 0; i < JSON.parse(sessionStorage.getItem("allHomeArr")).length; i++) {
                if (String(params) == JSON.parse(sessionStorage.getItem("allHomeArr"))[i]) {
                    sessionStorage.setItem("homeId", String(params))
                    flag = true;
                    break;
                }
            }
            if (!flag) {
                alert("Sorry.Access Not Permitted")
                return window.location.href = BASE_URL_FRONTEND;
            }
        }
        sessionStorage.setItem("homeId", props.homeIdForAdmin)
        if (!paramId)
            paramId = params



        // if (sessionStorage.getItem("userType") === 'home') {

        //     // //console.log(JSON.parse(sessionStorage.getItem("OtherOrgId")))
        //     var flag = false;
        //     for (var i = 0; i < JSON.parse(sessionStorage.getItem("allHomeArr")).length; i++) {
        //         if (String(paramId) == JSON.parse(sessionStorage.getItem("allHomeArr"))[i]) {
        //             //console.log("found", String(paramId), JSON.parse(sessionStorage.getItem("allHomeArr"))[i])
        //             flag = true;
        //             break;
        //         }
        //     }
        //     if (!flag) {
        //         alert("Sorry.Access Not Permitted")
        //         return window.location.href = BASE_URL_FRONTEND;
        //     }
        // }

        if (sessionStorage.getItem("userType") === 'admin') {
            setHomeList(["admin"], ...homeList)
        }
        else {
            setHomeList(JSON.parse(sessionStorage.getItem("allHomeArr")), ...homeList)
        }
        // if(String(useParams.id())!=sessionStorage.getItem("homeId")){
        //     alert("Sorry.Access Not Permitted")
        //     return window.location.href = BASE_URL_FRONTEND;
        // }
        ////console.log("home list", homeList)
        getHomeInitialData()

      
    }, [props.homeIdForAdmin])

    async function getHomeInitialData(){
        const gethomeDetailsUrl = BASE_API_URL + "orgnization/getHomeDetails/" + paramId
        var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint =  "orgnization/getHomeDetails/" + paramId;
           // apiRequest.data = permissionObj;
            apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
                if (res == 'Something went wrong!' || res == 'No Home Found!'){
                    console.log("No data")
                    setMissingHome(true)
                }
                   
                else {
                    setMissingHome(false)
                    setHomeDetails(res.data[0])
                    document.getElementById("homeEditName").value = res.data[0].name
                    document.getElementById("homeEditConFName").value = res.data[0].contact_firstName
                    document.getElementById("homeEditConLName").value = res.data[0].contact_lastName
                    document.getElementById("homeEditConNum").value = res.data[0].phone_no
                    document.getElementById("homeEditConEmail").value = res.data[0].email_id
                }
               
            }).catch((error) => {
                    console.error(error);
            });
            
    }

    function changeHomeText(event, id) {
        //console.log(event.target.value)
        let newHomeDetailObj = {};
        newHomeDetailObj = { ...homeDetails };
        if (id === 'det1')
            newHomeDetailObj.name = event.target.value;
        else if (id === 'det2')
            newHomeDetailObj.contact_firstName = event.target.value;
        else if (id === 'det3')
            newHomeDetailObj.contact_lastName = event.target.value;
        else if (id === 'det4')
            newHomeDetailObj.phone_no = event.target.value;
        else if (id === 'det5')
            newHomeDetailObj.email_id = event.target.value;
        setHomeDetails(homeDetails => ({
            ...homeDetails, ...newHomeDetailObj
        }));

        setHomeDetails(newHomeDetailObj);
        ////console.log("home details:", homeDetails)

    }
    async function saveHomeText() {
        ////console.log("In saveText")
        ////console.log(homeDetails)
        if (homeDetails.name && homeDetails.contact_firstName && homeDetails.contact_lastName && homeDetails.phone_no && homeDetails.email_id) {
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint =  "orgnization/editHomeDetails";
            apiRequest.data = { homeDetails };
            apiUtility.updateAsyncAPIDataPUT(apiRequest).then(res => {
                alert("Details Updated!")    
            }).catch((error) => {
                    console.error(error);
                    alert("Something went wrong!")
                  });
            // if(res){
            //     alert("Details Updated!")
            // }
            // else{
            //     alert("Something went wrong!")
            // }
            // axios.put(BASE_API_URL + "orgnization/editHomeDetails", { homeDetails },{headers})
            //     .then(res => {
            //         ////console.log(res);
            //         alert("Details Updated!")

            //     })
            //     .catch(err => {
            //         alert("Something went wrong!")
            //         //console.log(err);
            //     })
        }
        else {
            setErrors(true);
        }
    }
    function changeHome(event) {
        ////console.log(event.target.value)
        sessionStorage.setItem("homeId", event.target.value)
        return window.location.href = BASE_URL_FRONTEND + "home/" + String(event.target.value);
    }
    function handleShowStaff(event, id) {
        setId(id);
        setShowStaffModal(true);
    }
    function handleShowCheckList(event, id) {
        setId(id);
        setShowCheckListModal(true);
    }

    return (

        <div style={{ marginTop: "10vh" }} >
            {/* <h1>Home : {params} </h1> */}
            {/* <div>
                {homeList[0] === 'admin' ? (
                    <div></div>
                ) : (
                    <Form>
                        <Form.Group className="mb-2 col-xs-6" controlId="formBasicEmail" hidden>
                            <Form.Label>Select a Home</Form.Label>
                            <Form.Select style={{ width: "25%" }} aria-label="Default select example" onChange={(e) => {
                                changeHome(e);
                            }} >
                                <option>Select</option>
                                {homeList.map((item, _id) => {
                                    return <option value={item}>
                                        {item}
                                    </option>
                                })}
                            </Form.Select>
                        </Form.Group>
                    </Form>
                )}
            </div> */}
            {/* {!isHomeAdmin ? (<div></div>) :
                <div style={{ textAlign: "center", marginRight: "5%" }}>
                    <Button style={{ marginRight: "1%" }} variant="warning" onClick={(e) => {
                        handleShowStaff(e, paramId);
                    }} >View Staff Members</Button>
                    <Button variant="warning" onClick={(e) => {
                        handleShowCheckList(e, paramId);
                    }}>View CheckList Component</Button></div>} */}

{/* 
            <Modal show={showStaffModal} onHide={handleCloseStaff} className=" custom-map-modal">
                <Modal.Header closeButton>
                    <Modal.Title> Staff Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <StaffComponent id={id} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseStaff}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
            <Modal show={showCheckListModal} onHide={handleCloseCheckList} className="custom-map-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Home CheckList Component</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HomeCheckListComponent id={id} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCheckList} >
                        Close
                    </Button>

                </Modal.Footer>
            </Modal> */}
            {missingHome ? <div> </div> : 
            <div>
                 <div className='org-container'>
                 <Container >
                <Form>
                    <Row>
                        <Col>
                    <Form.Group className="mb-2 col-xs-6" controlId="homeEditName">
                        <Form.Label>Home Name</Form.Label>
                        <Form.Control type="input" defaultValue={homeDetails.name}
                            onChange={(e) => {
                                changeHomeText(e, 'det1');
                            }}
                        />
                    </Form.Group>
                    </Col>
                    <Col>
                    <Form.Group className="mb-3 col-xs-6" controlId="homeIdNoEdit">
                        <Form.Label>Home ID</Form.Label>
                        <Form.Control type="input"
                            defaultValue={homeDetails.home_id} disabled
                        />
                    </Form.Group>
                    </Col>
                    </Row>
                    <Row>
                        <Col>
                    <Form.Group className="mb-3 col-xs-6" controlId="homeEditConFName">
                        <Form.Label>Contact First-Name</Form.Label>
                        <Form.Control type="input" defaultValue={homeDetails.contact_firstName}
                            onChange={(e) => {
                                changeHomeText(e, 'det2');
                            }}
                        />
                    </Form.Group>
                    </Col>
                    <Col>
                    <Form.Group className="mb-3 col-xs-6" controlId="homeEditConLName">
                        <Form.Label>Contact Last-Name</Form.Label>
                        <Form.Control type="input" defaultValue={homeDetails.contact_lastName}
                            onChange={(e) => {
                                changeHomeText(e, 'det3');
                            }}
                        />
                    </Form.Group>
                    </Col>
                    </Row>
                    <Row>
                        <Col>
                    <Form.Group className="mb-3 col-xs-6" controlId="homeEditConNum">
                        <Form.Label>Contact Number</Form.Label>
                        <Form.Control type="input" defaultValue={homeDetails.phone_no}
                            onChange={(e) => {
                                changeHomeText(e, 'det4');
                            }}
                        />
                    </Form.Group>
                    </Col>
                    <Col>
                    <Form.Group className="mb-3 col-xs-6" controlId="homeEditConEmail">
                        <Form.Label>Contact Email</Form.Label>
                        <Form.Control type="input" defaultValue={homeDetails.email_id}
                            onChange={(e) => {
                                changeHomeText(e, 'det5');
                            }}
                        />
                    </Form.Group>
                    </Col>
                    </Row>
                    <div>
                        {errors ? (
                            <p className="text-danger">*All fields are mandatory</p>

                        ) : (
                            <p className="text-danger"></p>

                        )}
                    </div>
                    <Button
                        onClick={saveHomeText}
                        variant="warning"  >
                        Save Changes
                    </Button>
                </Form>
                </Container>
            </div>
                </div>}
           
        </div>)

}