import React from "react";
import { Navbar, Container, Nav } from 'react-bootstrap';
import { useState, useEffect } from "react";
import { BASE_URL_FRONTEND } from '../../config/Url-config';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import * as apiUtility from '../../config/APIUtils';
import Button from '@mui/joy/Button';

 

function Header(props) {
  const [headerShow, setHeaderShow] = useState(false)
 const [userName, setUserName] = useState("");
 const [firstName, setFirstName] = useState("");
 const [fullName, setFullName] = useState("");
  const [logText, setLogtext] = useState("Login");

  //const { userData } = useContext(AuthContext);
  //const params = useParams();
  if(sessionStorage.getItem('userType')=="admin"){
   var url = BASE_URL_FRONTEND+"admin_home/";
  }
  else if(sessionStorage.getItem('allHomeArr') && sessionStorage.getItem('userId')){
    var  url = BASE_URL_FRONTEND+"user/"+sessionStorage.getItem('userId');
  }
  else{
  var  url = BASE_URL_FRONTEND+"user/"+sessionStorage.getItem('userId');
  }
  useEffect(() => {
    setUserName(sessionStorage.getItem("userName"))
    setFirstName(sessionStorage.getItem("firstName"));
    setFullName(sessionStorage.getItem("fullName"));
    // if(window.location.href.includes("login")||(window.location.href.includes("userRegistration"))){
    //   setHeaderShow(false)
    // }
    // else{
    //   setHeaderShow(true)
    // }
    //if((window.location.href) !== BASE_URL_FRONTEND && (window.location.href) !== BASE_URL_FRONTEND+"login")
    //console.log("User : ", window.location.href, (window.location.href).includes(BASE_URL_FRONTEND+"userRegistration"));
   if(!(window.location.href).includes(BASE_URL_FRONTEND+"userRegistration") && ((window.location.href).includes(BASE_URL_FRONTEND+"admin_home/") || (window.location.href).includes(BASE_URL_FRONTEND+"user")))

    //{
      //console.log("User : ", sessionStorage.getItem('userTypeArr'), "UserIf : ", sessionStorage.getItem('userId'));
      //if(sessionStorage.getItem('userTypeArr')&&sessionStorage.getItem('userId'))
      {
        setHeaderShow(true);
      }else{
        setHeaderShow(false);
        setLogtext("Login")
      }
      // if(sessionStorage.getItem('userTypeArr')&&sessionStorage.getItem('userId'))
      // {
      //   setHeaderShow(true);
      //   setUserName(sessionStorage.getItem('userName'));
      //   if(sessionStorage.getItem('userTypeArr')[0]=="admin"){
      //     url = "http://localhost:3000/admin_home/";
      //   }
      //   else{
      //     url = "http://localhost:3000/user/"+sessionStorage.getItem('userId');
      //   }

      // }
   // }
    //console.log("params",(window.location.href)=='http://localhost:3000/')
    if (sessionStorage.getItem("userId")) {
      setLogtext("Logout")
    }

  }, [])
  var loginPgCall = () => {
    apiUtility.logOut();
    window.location = ("/login")

  }

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  function stringAvatar(name) {
    if(name === "" || name === null || name === undefined){
      return "";
    }
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  var toUserPage = () =>{
    //console.log(url)
  // if(sessionStorage.getItem('userTypeArr')=="admin"){
  //   var url = "http://localhost:3000/admin_home/";
  //  }
    return window.location.href = url
  }

  return (
    <Navbar bg="light" expand="lg" fixed="top">
      <Container fluid>
        <Navbar.Brand><Nav.Link onClick={toUserPage}>Ontario Long Term Care Orientation Co-operative Webportal</Nav.Link></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav pullright="true">
            <Nav.Link href="">About Us</Nav.Link>
            <Nav.Link href="">Contact Us</Nav.Link>
            
       { <div>
      {headerShow ? (
        // <div><Button bsStyle="primary" onClick={toUserPage}>{userName}</Button></div>
        <div style={{"display":"inline-block"}}>
        <Nav.Link onClick={toUserPage} style={{"display":"inline-block"}}>{userName}'s&nbsp;Dashboard</Nav.Link>
        <Button bsStyle="primary" onClick={loginPgCall}>{logText}</Button>
        &nbsp;
        &nbsp;
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          variant="dot"
        >
          <Avatar {...stringAvatar(fullName)} />
        </StyledBadge>
        </div>
      ) : (<Button bsStyle="primary" onClick={loginPgCall}>{logText}</Button>)}
    </div> } 
            
            {/* )}
            </div> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;