import React, { useEffect, useState } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { useParams } from "react-router-dom";
import HomeDetailComponent from './HomeDetailComponent';
import HomeCheckListComponent from './HomeCheckListComponent';
import StaffComponent from './StaffComponent';
import HomeAuditReportManagement from '../AuditReportManagement/HomeAuditReportManagement';
import * as apiUtility from "../../config/APIUtils";
import HomeAdminPermissions from '../AdminPermissions_Component/HomeAdminPermissions';
import TrainingSponsorship from './TrainingSponsorshipComponent';


function HomeMainComponent(props) {
    const [homes_list, setHomesList] = useState([]);

    const [user_ID, setUser_ID] = useState(-1);
    //const [showManageHome, setManageHomeShow] = useState(false);
    //    const handleCloseManageHome = () => setManageHomeShow(false);
    //    const handleShowManageHome = () => setManageHomeShow(true);
    //    const accessToken = sessionStorage.getItem('authToken');
    //     const headers = {
    //         Authorization: `Bearer ${accessToken}`,
    //       };

    var propsId = props.homeIdFprAdmin
    var params = propsId;
    if (props.id) {
        params = props.id
    }
    //console.log("Props here---------",props)

    var user_id = useParams().id;
    var propsId2 = props.userId;
    var userID = propsId2;
    if (!userID) {
        userID = user_id;
    }
    // if (!propsId) {

    //     params = paramId;

    // }
    useEffect(async () => {
        //console.log(props)
        setUser_ID(userID);
        if (props.homeIdFprAdmin) {
            params = props.homeIdFprAdmin
        }
        var orgProps;
        if (props.orgId) {
            orgProps = props.orgId
        }

        if(orgProps == undefined && params != undefined){
            //When ORG ID is not present just search with Home ID
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "orgnization/getHomeDetails/" + params;
            await apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
                setHomesList(res.data)
            }).catch((error) => {
                console.error(error);
                // setshowSpinner(false);
            });

        }else{
            //When ORG ID is present, then search for all Home.
            const BASE_URL_GET_All_HOMELIST = "orgnization/getHomesList/" + orgProps;
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = BASE_URL_GET_All_HOMELIST;
            await apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
                setHomesList(res.data)
            }).catch((error) => {
                console.error(error);
            });
        }

        
    }, [props.homeIdFprAdmin])

    // function showHomeRemoveOption(e,item){

    //     var tempHomeObj = {}

    //     console.log(e.target.checked,item)
    //     tempHomeObj.home_id = item.home_id;
    //     tempHomeObj.homeActiveStatus = e.target.checked
    //     const changeHomeActiveStatusUrl = BASE_API_URL + "orgnization/changeHomeActiveStatus"
    //     axios.put(changeHomeActiveStatusUrl, tempHomeObj,{headers})
    //     .then(res => {
    //         ////console.log(res);
    //         // getOrgData()
    //     })
    //     .catch(err => {
    //         //console.log(err);
    //     })


    // }





    return (
        <div>
            {/* <Button style={{  marginRight: "1%"}} variant="warning" onClick={handleShowManageHome} >Manage Homes</Button>
<div style={{width:"60%",marginLeft:"25%"}}>
<Modal show={showManageHome} onHide={handleCloseManageHome} >
            <Modal.Header closeButton>
                                <Modal.Title>Enable/Disable Home</Modal.Title>
                            </Modal.Header>
                            <Modal.Body >
            <div style={{"marginBottom":"8pc",width:"60%",marginLeft:"25%"}}>
            
            <Row className="justify-content-md-center">
           
                <Form style={{width:"50%"}}>
                            <br></br>
                            <br></br>
                                    <h3 className="text-center">All Homes List:</h3>
                                    {homes_list.length>0?
                                     <ListGroup as="ol" numbered>
                                     {['checkbox'].map((type) => (
                                         <div key={`reverse-${type}`} className="mb-3">
                                             {homes_list.map((item, _id) => {
                                                 return <ListGroup.Item as="li"> <Form.Check  label={item.name} name="group1" type={type} defaultChecked={item.homeActiveStatus} id={`reverse-${type}-1`} onChange={(e) => {
                                                     showHomeRemoveOption(e, item)
                                                 }} /></ListGroup.Item>
                                             })}
                                     
                                         </div>
                                     ))}
                                 </ListGroup>:<div>No Home Found!</div>
                                 
                                    }
                                   
                </Form>
                
            </Row>
     
    

</div>
</Modal.Body>
<Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseManageHome}>
                                    Close
                                </Button>
                                
                            </Modal.Footer>
            </Modal>
</div> */}


            <Tabs defaultActiveKey="homeDetails" fill style={{ display: "flex", marginTop: "5%" }}>
                <Tab eventKey="homeDetails" title="Home Details">
                    <HomeDetailComponent homeIdForAdmin={params} />
                </Tab>

                <Tab eventKey="homeStaffComponent" title="Manage Staff">
                    <StaffComponent id={params} />
                </Tab>

                <Tab eventKey="homeCheckListComponent" title="Manage Position Templates">
                    <HomeCheckListComponent id={params} />
                </Tab>

                <Tab eventKey="homeAuditReport" title="Manage Audit Report">
                    <HomeAuditReportManagement orgFlag={false} userId={user_ID} />
                </Tab>

                <Tab eventKey="addHomeInHome" title="Manage Home Admin Permission">
                    {/* <AddHomeInHome homeID={params} /> */}
                    <HomeAdminPermissions homeID={params} homesList={homes_list} />
                </Tab>
                <Tab eventKey="trainingSponsorship" title="Manage Training Sponsorship">
                    <TrainingSponsorship homeID={params} homesList={homes_list} />
                </Tab>
            </Tabs>

        </div>
    )
}

export default HomeMainComponent