import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Button, Modal, Container } from 'react-bootstrap'
import * as apiUtility from "../../config/APIUtils";
import ErrorPopUp from '../Utility/ErrorPopUp';

function EditCourseForm(props) {
    const courseData = props.courseID;
    const [courseType, setCourseType] = useState("0");
    const [years, setYears] = useState(0);
    const [months, setMonths] = useState(0);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [dataForErrorPopup, setDataForErrorPopup] = useState('');
    //const [trainingUrl, setTrainingUrl] = useState("");

    useEffect(async () => {
       setCourseType(courseData.type);
       setYears((courseData.validity_duration !== undefined && courseData.validity_duration.years !== undefined)?courseData.validity_duration.years:0);
       setMonths((courseData.validity_duration !== undefined && courseData.validity_duration.months !== undefined)?courseData.validity_duration.months:0);
       //setTrainingUrl((courseData.training_url !== undefined)?courseData.training_url:"");
    }, [courseData])

    var homeTypes = JSON.parse(sessionStorage.getItem("home_types"));
    var editCourseSubmitted = async function (e) {
        e.preventDefault();
        var formGridCourseSelect = document.getElementById("formGridCourseSelectEdit");
        var titleText = document.getElementById("formGridTitleEdit");
        var descText = document.getElementById("formGridDescriptionEdit");
        var vlDurText = document.getElementById("formGridValidityDurationEdit");
        if (formGridCourseSelect.value == "" || titleText.value == "" || descText.value == "") {
            setShowErrorPopup(true);
            setDataForErrorPopup("Please enter valid course details!");
        } else if (courseType === 0 || courseType === "0") {
            setShowErrorPopup(true);
            setDataForErrorPopup("Please select a valid course applicable industry!");
            return;
        } else {
            var courseFormDetails = {
                "title": titleText.value,
                "description": descText.value,
                "validity_duration": {
                    "years":(isNaN(years))?0:parseInt(years),
                    "months":(isNaN(months))?0:parseInt(months)
                },
                "courseType": courseType,
                //"training_url":trainingUrl
            }
            var crsId = formGridCourseSelect.value;
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "course/update-course";
            apiRequest.data = { courseFormDetails, crsId };
            await apiUtility.updateAsyncAPIDataPUT(apiRequest)
                .then(res => {
                    var rspMsg = [1, "Record updated!"];
                    props.handleCallBack(rspMsg)
                })
                .catch(err => {
                    var rspMsg = [-1, "Error while performing action"];
                    props.handleCallBack(rspMsg)
                })
        }

    }

    const handleYearsChange = (event) => {
        const { value } = event.target;
        // Check if the value is a positive number or an empty string
        if (value === '' || (parseInt(value) > 0 && !isNaN(value))) {
          setYears(parseInt(value));
        }
      };
    
      const handleMonthsChange = (event) => {
        const { value } = event.target;
        // Check if the value is a positive number or an empty string
        if (value === '' || (parseInt(value) > 0 && parseInt(value) < 12 && !isNaN(value))) {
          setMonths(parseInt(value));
        }
      };

    //   const handleTrainingUrl = (event) => {
    //     const { value } = event.target;
    //     setTrainingUrl(value);
    //   };


    return (
        <Container>
            <Row>

                <Modal
                    {...props}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Course</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Form.Group as={Col} >
                                    <Form.Label>Course ID</Form.Label>
                                    <Form.Control id="formGridCourseSelectEdit" defaultValue={courseData.courseID} disabled/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridTitleEdit">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control placeholder="Enter Title" defaultValue={courseData.title} />

                                </Form.Group>
                            </Row>
                            <br></br>
                            <Row>
                                <Form.Group as={Col} controlId="formGridDescriptionEdit">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control placeholder="Enter Description" defaultValue={courseData.description} />
                                </Form.Group>
                            </Row>
                            <br></br>
                            <Row>
                                <Form.Group as={Col} controlId="formGridValidityDurationEdit">
                                    <Form.Label>Validity Duration<span className='text-muted' style={{ "font-size": "11px" }}>&nbsp;&nbsp;(Please leave empty if course do not have expiry)</span></Form.Label>
                                    {/* <Form.Control type="number" placeholder="Enter total years for validity duration" defaultValue={courseData.validity_duration} /> */}
                                    <Row>
                                    <Form.Group as={Col} controlId="formGridValidityDurationYears">
                                        <Form.Label className='text-muted'>Years</Form.Label>
                                        <Form.Control type="number" value={years} onChange={handleYearsChange} placeholder="Validity in Years" />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridValidityDurationMonths">
                                        <Form.Label className='text-muted'>Months</Form.Label>
                                        <Form.Control type="number" value={months} onChange={handleMonthsChange} placeholder="Validity in Months" />
                                    </Form.Group>
                                    </Row>
                                </Form.Group>
                                </Row>
                                <br></br>
                                <Row>
                                <Form.Group as={Col} controlId="formGridIndustry">
                                    <Form.Label>Applicable Industry</Form.Label>
                                    <Form.Select name="industry" value={courseType} onChange={(e) => setCourseType(e.target.value)} >
                                        <option value="0"> -- Please select applicable industry --  </option>
                                        {homeTypes?.map((data, id) => (
                                            <option value={data.id} key={data.id}>{data.value}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                {/* <Form.Group as={Col} controlId="training-URL">
                                        <Form.Label>Training URL</Form.Label>
                                        <Form.Control type="text" value={trainingUrl} onChange={handleTrainingUrl} placeholder="Enter the training URL for this course.." />
                                </Form.Group> */}
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit" onClick={editCourseSubmitted}>
                            Submit
                        </Button>
                        <Button variant="primary" onClick={props.onHide}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <ErrorPopUp show={showErrorPopup} data={dataForErrorPopup} onHide={() => { setShowErrorPopup(false) }} />
            </Row>
        </Container>
    )
}

export default EditCourseForm