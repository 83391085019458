import React, { useEffect, useState } from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Button, Col, Row } from 'react-bootstrap';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import BootstrapTable from 'react-bootstrap-table-next';
import Spinner from 'react-bootstrap/Spinner';
import * as apiUtility from "../../config/APIUtils";
import { Divider, IconButton, Paper, Tooltip } from "@mui/material";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import ErrorPopUp from '../Utility/ErrorPopUp';
import { RiFileExcel2Line } from "react-icons/ri";
import { BsFiletypePdf } from "react-icons/bs";

function HomeStaffSummary(props) {
    const [staffRoleID, setStaffRoleID] = useState("");
    const [homeStaffDataFinal, setHomeStaffDataFinal] = useState(null);
    const [showSpinner, setshowSpinner] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [dataForErrorPopup, setDataForErrorPopup] = useState('');

    var homeID = props.homeId;
    var filterObj = props.filterBy;
    var staffFlag = props.staffFlag;
    //console.log(props)

    useEffect(async () => {
        // /audit-report/home-summary/1
        setshowSpinner(true)
        setStaffRoleID(props.roleId);
        var body = {
            "homeId": homeID,
            "firstName": filterObj.firstName,
            "lastName": filterObj.lastName,
            "staffFlag": staffFlag
        }
        if (homeID != undefined) {
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "audit-report/home-staff-summary";
            apiRequest.data = body;
            apiUtility.sendAPIDataPOST(apiRequest).then(res => {
                setshowSpinner(false)
                setHomeStaffDataFinal(res.data)
            }).catch((error) => {
                console.error(error);
                setshowSpinner(false);
            });
        }

    }, [homeID])

    var homeStaffCols = [{
        dataField: 'role_id',
        sort: true,
        text: 'Role ID'
    }, {
        dataField: 'role_name',
        sort: true,
        text: 'Role Name'
    }
        , {
        dataField: 'user_id',
        sort: true,
        text: 'User ID'
    }, {
        dataField: 'first_name',
        sort: true,
        text: 'First Name'
    }, {
        dataField: 'last_name',
        sort: true,
        text: 'Last Name'
    }
        , {
        dataField: 'status',
        sort: true,
        text: 'Status'
    },
        , {
        dataField: 'is_complaint',
        sort: true,
        text: 'Is Compliant ?'
    },

    ];


    var filteredHomeStaffData = [];
    if (homeStaffDataFinal != null) {
        filteredHomeStaffData = homeStaffDataFinal.filter((val) => {
            //console.log("Role : "+ staffRoleID);
            if (staffRoleID == "" || staffRoleID == "0" || staffRoleID == 0) {
                return val;
            } else if (staffRoleID == val.role_id) {
                return val;
            }
        })
    }

    var exportHomeDefPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 5;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "LCPT - Home Staff Report";

        var headers = [["Role ID", "Role Name", "User ID", "First Name", "Last Name", "Status", "Compliant"]];

        var dataArr = [];

        for (let i = 0; i < filteredHomeStaffData.length; i++) {
            var tempArr = [filteredHomeStaffData[i]["role_id"], filteredHomeStaffData[i]["role_name"], filteredHomeStaffData[i]["user_id"],
             filteredHomeStaffData[i]["first_name"], filteredHomeStaffData[i]["last_name"], filteredHomeStaffData[i]["status"], filteredHomeStaffData[i]["is_complaint"]];

            dataArr.push(tempArr);


        }

        doc.setProperties({
            title: title,
            subject: 'This is the subject',
            author: 'LCPT',
            keywords: 'generated, javascript, web 2.0, ajax',
            creator: 'LCPT',
            bodyStyles: {
                color: '#cccccc'
            }
        });
        doc.text(title, 300, 40, {
            align: 'center'
        });
        let content = {
            startY: 50,
            head: headers,
            body: dataArr
        };

        doc.autoTable(content);
        let dateVar = new Date();
        let fileName = "LCPT_HomeStaffSummary-"+dateVar.getDate()+dateVar.getMonth()+dateVar.getFullYear()+"_"+dateVar.getHours()+dateVar.getMinutes()+".pdf";
        doc.save(fileName)
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: "xlsx",
            bookSST: false,
            type: "binary",
        };
        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([s2ab(wbout)], {
            type: "application/octet-stream",
        });
        return blob;
    };
    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; i++) {
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    //Excel
    const exportInExcel = async () => {
        try {
            var rowsCount = 6;
            const title = [{A: 'Ontario Long Term Care Orientation Co-operative Webportal - Staff Summary Report for Home ID : '+String(homeID)}];
            let reportView = [{ A:'Name',B:'User ID',C:'Role',D:'Date'}];
            reportView.push({
                A: sessionStorage.getItem('fullName'),
                B: sessionStorage.getItem('userId'),
                C: (sessionStorage.getItem('userType') !== null)?sessionStorage.getItem('userType').toUpperCase():"User",
                D: new Date().toLocaleString()
            });
            reportView.push("");
            reportView.push("");
            let reportHeaders = {A:'Role ID',B:'Role Name',C:'User ID',D:'First Name',E:'Last Name',F:'Status',G:'Is Compliant?'};
            reportView.push(reportHeaders);
           // orgStaffTemplateData.forEach(element => {
            filteredHomeStaffData.forEach(element => {
                    rowsCount += 1;
                    var tempObj = {
                        A: element.role_id,
                        B: element.role_name,
                        C: element.user_id,
                        D: element.first_name,
                        E: element.last_name,
                        F: element.status,
                        G: element.is_complaint
                    };
                    reportView.push(tempObj);
                });
            const finalData = [...title, ...reportView];
            const worksheet = XLSX.utils.json_to_sheet(finalData, {
                skipHeader: true,
            });
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Home Staff Summary");
            const workbookblob = workbook2blob(workbook);
            var headerIndexes = [];
            finalData.forEach((data, index) =>
                data["A"] === "Role ID" ? headerIndexes.push(index) : null
            );
            const dataInfo = {
                titleCell: "A2",
                titleRange: "A1:G1",
                tbodyRange: `A7:G${finalData.length}`,
                uHeadRange: `A2:D2`,
                uBodyRange: `A3:D3`,
                theadRange:
                  headerIndexes?.length >= 1
                    ? `A${headerIndexes[0] + 1}:G${headerIndexes[0] + 1}`
                    : null,
                theadRange1:
                  headerIndexes?.length >= 2
                    ? `A${headerIndexes[1] + 1}:I${headerIndexes[1] + 1}`
                    : null,
                tFirstColumnRange:
                  headerIndexes?.length >= 1
                    ? `A${headerIndexes[0] + 1}:A${rowsCount + headerIndexes[0] + 1}`
                    : null,
                tLastColumnRange:
                  headerIndexes?.length >= 1
                    ? `K${headerIndexes[0] + 1}:I${rowsCount + headerIndexes[0] + 1}`
                    : null,
          
                tFirstColumnRange1:
                  headerIndexes?.length >= 1
                    ? `A${headerIndexes[1] + 1}:A${rowsCount + headerIndexes[1] + 1}`
                    : null,
                tLastColumnRange1:
                  headerIndexes?.length >= 1
                    ? `H${headerIndexes[0] + 1}:I${rowsCount + headerIndexes[1] + 1}`
                    : null,
              };
            XlsxPopulate.fromDataAsync(workbookblob).then((workbook)=>{
                workbook.sheets().forEach((sheet)=>{
                    sheet.column("A").width(15);
                    sheet.column("B").width(30);
                    sheet.column("C").width(20);
                    sheet.column("D").width(30);
                    sheet.column("E").width(30);
                    sheet.column("F").width(20);
                    sheet.column("G").width(20);
                    sheet.range(dataInfo.titleRange).merged(true).style({
                        bold: true,
                        fontFamily:"Arial",
                        horizontalAlignment: "center",
                        verticalAlignment: "center",
                    });
                    sheet.range(dataInfo.uHeadRange).style({
                        fill: "A6A6A6",
                        bold: true,
                        horizontalAlignment: "center",
                    });
                    sheet.range(dataInfo.uBodyRange).style({
                        bold: true,
                        horizontalAlignment: "center",
                    });
                    if (dataInfo.tbodyRange) {
                        sheet.range(dataInfo.tbodyRange).style({
                          horizontalAlignment: "left",
                        });
                      }
                      sheet.range(dataInfo.theadRange).style({
                        fill: "A6A6A6",
                        bold: true,
                        horizontalAlignment: "center",
                      });
                      if (dataInfo.tFirstColumnRange) {
                        sheet.range(dataInfo.tFirstColumnRange).style({
                          bold: true,
                        });
                      }
                     
                });
    
                return workbook.outputAsync().then((workbookBlob) => { 
                        const downloadAnchorNode = document.createElement("a");
                        var dateVar = new Date();
                        var fileName = "LCPT_HomeStaffSummary-"+dateVar.getDate()+dateVar.getMonth()+dateVar.getFullYear()+"_"+dateVar.getHours()+dateVar.getMinutes()+".xlsx";
                        downloadAnchorNode.setAttribute("href", URL.createObjectURL(workbookBlob));
                        downloadAnchorNode.setAttribute("download", fileName);
                        downloadAnchorNode.click();
                        downloadAnchorNode.remove();
                        // setDataForSuccessPopup("Downloaded Successfully");
                        // setShowSuccessPopup(true);
                });  
            });
        } catch (error) {
             setDataForErrorPopup("Something went wrong while processing excel!");
             setShowErrorPopup(true);
        }
    }

    return (
        <div key={props.homeID}>
            {showSpinner ? <div style={{ paddingLeft: "50%", paddingTop: "10%", position: "absolute" }}>
                <Spinner show={showSpinner} animation="border" size="lg" variant='primary' />

            </div> : <div></div>}
            <Paper sx={{ p: 1, borderRadius: "1.5px" }}>
                <BootstrapTable id='homeStaffSpecificTable' keyField='id' data={filteredHomeStaffData} columns={homeStaffCols} striped hover />
                <Row>
                    <Col xs={0} md={8}>

                    </Col>
                    <Col xs={12} md={4} className="text-center">
                        <Paper component="form" variant="outlined"
                        sx={{float:"right", display: 'flex', marginBottom:"4px",alignItems: 'center', width: 100 }}
                        >
                        <Tooltip variant="contained" title="Export to PDF" disableRipple>
                            <IconButton color="primary" onClick={exportHomeDefPDF} sx={{ p: '10px' }} aria-label="directions">
                                <BsFiletypePdf/>
                            </IconButton>
                        </Tooltip>
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <Tooltip variant="contained" title="Export to Excel" disableRipple>
                            <IconButton color="primary" onClick={exportInExcel} sx={{ p: '10px' }} aria-label="directions">
                                <RiFileExcel2Line/>
                            </IconButton>
                        </Tooltip>   
                        </Paper>
                    </Col>
                    
                </Row>
            </Paper>
            <ErrorPopUp show={showErrorPopup} data={dataForErrorPopup} onHide={() => { setShowErrorPopup(false) }} />
        </div>
    )
}

export default HomeStaffSummary;