import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Button, Container, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { BASE_API_URL } from '../../config/Url-config';
import { TbCircleNumber1, TbCircleNumber2, TbCircleNumber3, TbZoomReset } from "react-icons/tb";
import OrganizationSummaryComponent from '../Audit_Report_Components/organizationSummaryComponent';
import OrganizationMissingTemplate from '../Audit_Report_Components/organizationMissingTemplate';
import OrganizationTemplateSpecificComponent from '../Audit_Report_Components/organizationTemplateSpecificComponent';
import AdminHomeCourse from '../Audit_Report_Components/AdminHomeCourse';
import OrgFundedTrainingReport from '../Audit_Report_Components/OrgFundedTrainingReport';


import HomeDeficiencyReport from '../Audit_Report_Components/HomeDeficiencyReport';
import HomeSummary from '../Audit_Report_Components/HomeSummary';
import HomeStaffSummary from '../Audit_Report_Components/HomeStaffSummary';
import SpinnerBox from '../Utility/SpinnerBox';
import ErrorPopUp from '../Utility/ErrorPopUp';
import * as apiUtility from "../../config/APIUtils";

function OrgAuditReportManagement(props) {

    var radios = [
        { name: 'Organization', value: '1' }
    ];

    const homeStatus = [
        { name: 'Active', value: '3' },
        { name: 'Inactive', value: '4' },
        { name: 'Both', value: '5' }
    ];

    const staffStatus = [
        { name: 'Active', value: '6' },
        { name: 'Archive', value: '7' },
        { name: 'Both', value: '8' }
    ];
    
    const orgReportTypes = [
        { name: 'Organization Summary', value: '1' },
        { name: 'Organization Staff - Template Specific Summary', value: '2' },
        { name: 'Organization Staff - Deficiency Report', value: '3' },
        { name: 'Organization Funded Training Submissions', value: '8' }
    ];
    const homeReportTypes = [
        { name: 'Home Summary', value: '4' },
        { name: 'Home Staff Summary', value: '5' },
        { name: 'Home Deficiency Report', value: '6' },
        { name: 'Home Course role Report', value: '7' },
        // { name: 'Organization Staff - Deficiency Report', value: '3' }
    ];

    const BASE_URL_USER_INFO = BASE_API_URL + "user/info/";
    const [expFlag, setExpFlag] = useState(false);

    const [userId, setUserId]= useState("");
    const [organisations, setOrganisations]= useState([]);
    const [homes, setHomes]= useState([]);
    const [defaultHomesList, setDefaultHomesList]= useState([]);
    const [homeDropdownValue, sethomeDropdownValue] = useState(0);
    const [orgDropdownValue, setorgDropdownValue] = useState(0);
    const [radioValue, setRadioValue] = useState('1');
    const [homeStatusValue, setHomeStatusValue] = useState('3');
    const [staffStatusValue, setStaffStatusValue] = useState('6');
    const [homeLabel, setHomeLabel] = useState("Active Homes");
    const [reportSection, setreportSection] = useState("");
    const [selectedReportType, setselectedReportType] = useState(0);
    const [selectedRole, setselectedRole] = useState(0);
    const [roles, setRoles] = useState([]);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [dataForErrorPopup, setDataForErrorPopup] = useState('');
    const [spinnerBox, setSpinnerBox] = useState(false);
    const [expDate, setExpDate] = useState({'startDate':(new Date()).toISOString().split('T')[0],'endDate':getNextMonthDate()});

    // const accessToken = sessionStorage.getItem('authToken');
    // const headers = {
    //     Authorization: `Bearer ${accessToken}`,
    //   };
    var paramId = useParams().id;
    var propsId = props.userId;
    var params = propsId;
    if (!propsId) {
        params = paramId;
    }

    var orgFlag = props.orgFlag;

    useEffect(async () => {
        
        console.log("org Audit flag : ", orgFlag, " Admin ", sessionStorage.getItem("userType") === "admin");
        if (sessionStorage.getItem("userType") === "admin") {
           // alert("Admin");
           setUserId(-1);
        }else{
            setUserId(params);
            // if(orgFlag == true){
            //     setRadioValue(1);
            // }else{
            //     setRadioValue(2);
            // }
        }
        if(userId !== undefined && userId !== ""){

            var selectedUserId = -1;
            if(props.userId != undefined){
                selectedUserId = props.userId;
            }
            var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "user/info/"+selectedUserId;

        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
            if (res.data !== undefined) {
                for (let data of res.data) {
                    setOrganisations(data.orgAccessIdList);
                    setHomes(data.homeAccessIdList);
                    setDefaultHomesList(data.homeAccessIdList);
                }
            } else {
                //setNewUser(intialState)
            }       
        }).catch((error) => {
                console.error(error);
                // setshowSpinner(false);
              });
        // if(res){
        //     if (res.data !== undefined) {
        //         for (let data of res.data) {
        //             setOrganisations(data.orgAccessIdList);
        //             setHomes(data.homeAccessIdList);
        //             setDefaultHomesList(data.homeAccessIdList);
        //         }
        //     } else {
        //         //setNewUser(intialState)
        //     }
        // }
        // const getUserData = BASE_URL_USER_INFO + userId;
        //     axios.get(getUserData,{headers})
        //         .then(res => {
        //             if (res.data !== undefined) {
        //                 for (let data of res.data) {
        //                     setOrganisations(data.orgAccessIdList);
        //                     setHomes(data.homeAccessIdList);
        //                     setDefaultHomesList(data.homeAccessIdList);
        //                 }
        //             } else {
        //                 //setNewUser(intialState)
        //             }
        //         })
        //         .catch(err => {
        //             console.log({ err });
        //         })
        }
    }, [userId]);




    const filterByHome = e =>{
        setSpinnerBox(true);
        const { name, value } = e.target;
        sethomeDropdownValue(value);
        setreportSection("");
        setSpinnerBox(false);
        if(selectedReportType == 5  && value != 0){
            populateRoleDropDownData();
        }
    }

    const filterByOrg = e =>{
        setSpinnerBox(true);
        const { name, value } = e.target
        //setHomeLabel("Homes filtered by organisation");
        setorgDropdownValue(value);
        //changeHomeByOrgId(value);
        setreportSection("");
        setSpinnerBox(false);
    }

    const changeReportType = e => {
        const { name, value } = e.target;
        setselectedReportType(value);
        if(value == 5){
            populateRoleDropDownData();
        }
        console.log(name,value);

        setreportSection("");
    }

    const changeRole = e => {
        setSpinnerBox(true);
        const { name, value } = e.target;
        setselectedRole(value);
        setreportSection("");
        setSpinnerBox(false);
    }

    const populateRoleDropDownData = async () => {
        setSpinnerBox(true);
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "audit-report/role-home-staff-summary/" + homeDropdownValue;
        var res = await apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
            setRoles(res.data);
                setSpinnerBox(false);     
        }).catch((error) => {
                console.error(error);
                setSpinnerBox(false);
            setShowErrorPopup(true);
            setDataForErrorPopup("Error occured");
              });
        // if(res){
        //     setRoles(res.data);
        //         setSpinnerBox(false);
        // }
        // else{
        //     setSpinnerBox(false);
        //     setShowErrorPopup(true);
        //     setDataForErrorPopup("Error occured");
        // }
        // var gethomeDetailsUrl = BASE_API_URL + "audit-report/role-home-staff-summary/" + homeDropdownValue;
        // axios.get(gethomeDetailsUrl,{headers})
        //     .then(res => {
        //         setRoles(res.data);
        //         setSpinnerBox(false);
        //     })
        //     .catch(err => {
        //         setSpinnerBox(false);
        //         setShowErrorPopup(true);
        //         setDataForErrorPopup("Error occured"); 
        //     })
    }

    // const changeHomeByOrgId = (selectedOrgId) => {
    //     const homeListUrl = BASE_API_URL + "orgnization/getHomesList/" + selectedOrgId
    //     axios.get(homeListUrl,{headers})
    //         .then(res => {
    //             if (res.data != 'Something went wrong!' && res.data != 'No Home Found!') {
    //                 //setHomes(res.data)
    //                 var arr = [];
    //                 for (let data of res.data) {
    //                     //console.log("Outer: "+data.home_id);
    //                     homes.forEach(function(item, index) {
    //                         if(item.home_id == data.home_id){
    //                             arr.push(data);
    //                             //console.log("Pushed: "+item.home_id);
    //                         }
    //                     })
                           
                        
    //                 }
    //                 //console.log("Array final: "+arr);
    //                 setHomes(arr);
    //             }else {
    //                 setHomes([])
    //             }
    //         })
    //         .catch(err => {
    //             console.log(err);
    //         })
    // }

    const resetOptions = () => {
        setSpinnerBox(true);
        //setRadioValue(1);
        setorgDropdownValue(0);
        sethomeDropdownValue(0);
        setselectedReportType(0);
        setreportSection("");
        setFirstName("");
        setLastName("");
        setHomes(defaultHomesList);
        setSpinnerBox(false);
    }

    const handleOnNameFilter = (e) =>{
        setSpinnerBox(true);
        setreportSection("");
        const { name, value } = e.target;
        if(name === 'firstName'){
            setFirstName(value);
        }else{
            setLastName(value);
        }
        setSpinnerBox(false);
    }
    function getNextMonthDate() {
        const today = new Date();
        const nextMonth = new Date(today);
        
        // Set the month to the next month
        nextMonth.setMonth(today.getMonth() + 1);
        
        // If the day of the next month is less than the current day, adjust the date
        if (nextMonth.getDate() !== today.getDate()) {
            nextMonth.setDate(0); // Set to the last day of the current month
        }
    
        const formattedDate = nextMonth.toISOString().split('T')[0];

    return formattedDate;
    }
    function addDateFilter1(event){
        const [year, month, day] = (event.target.value).split('-').map(Number);

        const myDate = new Date(year, month - 1, day);
        var tempStartDate =  {...expDate};
            const formattedDate = `${myDate.getFullYear()}-${(myDate.getMonth() + 1).toString().padStart(2, '0')}-${myDate.getDate().toString().padStart(2, '0')}`;
            tempStartDate.startDate = formattedDate;
            setExpDate(tempStartDate)
        // if (myDate >= new Date()) {
        //     var tempStartDate =  {...expDate};
        //     const formattedDate = `${myDate.getFullYear()}-${(myDate.getMonth() + 1).toString().padStart(2, '0')}-${myDate.getDate().toString().padStart(2, '0')}`;
        //     tempStartDate.startDate = formattedDate;
        //     // tempStartDate.startDate = myDate.toLocaleDateString('en-US', {
        //     //     year: 'numeric',
        //     //     month: '2-digit',
        //     //     day: '2-digit'
        //     // });
        //     //tempStartDate.startDate = myDate;
        //     setExpDate(tempStartDate)
        // } else {
        //     alert("Please choose a date in the future.");
        // }
        //console.log(event.target.value)
            
    }
    function addDateFilter2(event){
        const [year, month, day] = (event.target.value).split('-').map(Number);

        const myDate = new Date(year, month - 1, day);
        if (myDate >= new Date()) {
            var tempEndDate =  {...expDate};
            const formattedDate = `${myDate.getFullYear()}-${(myDate.getMonth() + 1).toString().padStart(2, '0')}-${myDate.getDate().toString().padStart(2, '0')}`;
            tempEndDate.endDate = formattedDate;
            // tempEndDate.endDate = myDate.toLocaleDateString('en-US', {
            //     year: 'numeric',
            //     month: '2-digit',
            //     day: '2-digit'
            // });
            
            //tempEndDate.endDate = myDate;
            setExpDate(tempEndDate)        
        } else {
            alert("Please choose a date in the future.");
        }
        //console.log(event.target.value)
            
    }

    const fetchAuditReport= ()=>{
        var component = "";
        var flag = true;
        var nameFilter = {"firstName":"", "lastName":""}
        setSpinnerBox(true);
        setreportSection(component);
        console.log(selectedReportType)
        if((selectedReportType == 1 || selectedReportType == 2 || selectedReportType == 3) && orgDropdownValue == 0){
            setSpinnerBox(false);
            setShowErrorPopup(true);
            setDataForErrorPopup("Please select an organization");
            flag = false;
        }
        if(selectedReportType===8 || selectedReportType==='8'){
            setSpinnerBox(false);
            setShowErrorPopup(false);
        }
        if(selectedReportType >= 4 && selectedReportType != '8'  && selectedReportType != 8  && homeDropdownValue == 0){
            setSpinnerBox(false);
            setShowErrorPopup(true);
            setDataForErrorPopup("Please select a Home");
            flag = false;
        }
        if(selectedReportType == 2 || selectedReportType == 3 || selectedReportType == 5 || selectedReportType == 6){
            nameFilter.firstName = firstName;
            nameFilter.lastName = lastName;
        }
        
        if(selectedReportType == '8' || selectedReportType == 8){
            component = () => {
                return <OrgFundedTrainingReport orgId={orgDropdownValue} expDate={expDate} incExp={expFlag}/>
            } 

        }
        if(flag){
            if(selectedReportType == 1){
                component = () => {
                    return <OrganizationSummaryComponent org_id={orgDropdownValue} homeFlag={homeStatusValue} staffFlag={staffStatusValue}/>
                }
                
            }else if(selectedReportType == 2){
                component = () => {
                    return <OrganizationTemplateSpecificComponent org_id={orgDropdownValue} filterBy={nameFilter} homeFlag={homeStatusValue} staffFlag={staffStatusValue}/>
                }
    
            }else if(selectedReportType == 3){
                component = () => {
                    return <OrganizationMissingTemplate org_id={orgDropdownValue} filterBy={nameFilter} homeFlag={homeStatusValue} staffFlag={staffStatusValue}/>
                } 
    
            }else if(selectedReportType == 4){
                component = () => {
                    return <HomeSummary homeId={homeDropdownValue} />
                } 
    
            }else if(selectedReportType == 5){
                component = () => {
                    return <HomeStaffSummary homeId={homeDropdownValue} roleId={selectedRole} filterBy={nameFilter}/>
                } 
    
            }else if(selectedReportType == 6){
                component = () => {
                    return <HomeDeficiencyReport homeId={homeDropdownValue} filterBy={nameFilter}/>
                } 
    
            }else if(selectedReportType == 7){
                component = () => {
                    return <AdminHomeCourse homeId={homeDropdownValue} />
                } 
    
            }
             
            setSpinnerBox(false);
            setreportSection(component);
        }
        
    }

    return (
        <div>
            <Container fluid>
                <Row className="w-70" style={{"minHeight":"40pc"}}>
                    <Col xs={3} md={3} className='shadow p-3 mb-1 bg-white' style={{"border":"1px solid #dcdcd7"}}>
                        <Row>
                        <Form.Group as={Col} className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label><TbCircleNumber1 size={20}/> View by &nbsp; </Form.Label>
                           <ButtonGroup style={{"width":"100%"}}>
                                {radios.map((radio, idx) => (
                                <ToggleButton
                                    key={idx}
                                    id={`radio-${idx}`}
                                    type="radio"
                                    variant={idx == 1 ? 'outline-success' : 'outline-warning'}
                                    name="radio"
                                    //disabled={userId == -1?false:true}
                                    value={radio.value}
                                    checked={radioValue === radio.value}
                                    onChange={(e) => setRadioValue(e.currentTarget.value)}
                                >
                                    {radio.name}
                                </ToggleButton>
                                ))}
                            </ButtonGroup>
                        </Form.Group>
                        </Row>
                        
                        <hr className='w-90'></hr>
                        <Form.Group as={Col} controlId="forOrg">
                                <Form.Label size="sm"><TbCircleNumber2 size={20}/> Preference</Form.Label>
                        </Form.Group>
                        {(radioValue == 1)?
                            <div>
                                <Row>
                                    <Form.Group as={Col} controlId="forOrg">
                                        <Form.Label className='col-sm-2 col-form-label form-control-sm' style={{"color":"grey"}}>Organization</Form.Label>
                                        <Form.Select name="organisation" value={orgDropdownValue} onChange={filterByOrg}>
                                            <option value="0" key="0" >--Select Organization--</option>
                                                {organisations?.map((data, id) => (
                                                    <option value={data.org_id} key={data.org_id}>{data.org_name}</option>
                                                ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                                <Row style={{"marginTop":"1pc"}}>
                                        <Form.Group as={Col} className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label className='col-sm-12 col-form-label form-control-sm' style={{"color":"grey"}}>Home preference </Form.Label>
                                            <ButtonGroup style={{"width":"100%"}}>
                                                {homeStatus.map((radio, idx) => (
                                                    <ToggleButton
                                                        key={idx}
                                                        id={`radioHome-${idx}`}
                                                        type="radio"
                                                        variant={'outline-success'}
                                                        name="home-radio"
                                                        size="sm"
                                                        //disabled={userId == -1?false:true}
                                                        value={radio.value}
                                                        checked={homeStatusValue === radio.value}
                                                        onChange={(e) => {setHomeStatusValue(e.currentTarget.value); setreportSection("")}}
                                                    >
                                                    {radio.name}
                                                    </ToggleButton>
                                                ))}
                                            </ButtonGroup>
                                        </Form.Group>     
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label className='col-sm-12 col-form-label form-control-sm' style={{"color":"grey"}}>Staff preference </Form.Label>
                                            <ButtonGroup style={{"width":"100%"}}>
                                                {staffStatus.map((radio, idx) => (
                                                    <ToggleButton
                                                        key={idx}
                                                        id={`radioStaff-${idx}`}
                                                        type="radio"
                                                        variant={'outline-success'}
                                                        name="staff-radio"
                                                        size="sm"
                                                        //disabled={userId == -1?false:true}
                                                        value={radio.value}
                                                        checked={staffStatusValue === radio.value}
                                                        onChange={(e) => {setStaffStatusValue(e.currentTarget.value); setreportSection("")}}
                                                    >
                                                    {radio.name}
                                                    </ToggleButton>
                                                ))}
                                            </ButtonGroup>
                                        </Form.Group>     
                                    </Row>
                               
                                {/* <Row>
                                    <Form.Group as={Col} controlId="forHome">
                                        <Form.Label className='col-sm-12 col-form-label form-control-sm' style={{"color":"grey"}}>{homeLabel}</Form.Label>
                                        <Form.Select name="home" value={homeDropdownValue} onChange={filterByHome}>
                                            <option value="0" key="0" >--Select Home--</option>
                                                {homes?.map((data, id) => (
                                                    <option value={data.home_id} key={data.home_id}>{data.name}</option>
                                                ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Row> */}
                                </div>
                        :
                                <div>
                                    <Row>
                                        <Form.Group as={Col} controlId="forHome">
                                            <Form.Label className='col-sm-12 col-form-label form-control-sm' style={{"color":"grey"}}>{homeLabel}</Form.Label>
                                            <Form.Select name="home" value={homeDropdownValue} onChange={filterByHome}>
                                                <option value="0" key="0" >--Select Home--</option>
                                                    {homes?.map((data, id) => (
                                                        <option value={data.home_id} key={data.home_id}>{data.name}</option>
                                                    ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Row>
                                    <br></br>
                                </div>
                        
                        }
                       
                        
                        <hr className='w-90'></hr>
                        <Row>
                            <Form.Group as={Col} controlId="forType">
                                <Form.Label><TbCircleNumber3 size={20}/> Report type</Form.Label>
                                <Form.Select name="type" value={selectedReportType} onChange={changeReportType}>
                                    <option value="0" key="0" >--Select type--</option>
                                        {(radioValue == 1)?
                                            orgReportTypes?.map((data, id) => (
                                                <option value={data.value} key={data.value}>{data.name}</option>
                                            ))
                                        :  homeReportTypes?.map((data, id) => (
                                            <option value={data.value} key={data.value}>{data.name}</option>
                                        )) }
                                       
                                </Form.Select>
                            </Form.Group>      
                        </Row>
                        
                        <br></br>
                        {(radioValue == 2 && selectedReportType == 5)?
                            <div>
                                <Row>
                                    <Form.Group as={Col} controlId="forType">
                                        <Form.Label className='col-sm-12 col-form-label form-control-sm' style={{"color":"grey"}}>Role</Form.Label>
                                        <Form.Select name="type" value={selectedRole} onChange={changeRole}>
                                            <option value="0" key="0">All</option>
                                                {roles?.map((data, id) => (
                                                    <option value={data.role_id} key={data.role_id}>{data.role_name}</option>
                                                ))}
                                        </Form.Select>
                                    </Form.Group>      
                                </Row>
                             <br></br>
                             </div>
                        :""}
                        {(selectedReportType == 2 || selectedReportType == 3 || selectedReportType == 5 || selectedReportType == 6)?
                            <div>
                                <Row>
                                    <Form.Group as={Col} controlId="forType">
                                        <Form.Label className='col-sm-12 col-form-label form-control-sm' style={{"color":"grey"}}>First Name</Form.Label>
                                        <Form.Control type="input" name="firstName" onChange={handleOnNameFilter} value={firstName} placeholder="Filter by first name" />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="forType">
                                        <Form.Label className='col-sm-12 col-form-label form-control-sm' style={{"color":"grey"}}>Last Name</Form.Label>
                                        <Form.Control type="input" name="lastName" onChange={handleOnNameFilter} value={lastName} placeholder="Filter by last name" />
                                    </Form.Group>      
                                </Row>
                             <br></br>
                             </div>
                        :""}
                          {(selectedReportType == 8)?
                        <div>
                                        
                                        <Row style={{"marginBottom":"1pc"}}>
                                            
                                            <Form.Group as={Col} controlId="dob">
                                            <Form.Label className='col-sm-12 col-form-label form-control-sm' style={{"color":"grey"}}>Date From </Form.Label>

                                <Form.Control type="date"
                                    onChange={(e) => {
                                        addDateFilter1(e);
                                    }}
                                />
                                <Form.Label className='col-sm-12 col-form-label form-control-sm' style={{"color":"grey"}}>Date Till </Form.Label>

                                <Form.Control type="date"
                                    onChange={(e) => {
                                        addDateFilter2(e);
                                    }}
                                />
                                <Row style={{"marginTop":"1pc"}}>
                                            <Form.Group as={Col} className="mb-3" controlId="exampleForm.ControlInput1">

                                            <input as={Col} 
                                            defaultChecked={expFlag}  onChange={(e) => {
                                        setExpFlag(e.target.checked);
                                    }} style={{marginLeft:"2%"}} type='checkbox'></input>
                                            <Form.Label as={Col} className='col-sm-12 col-form-label form-control-sm' >Since last expiry</Form.Label>
                                            
                                            </Form.Group>     
                                        </Row>
                            </Form.Group>
                                        </Row>
                                        </div>
                                    :""}
                            
                             
                        <Row>   
                            <Col xs={6} md={6}>
                                <Button variant="primary" onClick={(e) => {
                                fetchAuditReport();
                                }}> Fetch Report</Button>
                            </Col>
                            <Col  xs={6} md={6}>
                            <Button variant="primary" onClick={(e) => {
                                    resetOptions();
                                }}><TbZoomReset size={20}/>&nbsp; Reset</Button>
                            </Col>
                        </Row>  
                    </Col>
                    <Col xs={9} md={9}>
                        {/* Data */}
                        {reportSection}
                    </Col>
                   
                </Row>
               </Container>
            <SpinnerBox show={spinnerBox} onHide={() => { setSpinnerBox(false)}}/> 
            <ErrorPopUp show={showErrorPopup} data={dataForErrorPopup} onHide={() => { setShowErrorPopup(false)}}/> 
        </div>
    );
}

export default OrgAuditReportManagement;