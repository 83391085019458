import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'
import $ from 'jquery';
import * as apiUtility from '../../config/APIUtils';


function VerifyCredentials(props) {
    const [crsStatus, setCrsStatus] = useState('');
    const [validityDate, setValidityDate] = useState('');
    var userCrsRowData = props.data;
    var editUsrCrsForm = (e) => {
        e.preventDefault();
        var userID = userCrsRowData.userId;
        var crsID = userCrsRowData.courseId;
        var crsVerId = userCrsRowData.courseVersionId;
        var validityDate = $("#validityDateUsrCrs").val();
        var isUrlValid = $("#isUrlValid").val();
        var url = userCrsRowData.url;

        var getAdmUsrCrsURL = "admin-data/verify-credentials";
        var apiInputRequest = apiUtility.apiInputRequest;
        apiInputRequest.endPoint = getAdmUsrCrsURL;
        apiInputRequest.data = {
            "id": userCrsRowData.mongoID,
            "userId": userID,
            "courseId": crsID,
            "validityDate": validityDate+ 'T' + new Date().toISOString().split('T')[1],
            "isURLValid": isUrlValid,
            "crsVerId": crsVerId,
            "url": url
        }
        apiUtility.sendAPIDataPOST(apiInputRequest)
            .then(res => {
                var rspMsg = [1, "Record updated!"];
                props.handleCallBack(rspMsg)

            }).catch(err => {
                var rspMsg = [-1, "Error while updating data!"];
                props.handleCallBack(rspMsg)
            })

    }


    useEffect(() => {
        var validityDate = new Date(userCrsRowData.validityDateOG);
        var month = '' + (validityDate.getMonth() + 1);
        var day = '' + validityDate.getDate();
        var year = validityDate.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        validityDate = [year, month, day].join('-');
        setCrsStatus(props.data.status);
        setValidityDate(validityDate)
    }, [userCrsRowData])

    const changeStatus = e => {
        setCrsStatus(e.target.value);
    }

    const changeDate = e => {
        setValidityDate(e.target.value);
    }

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            
        >
            <Modal.Header style={{"borderRadius":"1.5px"}} closeButton>
                <Modal.Title>Verify Credentials</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Form.Group as={Col} controlId="userCrsUserID">
                            <Form.Label>User ID: {userCrsRowData.userId}</Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} controlId="userCrsCourseID">
                            <Form.Label>Course ID: {userCrsRowData.courseId}</Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} controlId="userCrsCourseVersionID">
                            <Form.Label>Course Version ID: {userCrsRowData.courseVersionId}</Form.Label>
                        </Form.Group>
                    </Row>
                    <Row style={{ margin: '0 auto', textAlign: 'center', borderTop: '.1em solid', borderBottom: '.1em solid' }}>
                        <Form.Group controlId="userCrsURL">
                            <Form.Label>Badge URL: {userCrsRowData.url}</Form.Label>
                            <Form.Control type='text' hidden value={userCrsRowData.url} />
                        </Form.Group>
                        <Form.Group controlId="userCrsURL">
                            <Form.Label>Badge ID : {userCrsRowData.mongoID}</Form.Label>
                        </Form.Group>
                    </Row>
                    <Row >
                        <Form.Group as={Col}>
                            <Form.Label>Completion Date</Form.Label>
                            <Form.Control type='date' id='validityDateUsrCrs' value={validityDate} onChange={changeDate} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Action</Form.Label>
                            <Form.Select id='isUrlValid' value={crsStatus} onChange={changeStatus}>
                                <option value='Denied'>Reject</option>
                                <option value='Validated'>Accept</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" type="submit" onClick={editUsrCrsForm}>Submit</Button>
                <Button variant="primary" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>


            <br></br>
        </Modal>
    )
}

export default VerifyCredentials