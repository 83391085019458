import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userInfo: {},
    trainingCart: {
        "pending":[],
        "active-approved":[],
        "approved":[],
        "rejected":[],
    }
}

export const userSlice = createSlice({
    name:'custom',
    initialState,
    reducers: {
        addUserInfo: (state, action) => {
            return state.userInfo = action.payload 
        },
        // updateUserInfo: (state, action) => {
        //     state.userInfo = action.payload
        // },
        loadUserTrainingCart: (state = initialState, action) => {
            //console.log("What we got  : ", action);
            let tempCart = {};
            
            tempCart["pending"] = (action.payload["pending"] != undefined && action.payload["pending"].length > 0) ? action.payload["pending"] : [];
            tempCart["active-approved"] = (action.payload["active-approved"] != undefined && action.payload["active-approved"].length > 0) ? action.payload["active-approved"] : [];
            tempCart["approved"] = (action.payload["approved"] != undefined && action.payload["approved"].length > 0) ? action.payload["approved"] : [];
            tempCart["rejected"] = (action.payload["rejected"] != undefined && action.payload["rejected"].length > 0) ? action.payload["rejected"] : []; 
            //console.log("From tempCart : ", tempCart);
            return {
                ...state,
                trainingCart : tempCart
            };
        },

        handleCartUpdate: (state = initialState, action) => {
            //let obj = {...state};
            let result = action.payload.data;
            //let cart = obj["trainingCart"];
            // console.log("From initial : ", cart);
            // console.log("From state : ", state);
            //console.log("From action : ", action.payload.type, " Payload : ", action.payload.data);

            const newState = {...state};
            //console.log("From newState : ", newState);
                    
            if (action.payload.type === "deletedFromRejected") {
                //Just deleting from Pending list
                // let filteredRejArray = cart["rejected"].filter(item => item.courseVersionId !== result.courseVersionId);
                // cart["rejected"] = filteredRejArray;
                
                newState.trainingCart= {
                    ...state.trainingCart,
                    rejected: state.trainingCart.rejected.filter(item => item.courseVersionId !== result.courseVersionId)
                }
                
            } else {
                //Different scenario working on both pending rejected list
                if (action.payload.type == "isRemovedFromPending") {
                    // let filteredArray = cart["pending"].filter(item => item.courseVersionId !== result.courseVersionId);
                    // cart["pending"] = filteredArray;
                    
                    newState.trainingCart= {
                        ...state.trainingCart,
                        pending: state.trainingCart.pending.filter(item => item.courseVersionId !== result.courseVersionId)
                    }

                    //setCartCourseList(cart);
                } else {
                    // cart["pending"].push(result);//addedToPending case
                    // newState.trainingCart = state.trainingCart.map(item => {
                    //     return {
                    //         ...item, trainingCart: [...item.trainingCart, result]
                    //     }

                        newState.trainingCart= {
                            ...state.trainingCart,
                            pending: [...state.trainingCart.pending, result]
                        }
                    
                    //setCartCourseList(cart);
                }
                if (action.payload.type == "addedFromRejected") {
                    // let filteredRejArray = cart["rejected"].filter(item => item.courseVersionId !== result.courseVersionId);
                    // cart["rejected"] = filteredRejArray;

                    newState.trainingCart= {
                        ...state.trainingCart,
                        rejected: state.trainingCart.rejected.filter(item => item.courseVersionId !== result.courseVersionId)
                    }
                   // setCartCourseList(cart);
                }
            }
            
            return newState;
            //countLengthOfCartCourses();
        }
        // updateUserTrainingCart: (state, action) => {
        //     state.trainingCart["pending"] = (action.payload["pending"] != undefined && action.payload["pending"].length > 0) ? action.payload["pending"] : [];
        //     state.trainingCart["active-approved"] = (action.payload["active-approved"] != undefined && action.payload["active-approved"].length > 0) ? action.payload["active-approved"] : [];
        //     state.trainingCart["approved"] = (action.payload["approved"] != undefined && action.payload["approved"].length > 0) ? action.payload["approved"] : [];
        //     state.trainingCart["rejected"] = (action.payload["rejected"] != undefined && action.payload["rejected"].length > 0) ? action.payload["rejected"] : []; 
        // }
    }
});

export const {addUserInfo, loadUserTrainingCart, handleCartUpdate} = userSlice.actions

export const storeTrainingCart = (state) => state.custom?.trainingCart


export default userSlice.reducer