import React from 'react';
import NewCourseTab from './NewCourseTab'
function MicroCredsDashboard() {

    return (
        <div style={{ marginTop: "1vh" }}>
                    <NewCourseTab />
        </div>
    )

}

export default MicroCredsDashboard