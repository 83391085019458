
import './organisation.css';
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import { BASE_API_URL } from '../../config/Url-config';
import { BASE_URL_FRONTEND } from '../../config/Url-config';
import StaffComponent from './StaffComponent';
import HomeMainComponent from './HomeMainComponent';
import HomeCheckListComponent from './HomeCheckListComponent';
import { Col, Row, ListGroup } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import * as apiUtility from "../../config/APIUtils";


export default function OrganisationListComponent(props) {
    const handleCloseHome = () => setHomeShow(false);
    const handleShowHome = () => setHomeShow(true);
    const [id, setId] = useState('')
    const [showHome, setHomeShow] = useState(false);
    const [newHomeId, setNewHomeId] = useState('');
    const [HomeDetails, setHomeDetails] = useState({});
    const [homeListArray, setHomeListArray] = useState([]);
    const [errors, setErrors] = useState(false)
    const [showHomeDetailModal,setShowHomeDetailModal] = useState(false);
    const handleCloseHomeDetail = () => setShowHomeDetailModal(false);
   // const handleShowHomeDetail = () => setShowHomeDetailModal(true);
    const [showStaffModal,setShowStaffModal] = useState(false);
    const handleCloseStaff = () => setShowStaffModal(false);
    // const handleShowStaff = () => setShowStaffModal(true);
    const [showCheckListModal,setShowCheckListModal] = useState(false);
    const handleCloseCheckList= () => setShowCheckListModal(false);
   // const handleShowCheckList = () => setShowCheckListModal(true);
   const [homes_list, setHomesList] = useState([]);
   const [showManageHome, setManageHomeShow] = useState(false);
   const handleCloseManageHome = () => setManageHomeShow(false);
   const handleShowManageHome = () => setManageHomeShow(true);
   const [homesType, setHomesType] = useState(JSON.parse(sessionStorage.getItem('home_types'))); 
   const [selectedHomeType, setSelectedHomeType] = useState(); 
   
     const user_id = useParams().id;
    //const params = props.id;
    if (sessionStorage.getItem("userType") == 'admin'){
        var params = props.id

     }
     else{
        if(props.changeOrg)
        var params = props.changeOrg
        else
        var params = sessionStorage.getItem("orgId")

     }

    
   

    useEffect(() => {
        console.log('homeType',selectedHomeType) 
        if(selectedHomeType!='undefined' && selectedHomeType!=undefined)
        localStorage.setItem('DefaultHomeType',selectedHomeType)
    getHomeData()
},[selectedHomeType])

useEffect(() => {
    if(localStorage.getItem('DefaultHomeType') && localStorage.getItem('DefaultHomeType')!='undefined'  ){
        console.log(localStorage.getItem('DefaultHomeType'));
        setSelectedHomeType(localStorage.getItem('DefaultHomeType'));
        console.log("in if block",localStorage.getItem('DefaultHomeType'))
    }
    else{
        localStorage.setItem('DefaultHomeType',homesType[0].id)
        setSelectedHomeType(homesType[0].id);  
        console.log("in else block",localStorage.getItem('DefaultHomeType'))

    }
    if (sessionStorage.getItem("userId") != user_id  && sessionStorage.getItem("userType") != 'admin' && sessionStorage.getItem("OtherOrgId")) {
        alert("Sorry.Access Not Permitted")
        return window.location.href = BASE_URL_FRONTEND;
        //alert("Sorry.Access Not Permitted")
    }
    console.log("Defualt : ", localStorage.getItem('DefaultHomeType'), selectedHomeType, homesType[0].value);

    if(params !== undefined && params !== ""){
        getHomeData();
    }
    const BASE_URL_GET_All_HOMELIST = BASE_API_URL + "orgnization/getAllHomes";

}, [params])

    async function getHomeData() {
        const homeListUrl = BASE_API_URL + "orgnization/getHomesList/" + params
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint =  "orgnization/getHomesList/" + params;
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
            console.log("Hone Data : ", res);
            var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint =  "orgnization/getAllHomesCount/";
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(newRes => {
            setNewHomeId(String(newRes.data + 1))       
        }).catch((error) => {
                console.error(error);
              });
       
        console.log("selectedHomeType----------->",selectedHomeType)
        if (res.data != 'Something went wrong!' && res.data != 'No Home Found!') {
            setHomesList(res.data)
            var tempHome_List = [];
            for(var i=0;i<res.data.length;i++){
                
                 if(res.data[i].homeActiveStatus && '101' == selectedHomeType){
                    tempHome_List.push(res.data[i])
                }
                else if(res.data[i].homeActiveStatus && res.data[i].type == selectedHomeType){
                    tempHome_List.push(res.data[i])
                }
            }
            console.log(homeListArray)
            setHomeListArray(tempHome_List)

        }
        else {

            setHomeListArray([])
        }
            
        }).catch((error) => {
                console.error(error);
              });
        
    }

    function addHomeText(event, id) {

        let newHomeDetailObj = {};
        newHomeDetailObj = { ...HomeDetails };
        if (id === 'hm1')
            newHomeDetailObj.name = event.target.value;
        else if (id === 'hm2')
            newHomeDetailObj.address = event.target.value;
        else if (id === 'hm3')
            newHomeDetailObj.contact_firstName = event.target.value;
        else if (id === 'hm4')
            newHomeDetailObj.contact_lastName = event.target.value;
        else if (id === 'hm5')
            newHomeDetailObj.phone_no = event.target.value;
        else if (id === 'hm6')
            newHomeDetailObj.email_id = event.target.value;
        else if (id === 'hm7')
        newHomeDetailObj.type = event.target.value;
   
        if(!newHomeDetailObj.userId && sessionStorage.getItem("userId") && sessionStorage.getItem("userType")!="admin")
        newHomeDetailObj.userId = sessionStorage.getItem("userId")
        newHomeDetailObj.org_id = params;
        newHomeDetailObj.home_id = newHomeId;
        newHomeDetailObj.homeActiveStatus = true;
        setHomeDetails(HomeDetails => ({
            ...HomeDetails, ...newHomeDetailObj
        }));


    }
    async function saveNewHomeText() {
        
        if(!HomeDetails.type){
            var newHomeDetailObj={};
            newHomeDetailObj = HomeDetails;
            newHomeDetailObj.type = '102'
            setHomeDetails(newHomeDetailObj)
        }
       

        if (HomeDetails.home_id && HomeDetails.name && HomeDetails.address && HomeDetails.contact_firstName && HomeDetails.contact_lastName && HomeDetails.phone_no && HomeDetails.email_id) {
            setErrors(false);
            const saveHomeUrl = BASE_API_URL + "orgnization/addNewHome"
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint =  "orgnization/addNewHome";
            apiRequest.data = HomeDetails;
            apiUtility.sendAPIDataPOST(apiRequest).then(res => {
                setHomeDetails({});
                getHomeData();
                    handleCloseHome();
                    var tempArr = [];
                    if(JSON.parse(sessionStorage.getItem("allHomeArr"))){
                        tempArr = JSON.parse(sessionStorage.getItem("allHomeArr"));
                        
                    }
                    tempArr.push(newHomeId)
                    sessionStorage.setItem("allHomeArr",String(tempArr))      
            }).catch((error) => {
                setErrors(true);
                    console.error(error);
                    // setshowSpinner(false);
                  });
        }
        else {
            setErrors(true);
        }

    }
    function handleShowHomeDetail(event,id){
        // var myJSONObject = {"customerid":"KumariHanisha","contactname":"Hanisha Kumari Chander","companyname":"Lambton College","contacttitle":"Owner","address":"Brampton","region":"Ontario","city":"Brampton","postalcode":"44343","country":"Canada","phone":"(5) 555-4711","fax":"(5) 555-2311","notes":"Lets work on Lianja Project"}
        //  const config = {
        //     headers : {
        //       'Content-Type': 'application/json',
        //       'lianjaApiKey':'HanishAPIKey',              
        //       'lianjaAuthorization': 'Basic ' + btoa("admin:" + 'Hanish')
        //     }
        //   };
        // axios.post('http://localhost:80/odata/southwind/customers', myJSONObject,config)
        // .then(res => {
        //     console.log(res);
            
        //     //  setNotificationText("New Staff Member was added");
        // })
        // .catch(err => {
        //     console.log(err);
        // })
        setId(id);
        setShowHomeDetailModal(true);
    }
    function handleShowStaff(event,id){
        setId(id);
        setShowStaffModal(true);
    }
    function handleShowCheckList(event,id){
        setId(id);
        setShowCheckListModal(true);
    }  
    async function showHomeRemoveOption(e,item){
        
        var tempHomeObj = {}

        console.log(e.target.checked,item)
        tempHomeObj.home_id = item.home_id;
        tempHomeObj.homeActiveStatus = e.target.checked
        const changeHomeActiveStatusUrl = BASE_API_URL + "orgnization/changeHomeActiveStatus"
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint =  "orgnization/changeHomeActiveStatus";
        apiRequest.data = tempHomeObj;
        apiUtility.updateAsyncAPIDataPUT(apiRequest).then(res => {
            handleCloseManageHome()
            alert("Home Status Changed!")
            getHomeData()        
        }).catch((error) => {
                console.error(error);
                // setshowSpinner(false);
              });
        // if(res){
        //     handleCloseManageHome()
        //     alert("Home Status Changed!")
        //     getHomeData()
        // }
        // axios.put(changeHomeActiveStatusUrl, tempHomeObj,{headers})
        // .then(res => {
        //     handleCloseManageHome()
        //     alert("Home Status Changed!")
        //     getHomeData()
        //     ////console.log(res);
        //     // getOrgData()
        // })
        // .catch(err => {
        //     //console.log(err);
        // })
   

    }

    function handleHomeTypeChange(homeType){
        console.log('homeType1',homeType)
    localStorage.setItem('DefaultHomeType',homeType)
        setSelectedHomeType(homeType)
        
        //getHomeData()

    }


    return (
        <div className='org-container'>
            <Row>
                {/* <br/> */}
                {/* <Col className="text-left text-muted"><br/><h1> List of Homes in the Organization</h1></Col> */}
                <Col>{(sessionStorage.getItem("orgId")==="undefined")?<div></div>:<div><Dropdown style={{ float: "right", marginRight: "1%", marginBottom: "1%" }}>
                <Dropdown.Toggle  variant="warning"  id="dropdown-basic">
                {/* {selectedHomeType} */}
                {homesType.find(i=>i.id==selectedHomeType)?homesType.find(i=>i.id==selectedHomeType).value:''}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {homesType?.map((item)=>{

                    return    <Dropdown.Item onClick={() => handleHomeTypeChange(item.id)}>{homesType.find(i=>i.id==item.id).value}</Dropdown.Item>
                    // <Dropdown.Item onClick={() => handleHomeTypeChange('LTC and Retirement Homes')}>LTC and Retirement Homes</Dropdown.Item>
                    // <Dropdown.Item onClick={() => handleHomeTypeChange('LTC Homes Only')}>LTC Homes Only</Dropdown.Item>
                    // <Dropdown.Item onClick={() => handleHomeTypeChange('Retirement Homes Only')}>Retirement Homes Only</Dropdown.Item>
                    })}
                    
                </Dropdown.Menu>
                </Dropdown><Button style={{ float: "right", marginRight: "1%", marginBottom: "1%" }} variant="warning" onClick={handleShowManageHome} >Manage Homes</Button><Button style={{ float: "right", marginRight: "1%", marginBottom: "1%" }} variant="warning" onClick={handleShowHome} >Add New Home</Button></div>}</Col>
            </Row>
            
            <Modal show={showManageHome} onHide={handleCloseManageHome} className="custom-map-modal">
            <Modal.Header closeButton>
                                <Modal.Title>Enable/Disable Home</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
            <div style={{"marginBottom":"8pc"}}>
            
            <Row className="justify-content-md-center">
           
                <Form style={{width:"50%"}}>
                            <br></br>
                            <br></br>
                                    <h3 className="text-center">All Homes List:</h3>
                                    {homes_list.length>0?
                                     <ListGroup as="ol" numbered>
                                     {['checkbox'].map((type) => (
                                         <div key={`reverse-${type}`} className="mb-3">
                                             {homes_list?.map((item, _id) => {
                                                 return <ListGroup.Item as="li"> <Form.Check  label={item.name} name="group1" type={type} defaultChecked={item.homeActiveStatus} id={`reverse-${type}-1`} onChange={(e) => {
                                                     showHomeRemoveOption(e, item)
                                                 }} /></ListGroup.Item>
                                             })}
                                     
                                         </div>
                                     ))}
                                 </ListGroup>:<div>No Home Found!</div>
                                 
                                    }
                                   
                </Form>
                
            </Row>
     
    {/* </Form> */}
    

</div>
</Modal.Body>
<Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseManageHome}>
                                    Close
                                </Button>
                                {/* <Button variant="primary" onClick={saveNewOrgText} >
                                    Save Changes
                                </Button> */}
                            </Modal.Footer>
            </Modal>
            
             <Modal show={showHome} onHide={handleCloseHome} id="addHomeInOrg">
                <Modal.Header closeButton>
                    <Modal.Title>Add New Home in Organization</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-2 col-xs-6" controlId="formBasicEmail">
                            <Form.Label>Enter the Home Name</Form.Label>
                            <Form.Control type="input"
                                onChange={(e) => {
                                    addHomeText(e, 'hm1');
                                }}
                            />
                            <Form.Label>Enter the Home Address</Form.Label>
                            <Form.Control type="input"
                                onChange={(e) => {
                                    addHomeText(e, 'hm2');
                                }}
                            />
                            <Form.Label>Enter the Contact First Name</Form.Label>
                            <Form.Control type="input"
                                onChange={(e) => {
                                    addHomeText(e, 'hm3');
                                }}
                            />
                            <Form.Label>Enter the Contact Last Name</Form.Label>
                            <Form.Control type="input"
                                onChange={(e) => {
                                    addHomeText(e, 'hm4');
                                }}
                            />
                            <Form.Label>Enter the Phone Number</Form.Label>

                            <Form.Control type="input"
                                onChange={(e) => {
                                    addHomeText(e, 'hm5');
                                }}
                            />
                            <Form.Label>Enter the Email ID</Form.Label>

                            <Form.Control type="input"
                                onChange={(e) => {
                                    addHomeText(e, 'hm6');
                                }}
                            />
                              {/* <Form.Group as={Col} controlId="formBasicEmail"> */}
                           
                           {/* <Form.Label>Select an Organisation</Form.Label> */}
                           <p className="text-muted"> Select Home Type</p>
                           <Form.Select  aria-label="Default select example"   onChange={(e) => {
                               addHomeText(e,'hm7');
                           }} >
                            {!sessionStorage.getItem('home_types')?
                            <option>Please select a home type</option>:
                            JSON.parse(sessionStorage.getItem('home_types')).map((item)=>{
                                return (item.id==101?'':<option value={item.id}>{item.value}</option>)
                                // return 
                            })
                            }
                            {/* <option value='L'>LTC</option>
                            <option value='R'>Retirement</option>
                            <option value='LR'>Both LTC and Retirement</option> */}
                           </Form.Select>
                      
                     
                           {/* </Form.Group><Col xs={0} md={4}></Col> */}
                            {/* <Form.Label>Enter Home Type</Form.Label>

                            <Form.Control type="input"
                                onChange={(e) => {
                                    addHomeText(e, 'hm7');
                                }}
                            /> */}
                        </Form.Group>
                        
                        <div>
                            {errors ? (
                                <p className="text-danger">*All fields are mandatory</p>

                            ) : (
                                <p className="text-danger"></p>

                            )}
                        </div>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseHome} >
                        Close
                    </Button>
                    <Button variant="primary" onClick={saveNewHomeText} >
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            
            <Modal show={showHomeDetailModal} onHide={handleCloseHomeDetail} className=" custom-map-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Home Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <HomeMainComponent id={id}  />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseHomeDetail} >
                        Close
                    </Button>
                    {/* <Button variant="primary" onClick={saveNewHomeText} >
                        Save Changes
                    </Button> */}
                </Modal.Footer>
            </Modal>
            <Modal show={showStaffModal} onHide={handleCloseStaff}>
                <Modal.Header closeButton>
                    <Modal.Title> Staff Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <StaffComponent id={id} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseStaff} >
                        Close
                    </Button>
                   
                </Modal.Footer>
            </Modal>
            
            {showCheckListModal?
            <Modal show={showCheckListModal} fullscreen={true} onHide={handleCloseCheckList} className="custom-map-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Home Position Templates</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <HomeCheckListComponent id={id} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCheckList} >
                        Close
                    </Button>
                   
                </Modal.Footer>
            </Modal>:""}
                

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Home ID</th>
                        <th>Home Name</th>
                        {/* <th>Action</th> */}
                        <th>Manage Staff</th>
                        <th>Position Templates</th>
                    </tr>
                </thead>



                {homeListArray.map((data, id) => {
                    return <tbody key={id}>
                        <tr >
                            <td>{data.home_id}</td>
                            <td style={{cursor:"pointer"}} onClick={(e) => {
                                    handleShowHomeDetail(e, data.home_id);
                                }}><b>{data.name}</b></td>
                            {/* <td> <Link to={`/home/${data.home_id}`}><Button variant="warning" onClick={handleShowHomeDetail}>View Home Details</Button></Link></td>
                            <td><Link to={`/showStaff/${data.home_id}`}><Button variant="warning" onClick={handleShowStaff}>View Staff Members</Button></Link></td>
                            <td><Link to={`/checkList/${data.home_id}`}><Button variant="warning" onClick={handleShowCheckList}>View CheckList Component</Button></Link></td>
                       */}
                        {/* <td> <Link ><Button variant="warning" onClick={handleShowHomeDetail}>View Home Details</Button></Link></td> */}
                            <td><Button variant="warning"  onClick={(e) => {
                                    handleShowStaff(e, data.home_id);
                                }}
                               >View Staff Members</Button></td>
                            <td><Button variant="warning" onClick={(e) => {
                                    handleShowCheckList(e, data.home_id);
                                }} >View Position Templates</Button></td>
                      
                        </tr>

                    </tbody>
                })}

            </Table>
        </div>);
}