import React, { useState } from 'react'
import { Col, Row, Nav, Navbar, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { SlSocialFacebook, SlSocialTwitter, SlSocialYoutube } from "react-icons/sl";

const Footer = () => {

    const [showPrivacyModal, setShowPrivacyModal] = useState(false);
    const [showTermsModal, setShowTermsModal] = useState(false);

    const SOCIAL_MEDIA_URLS = Object.freeze({
        TWITTER: "https://twitter.com/",
        FACEBOOK: "https://www.facebook.com/" ,
        YOUTUBE: "https://www.youtube.com/"
    })

    return (
            <footer className="main text-secondary">
            <Container fluid style={{"background":"linear-gradient(0deg, rgba(255,255,255,1)0%, rgba(212,231,240,1) 100%)"}}>
                <br></br>
                <Row>
                    <Col className="justify-content-center d-flex">
                        <Nav className="flex-column">
                            <Nav.Link className="text-dark" href="/home">Home</Nav.Link>
                            <Nav.Link className="text-dark" href="/about">About Us</Nav.Link>
                            {/* <Nav.Link className="fw-bold" href="/support">Support</Nav.Link> */}
                            <Nav.Link onClick={() => setShowPrivacyModal(true)} className="text-dark" >Privacy Policy</Nav.Link>
                            <Nav.Link onClick={() => setShowTermsModal(true)} className="text-dark" >Terms of use</Nav.Link>
                        </Nav>
                    </Col>
                    <Col className="justify-content-center d-flex">
                        <Nav className="flex-column">
                                <Nav.Link className="text-dark" href="/home">Contact Us</Nav.Link> 
                                <Nav.Link className="text-dark text-muted" href="tel:519-434-9700">carepassporthelp@lambtoncollege.ca</Nav.Link>     
                        </Nav>
                    </Col>
                    <Col className="justify-content-center d-flex">
                        <Navbar.Brand as={Link} className="m-0 logo" to=""> </Navbar.Brand>
                    </Col>
                    <Col className="justify-content-center d-flex d-none d-md-block">
                        <Nav className="flex-row pt-4">
                        
                            <Nav.Link target="_blank" href={SOCIAL_MEDIA_URLS.TWITTER}  className="text-dark px-1">
                                <SlSocialTwitter style={{"fontSize":"2pc"}}/>
                            </Nav.Link>
                            <Nav.Link target="_blank" href={SOCIAL_MEDIA_URLS.FACEBOOK}  className="text-dark px-1">
                                <SlSocialFacebook style={{"fontSize":"2pc"}}/>
                            </Nav.Link>
                            <Nav.Link target="_blank" href={SOCIAL_MEDIA_URLS.YOUTUBE} className="text-dark px-1">
                                <SlSocialYoutube style={{"fontSize":"2pc"}}/>
                            </Nav.Link>
                        </Nav> 
                    </Col>
                    
                </Row>
                <div className="text-center mt-4">
                    <div className="justify-content-center d-flex d-md-none">
                        <Nav className="flex-row pt-4 justify-content-center">
                            <Nav.Link target="_blank" href={SOCIAL_MEDIA_URLS.TWITTER}  className="text-dark px-1">
                                <SlSocialTwitter style={{"fontSize":"2pc"}}/>
                            </Nav.Link>
                            <Nav.Link target="_blank" href={SOCIAL_MEDIA_URLS.FACEBOOK}  className="text-dark px-1">
                                <SlSocialFacebook style={{"fontSize":"2pc"}}/>
                            </Nav.Link>
                            <Nav.Link target="_blank" href={SOCIAL_MEDIA_URLS.YOUTUBE} className="text-dark px-1">
                                <SlSocialYoutube style={{"fontSize":"2pc"}}/>
                            </Nav.Link>
                        </Nav> 
                    </div>
                    <small className="fw-bold text-muted mt-5">&copy; {new Date().getFullYear()} LCPT, </small> <br/>
                    <small className="fw-bold text-muted mt-5">All Rights Reserved. </small><br/>
                    <small className="fw-bold text-muted mt-5">Powered By Ontario Long Term Care Orientation Co-operative Webportal </small>
                </div>
            </Container>
            </footer>
    )
}

export default Footer;
