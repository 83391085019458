
import './organisation.css';
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Form, Row, Col, Button, Container, FormText } from 'react-bootstrap';
import axios from 'axios';
import { BASE_API_URL } from '../../config/Url-config';
import { BASE_URL_FRONTEND } from '../../config/Url-config';
import * as apiUtility from "../../config/APIUtils";

export default function RoleTemplate(props) {
    var { homeId, roleId } = useParams();
    if(props.homeId && props.roleId){
        homeId = props.homeId;
        roleId = props.roleId;
    }
    const [templateDetails, setTemplateDetails] = useState({});
    const [showTemplateDetails, setShowTemplateDetails] = useState(false)
    const accessToken = sessionStorage.getItem('authToken');
    const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    useEffect(async () => {
        // if (sessionStorage.getItem("orgId") && sessionStorage.getItem("userType") != 'admin' && sessionStorage.getItem("homeId") != String(homeId)) {
        //     alert("Sorry.Access Not Permitted")
        //     return window.location.href = BASE_URL_FRONTEND;

        // }
        // if(sessionStorage.getItem("userType")==='organization'){
        //     if(JSON.parse(sessionStorage.getItem("OtherOrgId")).find(item=>{ item!=(String(params))){
        //       return window.location.href = BASE_URL_FRONTEND;  
        //     }
        //   }
        // if (sessionStorage.getItem("OrgAllHomeId")) {

        //     var flag = false;
        //     for (var i = 0; i < JSON.parse(sessionStorage.getItem("OrgAllHomeId")).length; i++) {
        //         if (String(homeId) == JSON.parse(sessionStorage.getItem("OrgAllHomeId"))[i]) {
        //             ////console.log("found", String(homeId), JSON.parse(sessionStorage.getItem("OrgAllHomeId"))[i])
        //             flag = true;
        //             break;
        //         }
        //     }
        //     if (!flag) {
        //         alert("Sorry.Access Not Permitted")
        //         return window.location.href = BASE_URL_FRONTEND;
        //     }
        // }
        const params = homeId + "/" + roleId

        const getRoleTemplateUrl = BASE_API_URL + "orgnization/getRoleTemplateDetails/" + params
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint =  "orgnization/getRoleTemplateDetails/" + params;
        //apiRequest.data = tempHomeObj;
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
            if (res == 'Something went wrong!' || res == 'No Role Found!')
                    console.log("No data")
                else {
                    console.log(res.data[0]);
                    setTemplateDetails(res.data[0])
                    if (templateDetails != {})
                        setShowTemplateDetails(true)
                }       
        }).catch((error) => {
            setShowTemplateDetails(false)

                console.error(error);
                // setshowSpinner(false);
              });
        // if(res){
        //     if (res == 'Something went wrong!' || res == 'No Role Found!')
        //             console.log("No data")
        //         else {
        //             ////console.log(res.data[0]);
        //             setTemplateDetails(res.data[0])
        //             if (templateDetails != {})
        //                 setShowTemplateDetails(true)
        //         }
        // }
        // axios.get(getRoleTemplateUrl,{headers})
        //     .then(res => {
        //         if (res == 'Something went wrong!' || res == 'No Role Found!')
        //             console.log("No data")
        //         else {
        //             ////console.log(res.data[0]);
        //             setTemplateDetails(res.data[0])
        //             if (templateDetails != {})
        //                 setShowTemplateDetails(true)
        //         }

        //     })
        //     .catch(err => {
        //         //console.log(err);
        //     })
    }, [])


    function changeRoleTemplateText(event, id) {
        let roleDetailObj = {};
        roleDetailObj = { ...templateDetails };
        if (id === 'det1')
            roleDetailObj.role_name = event.target.value;
        else if (id === 'det2')
        roleDetailObj.role_details = event.target.value;
               setTemplateDetails(templateDetails => ({
            ...templateDetails, ...roleDetailObj
        }));

        // setHomeDetails(newHomeDetailObj);
        ////console.log("template details:", templateDetails)

    }
    async function saveRoleTemplateText() {
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint =  "orgnization/editRoleTemplateDetails";
        apiRequest.data = templateDetails;
        var res = await apiUtility.updateAsyncAPIDataPUT(apiRequest).then(res => {
            alert('Role Details saved successfully!')       
        }).catch((error) => {
                console.error(error);
                alert('Something went wrong!');
              });
        // if(res){
        //     alert('Role Details saved successfully!')
        // }
        // else{
        //     alert('Something went wrong!')
        // }
        // axios.put(BASE_API_URL + "orgnization/editRoleTemplateDetails", templateDetails,{headers})
        //     .then(res => {
        //         ////console.log(res);
        //         alert('Role Details saved successfully!')

        //     })
        //     .catch(err => {
        //         alert('Something went wrong!')
        //         //console.log(err);
        //     })
    }


    return (

        <div style={{ marginTop: "8vh" }} >
            <h1>Role Template </h1>
            <div className='org-container'>
            {showTemplateDetails ? (
                           <Form>
                            <Row>
                                <Col>
                           <Form.Group className="mb-2 col-xs-6" controlId="formBasicEmail">
                               <Form.Label style={{display:"block"}}>Role Name</Form.Label>
                               <Form.Control type="input"
                                   defaultValue={templateDetails.role_name} 
                               onChange={(e) => {
                                   changeRoleTemplateText(e, 'det1');
                               }}
                               />
                           </Form.Group>
                           </Col>
                           <Col>
                           <Form.Group className="mb-3 col-xs-6" controlId="formBasicEmail">
                               <Form.Label style={{display:"block"}}>Role Summary</Form.Label>
                               <Form.Control type="input"
                                   defaultValue={templateDetails.role_details} 
                                   onChange={(e) => {
                                       changeRoleTemplateText(e, 'det2');
                                   }}
                               />
                           </Form.Group>
                           </Col>
                           </Row>
                           <Row>
                            <Col>
                           <Form.Group className="mb-3 col-xs-6" controlId="formBasicEmail">
                               <Form.Label style={{display:"block"}}>Role ID</Form.Label>
                               <Form.Control type="input"
                                   defaultValue={templateDetails.role_id} disabled
                               />
                           </Form.Group>
                           </Col>
                           <Col>
                           <Form.Group className="mb-3 col-xs-6" controlId="formBasicEmail">
                               <Form.Label style={{display:"block"}}>Archived Status</Form.Label>
                               <Form.Control type="input"
                                   defaultValue={templateDetails.archived} disabled
                               />
                           </Form.Group>
                           </Col>
                           </Row>
                           {/* <Form.Group className="mb-3 col-xs-6" controlId="formBasicEmail">
                               <Form.Label style={{display:"block"}}><b>Courses Mandatory for this Role : </b></Form.Label>
                               {showTemplateDetails ? (
                                   templateDetails.course_details.map((data, id) => {
                                       return <ul key={id}>
                                           <li><b>{data.id}</b> - {data.details}</li>
                                       </ul>
                                   })
                               ) : (
       
                                   <p>...Data Loading.....</p>)
                               }
                           </Form.Group> */}
       
       
                           <Button
                               onClick={saveRoleTemplateText}
                               variant="warning"  >
                               Save Changes
                           </Button>
                       </Form>
                        ) : (

                            <p>...Data Loading.....</p>)
                        }
                
            </div>
        </div>)

}