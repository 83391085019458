import React from 'react';
import { Card } from 'react-bootstrap';
import { IoWarningOutline } from "react-icons/io5";

function MessageBox(props) {

return (
    <Card flex style={{"margin":"2pc","width":"90%","border":"0px","border-radius":"1.5px"}} className='mx-auto'>
        <p style={{"margin":"2pc"}} className="text-muted text-center p-3">
            <IoWarningOutline style={{"fontSize":"2pc"}} className="text-muted text-center"/>
            <br></br>{props.data}</p>
    </Card>
);}
export default MessageBox;