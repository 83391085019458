
import './organisation.css';
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Form, Row, Col, Button, Container, FormText } from 'react-bootstrap';
import axios from 'axios';
import { BASE_API_URL } from '../../config/Url-config';
import { BASE_URL_FRONTEND } from '../../config/Url-config';
import * as apiUtility from "../../config/APIUtils";

export default function RoleTemplateOrg(props) {
    var { orgId, roleId } = useParams();
    if(props.orgId && props.roleId){
        orgId = props.orgId;
        roleId = props.roleId;
    }
    const [templateDetails, setTemplateDetails] = useState({});
    const [showTemplateDetails, setShowTemplateDetails] = useState(false)
    
    useEffect(async () => {
        const params = orgId + "/" + roleId

        // const getRoleTemplateUrl = BASE_API_URL + "orgnization/getRoleTemplateDetailsForOrg/" + params
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint =  "orgnization/getRoleTemplateDetailsForOrg/" + params;
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
            if (res == 'Something went wrong!' || res == 'No Role Found!')
                    console.log("No data")
                else {
                    console.log(res)
                    setTemplateDetails(res.data)
                    if (templateDetails != {})
                        setShowTemplateDetails(true)
                }       
        }).catch((error) => {
                console.error(error);
                // setshowSpinner(false);
              });
        
    }, [])


    function changeRoleTemplateText(event, id) {
        let roleDetailObj = {};
        roleDetailObj = { ...templateDetails };
        if (id === 'det1')
            roleDetailObj.role_name = event.target.value;
        else if (id === 'det2')
        roleDetailObj.role_details = event.target.value;
               setTemplateDetails(templateDetails => ({
            ...templateDetails, ...roleDetailObj
        }));


    }
    async function saveRoleTemplateText() {
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint =  "orgnization/editRoleTemplateDetailsForOrg";
        apiRequest.data = templateDetails;
        console.log('templateDetails----------->',templateDetails)
        var res = await apiUtility.updateAsyncAPIDataPUT(apiRequest).then(res => {
            alert('Role Details saved successfully!')       
        }).catch((error) => {
                console.error(error);
                alert('Something went wrong!');
              });
    }


    return (

        <div style={{ marginTop: "8vh" }} >
            <h1>Role Template </h1>
            <div className='org-container'>
            {showTemplateDetails ? (
                           <Form>
                            <Row>
                                <Col>
                           <Form.Group className="mb-2 col-xs-6" controlId="formBasicEmail">
                               <Form.Label style={{display:"block"}}>Role Name</Form.Label>
                               <Form.Control type="input"
                                   defaultValue={templateDetails.role_name} 
                               onChange={(e) => {
                                   changeRoleTemplateText(e, 'det1');
                               }}
                               />
                           </Form.Group>
                           </Col>
                           <Col>
                           <Form.Group className="mb-3 col-xs-6" controlId="formBasicEmail">
                               <Form.Label style={{display:"block"}}>Role Summary</Form.Label>
                               <Form.Control type="input"
                                   defaultValue={templateDetails.role_details} 
                                   onChange={(e) => {
                                       changeRoleTemplateText(e, 'det2');
                                   }}
                               />
                           </Form.Group>
                           </Col>
                           </Row>
                           <Row>
                            <Col>
                           <Form.Group className="mb-3 col-xs-6" controlId="formBasicEmail">
                               <Form.Label style={{display:"block"}}>Role ID</Form.Label>
                               <Form.Control type="input"
                                   defaultValue={templateDetails.role_id} disabled
                               />
                           </Form.Group>
                           </Col>
                           <Col>
                           <Form.Group className="mb-3 col-xs-6" controlId="formBasicEmail">
                               <Form.Label style={{display:"block"}}>Archived Status</Form.Label>
                               <Form.Control type="input"
                                   defaultValue={templateDetails.archived} disabled
                               />
                           </Form.Group>
                           </Col>
                           </Row>
                           {/* <Form.Group className="mb-3 col-xs-6" controlId="formBasicEmail">
                               <Form.Label style={{display:"block"}}><b>Courses Mandatory for this Role : </b></Form.Label>
                               {showTemplateDetails ? (
                                   templateDetails.course_details.map((data, id) => {
                                       return <ul key={id}>
                                           <li><b>{data.id}</b> - {data.details}</li>
                                       </ul>
                                   })
                               ) : (
       
                                   <p>...Data Loading.....</p>)
                               }
                           </Form.Group> */}
       
                          
       
                           <Button
                               onClick={saveRoleTemplateText}
                               variant="warning"  >
                               Save Changes
                           </Button>
                       </Form>
                        ) : (

                            <p>...Data Loading.....</p>)
                        }
                
            </div>
        </div>)

}