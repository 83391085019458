import { Form, Row, Col, Button, Modal, Container, Table } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import * as apiUtility from '../../config/APIUtils';
import { IoIosAddCircleOutline } from "react-icons/io";
import { SlCheck } from "react-icons/sl";
import { Typography } from '@mui/material';

function UserBadgeUrlDialog(props) {

  const [badgeUrlResponse, setbadgeUrlResponse] = useState('');
  //const [courseBadgeUrl, setCourseBadgeUrl] = useState('');
  const [courseBadgeTableData, setCourseBadgeTableData] = useState([]);
  //const [addAnotherField, setAddAnotherField] = useState(false);
  const [disabled, setDisabled] = useState(false);
  //const [buttonText, setButtonText] = useState(<IoIosAddCircleOutline />);

  const BASE_URL_SAVE_BADGE_URL = "user/saveBadgeUrl/";
  const BASE_GET_USER_BADGE_URL_DATA = "course/fetchUserBadgeUrls/";
  var userId = props.userId;
  var data = props.data;
  var validityDuration = props.data.validFor;

  var training_providers = JSON.parse(sessionStorage.getItem("training_providers"));
  var mapOfTpIdName = {};
  if (training_providers !== undefined && training_providers !== null) {
      training_providers?.forEach(element => {
          mapOfTpIdName[element.tp_id] = element.provider_name;
      });
  } else {
      mapOfTpIdName["1000"] = "Lambton College";
  }

  useEffect(() => {
    fetchUsrCourseData();
    setbadgeUrlResponse('');
    setDisabled((data.status === 'Pending') ? false : true)
  }, [props.data]);

  // const setBadgeUrlMethod = e => {
  //   const { name, value } = e.target
  //   setCourseBadgeUrl(value);
  // };


  // const createAnotherField = e => {
  //   if (addAnotherField) {
  //     setAddAnotherField(false);
  //     setButtonText(<IoIosRemoveCircleOutline />)
  //   } else {
  //     setAddAnotherField(true);
  //     setButtonText(<IoIosAddCircleOutline />)
  //   }

  // };

  const fetchUsrCourseData = () => {
    try {
      //console.log("User Id "+userId+" COURSE "+props.data.crsVersionId);
      if (userId === undefined || userId === "" || props.data.crsId === undefined) {
        return "";
      }
      var apiRequest = apiUtility.apiInputRequest;
      apiRequest.endPoint = BASE_GET_USER_BADGE_URL_DATA + userId + "/" + props.data.crsId;
      apiUtility.fetchAPIDataGET(apiRequest).then(res => {
        //console.log("Response is here : ",res, props);
        setCourseBadgeTableData(res.data);
      }).catch(err => {
        console.log("Error is here : ", err.response);
      });
    } catch (error) {
      console.log(error);
    }
  }

  // const submitBadgeUrl = () => {
  //   try {
  //     if (courseBadgeUrl !== "") {
  //       //const body = JSON.stringify({ userId: data.userId, courseId: data.crsId, badgeUrl: courseBadgeUrl, crsVersionId: data.crsVersionId, validityDuration: validityDuration });
  //       var apiRequest = apiUtility.apiInputRequest;
  //       apiRequest.endPoint = BASE_URL_SAVE_BADGE_URL;
  //       apiRequest.data = { userId: data.userId, courseId: data.crsId, badgeUrl: courseBadgeUrl, crsVersionId: data.crsVersionId, validityDuration: validityDuration };
  //       apiUtility.sendAPIDataPOST(apiRequest).then(res => {
  //         props.handleUpdateCallback(res)
  //         setbadgeUrlResponse(res.data);
  //         // console.log("Response is here : ",res);
  //         fetchUsrCourseData();
  //       }).catch(err => {
  //         setbadgeUrlResponse(err.response.data);
  //         //console.log("Error is here : ",err.response);
  //       });
  //     } else {
  //       alert("Enter your badge url!")
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <div>
      <Container>
        <Row>
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="modal-70w"
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Course Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row>
                  <Row>
                    <Typography variant='h6' gutterBottom className="mb-12 text-center" style={{ color: '#0f6fc5' }}>{data.title}</Typography>
                  </Row>
                  <Row>
                    <Typography variant='subtitle2' gutterBottom className="mb-12 text-center" style={{ color: '#0f6fc5' }}>- By&nbsp;{mapOfTpIdName[parseInt(data.orgIssued)]}</Typography>
                  </Row>

                  <Row className='bg-light border' >
                    <Col xs={12} md={4}>
                      <br></br>
                      <div style={{ "width": "20pc" }}>
                        <Row className="mb-12 text-left">
                          <Col><Typography variant="body2" gutterBottom>Code : {data.crsId}</Typography></Col>
                        </Row>
                        <Row className="mb-12 text-left">
                          <Col><Typography variant="body2" gutterBottom>Version Code : {data.tp_course_code}</Typography></Col>
                        </Row>
                        <Row className="mb-12 text-left">
                          <Col><Typography variant="body2" gutterBottom>Version Number : {data.versionNo}</Typography></Col>
                        </Row>
                        <Row className="mb-12 text-left">
                          <Col><Typography variant="body2" gutterBottom>Training Duration : {data.trainDuration}</Typography></Col>
                        </Row>
                      </div>
                      <br></br>
                    </Col>

                    <Col xs={12} md={8} style={{ "border-left": "1px solid grey" }}>
                      <br></br>
                      <Typography variant="body2" className="mb-12 text-center" gutterBottom>{data.desc}</Typography>
                      <br></br>
                    </Col>
                  </Row>
                </Row>
                <br></br>

                {(courseBadgeTableData.length > 0) ?
                  <Row style={{ "width": "100%" }}>
                    <h3 className='text-center'>Course History</h3>
                    <br></br>
                    <Table striped bordered hover>
                      <thead className='table-light'>
                        <tr className='text-center'>
                          <th>Course Code</th>
                          <th>Version Code</th>
                          <th>Process</th>
                          <th>Status</th>
                          <th>Registration Date</th>
                          <th>Expiry Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {courseBadgeTableData?.map((data2, id) => (
                          (props.data.crsVersionId === data2.course_version_id) && <tr>
                            <td>{data2.course_id}</td>
                            <td>{data.tp_course_code}</td>
                            <td>{data2.badging_document_url}</td>
                            <td>{data2.verification}</td>
                            <td>{data2.completion_date}</td>
                            <td>{data2.expiry_date}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Row>

                  :
                  <Row className='text-center'><Typography variant='subtitle2' gutterBottom>No prior course history available</Typography></Row>
                }


                <br></br>
                <Row className="mb-12 text-center">
                  <Col md={12}> <h5>{(badgeUrlResponse !== "") ? <SlCheck /> : ""}&nbsp;&nbsp;{badgeUrlResponse}</h5></Col>
                </Row>
              </Container>
              <Form onSubmit={event => event.preventDefault()}>
                <Container>
                  <Row>
                    {/* <Typography variant='caption' gutterBottom>To submit a request for credit for prior training, enter your verified Badge URL here.</Typography>
                    {
                      disabled ?
                        <div class="input-group mb-3">
                          <input type="input" class="form-control" value={data.badgeUrl} aria-label="badgeUrl" aria-describedby="button-addon2" />
                          <Button class="btn btn-outline-success" onClick={createAnotherField} id="button-addon2">{buttonText}</Button>
                        </div> :
                        <div class="input-group mb-3">
                          <input type="input" class="form-control" autoFocus onBlur={setBadgeUrlMethod} placeholder="Please enter your badge URL" aria-label="badgeUrl" aria-describedby="button-addon2" />
                        </div>
                    }
                    {
                      addAnotherField ?
                        <div class="input-group mb-3">
                          <input type="input" class="form-control" autoFocus onBlur={setBadgeUrlMethod} placeholder="Please enter your new badge URL" aria-label="badgeUrl" aria-describedby="button-addon2" />
                        </div>
                        : ""
                    } */}


                    {/* {disabled ?  <Form.Group className="mb-3" display={disabled} controlId="exampleForm.ControlInput1">
              <Form.Label>Badge URL</Form.Label>
              <Form.Control
                type="input"
                value={data.badgeUrl}
              />
            </Form.Group> : <Form.Group className="mb-3" display={disabled} controlId="exampleForm.ControlInput1">
              <Form.Label>Badge URL &nbsp;</Form.Label>
              <Form.Control
                type="input"
                placeholder="Please enter a badge url"
                autoFocus
                onBlur={setBadgeUrlMethod}
              />
            </Form.Group> } */}

                  </Row>
                </Container>

              </Form>
            </Modal.Body>
            <Modal.Footer>
              {/* <Button type="button" variant="success" onClick={submitBadgeUrl}>
                Submit
              </Button> */}
              <Button type="button" variant="primary" onClick={props.onHide}>
                Close
              </Button>

            </Modal.Footer>
          </Modal>
          {/* <SuccessPopUp show={showSuccessPopup} data={dataForSuccessPopup} onHide={() => { setShowSuccessPopup(false)}}/> */}
        </Row>
      </Container>
    </div>

  );
}

// function App() {
//   const [modalShow, setModalShow] = React.useState(false);

//   return (
//     <>
//       <Button variant="primary" onClick={() => setModalShow(true)}>
//         Launch vertically centered modal
//       </Button>

//       <MyVerticallyCenteredModal
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//       />
//     </>
//   );
// }

// render(<App />);

export default UserBadgeUrlDialog;