import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Form, Row, Col, Button, ListGroup, Container } from 'react-bootstrap'
import * as apiUtility from "../../config/APIUtils";
import { Paper, Typography } from "@mui/material";

function AddNewAdmin() {
    const [userIdList, setUserIdList] = useState([]);
    const [userId, setUserId] = useState("");
    const [adminUserIdList, setAdminUserIdList] = useState([]);

    useEffect(() => {
        getPermissionDetails()
    }, [])

    async function getPermissionDetails() {
        // user / getAll
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "user/getAll";
        // apiRequest.data = newUser;
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {

            var userIDListArr = [];

            for (let i = 0; i < res.data.data.length; i++) {
                if (res.data.data[i]["firstName"] === undefined) {
                    res.data.data[i]["firstName"] = "";
                }
                if (res.data.data[i]["lastName"] === undefined) {
                    res.data.data[i]["lastName"] = "";
                }
                userIDListArr.push(res.data.data[i]["firstName"] + " " + res.data.data[i]["lastName"] + " (" + res.data.data[i]["user_id"] + ")");
            }
            setUserIdList(userIDListArr);
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "permissions/getUsersByPermission/admin/1";
            // apiRequest.data = newUser;
            apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
                //console.log("permissions",res.data);
                var userIDListArr = [];
                for (let i = 0; i < res.data.length; i++) {
                    userIDListArr.push(res.data[i]);
                }
                setAdminUserIdList(userIDListArr)
            }).catch((error) => console.error(error));
            if (res) {
                //console.log("permissions",res.data);
                var userIDListArr = [];
                for (let i = 0; i < res.data.length; i++) {
                    userIDListArr.push(res.data[i]);
                }
                setAdminUserIdList(userIDListArr)
            }

        }).catch((error) => console.error(error));;

    }

    async function addNewOrganizationClicked(e) {
        if (userId === "") {
            var retMsg = document.getElementById("returnMsgOrg");
            retMsg.innerHTML = "User ID cannot be empty";
            retMsg.style.color = "red";

        } else {
            var permissionObj = {
                "userId": userId,
                "permissionType": "admin",
                "permissionOfId": ""
            }
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "permissions/add";
            apiRequest.data = { permissionObj };
            apiUtility.sendAPIDataPOST(apiRequest).then(res => {
                var retMsg = document.getElementById("returnMsgAdmin");
                retMsg.innerHTML = res.data.msg;
            }).catch((error) => console.error(error));

        }

    }

    function alertAdminUser() {
        alert("Mininum one admin required!")
    }

    async function showAdminRemoveOption(e, item) {

        if (adminUserIdList.length > 1) {

            var permissionObj = {
                "userId": item,
                "permissionType": "admin",
                "permissionOfId": ""
            }
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "permissions/remove";
            apiRequest.data = { permissionObj };
            await apiUtility.sendAPIDataPOST(apiRequest).then(res => {
                getPermissionDetails()
            }).catch((error) => console.error(error));
        }
        else {
            var doc = document.getElementById("userIdAutoInputs");
            doc.checked = true
            // console.log(doc.checked)
            alert("Mininum one admin required!")
        }


    }

    return (
        <div style={{ "marginBottom": "5pc" }}>
            <Container fluid>
            <Paper variant="outlined">
                
                    <Row className="justify-content-md-center shadow p-3 mb-2 bg-white" style={{ "border": "1px solid #ebebe6" }}>
                        <Row><Typography variant="h6" color={"primary"} align="center">System Administrator Permissions</Typography></Row>
                        {/* <Col>
                                <h2>User Id: {userId}</h2>
                             </Col> */}
                        <Row>
                            <Col></Col>
                            <Col>

                                <Autocomplete
                                    disablePortal
                                    id="userIdAutoInputs"
                                    options={userIdList}
                                    sx={{ width: 300 }}
                                    onChange={(e, v) => {
                                        var val = v.split("(")[1].split(")")[0];
                                        //console.log("in on change  " + val)
                                        setUserId(val);
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Users" />}
                                />

                            </Col>
                            <Col>
                                <Button style={{ marginTop: "3%" }} variant="primary" type="submit" onClick={addNewOrganizationClicked}>
                                    Add User
                                </Button>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Row>
                    <Row className="justify-content-md-center">
                        <h2 id="returnMsgAdmin" style={{ "color": "green" }}></h2>
                    </Row>
                    <Row className="justify-content-md-center" >

                        <Form style={{ width: "50%" }}>
                            <br></br>
                            <br></br>
                            <h3 className="text-center">Existing System Admins</h3>
                            {adminUserIdList.length > 1 ?
                                <ListGroup as="ol" numbered>
                                    {['checkbox'].map((type) => (
                                        <div key={`reverse-${type}`} className="mb-3">
                                            {adminUserIdList.map((item, _id) => {
                                                return <ListGroup.Item as="li"> <Form.Check value={item.user_id} label={item.user_name} name="group1" type={type} defaultChecked={true} id={`reverse-${type}-1`} onChange={(e) => {
                                                    showAdminRemoveOption(e, item.user_id)
                                                }} /></ListGroup.Item>

                                            })}

                                        </div>
                                    ))}
                                </ListGroup> :
                                <ListGroup as="ol" numbered>
                                    {['checkbox'].map((type) => (
                                        <div key={`reverse-${type}`} className="mb-3">
                                            {adminUserIdList.map((item, _id) => {
                                                return <ListGroup.Item as="li"> <Form.Check value={item.user_id} label={item.user_name} name="group1" type={type} checked id={`reverse-${type}-1`} onClick={alertAdminUser} /></ListGroup.Item>

                                            })}

                                        </div>
                                    ))}
                                </ListGroup>
                            }
                        </Form>
                    </Row>
               
            </Paper>
            </Container>
        </div>
    )
}

export default AddNewAdmin