import React, { useState } from "react";
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    Modal,
    InputGroup,
} from "react-bootstrap";
import "./login.css";
import { SlUserUnfollow } from "react-icons/sl";
import { BASE_URL_FRONTEND } from '../../config/Url-config';
import ForgotPassword from "../user_registration/ForgotPassowrd";
import SuccessPopUp from "../Utility/SuccessPopUp";
import LoginVerificationModal from "./LoginVerificationModal";
import * as apiUitlity from '../../config/APIUtils';
import { Typography } from "@mui/material";
import ForgotUsername from "../user_registration/ForgotUsername";


function LoginForm() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showUserMsg, setShowUserMsg] = useState(false);
    const [userMsg, setUserMsg] = useState('');
    const [verificationModal, setVerificationModal] = useState(false);
    const [show, setshow] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMsg, setsuccessMsg] = useState(false);
    const [showUsername, setShowUsername] = useState(false);

    const currentURL = window.location.href;
    if (process.env.NODE_ENV === 'production' && currentURL.includes("herokuapp.com")) {
        alert("Please use new URL : " + BASE_URL_FRONTEND)
        return window.location.href = BASE_URL_FRONTEND;
    }

    const handleOnChange = e => {
        const { name, value } = e.target;

        switch (name) {
            case "username":
                setUsername(value);
                break;

            case "password":
                setPassword(value);
                break;

            default:
                break;
        }
    };

    const handleOnSubmit = async e => {


        e.preventDefault();
        setVerificationModal(false);
        if (!username || !password) {
            return alert("Fill up all the form!");
        }
        try {
            var apiRequest = apiUitlity.apiInputRequest;
            apiRequest.endPoint = "login/";
            apiRequest.data = { login_id: username, password: password }
            var res = await apiUitlity.sendAPIDataPOST(apiRequest);
            if (res.data !== 'No User Found!' && res.data !== 'Not verified' && res.data !== 'Account Deactivated') {
                sessionStorage.setItem("authToken", (res.data.token));
                sessionStorage.setItem("userTypeArr", JSON.stringify(res.data.res[0].typeArr));
                sessionStorage.setItem("userId", res.data.res[0].user_id)
                sessionStorage.setItem("userName", res.data.res[0].login_id)
                sessionStorage.setItem("fistName", res.data.newRes[0].firstName)
                sessionStorage.setItem("fullName", res.data.newRes[0].firstName + " " + res.data.newRes[0].lastName);
                sessionStorage.setItem("home_types", JSON.stringify(res.data.appHomeTypeConstants[0].values));
                sessionStorage.setItem("training_providers", JSON.stringify(res.data.training_providers));
                var typeArr = res.data.res[0].typeArr
                var homeArray = []
                var orgArray = []
                for (var i = 0; i < typeArr.length; i++) {
                    if (typeArr[i].permission_type === "organization" && typeArr[i].status === "Active") {
                        orgArray.push((res.data.res[0].typeArr[i].permission_of_id))
                    }
                    else if (res.data.res[0].typeArr[i].permission_type === "home" && res.data.res[0].typeArr[i].status === "Active")
                        homeArray.push((res.data.res[0].typeArr[i].permission_of_id))
                    else if (res.data.res[0].typeArr[i].permission_type === "admin" && res.data.res[0].typeArr[i].status === "Active") {
                        sessionStorage.setItem("userType", "admin")
                        return window.location.href = BASE_URL_FRONTEND + "admin_home/";
                    }
                }

                if (orgArray.length != 0) {
                    sessionStorage.setItem("orgId", orgArray[0]);
                    sessionStorage.setItem("OtherOrgId", JSON.stringify(orgArray));
                    sessionStorage.setItem("homeId", homeArray[0]);
                }
                else {
                    if (sessionStorage.getItem("OtherOrgId"))
                        sessionStorage.removeItem("OtherOrgId")
                    if (sessionStorage.getItem("orgId"))
                        sessionStorage.removeItem("orgId")
                }
                if (homeArray.length != 0) {
                    sessionStorage.setItem("allHomeArr", JSON.stringify(homeArray));
                    sessionStorage.setItem("homeId", homeArray[0]);
                }
                return window.location.href = BASE_URL_FRONTEND + "user/" + res.data.res[0].user_id
            } else {
                if (res.data === 'Not verified') {
                    setVerificationModal(true);
                } else if (res.data === 'Account Deactivated') {
                    setUserMsg("Account Deactivated! \nPlease contact administrator to enable.");
                    setShowUserMsg(true);
                } else {
                    setUserMsg("Invalid Credentials. Please try again.");
                    setShowUserMsg(true);
                }
            }
        } catch (error) {
            setUserMsg("Invalid Credentials. Please try again.");
            setShowUserMsg(true);
        }
    };

    const handleUpdateCallback = (result) => {
        setshow(false);
        setVerificationModal(false);
        setsuccessMsg(result);
        setShowSuccess(true);
    }
    return (
        <html>
            <body className="login-body">
                <Container className="Login" style={{ marginTop: "4pc", marginBottom: "0.5pc" }}>
                    <Row>
                        <Col>
                            <h1 className="text-center">LCPT</h1>
                            <hr />
                            <Form autoComplete="off" onSubmit={handleOnSubmit}>
                                <Form.Group>
                                    <Form.Label className="text-center">Username</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            name="username"
                                            value={username}
                                            onChange={handleOnChange}
                                            placeholder="Enter username"
                                            required
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"

                                        />
                                    </InputGroup>

                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="text-center">Password</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            onChange={handleOnChange}
                                            value={password}
                                            placeholder="password"
                                            required
                                            aria-label="password"
                                            aria-describedby="basic-addon2"
                                        />
                                    </InputGroup>

                                </Form.Group>
                                <Row>
                                    <Col xs={0} md={4}></Col>
                                    <Col xs={12} md={4}>
                                        <Button type="submit" >Login</Button>
                                    </Col>
                                    <Col xs={0} md={4}></Col>
                                </Row>
                            </Form>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Typography variant="subtitle1" style={{ "color": "#0f6fc5", display: "inline-block" }} align="left" sx={{ color: 'text.secondary' }}> Forgot&nbsp; </Typography>
                            <Typography variant="subtitle1" style={{ "color": "#0f6fc5", "cursor": "pointer", display: "inline-block", "textDecoration": "underline" }} align="left" onClick={() => setShowUsername(true)} sx={{ color: 'text.secondary' }}>Username</Typography>
                            <Typography variant="subtitle1" style={{ "color": "#0f6fc5", display: "inline-block" }} align="left" sx={{ color: 'text.secondary' }}> &nbsp;or&nbsp; </Typography>
                            <Typography variant="subtitle1" style={{ "color": "#0f6fc5", "cursor": "pointer", display: "inline-block", "textDecoration": "underline" }} onClick={() => setshow(true)}>Password?</Typography>
                        </Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col>
                            Are you new here? <a href="/userRegistration" style={{ "color": "#0f6fc5", "cursor": "pointer" }}>Register Now</a>
                        </Col>
                    </Row>
                    <Row className="py-4">
                        <Col>
                            New login page? <a href="/newlogin" style={{ "color": "#0f6fc5", "cursor": "pointer" }}>Click</a>
                        </Col>
                    </Row>
                </Container>

                <Modal show={showUserMsg} centered style={{ "bordeRadius": "0px", "width": "20pc", "top": "0%", "left": "40%" }} onHide={() => setShowUserMsg(false)}>
                    <Modal.Body className='text-center'>
                        <h1><SlUserUnfollow style={{ "fontSize": "5pc", "color": "#0f6fc5" }} /></h1> <br></br><p className="text-muted">{userMsg}</p>
                    </Modal.Body>
                </Modal>
                <ForgotPassword show={show} handleUpdateCallback={handleUpdateCallback} onHide={() => { setshow(false) }} />
                <ForgotUsername show={showUsername} handleUpdateCallback={handleUpdateCallback} onHide={() => { setShowUsername(false) }} />
                <SuccessPopUp show={showSuccess} data={successMsg} onHide={() => { setShowSuccess(false) }} />
                <LoginVerificationModal show={verificationModal} username={username} handleUpdateCallback={handleUpdateCallback} onHide={() => { setVerificationModal(false) }} />

            </body>
        </html>
    )
}

export default LoginForm;
