import React, { useState } from 'react';
import { Card, CardContent, Switch, Typography } from '@material-ui/core';
import { Form, Row, Col, Button, Modal, Container } from 'react-bootstrap'
import * as apiUtility from "../../config/APIUtils";
import ErrorPopUp from '../Utility/ErrorPopUp';

function CreateCourseForm(props) {

    var homeTypes = JSON.parse(sessionStorage.getItem("home_types"));
    var trainingProvidersMap = JSON.parse(sessionStorage.getItem("training_providers"));
    const [trainingProvider, setTrainingProvider] = useState(0);
    const [courseType, setCourseType] = useState("0");
    const [years, setYears] = useState(0);
    const [months, setMonths] = useState(0);
    const [trainingUrl, setTrainingUrl] = useState("");
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [dataForErrorPopup, setDataForErrorPopup] = useState('');

    var submitCourseForm = async function (e) {
        e.preventDefault();
        var crsIdText = document.getElementById("formGridCourseId");
        var titleText = document.getElementById("formGridTitle");
        var descText = document.getElementById("formGridDescription");
        var trainingDuration = document.getElementById("formGridTrainingDurationForNewVersion").value;
        //var issuingOrg = document.getElementById("formGridIssuingOrgForNewVersion").value;
        var tp_CourseCode = document.getElementById("formGridTpCourseCodeForNewVersion").value;
        var crsVerNum = document.getElementById("formGridVersionNumberForNewVersion").value;
        //var vlDurText = document.getElementById("formGridValidityDuration");
        var trainingDurHrOrMin = document.getElementById("addCrsVerTrainDurInCrs").checked == true ? "m" : "h";
        var crsVersionTitle = document.getElementById("crsVerTitle").value;
        var crsVersionDesc = document.getElementById("crsVerDescription").value;
        var directRegistration = document.getElementById("direct_registration").checked == true ? true : false;

        if (trainingDuration === "" || crsVerNum === "") {
            setShowErrorPopup(true);
            setDataForErrorPopup("Please enter course version details!");
            return;
        }
        if (courseType === 0 || courseType === "0") {
            setShowErrorPopup(true);
            setDataForErrorPopup("Please select a valid course applicable industry!");
            return;
        }
        if (trainingProvider == 0 || trainingProvider == "0") {
            setShowErrorPopup(true);
            setDataForErrorPopup("Please select a training provider! Select default if not applicable");
            return;
        }
        if (tp_CourseCode == "" || crsVersionTitle === "" || crsVersionDesc === "") {
            setShowErrorPopup(true);
            setDataForErrorPopup("Course version details cannot be left blank.");
            return;
        }

        if (crsIdText.value === "" || titleText.value === "" || descText.value === "") {
            var emptyFieldMsg = document.getElementById("emptyFieldMsg");
            emptyFieldMsg.style.display = "block";

        } else {
            var courseFormDetails = {
                "courseID": crsIdText.value,
                "title": titleText.value,
                "description": descText.value,
                // "training_duration": trDurText.value,
                "validity_duration": {
                    "years":parseInt(years),
                    "months":parseInt(months)
                },
                "courseType": courseType,
                //"crsIssuingOrg": issueOrgType,//issuingOrg,
                "course_version_title":crsVersionTitle,
                "course_version_desc":crsVersionDesc,
                "tp_course_code":tp_CourseCode,
                "crsVerNum": crsVerNum,
                "training_provider_id": trainingProvider,
                "training_url":trainingUrl,
                "crsTrainingDur": trainingDuration + "-" + trainingDurHrOrMin,
                "direct_registration":directRegistration,
            }
            
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "course/create-course";
            apiRequest.data = { courseFormDetails };
            await apiUtility.sendAPIDataPOST(apiRequest).then(res => {
                //console.log(res.data.msg);
                var rspMsg = [1, res.data.msg];
                props.handleCallBack(rspMsg)
            })
                .catch(err => {
                    var rspMsg = [-1, "Error while performing action"];
                    props.handleCallBack(rspMsg)
                })
        }

    }

    const handleYearsChange = (event) => {
        const { value } = event.target;
        // Check if the value is a positive number or an empty string
        if (value === '' || (parseInt(value) > 0 && !isNaN(value))) {
          setYears(parseInt(value));
        }
      };
    
      const handleMonthsChange = (event) => {
        const { value } = event.target;
        // Check if the value is a positive number or an empty string
        if (value === '' || (parseInt(value) > 0 && parseInt(value) < 12 && !isNaN(value))) {
          setMonths(parseInt(value));
        }
      };

      const handleTrainingUrl = (event) => {
        const { value } = event.target;
        setTrainingUrl(value);
      };

    return (
        // <div style={mainDivStyling}>
        <Container>
            <Row>
                <Modal
                    {...props}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title ><Typography variant='h5' color='primary'>Add New Course</Typography></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <h3 id='emptyFieldMsg' style={{ "textAlign": 'center', "color": "red", "display": "none" }}>Field is Empty</h3>
                            <h3 id='successMesage' style={{ "textAlign": 'center', "color": "green", "display": "none" }}>Your entry was saved</h3>
                        </Row>
                        <Form>
                            <Card variant="outlined">
                            <CardContent>
                            
                            <Row>
                                <Form.Group as={Col} controlId="formGridCourseId">
                                    <Form.Label>Course ID</Form.Label>
                                    <Form.Control placeholder="Enter Course ID" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridTitle">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control placeholder="Enter Title" />
                                </Form.Group>
                            </Row>
                            <br></br>
                            <Row>
                                <Form.Group as={Col} controlId="formGridDescription">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control placeholder="Enter Description" />
                                </Form.Group>
                            </Row>
                            <br></br>
                            <Row>
                                <Form.Group as={Col} controlId="formGridValidityDuration">
                                    <Form.Label>Validity Duration<span className='text-muted' style={{ "font-size": "11px" }}>&nbsp;&nbsp;(Please leave empty if course do not have expiry)</span></Form.Label>
                                    <Row>
                                    <Form.Group as={Col} controlId="formGridValidityDurationYears">
                                        <Form.Label className='text-muted'>Years</Form.Label>
                                        <Form.Control type="number" value={years} onChange={handleYearsChange} placeholder="Validity in Years" />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridValidityDurationMonths">
                                        <Form.Label className='text-muted'>Months</Form.Label>
                                        <Form.Control type="number" value={months} onChange={handleMonthsChange} placeholder="Validity in Months" />
                                    </Form.Group>
                                    </Row>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridIndustry">
                                    <Form.Label>Applicable Industry</Form.Label>
                                    <Form.Select name="industry" value={courseType} onChange={(e) => setCourseType(e.target.value)} >
                                        <option value="0"> -- Please select applicable industry --  </option>
                                        {homeTypes?.map((data, id) => (
                                            <option value={data.id} key={data.id}>{data.value}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                </Row>
                                <br></br>
                               
                            </CardContent>
                            </Card>
                            <br></br>
                            {/* <Row>
                                <h4 style={{ "textAlign": "center" }}>Details for Default Version</h4>
                            </Row>
                            <br></br> */}
                            <Card variant="outlined">
                            <CardContent>
                                <Typography variant="subtitle1" align="center" color="primary">
                                    Training Provider Course Details
                                </Typography>
                                <br></br>
                                <Row>
                                <Form.Group as={Col} controlId="crsVerTitle">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control placeholder="Enter Title" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="crsVerDescription">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control placeholder="Enter Description" />
                                </Form.Group>
                            </Row>
                            <br></br>
                            <Row>
                                <Form.Group as={Col} controlId="formGridTpCourseCodeForNewVersion">
                                    <Form.Label>Course Code</Form.Label>
                                    <Form.Control placeholder="Enter Training provider course code" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridVersionNumberForNewVersion">
                                    <Form.Label>Version Reference Number</Form.Label>
                                    <Form.Control placeholder="Enter Version Number for this version" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridIssuingOrgForNewVersion">
                                    <Form.Label>Training Provider</Form.Label>
                                    <Form.Select name="organization" value={trainingProvider} onChange={(e) => setTrainingProvider(e.target.value)} >
                                        <option value="0"> -- Please select Training Provider --  </option>
                                        {trainingProvidersMap?.map((data, id) => (
                                            <option value={data.tp_id} key={data.tp_id}>{data.provider_name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                </Row>
                                <br></br>
                                <Row>
                                <Form.Group as={Col} controlId="training-URL">
                                        <Form.Label>Training URL</Form.Label>
                                        <Form.Control type="text" value={trainingUrl} onChange={handleTrainingUrl} placeholder="Enter the training URL for this course.." />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridTrainingDurationForNewVersion">
                                    <Form.Label>Training Duration</Form.Label>
                                    <Form.Control placeholder="Enter Training Duration for Default Version" />
                                </Form.Group>
                                <Form.Group as={Col} >
                                    <Form.Label as={Col}>Traning Duration is in: </Form.Label>

                                    <Form.Label >Hours</Form.Label>
                                    <Switch id="addCrsVerTrainDurInCrs" onChange={function () {
                                        console.log(document.getElementById("addCrsVerTrainDurInCrs").checked)
                                    }} color="primary" />
                                    <Form.Label >Minutes</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} controlId="direct_registration">
                                    <Form.Label defaultChecked>Direct Registration</Form.Label>
                                    <Form.Check placeholder="Check if direct registration" />
                                </Form.Group>

                            </Row>
                            </CardContent>
                            </Card>
                            <br></br>





                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit" onClick={submitCourseForm}>
                            Submit
                        </Button>
                        <Button variant="primary" onClick={props.onHide}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Row>
            <ErrorPopUp show={showErrorPopup} data={dataForErrorPopup} onHide={() => { setShowErrorPopup(false) }} />
        </Container>

    )
}

export default CreateCourseForm