import React from 'react';
import { Row, Button, Modal, Col } from 'react-bootstrap';
import { ImUserMinus } from "react-icons/im";
import * as apiUtility from '../../config/APIUtils';


function DeactivateAccount(props) {

   
    var username = props.data;
   
    const handleDeactivation = async e => {
        e.preventDefault();
        //const json = JSON.stringify({ login_id: username});
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "login/deactivate";
        apiRequest.data = { "login_id": username};
        //axios.post(BASE_API_URL+"login/deactivate", json, {    headers: {'Content-Type': 'application/json'}
        await apiUtility.sendAsyncAPIDataPOST(apiRequest).then(res => {
            //if(res.data.msg === "Deactivated successfully!"){
                props.handleUpdateCallback(res.data.msg);
            //}
        }).catch(err=>{
            console.log("Error: ", err);
        });
    }

return (
    
        <Modal {...props} centered style={{"width":"40%","top":"0%","left":"30%"}} >
            <Modal.Body>
                <Row className='text-center'>
                    <p className='text-center'><ImUserMinus style={{"fontSize":"5pc", "color":"#0f6fc5"}}/></p>
                    <p className='text-lg-center' style={{"color":"#0f6fc5"}}>Once account deactivated, Please contact administrator to enable it again.</p>
                </Row>
                    <hr style={{"color":"#0f6fc5"}}></hr>
                <Row className='text-center'>
                    <Col>
                        <Button className='btn btn-danger me-1' style={{"float":"right"}} onClick={handleDeactivation}>
                            Deactivate Profile
                        </Button>
                    </Col>
                    <Col>
                        <Button className='btn btn-warning me-1' style={{"float":"left"}} onClick={props.onHide}>
                            Never Mind
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>

);}
export default DeactivateAccount;