import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { Form, Row, Col, Button, Container, FloatingLabel, Tabs, Tab } from 'react-bootstrap'
import Box from '@mui/material/Box';
import TrainingProviderForm from './TrainingProviderForm';
import * as apiUtility from "../../config/APIUtils";
import TrainingProviderDetails from './TrainingProviderDetails';

function TrainingProvider() {
    const [value, setValue] = React.useState(0);
    const [trainingProviders, setTrainingProviders] = React.useState([]);
    const [selectedTpObj, setSelectedTpObj] = React.useState({});

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "course/fetch-all-training-providers";
        apiUtility.fetchAPIDataGET(apiRequest).then(res => {
            setTrainingProviders(res.data)
        }).catch((error) => console.error(error));
    }, []);
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                style={{ "width": "100%" }}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    const changeTrainingProv = (tpId) => {
        var tpBody = trainingProviders.filter((s) => s.tp_id === parseInt(tpId));
        setSelectedTpObj(tpBody[0]);
    }


    return (
        <div>
            <Row className="shadow p-3 mb-5 bg-white rounded">
                <Col xs={0} md={3}>
                </Col>
                <Col xs={12} md={6}>
                    <Row>
                        <FloatingLabel
                            controlId="floatingOrgTab"
                            label="Training Provider"
                        >
                            {/* onChange={(e) => { changeOrgId(e) }} */}
                            <Form.Select aria-label="Floating label select example" onChange={(e) => { changeTrainingProv(e.target.value) }}>
                                <option value=""> -- Please select Training Provider --  </option>
                                {trainingProviders?.map((data, id) => (
                                    <option value={data.tp_id} key={data.tp_id}>{data.provider_name}</option>
                                ))}
                            </Form.Select>
                        </FloatingLabel>
                    </Row>



                </Col>
                <Col xs={0} md={3}>

                </Col>
            </Row>

            <Container fluid>
                <Row>

                    <Tabs defaultActiveKey="overview" fill className="mb-3" >
                        <Tab eventKey="overview" title="Overview">
                            <TrainingProviderForm data={selectedTpObj} />
                        </Tab>
                        <Tab eventKey="details" title="Course Details">
                            <TrainingProviderDetails data={selectedTpObj}/>
                        </Tab>
                    </Tabs>

                    {/* <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                    <Tab label="Overview" {...a11yProps(0)} />
                    <Tab label="Course Details" {...a11yProps(1)} />

                </Tabs>
                <TabPanel value={value} index={0}>
                    <TrainingProviderForm data={selectedTpObj}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                   <div>
                    <h1 align="center">Details Tab with Courses</h1>
                   </div>
                </TabPanel> */}

                </Row>
            </Container>
        </div>
    )
}

export default TrainingProvider