
import './organisation.css';
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Tabs, Tab } from 'react-bootstrap'
import OrganisationListComponent from "./OrganisationListComponent";
import TrainStandardComponent from "./TrainStandardComponent";
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { BASE_API_URL } from '../../config/Url-config';
import { BASE_URL_FRONTEND } from '../../config/Url-config';
import EditOrgAdminPermission from './EditOrgAdminPermission';
import { Form, Row, Col, Button, Container } from 'react-bootstrap';
import OrgAuditReportManagement from '../AuditReportManagement/OrgAuditReportManagement';
import * as apiUtility from "../../config/APIUtils";
import OrgAdminPermissions from '../AdminPermissions_Component/OrgAdminPermissions';

export default function Organisation(props) {
    const [errors, setErrors] = useState(false)
    const [missingOrg, setMissingOrg] = useState(false)
    const [orgList, setOrgList] = useState([])
    const [orgDetailList,setOrgDetailList] = useState([])
    const [changeOrg, setChangeOrg] = useState("")
    const [user_ID, setUser_ID] = useState(-1);
    const [homes_list, setHomesList] = useState([]);

    var paramId = sessionStorage.getItem("orgId")
    
    

    var propsId = props.orgIdForAdmin
    var id = propsId;
    if (!propsId) {

        id = paramId;
    }

    var user_id = useParams().id;
    var propsId2 = props.userId;
    var userID = propsId2;
    if (!userID) {
        userID = user_id;
    }
    // id = useParams().id;
    const [homeDetails, setHomeDetails] = useState({ id: id });
    const [showSpinner, setshowSpinner] = useState(false);
    const toggleshowSpinner = () => setshowSpinner(!showSpinner);

    const accessToken = sessionStorage.getItem('authToken');
    const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    useEffect(async () => {
        setUser_ID(userID);
        // toggleshowSpinner()
        // if(sessionStorage.getItem("userType")==='organization'){
        //     if(JSON.parse(sessionStorage.getItem("OtherOrgId")).findIndex(String(paramId))==-1){
        //       return window.location.href = BASE_URL_FRONTEND;  
        //     }
        //   }
        if (sessionStorage.getItem("userId") != String(user_id) && !sessionStorage.getItem("orgId") && sessionStorage.getItem("userType") != 'admin') {
            alert("Sorry.Access Not Permitted")
            return window.location.href = BASE_URL_FRONTEND;

        }

        else {
            if (sessionStorage.getItem("OtherOrgId")) {
                let tempArr = (JSON.parse(sessionStorage.getItem("OtherOrgId")))
                let flag = 0
                for (var i = 0; i < tempArr.length; i++) {
                    if (tempArr[i] == sessionStorage.getItem("orgId")) {
                        flag = 1
                        break
                    }

                }
                if (flag == 0) {
                    alert("Sorry.Access Not Permitted")
                    return window.location.href = BASE_URL_FRONTEND;

                }

            }
            else if (sessionStorage.getItem("userType") != 'admin') {
                alert("Sorry.Acces Not Permitted")
                return window.location.href = BASE_URL_FRONTEND;
            }
            console.log("ID : "+id)
            if(id != undefined && id != ""){
                getInitialOrgData();
            }

            const BASE_URL_GET_All_HOMELIST = BASE_API_URL + "orgnization/getAllHomes";

            var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint =  "orgnization/getAllHomes";
        //apiRequest.data = { homeDetails };
        apiUtility.fetchAsyncAPIDataGET(apiRequest)
        .then(res => {
            setHomesList(res.data)      
        }).catch((error) => {
                console.error(error);
                setshowSpinner(false);
              });
        // if(res){
        //     setHomesList(res.data)

        // }
            // axios.get(BASE_URL_GET_All_HOMELIST,{headers})
            // .then(res => {
            //     setHomesList(res.data)
            // })
        }
        

        if (sessionStorage.getItem("userType") === 'admin') {
            console.log("Set orgId : ", id);
            setChangeOrg(props.orgIdForAdmin);
            sessionStorage.setItem("orgId", props.orgIdForAdmin);
        }


    }, [id])

    async function getInitialOrgData() {
        if (sessionStorage.getItem("userType") === 'admin') {
            setOrgList(["admin"], ...orgList)
        }
        else {
            const getOrgListDetail = BASE_API_URL + "orgnization/orgListDetail/"
            var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint =  "orgnization/orgListDetail/";
        apiRequest.data = {"isAdmin":false,"orgList":JSON.parse(sessionStorage.getItem("OtherOrgId"))};
        apiUtility.updateAsyncAPIDataPUT(apiRequest).then(res => {
            console.log(res)
                    if (res != 'Something went wrong!' || res != 'No Home Found!')
                        setOrgDetailList(res.data)
                    else{
                        setMissingOrg(true);
                        setOrgDetailList([])

                    }
                    setshowSpinner(false)     
        }).catch((error) => {
                console.error(error);
                setshowSpinner(false);
              });
        // if(res){
        //     console.log(res)
        //             if (res != 'Something went wrong!' || res != 'No Home Found!')
        //                 setOrgDetailList(res.data)
        //             else{
        //                 setMissingOrg(true);
        //                 setOrgDetailList([])

        //             }
        //             setshowSpinner(false)
        //             // toggleshowSpinner()
        // }
            // axios.put(getOrgListDetail,{"isAdmin":false,"orgList":JSON.parse(sessionStorage.getItem("OtherOrgId"))},{headers})
            //     .then(res => {
            //         console.log(res)
            //         if (res != 'Something went wrong!' || res != 'No Home Found!')
            //             setOrgDetailList(res.data)
            //         else{
            //             setMissingOrg(true);
            //             setOrgDetailList([])

            //         }
            //         setshowSpinner(false)
            //         // toggleshowSpinner()
            //     })
            //     .catch(err => {
            //         //console.log(err);
            //     })
            setOrgList(JSON.parse(sessionStorage.getItem("OtherOrgId")), ...orgList)
        }
        // //console.log(orgList)
        setshowSpinner(true)
        const gethomeDetailsUrl = BASE_API_URL + "orgnization/getOrganisationDetails/" + id
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint =  "orgnization/getOrganisationDetails/" + id;
       // apiRequest.data = {"isAdmin":false,"orgList":JSON.parse(sessionStorage.getItem("OtherOrgId"))};
        var res = await apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
            if (res != 'Something went wrong!' || res != 'No Home Found!')
            setHomeDetails(res.data[0])        
        }).catch((error) => {
                console.error(error);
                setHomeDetails([])
              });
        // if(res){
        //     if (res != 'Something went wrong!' || res != 'No Home Found!')
        //     setHomeDetails(res.data[0])
        // else
        //     setHomeDetails([])
        setshowSpinner(false)
        
        // axios.get(gethomeDetailsUrl,{headers})
        //     .then(res => {
        //         if (res != 'Something went wrong!' || res != 'No Home Found!')
        //             setHomeDetails(res.data[0])
        //         else
        //             setHomeDetails([])
        //         setshowSpinner(false)
        //         // toggleshowSpinner()
        //     })
        //     .catch(err => {
        //         //console.log(err);
        //     })
    }

    function changeOrgText(event, id) {
        let newHomeDetailObj = {};
        newHomeDetailObj = { ...homeDetails };
        if (id === 'det1')
            newHomeDetailObj.org_name = event.target.value;
        else if (id === 'det2')
            newHomeDetailObj.contact_firstName = event.target.value;
        else if (id === 'det3')
            newHomeDetailObj.contact_lastName = event.target.value;
        else if (id === 'det4')
            newHomeDetailObj.phone_no = event.target.value;
        else if (id === 'det5')
            newHomeDetailObj.email_id = event.target.value;
        setHomeDetails(homeDetails => ({
            ...homeDetails, ...newHomeDetailObj
        }));

        setHomeDetails(newHomeDetailObj);
        // //console.log("home details:", homeDetails)

    }
    async function saveOrgText(event, id) {
        ////console.log("In saveText")
        ////console.log(homeDetails)
        if (homeDetails.org_name && homeDetails.contact_firstName && homeDetails.contact_lastName && homeDetails.phone_no && homeDetails.email_id) {
            toggleshowSpinner()
            setErrors(false)
            var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint =  "orgnization/editOrgDetails";
        apiRequest.data = homeDetails;
        apiUtility.updateAsyncAPIDataPUT(apiRequest).then(res => {
            getInitialOrgData()
            setshowSpinner(false)
            alert("Organization Details Updated!")        
        }).catch((error) => {
                console.error(error);
                setErrors(true);              });
        // if(res){
        //     getInitialOrgData()
        //     setshowSpinner(false)
        //     alert("Organization Details Updated!")
        // }
            // axios.put(BASE_API_URL + "orgnization/editOrgDetails", homeDetails,{headers})
            //     .then(res => {
            //         ////console.log(res);
            //         getInitialOrgData()
            //         setshowSpinner(false)
            //         alert("Organization Details Updated!")
            //     })
            //     .catch(err => {
            //         //console.log(err);
            //     })
        }
        else {
            setErrors(true);
        }

    }
    // async function showHomeRemoveOption(e,item){
        
    //     var tempHomeObj = {}

    //     console.log(e.target.checked,item)
    //     tempHomeObj.home_id = item.home_id;
    //     tempHomeObj.homeActiveStatus = e.target.checked
    //     const changeHomeActiveStatusUrl = BASE_API_URL + "orgnization/changeHomeActiveStatus"
    //     var apiRequest = apiUtility.apiInputRequest;
    //     apiRequest.endPoint =  "orgnization/changeHomeActiveStatus";
    //     apiRequest.data = tempHomeObj;
    //     var res = await apiUtility.updateAsyncAPIDataPUT(apiRequest);
    //     // axios.put(changeHomeActiveStatusUrl, tempHomeObj,{headers})
    //     // .then(res => {
    //     //     ////console.log(res);
    //     //     // getOrgData()
    //     // })
    //     // .catch(err => {
    //     //     //console.log(err);
    //     // })
   

    // }

    function changeOrganisation(event) {
        console.log("Changes org: "+event.target.value)
       // setShowOrgDetailsView(false);
        if (event.target.value != "") {
         //   setShowOrgDetailsView(true);
            sessionStorage.setItem("orgId", event.target.value)
            setChangeOrg((event.target.value))
            // const homeListUrl = BASE_API_URL + "orgnization/getHomesList/" + event.target.value
            // axios.get(homeListUrl)
            //     .then(new_res => {
            //         //console.log("newRes", new_res)
            //         if (new_res != 'Something went wrong!' || new_res != 'No Home Found!') {
            //             var homeArray = []
            //             for (var i = 0; i < new_res.data.length; i++) {
            //                 homeArray.push((new_res.data[i].home_id))
            //             }
            //             sessionStorage.setItem("allHomeArr", JSON.stringify(homeArray));
            //             getInitialOrgData()
            //             // return window.location.href = BASE_URL_FRONTEND + "user/" + String(sessionStorage.getItem("userId"));
            //         }
            //         else {
            //             getInitialOrgData()
            //             // return window.location.href = BASE_URL_FRONTEND + "user/" + String(sessionStorage.getItem("userId"));
            //         }

            //     })
        }

        //return window.location.href = BASE_URL_FRONTEND+"organisation/"+String(event.target.value);
    }



//     <div style={{ display: "flex", marginLeft: "10%" }}>

//     <h2>HOME ID:  </h2>
//     <Form.Select size="lg" style={{ width: "30%" }} id="homeDropDown" onChange={(e) => {
//         changeHomeId(e)

//     }}>
//         <option value="">Select one Home</option>
//         {createHomeIDSelectItems()}

//     </Form.Select>

// </div>


    return (

        <div style={{ marginTop: "2vh" }} >
            <div>
                {orgList[0] === 'admin' ? (
                    <div></div>
                ) : (
                    <Form>
                         {orgDetailList.length>0?(
                            //<div style={{ display: "flex", marginLeft: "10%",marginTop:"-5.5%",marginBottom:"5%" }}>
                            <Row><Col xs={0} md={4}></Col>
                        <Form.Group as={Col} controlId="formBasicEmail">
                           
                                 {/* <Form.Label>Select an Organisation</Form.Label> */}
                                 <p className="text-muted"> Select Organisation</p>
                                 <Form.Select size="lg" aria-label="Default select example" onChange={(e) => {
                                     changeOrganisation(e);
                                 }} >
                                     <option value=""> -- Please select an Organization -- </option>
                                     {orgDetailList.map((item, _id) => {
                                         return <option value={item.org_id}>
                                             {item.org_name}
                                         </option>
                                     })}
                                 </Form.Select>
                            
                           
                                 </Form.Group><Col xs={0} md={4}></Col>
                        </Row>
                        ):(<div></div>)}
                    </Form>
                )}
            </div>


            {/* <h1>Organisation : {id} </h1> */}
            
                    <Tabs style={{ marginTop: "3pc" }} defaultActiveKey="OrgDetails" id="uncontrolled-tab-example" className="mb-3" fill>
                    <Tab eventKey="OrgDetails" title="Organization Details">
    
                    {missingOrg ? 
                    <div></div>: 
                           <div className='org-container'>
                               {showSpinner ? <div style={{ paddingLeft: "50%", paddingTop: "10%", position: "absolute" }}>
                                   <Spinner show={showSpinner} animation="border" size="lg" variant='primary' />
       
                               </div> : <div></div>}
    
                                <Container >
                                    <Form>
                                        <Row>
                                            <Form.Group as={Col} className="mb-2 col-xs-6" controlId="formGridName">
                                                <Form.Label>Organization Name</Form.Label>
                                                <Form.Control type="input" value={homeDetails.org_name} onChange={(e) => {
                                                    changeOrgText(e, 'det1');
                                                }} />
                                            </Form.Group>
                                            <Form.Group as={Col} className="mb-3 col-xs-6" controlId="formGridName">
                                                <Form.Label>Organization ID</Form.Label>
                                                <Form.Control type="input" value={homeDetails.org_id} disabled />
                                            </Form.Group>
                                        </Row>
                                        <Row>
    
                                            <Form.Group as={Col} className="mb-3 col-xs-6" controlId="formGridName">
                                                <Form.Label>Contact First-Name</Form.Label>
                                                <Form.Control type="input" value={homeDetails.contact_firstName} onChange={(e) => {
                                                    changeOrgText(e, 'det2');
                                                }} />
                                            </Form.Group>
                
                                            <Form.Group as={Col} className="mb-3 col-xs-6" controlId="formGridName">
                                                <Form.Label>Contact Last-Name</Form.Label>
                                                <Form.Control type="input" value={homeDetails.contact_lastName} onChange={(e) => {
                                                    changeOrgText(e, 'det3');
                                                }} />
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} className="mb-3 col-xs-6" controlId="formGridName">
                                                <Form.Label>Contact Number</Form.Label>
                                                <Form.Control type="input" value={homeDetails.phone_no} onChange={(e) => {
                                                    changeOrgText(e, 'det4');
                                                }} />
                                            </Form.Group>
                                            <Form.Group as={Col} className="mb-3 col-xs-6" controlId="formGridName">
                                                <Form.Label>Contact Email</Form.Label>
                                                <Form.Control type="input" value={homeDetails.email_id} onChange={(e) => {
                                                    changeOrgText(e, 'det5');
                                                }} />
                                            </Form.Group>
                                        </Row>
                                   
                                  
                                        <div>
                                            {errors ? (
                                                <p className="text-danger">*All fields are mandatory</p>
            
                                            ) : (
                                                <p className="text-danger"></p>
            
                                            )}
                                        </div>
                                        <Button onClick={(e) => {
                                            saveOrgText(e, 'det1');
                                        }} variant="warning" >
                                            Save Changes
                                        </Button>
                                    </Form>
                                </Container>
       
                              
                           </div>}
    
    
    
                    </Tab>
                    
                    <Tab eventKey="organistionList" title="Homes List" >
                        <OrganisationListComponent id={id} orgId={changeOrg} />
                    </Tab>
                    <Tab eventKey="trainStandard" title="Training Standard">
                    <TrainStandardComponent id={id} orgId={changeOrg} />
    
                    </Tab>
     
                   
                   
                    {/* <Tab eventKey="auditReport" title="Audit Report" >
                        <OrganizationAuditTabs organizationID={id} orgId={changeOrg} />
                    </Tab> */}
                    <Tab eventKey="orgAuditReport" title="Manage Audit Report" >
                        <OrgAuditReportManagement orgFlag={true} userId={user_ID}/>
                    </Tab>
                    {/* <Tab eventKey="adminPermission" title="Admin Permission" >
                        <AddOrgAdminInOrg id={id} orgId={changeOrg} />
                    </Tab> */}
                    {/* <Tab eventKey="removeAdminPermission" title="Remove Admin Permission" >
                        <RemoveOrgAdmin id={changeOrg} />
                    </Tab> */}
                    <Tab eventKey="editPermission" title="Manage Admin Permission" >
                        {/* <EditOrgAdminPermission id={changeOrg} /> */}
                        <OrgAdminPermissions orgId={changeOrg}/>
                    </Tab>
                    
                </Tabs>
                
            </div>)

}