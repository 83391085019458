import React from 'react'
// import AddNewAdmin from './AddNewAdmin'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import StaffUITableSponsor from './StaffUITableSponsor';
import HomePolicySettings from './HomePolicySettings';

import EmailTriggerLogs from '../AdminPermissions_Component/EmailTriggerLogs';

function TrainingSponsorship(props) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                style={{ "width": "100%" }}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }


    return (
        <div>
            <Box
                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
            >
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                    {/* <Tab label="System Admin Permissions" {...a11yProps(0)} /> */}
                    <Tab label="Home Policy Settings" {...a11yProps(0)} />
                    <Tab label="Staff Table" {...a11yProps(1)} />

                </Tabs>
                <TabPanel value={value} index={0}>
                    <HomePolicySettings data={props} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {/* <AdminPermissionLogs /> */}
                    <StaffUITableSponsor data={props} />
                </TabPanel>
                
                {/* <TabPanel value={value} index={2}>
                    <EmailTriggerLogs />
                </TabPanel> */}
            </Box>
        </div>
    )
}

export default TrainingSponsorship