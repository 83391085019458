import React, { useState, useEffect } from "react";
import * as apiUtility from "../../config/APIUtils";
import { Col, Row } from 'react-bootstrap';
import { DataGrid } from '@mui/x-data-grid';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { Divider, IconButton, InputBase, LinearProgress, Paper, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/system';

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
    '.MuiDataGrid-columnHeadersInner, .MuiDataGrid-footerContainer, .css-rtrcn9-MuiTablePagination-root': {
      backgroundColor: '#007bff', // Header and cell background color\
      fontSize: '1pc',
      color:"white"
    },
  }));

export default function EmailTriggerLogs() {

    var params = "";
    
    const [emailLogsData, setEmailLogsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 25,
      });
     const [totalRows, setTotalRows] = useState(0);
     const [searchText, setSearchText] = useState("");

    useEffect(() => {
        setSearchText("");
        fetchDataFromServer();
    }, [paginationModel.page, paginationModel.pageSize, params])

    const clearFormatting = () => {
        setLoading(true);
        setSearchText("");
        fetchData(paginationModel.page, paginationModel.pageSize, "")
        setLoading(false);
    }

    const handleSearch = (e) => {
        e.preventDefault();
        setLoading(true);
        fetchData(paginationModel.page, paginationModel.pageSize, searchText)
        setLoading(false);
            
    }
    

    const fetchData = async (page, pageSize, searchBy) => {
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.data = {
            "page":page,
            "pageSize":pageSize,
            "search":searchBy
        }
        apiRequest.endPoint = "permissions/fetchEmailLogs";
        await apiUtility.sendAPIDataPOST(apiRequest).then(res => {
            var result =res.data;
            console.log("setEmailLogsData",result);
            setEmailLogsData(result.data)
            setTotalRows(result.totalCount);
        }).catch(e=>{
            //console.log("Error: ", e.response.data);
            // setDataForErrorPopup(e.response.data);
            // setShowErrorPopup(true);
        })
    };

    const fetchDataFromServer = async () => {
        setLoading(true);
        await fetchData(paginationModel.page, paginationModel.pageSize);
        setLoading(false);
    };

    const captureKeyDown = (e) => {
      if(e.keyCode == 13){
          e.preventDefault();
          handleSearch(e);
       }
    }

    const columns = [
        { field: 'user_id',
            renderHeader: () => (
                <Typography variant="subtitle1">
              {' User Id'}
            </Typography>
          ), 
          flex: 1, 
          filterable: true },
        { field: 'login_id',
            renderHeader: () => (
                <Typography variant="subtitle1">
              {' Username'}
            </Typography>
          ), flex: 1, filterable: true },
        {
            field: 'email_type',
            flex: 1,
            filterable: true,
            renderHeader: () => (
                <Typography variant="subtitle1">
                  {'Email Type'}
                </Typography>
              ),
            renderCell: (params) => (
              <Typography variant="caption" align="center">{params.row.email_type}</Typography>
            ),
        },
        {
            field: 'created_on',
            flex: 1,
            filterable: true,
            renderHeader: () => (
                <Typography variant="subtitle1">
                  {'Initiated'}
                </Typography>
              ),
            renderCell: (params) => (
              <Typography variant="caption" align="center">{params.row.created_on}</Typography>
            ),
        },
        {
            field: 'confirmation',
            flex: 1,
            filterable: true,
            renderHeader: () => (
                <Typography variant="subtitle1">
                  {'Confirmation'}
                </Typography>
              ),
            renderCell: (params) => (
              <Typography variant="caption" align="center">{(params.row.confirmation)?"Yes":"No"}</Typography>
            ),
        },{
            field: 'modified_on',
            flex: 1,
            filterable: true,
            renderHeader: () => (
                <Typography variant="subtitle1">
                  {'Last Modified'}
                </Typography>
              ),
            renderCell: (params) => (
              <Typography variant="caption" align="center">{params.row.modified_on}</Typography>
            ),
        },
        {
            field: 'org_id',
            renderHeader: () => (
                <Typography variant="subtitle1">
                  {'Org ID'}
                </Typography>
              ),
            flex: 1,
            renderCell: (params) => (
              <Typography variant="caption" align="center">{(params.row.org_id === "" || params.row.org_id === null || params.row.org_id === undefined)?"NA":params.row.org_id}</Typography>
            ),
        },
        {
            field: 'home_id',
            renderHeader: () => (
                <Typography variant="subtitle1">
                  {'Home ID'}
                </Typography>
              ),
            flex: 1,
            renderCell: (params) => (
              <Typography variant="caption" align="center">{(params.row.home_id === "" || params.row.home_id === null || params.row.home_id === undefined)?"NA":params.row.home_id}</Typography>
            ),
        },
        {
            field: 'role_id',
            renderHeader: () => (
                <Typography variant="subtitle1">
                  {'Role ID'}
                </Typography>
              ),
            flex: 1,
            renderCell: (params) => (
              <Typography variant="caption" align="center">{(params.row.role_id === "" || params.row.role_id === null || params.row.role_id === undefined)?"NA":params.row.role_id}</Typography>
            ),
        }
    ];

    
    
    
    return (
        <div className='org-container' disableEnforceFocus>
            <Row>
              <Row>
              <Typography variant="h6" display="block" align="center" color={"primary"} >Email Activity Log</Typography>
              <br></br>
                <br></br>
              </Row>
                <Col xs={12} md={6}>
                
                    {/* <Button variant='warning' style={{margin:"1%"}} onClick={exportInExcel}>Export to Excel</Button> */}
                </Col> 
            
                <Col xs={12} md={6}>
                  <Paper component="form" variant="outlined"
                        sx={{float:"right", display: 'flex', marginBottom:"4px",alignItems: 'center', width: 450 }}
                        >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search by Firstname or Lastname"
                            inputProps={{ 'aria-label': 'search by last or first' }}
                            value={searchText}
                            onChange={(e) => {
                                setSearchText(e.target.value);
                            }}
                            onKeyDown={captureKeyDown}
                        />
                        <Tooltip variant="contained" title="Search" disableRipple>
                            <IconButton type="button" onClick={handleSearch} sx={{ p: '10px' }} aria-label="search">
                                <ManageSearchIcon />
                            </IconButton>
                        </Tooltip>
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <Tooltip variant="contained" title="Reset Grid" disableRipple>
                            <IconButton color="primary" onClick={clearFormatting} sx={{ p: '10px' }} aria-label="directions">
                                <ClearAllIcon />
                            </IconButton>
                        </Tooltip>    
                        </Paper>
                </Col>
                
            </Row>

            <CustomDataGrid
                loading={loading}
                rowCount={totalRows}
                rows={emailLogsData}
                columns={columns}
                slots={{
                    loadingOverlay: LinearProgress
                  }}
                pageSizeOptions={[10, 25, 50, 100]}
                paginationMode="server"
                componentsProps={{ panel: { disablePortal: true } }}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                  }}
                rowSelectionModel={rowSelectionModel}
                getRowId={(row) => row._id}
                sx={{  p: 0, borderRadius: "0"}}
                autoHeight={true} // Set automatic height
                getRowHeight={() => 'auto'}
                
            />

        </div>);
}