import React, { useEffect, useState } from "react";
import * as apiUtility from '../../config/APIUtils';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Checkbox, Divider, FormControlLabel, FormGroup, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { SlUserFollowing, SlUserUnfollow } from "react-icons/sl";
import { FiCheckCircle, FiXCircle } from "react-icons/fi";
import ErrorPopUp from "../Utility/ErrorPopUp";
import DeleteOutlineSharpIcon from '@mui/icons-material/Delete';
import RefreshSharpIcon from '@mui/icons-material/RefreshSharp';
import SuccessPopUp from "../Utility/SuccessPopUp";
import { Modal, Spinner } from "react-bootstrap";

function OrgAdminPermissions(props) {
    var propsOrgId = props.orgId;

    const [usersList, setUsersList] = useState([]);
    const [adminList, setAdminList] = useState([]);
    const [homeList, setHomeList] = useState([]);
    const [orgInfo, setOrgInfo] = useState({});

    const [checkedOrg, setCheckedOrg] = useState(false);
    const [checkedItems, setCheckedItems] = useState({});
    const [finalChangeList, setFinalChangeList] = useState({});
    const [userObj, setUserObj] = useState({});

    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMsg, setsuccessMsg] = useState("");
    const [spinnerBox, setSpinnerBox] = useState(false);

    const [dialogBox, setDialogBox] = useState(false);
    const [dialogBoxMsg, setDialogBoxMsg] = useState("");
    const [waitBlock, setWaitBlock] = useState(false);
    const [requestCompleted, setRequestCompleted] = useState(false);

    const [removalUserInfo, setRemovalUserInfo] = useState({});
    const [removalDialogBox, setRemovalDialogBox] = useState(false);
    const [removeHomeItems, setRemoveHomeItems] = useState({});

    useEffect(() => {
        setCheckedOrg(false);
        getUsersList();
        existingOrgAdminUsers();
    }, [propsOrgId]);

    const handleUncheckAll = () => {
        const updatedCheckedItems = {};
        homeList.forEach((item) => {
            updatedCheckedItems[item.home_id + "_h"] = false;
        });
        setCheckedItems(updatedCheckedItems);
        setCheckedOrg(false);
    };

    const handleCheckboxChange = (event) => {
        setRequestCompleted(false);
        setCheckedItems({
            ...checkedItems,
            [event.target.id]: event.target.checked,
        });
        setFinalChangeList({
            ...finalChangeList,
            [event.target.id + "~" + event.target.name]: event.target.checked,
        });
    };

    const handleOrgCheck = (event) => {
        setRequestCompleted(false);
        setCheckedOrg(event.target.checked);
        setFinalChangeList({
            ...finalChangeList,
            [event.target.id + "~" + event.target.name]: event.target.checked,
        });
    };

    const handleHomeRemoval = (event) => {
        setRemoveHomeItems({
            ...removeHomeItems,
            [event.target.id]: event.target.checked,
        });
    }

    const handleSelectAllCheckboxChange = (event) => {
        setRequestCompleted(false);
        const updatedCheckedItems = {};
        homeList.forEach((item) => {
            //console.log("Item: ", item);
            updatedCheckedItems[item.home_id + "_h"] = event.target.checked;
            // setFinalChangeList({
            //     ...finalChangeList,
            //     [item.home_id + "_h" + "~" + event.target.name]: event.target.checked,
            // });
            finalChangeList[item.home_id + "_h" + "~" + item.name] = event.target.checked;
        });
        setCheckedItems(updatedCheckedItems);
    };

    const isSelectAllIndeterminate =
        Object.values(checkedItems).some((isChecked) => isChecked) &&
        Object.values(checkedItems).some((isChecked) => !isChecked);


    function existingOrgAdminUsers() {
        setSpinnerBox(true);
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "permissions/getUsersByPermission/organization/" + propsOrgId;
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then((res) => {
            setAdminList(res.data);
            setSpinnerBox(false);
        }).catch((err) => {
            setSpinnerBox(false);
            // setErrorMsg("Error occured while fetching Organization's Admins");
            // setShowErrorMsg(true);
        })
    }

    function getUsersList() {
        setSpinnerBox(true);
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "user/getAllUserForOrg/" + propsOrgId;
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then((res) => {
            setUsersList(res.data.users);
            setHomeList(res.data.orgHomesList)
            setOrgInfo(res.data.orgInfo[0]);
            setSpinnerBox(false);
        }).catch((err) => {
            setSpinnerBox(false);
            // setErrorMsg("Error occured while fetching users for Organization");
            // setShowErrorMsg(true);
        })
    }



    const userChange = (e, v) => {
        setSpinnerBox(true);
        setFinalChangeList({});
        setCheckedOrg(false);
        setWaitBlock(false);
        setDialogBoxMsg("");
        const updatedCheckedItems = {};
        homeList?.map((home, _id) => {
            updatedCheckedItems[home.home_id + "_h"] = false;
        })
        setUserObj(v);
        if (v === null) {
            return;
        }
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "permissions/getPermissionByUser/" + v.user_id;
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then((res) => {
            if (res.data[0] !== undefined && res.data.length > 0) {
                res.data[0].typeArr.forEach(element => {
                    if (element.status === "Active" && element.permission_type === "home") {
                        if (homeList.some(u => u.home_id === element.permission_of_id)) {
                            updatedCheckedItems[element.permission_of_id + "_h"] = true;
                        }
                    }
                    if (element.status === "Active" && element.permission_type === "organization" && orgInfo.org_id === element.permission_of_id) {
                        setCheckedOrg(true);
                    }
                });
                setCheckedItems(updatedCheckedItems);
            } else {
                handleUncheckAll();
            }
            setSpinnerBox(false);
        }).catch((err) => {
            setSpinnerBox(false);
            //console.log("Error : " + err);
            setErrorMsg("Error occured while fetch user admin details.");
            setShowErrorMsg(true);
        })
    }


    const updatePermissions = () => {
        setWaitBlock(false);
        if(userObj === undefined || userObj === null || userObj.length === 0){
            setErrorMsg("Please select a user first");
            setShowErrorMsg(true);
            return;
        }
        setDialogBoxMsg(reviewMsg)
        setDialogBox(true);
    }

    const submitPermissionRequest = () => {
        setWaitBlock(true);
        setRequestCompleted(true);
        setDialogBoxMsg("");
        if (Object.keys(finalChangeList).length > 0) {
            var permissionList = [];
            Object.keys(finalChangeList).map(a => {
                if (a.split("~")[1] !== "allHomes") {
                    permissionList.push({
                        "permissionType": (a.split("~")[0].split("_")[1] === "h") ? "home" : "organization",
                        "id": a.split("~")[0].split("_")[0],
                        "action": (finalChangeList[a]) ? "add" : "remove",
                        "name": a.split("~")[1],
                    });
                }
            })
            var permissionObj = {
                "permissionList": permissionList,
                "userId": userObj.user_id,
                "userEmail": userObj.email,
                "fullName": userObj.firstName + " " + userObj.lastName,
                "userName": userObj.userName
            }
            //console.log("Permission : ", permissionObj);
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "permissions/addOrgPermissions";
            apiRequest.data = permissionObj;
            apiUtility.sendAPIDataPOST(apiRequest).then((res) => {
                setTimeout(
                    () => {
                        setDialogBoxMsg(afterChangeMsg(res.data));
                        setWaitBlock(false);
                        setFinalChangeList({});
                    },
                    1000
                );
            }).catch((err) => {
                console.log("Error : " + err);
                setWaitBlock(false);
                setDialogBox(false);
                setErrorMsg("Error occured while processing request.");
                setShowErrorMsg(true);
            })
        }
    }

    const removePermissions = (e, v) => {
        var userId = String(e.target.id);
        setRemoveHomeItems({});
        const userHomeDetails = adminList.find((e) => String(e.user_id) === userId);
        const userDetails = usersList.find((e) => String(e.user_id) === userId);
        setRemovalUserInfo(userHomeDetails);
        setUserObj(userDetails);
        setRemovalDialogBox(true);
    }

    const populateDropdown = (data) => {
        console.log("Data : ",data);
        
    }

    const submitAdminRemoval = () => {
        var homeIds = [];
        setSpinnerBox(true);
        Object.keys(removeHomeItems).map(key => {
            if (removeHomeItems[key] === true)
                homeIds.push(key);
        });
        var homeMap = {};
        homeList?.map((home, _id) => {
            homeMap[home.home_id] = home.name;
        })
        var permissionObj = {
            "userId": removalUserInfo.user_id,
            "permissionType": "organization",
            "permissionOfId": orgInfo.org_id,
            "homeIds": homeIds,
            "orgName": orgInfo.org_name,
            "home":homeMap,
            "userEmail": userObj.email,
            "fullName": removalUserInfo.name        
        }
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "permissions/removeOrgPermissions";
        apiRequest.data = { permissionObj };
        setRemovalDialogBox(false);
        apiUtility.sendAPIDataPOST(apiRequest).then((res) => {
            setSpinnerBox(false);
            setsuccessMsg(res.data.msg);
            setShowSuccess(true);
        }).catch((err) => {
            setSpinnerBox(false);
            setErrorMsg("Something went wrong while processing.");
            setShowErrorMsg(true);
        })

    }

    const reviewMsg = () => {
        return (
            (Object.keys(finalChangeList).length > 0) ?
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ "background-color": "#0d6efd", "color": "white" }} key="id" align="center">ID</TableCell>
                                <TableCell style={{ "background-color": "#0d6efd", "color": "white" }} key="name" align="center">Name</TableCell>
                                <TableCell style={{ "background-color": "#0d6efd", "color": "white" }} key="type" align="left">Type</TableCell>
                                <TableCell style={{ "background-color": "#0d6efd", "color": "white" }} key="permission" align="left">Permission</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(finalChangeList).map(a =>
                                <TableRow hover tabIndex={-1}>
                                    <TableCell id="id" align="center"> {a.split("~")[0].split("_")[0]} </TableCell>
                                    <TableCell id="name" align="center"> {a.split("~")[1]} </TableCell>
                                    <TableCell id="type" align="left"> {(a.split("~")[0].split("_")[1] === "h") ? "Home" : "Organization"} </TableCell>
                                    {(finalChangeList[a]) ?
                                        <TableCell id="permission" align="left"> Added </TableCell>
                                        :
                                        <TableCell id="permission" style={{ "background-color": "#ffebeb" }} align="left"> Removed </TableCell>}
                                </TableRow>
                            )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                : "Please select an user"
        )
    }

    const afterChangeMsg = (processedChanges) => {
        return (

            (processedChanges.length > 0) ?

                <TableContainer>
                    <Table stickyHeader aria-label="sticky table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ "background-color": "#0d6efd", "color": "white" }} key="id" align="center">ID</TableCell>
                                <TableCell style={{ "background-color": "#0d6efd", "color": "white" }} key="name" align="center">Name</TableCell>
                                <TableCell style={{ "background-color": "#0d6efd", "color": "white" }} key="type" align="left">Type</TableCell>
                                <TableCell style={{ "background-color": "#0d6efd", "color": "white" }} key="status" align="left">Status</TableCell>
                                <TableCell style={{ "background-color": "#0d6efd", "color": "white" }} key="action" align="left">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {processedChanges.map((data) =>
                            (
                                <TableRow hover tabIndex={-1}>
                                    <TableCell id="id" align="center"> {data.id}</TableCell>
                                    <TableCell id="name" align="center"> {data.name} </TableCell>
                                    <TableCell id="type" align="left"> {data.permissionType} </TableCell>
                                    {(data.status !== "Inactive" && data.status !== "") ?
                                        <TableCell id="status" align="left"> {data.status} </TableCell>
                                        :
                                        <TableCell id="status" style={{ "background-color": "#ffebeb" }} align="left"> {data.status} </TableCell>}
                                    {(data.status !== "Inactive" && data.status !== "") ?
                                        <TableCell id="action" align="left"> {data.response} </TableCell>
                                        :
                                        <TableCell id="action" style={{ "background-color": "#ffebeb" }} align="left"> {data.response} </TableCell>}
                                </TableRow>
                            )

                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                : <div><Typography variant="caption" display="block" align="center" sx={{ color: 'primary.main' }} gutterBottom>Request processed. Error while rendering response.</Typography></div>
        )
    }

    return (
        <div>
            <Grid container>
                <Grid item md={6}>
                    <Typography variant="h6" align="center">Existing Admins
                    <Tooltip title="Refresh Data" disableRipple>
                        <IconButton>
                        <Button sx={{ color: 'text.secondary', padding: '0px' }} onClick={existingOrgAdminUsers}><RefreshSharpIcon /></Button>
                        </IconButton>
                    </Tooltip>
                    </Typography>
                    <br></br>
                    {adminList?.map((data, _id) => {
                        return (<div><Accordion style={{ "width": "90%", "border": "1px solid #dfdbdb", "border-radius": "1.5px", "margin": "2px" }}><AccordionSummary expandIcon={<GridExpandMoreIcon />} aria-controls={data.user_id + "-content"}
                        onClick = {(e,expanded) => {
                            populateDropdown(data);
                            //console.log(e,e.target.ariaExpanded,expanded);
                            // if(expanded){
                            //     populateDropdown(data);
                            // }
                            }} id={data.user_id}>
                            <Typography>
                                {(data.status.toLowerCase() === "active") ? <FiCheckCircle style={{ "fontSize": "1.5pc" }} /> : <FiXCircle style={{ "fontSize": "1.5pc" }} />} &nbsp;
                                {data.name.toUpperCase()} ({data.user_id})</Typography>
                        </AccordionSummary>
                            {(data.status.toLowerCase() === "pending") ?
                                <Typography variant="caption" display="block" align="center" sx={{ color: 'text.secondary' }} gutterBottom>{orgInfo.org_name} - Acceptance Pending</Typography>
                                :
                                <div><Typography variant="caption" display="block" align="center" sx={{ color: 'primary.main' }} gutterBottom>{orgInfo.org_name} - {data.status.toUpperCase()}</Typography>
                                <Typography variant="caption" display="block" align="center" gutterBottom><Button sx={{ color: 'text.secondary', padding: '0px' }} size="small" id={data.user_id} onClick={removePermissions} startIcon={<DeleteOutlineSharpIcon />}>Remove</Button></Typography></div>
                            }
                            <AccordionDetails>
                                {(data?.homes.length > 0) ?
                                    <div>
                                        <Divider><Typography variant="caption" display="block" align="center" gutterBottom>Home Access List</Typography></Divider>
                                        <TableContainer sx={{ maxHeight: 440, boxShadow: 2 }}>
                                            <Table stickyHeader aria-label="sticky table" size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ "background-color": "#0d6efd", "color": "white" }} key="name" align="center">Name</TableCell>
                                                        <TableCell style={{ "background-color": "#0d6efd", "color": "white" }} key="type" align="left">Type</TableCell>
                                                        <TableCell style={{ "background-color": "#0d6efd", "color": "white" }} key="status" align="left">Status</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    {data?.homes.map((innerData, _id) => (
                                                        <TableRow hover tabIndex={-1} key={innerData.name}>
                                                            <TableCell align="center"> {innerData.name} </TableCell>
                                                            <TableCell align="left"> {innerData.type} </TableCell>
                                                            <TableCell align="left"> {innerData.status} </TableCell>
                                                        </TableRow>
                                                    ))}

                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                    </div>
                                    :
                                    <Typography variant="caption" display="block" align="center" sx={{ color: 'text.secondary' }} gutterBottom>No Home access for selected Organization</Typography>
                                }

                            </AccordionDetails></Accordion></div>);

                    })}
                    {
                        (adminList.length == 0) ?
                            <Typography variant="subtitle1" display="block" align="center" sx={{ color: 'text.secondary' }}>Cannot find admins for selected Organization</Typography>
                            : ""
                    }

                </Grid>
                <Grid item md={6}>
                    <Typography variant="h6" align="center">Add or Modify user's access rights</Typography>
                    <Divider textAlign="left"><Typography variant="caption" sx={{ color: 'text.secondary' }} display="block">Active Users</Typography></Divider>
                    <Typography variant="caption" display="block" align="left" sx={{ color: 'text.secondary' }}>*Showing all users affiliated to {orgInfo.org_name}</Typography>
                    <br></br>
                    <Autocomplete
                        id="user-select"
                        sx={{ "width": "90%" }}
                        // sx={{ width: 200 }}
                        options={usersList}
                        autoHighlight
                        onChange={userChange}
                        isOptionEqualToValue={(option, value) =>
                            option.user_id === value.user_id
                        }
                        getOptionLabel={(option) => option.firstName + " " + option.lastName + " (" + option.user_id + ")"}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} id={option.user_id} {...props}>

                                {
                                    (option.verified === true || option.verified === "true") ?
                                        <SlUserFollowing style={{ "fontSize": "1.5pc", "color": "#0f6fc5", "float": "left" }} /> :
                                        ((option.active === false || option.active === "false") && (option.verified === false || option.verified === "false")) ?
                                            <SlUserUnfollow style={{ "fontSize": "1.5pc", "color": "red", "float": "left" }} />
                                            : <SlUserUnfollow style={{ "fontSize": "1.5pc", "color": "#0f6fc5", "float": "left" }} />
                                } &nbsp; &nbsp;{option.firstName + " " + option.lastName} ({option.user_id})


                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select user"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                    <br></br>
                    <Divider textAlign="left"><Typography variant="caption" display="block" sx={{ color: 'text.secondary' }}>Available Organization & Homes</Typography></Divider>
                    <br></br>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '90%',
                        border: (theme) => `1px solid ${theme.palette.divider}`,
                        borderRadius: "1.5px",
                        bgcolor: 'background.paper',
                        color: 'text.secondary',
                        '& svg': {
                            m: 1.5,
                        },
                        '& hr': {
                            mx: 0.5,
                        },
                    }}>
                        <FormGroup
                            sx={{
                                width: '50%'
                            }}>

                            <FormControlLabel
                                label={orgInfo.org_name}
                                control={
                                    <Checkbox

                                        tabIndex={-1}
                                        name={orgInfo.org_name}
                                        id={orgInfo.org_id + "_o"}
                                        onChange={handleOrgCheck}
                                        checked={checkedOrg}//{Object.values(checkedOrg).every((isChecked) => isChecked)}
                                    />
                                }

                            />
                        </FormGroup >
                        <Divider flexItem orientation="vertical" variant="inset" />
                        <FormGroup sx={{
                            width: '100%'
                        }}>

                            <FormControlLabel
                                label="All Homes"
                                control={
                                    <Checkbox
                                        id="allHomes"
                                        name="allHomes"
                                        onChange={handleSelectAllCheckboxChange}
                                        checked={Object.values(checkedItems).every((isChecked) => isChecked)}//checkedItems[orgInfo.org_id + "_o"]}//
                                        indeterminate={isSelectAllIndeterminate}
                                    />
                                }
                            />
                            <List sx={{
                                width: '100%',
                                maxWidth: 360,
                                bgcolor: 'background.paper',
                                position: 'relative',
                                '& ul': { padding: 0 },
                            }}>

                                {homeList?.map((home, _id) => {
                                    return (<ListItem dense>
                                        <ListItemButton role={undefined} dense>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    tabIndex={-1}
                                                    disableRipple
                                                    name={home.name}
                                                    id={home.home_id + "_h"}
                                                    checked={checkedItems[home.home_id + "_h"] || false}
                                                    onChange={handleCheckboxChange}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={home.home_id + "_h"} primary={home.name} />
                                        </ListItemButton>
                                    </ListItem>);
                                })
                                }
                            </List>
                        </FormGroup>
                    </Box>
                    <Divider />
                    <br></br>
                    <Button align="right" size="success" variant="contained" onClick={() => updatePermissions()} color="primary">Submit</Button>
                    <br></br>
                </Grid>
            </Grid>
            <ErrorPopUp show={showErrorMsg} data={errorMsg} onHide={() => { setShowErrorMsg(false) }} />
            <SuccessPopUp show={showSuccess} data={successMsg} onHide={() => { setShowSuccess(false) }} />
            <Modal
                show={dialogBox}
                onHide={() => { setDialogBox(false) }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header><Modal.Title >Organization Permissions</Modal.Title></Modal.Header>
                <Modal.Body>
                        {(waitBlock) ?
                            <div>
                                <Skeleton animation="wave" />
                                <Skeleton animation="wave" />
                                <Skeleton animation="wave" />
                            </div> : ""}
                        {dialogBoxMsg}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { setDialogBox(false) }}>{(requestCompleted) ? "Close" : "Cancel"}</Button>
                    {(requestCompleted) ? "" : <Button onClick={() => { submitPermissionRequest() }}>Submit</Button>}
                </Modal.Footer>
            </Modal>

            <Modal show={removalDialogBox}
                onHide={() => { setRemovalDialogBox(false) }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header><Modal.Title >Organization Permissions</Modal.Title></Modal.Header>
                <Modal.Body>
                        {(removalUserInfo !== undefined && removalUserInfo.homes?.length > 0) ?
                            <Grid>
                                <Typography variant="subtitle1" color="primary" align="left" >
                                    Are you sure want remove {orgInfo.org_name.toUpperCase()} - ADMIN access from  {removalUserInfo.name}?
                                </Typography>
                                <Typography variant="caption" color="primary" align="left" >
                                    Please select homes that needs to be removed.
                                </Typography>
                                <FormGroup>
                                    {removalUserInfo.homes.map((data) =>
                                    ((data.status == "Active")?<FormControlLabel sx={{ "margin-left": "1pc" }} label={data.name} id={data.home_id} control={<Checkbox checked={removeHomeItems[data.home_id] || false} id={data.home_id} onChange={handleHomeRemoval} />} />
                                    :"")
                                    )}
                                </FormGroup>
                                <br></br>
                                <Typography variant="caption" display="block" align="left" sx={{ color: 'text.secondary' }}>*Only organization access will be removed untill homes are checked. </Typography>
                                <Typography variant="caption" display="block" align="left" sx={{ color: 'text.secondary' }}>*Once access is removed user will not be available in Organization admin list. </Typography>
                            </Grid>
                            : <div><Typography variant="caption" display="block" align="center" sx={{ color: 'primary.main' }} gutterBottom>Error while fetching details for user.</Typography></div>
                        }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { setRemovalDialogBox(false) }}>Cancel</Button>
                    <Button onClick={submitAdminRemoval}>Submit</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={spinnerBox}
                onHide={() => { setSpinnerBox(false) }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{"borderRadius":"0px","width":"4pc","top":"20%","left":"50%"}}
            >
                <Modal.Body>
                    <Spinner variant='primary' role="status" animation="border"/>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default OrgAdminPermissions;