import React, { useEffect, useState } from 'react';
import "jspdf-autotable";
import { Col, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Spinner from 'react-bootstrap/Spinner';
import * as apiUtility from "../../config/APIUtils";
import { Icon, IconButton, Paper, Tooltip } from "@mui/material";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import ErrorPopUp from '../Utility/ErrorPopUp';
import { RiFileExcel2Line } from "react-icons/ri";
import { RxInfoCircled } from 'react-icons/rx';

function HomeSummary(props) {
    const [homeSummData, setHomeSummData] = useState(null);
    const [showSpinner, setshowSpinner] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [dataForErrorPopup, setDataForErrorPopup] = useState('');

    var homeID = props.homeId;
    var staffFlag = props.staffFlag;

    useEffect(async () => {
        setshowSpinner(true)

        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "audit-report/home-summary/" + homeID + "/" + staffFlag;
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
            setHomeSummData(res.data)
            setshowSpinner(false)
        }).catch((error) => {
            console.error(error);
            setshowSpinner(false);
        });

    }, [homeID])

    if (homeSummData == null) {
        return <h1>
            {/* Loading */}
        </h1>
    }

    const holdUsersFormatter = (data, row) => {
        return row.hold_users;
    };

    var homeSummCols = [{
        dataField: 'role_id',
        sort: true,
        text: 'Role ID'
    }, {
        dataField: 'role_name',
        sort: true,
        text: 'Role Name'
    }, {
        dataField: 'total_courses',
        sort: true,
        text: 'Total Courses'
    }, {
        dataField: 'total_staff',
        sort: true,
        text: 'Total Staff'
    }
        , {
        dataField: 'complaint_staff',
        sort: true,
        text: 'Compliant Staff'
    }
        , {
        dataField: 'non_complaint_staff',
        sort: true,
        text: 'Non-Compliant Staff'
    }, {
        dataField: 'hold_users',
        sort: true,
        text: 'Unknown',
        headerFormatter: (cell, row) => (
            <span>
                {"Unknown"} &nbsp;
                <Tooltip variant="contained" title="Users that have been added to the home but do not consent to share their records" disableRipple>
                    <Icon color='primary' style={{ "fontSize": "15px" }}>
                        <RxInfoCircled style={{ "marginBottom": "9px" }} />
                    </Icon>
                </Tooltip>
            </span>
        ),
        formatter: holdUsersFormatter
    }
    ];

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: "xlsx",
            bookSST: false,
            type: "binary",
        };
        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([s2ab(wbout)], {
            type: "application/octet-stream",
        });
        return blob;
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; i++) {
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    //Excel
    const exportInExcel = async () => {
        try {
            var rowsCount = 6;
            const title = [{ A: 'Ontario Long Term Care Orientation Co-operative Webportal - Summary Report for Home ID : ' + String(homeID) }];
            let reportView = [{ A: 'Name', B: 'User ID', C: 'Role', D: 'Date' }];
            reportView.push({
                A: sessionStorage.getItem('fullName'),
                B: sessionStorage.getItem('userId'),
                C: (sessionStorage.getItem('userType') !== null)?sessionStorage.getItem('userType').toUpperCase():"User",
                D: new Date().toLocaleString()
            });
            reportView.push("");
            reportView.push("");
            let reportHeaders = { A: 'Role ID', B: 'Role Name', C: 'Total Staff', D: 'Compliant Staff', E: 'Non-Compliant Staff', F: 'Unknown', G: 'Total Courses' };
            reportView.push(reportHeaders);
            // orgStaffTemplateData.forEach(element => {
            homeSummData.forEach(element => {
                rowsCount += 1;
                var tempObj = {
                    A: element.role_id,
                    B: element.role_name,
                    C: element.total_staff,
                    D: element.complaint_staff,
                    E: element.non_complaint_staff,
                    F: element.hold_users,
                    G: element.total_courses,
                };
                reportView.push(tempObj);
            });
            const finalData = [...title, ...reportView];
            const worksheet = XLSX.utils.json_to_sheet(finalData, {
                skipHeader: true,
            });
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Home Summary");
            const workbookblob = workbook2blob(workbook);
            var headerIndexes = [];
            finalData.forEach((data, index) =>
                data["A"] === "Role ID" ? headerIndexes.push(index) : null
            );
            const dataInfo = {
                titleCell: "A2",
                titleRange: "A1:G1",
                tbodyRange: `A7:G${finalData.length}`,
                uHeadRange: `A2:D2`,
                uBodyRange: `A3:D3`,
                theadRange:
                    headerIndexes?.length >= 1
                        ? `A${headerIndexes[0] + 1}:G${headerIndexes[0] + 1}`
                        : null,
                theadRange1:
                    headerIndexes?.length >= 2
                        ? `A${headerIndexes[1] + 1}:I${headerIndexes[1] + 1}`
                        : null,
                tFirstColumnRange:
                    headerIndexes?.length >= 1
                        ? `A${headerIndexes[0] + 1}:A${rowsCount + headerIndexes[0] + 1}`
                        : null,
                tLastColumnRange:
                    headerIndexes?.length >= 1
                        ? `K${headerIndexes[0] + 1}:I${rowsCount + headerIndexes[0] + 1}`
                        : null,

                tFirstColumnRange1:
                    headerIndexes?.length >= 1
                        ? `A${headerIndexes[1] + 1}:A${rowsCount + headerIndexes[1] + 1}`
                        : null,
                tLastColumnRange1:
                    headerIndexes?.length >= 1
                        ? `H${headerIndexes[0] + 1}:I${rowsCount + headerIndexes[1] + 1}`
                        : null,
            };
            XlsxPopulate.fromDataAsync(workbookblob).then((workbook) => {
                workbook.sheets().forEach((sheet) => {
                    sheet.column("A").width(15);
                    sheet.column("B").width(30);
                    sheet.column("C").width(20);
                    sheet.column("D").width(20);
                    sheet.column("E").width(20);
                    sheet.column("F").width(20);
                    sheet.column("G").width(20);
                    sheet.range(dataInfo.titleRange).merged(true).style({
                        bold: true,
                        fontFamily: "Arial",
                        horizontalAlignment: "center",
                        verticalAlignment: "center",
                    });
                    sheet.range(dataInfo.uHeadRange).style({
                        fill: "A6A6A6",
                        bold: true,
                        horizontalAlignment: "center",
                    });
                    sheet.range(dataInfo.uBodyRange).style({
                        bold: true,
                        horizontalAlignment: "center",
                    });
                    if (dataInfo.tbodyRange) {
                        sheet.range(dataInfo.tbodyRange).style({
                            horizontalAlignment: "left",
                        });
                    }
                    sheet.range(dataInfo.theadRange).style({
                        fill: "A6A6A6",
                        bold: true,
                        horizontalAlignment: "center",
                    });
                    if (dataInfo.tFirstColumnRange) {
                        sheet.range(dataInfo.tFirstColumnRange).style({
                            bold: true,
                        });
                    }

                });

                return workbook.outputAsync().then((workbookBlob) => {
                    const downloadAnchorNode = document.createElement("a");
                    var dateVar = new Date();
                    var fileName = "LCPT_HomeSummaryReport-" + dateVar.getDate() + dateVar.getMonth() + dateVar.getFullYear() + "_" + dateVar.getHours() + dateVar.getMinutes() + ".xlsx";
                    downloadAnchorNode.setAttribute("href", URL.createObjectURL(workbookBlob));
                    downloadAnchorNode.setAttribute("download", fileName);
                    downloadAnchorNode.click();
                    downloadAnchorNode.remove();
                });
            });
        } catch (error) {
            setDataForErrorPopup("Something went wrong while processing excel!");
            setShowErrorPopup(true);
        }
    }


    return (
        <div key={props.homeID}>
            {showSpinner ? <div style={{ paddingLeft: "50%", paddingTop: "10%", position: "absolute" }}>
                <Spinner show={showSpinner} animation="border" size="lg" variant='primary' />

            </div> : <div></div>}
            <Paper sx={{ p: 1, borderRadius: "1.5px" }}>
                <BootstrapTable id='homeSummaryTable' keyField='id' data={homeSummData} columns={homeSummCols} striped hover />
                <Row>
                    <Col xs={0} md={8}></Col>
                    <Col xs={12} md={4} className="text-center">
                        <Paper component="form" variant="outlined"
                            sx={{ float: "right", display: 'flex', marginBottom: "4px", alignItems: 'center', width: 50 }}
                        >
                            <Tooltip variant="contained" title="Export to Excel" disableRipple>
                                <IconButton color="primary" onClick={exportInExcel} sx={{ p: '10px' }} aria-label="directions">
                                    <RiFileExcel2Line />
                                </IconButton>
                            </Tooltip>
                        </Paper>
                    </Col>
                </Row>
            </Paper>
            <ErrorPopUp show={showErrorPopup} data={dataForErrorPopup} onHide={() => { setShowErrorPopup(false) }} />
        </div>
    )
}

export default HomeSummary;