
import axios from 'axios';

const instance = axios.create();

instance.interceptors.request.use(config => {
    //console.log("Here-----------in interceptor")
  const token = sessionStorage.getItem("authToken"); // Get the authorization token
  config.headers['Authorization'] = `Bearer ${token}`;
  return config;
});

export default instance;
