import { Typography } from '@mui/material';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { SlClose } from "react-icons/sl";

function ErrorPopUp(props) {

return (
             <Modal centered  {...props} onHide={props.onHide} style={{"borderRadius":"0px","minWidth":"20pc","maxWidth":"33pc","top":"0%","left":"35%"}} closeButton>
                <Modal.Body className='text-center' style={{"border":"1px solid #6c757d"}}>
                    <h1><SlClose style={{"fontSize":"3pc"}} className="text-danger"/></h1> <br></br>
                    <Typography variant='caption' gutterBottom>{props.data}</Typography>
                </Modal.Body>
            </Modal>
);}
export default ErrorPopUp;