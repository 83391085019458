import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';

function SpinnerBox() {

return (
    
        <Modal centered backdrop="static" style={{"borderRadius":"0px","width":"5pc","top":"20%","left":"50%"}} keyboard={false}>
            <Modal.Body className='text-center'>
                <Spinner variant='primary' role="status" animation="border"/>
            </Modal.Body>
        </Modal>

);}
export default SpinnerBox;