import React, {useState} from 'react';
import { Form, Row, Col, Button, Modal, Container } from 'react-bootstrap';
import axios from 'axios';
import { BASE_API_URL } from '../../config/Url-config';
import { SlPaperPlane,SlUserUnfollow } from "react-icons/sl";


function LoginVerificationModal(props) {

    var username = props.username;

    const [verificationCode, setVerificationCode] = useState("");
    const [linkResponse, setLinkResponse] = useState("");

    const triggerEmail = async () => {
        setLinkResponse("");
        const json = JSON.stringify({ login_id: username});
        await axios.post(BASE_API_URL + 'login/resend-link', json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            setLinkResponse(res.data);
        }).catch(err=>{
            setLinkResponse("Something went wrong!");
            console.log("Error: ", err);
        });
        
    }
    const generateCode = async () => {
        setLinkResponse("");
        const json = JSON.stringify({ login_id: username});
        await axios.post(BASE_API_URL + 'login/generate-code', json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if(res.data.error !== "" && res.data.error === false){
                setLinkResponse("Verification code sent : "+res.data.emailId);
            }else{
                setLinkResponse(res.data.error);
            }
            
        }).catch(err=>{
            setLinkResponse("Something went wrong!");
            console.log("Error: ", err);
        });
        
    }
    const verifyCode = async () => {
        setLinkResponse("");
        const json = JSON.stringify({ login_id: username, "verificationCode": verificationCode});
        await axios.post(BASE_API_URL + 'login/verify-code', json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if(res.data.msg === "Account verified successfully!"){
                props.handleUpdateCallback(res.data.msg);
            }else{
                setLinkResponse(res.data.msg);
            }
           
            
        }).catch(err=>{
            setLinkResponse("Something went wrong!");
            console.log("Error: ", err);
        });
        
    }

    const handleOnChange = e => {
        const{name, value} = e.target;
        if(name === 'verificationCode'){
            setVerificationCode(value);
        }
    }

return (
    
        <Modal {...props} centered style={{"width":"50%","top":"0%","left":"25%"}}>
            <Modal.Body className='text-center'>
                <Container style={{"width":"100%"}}>
                <h1><SlUserUnfollow style={{"fontSize":"5pc","color":"#0f6fc5"}}/></h1> <br></br><p className="text-muted">User has not confirmed registration. <br></br>Please check inbox and/or junk mail folder to verify</p>
                <br></br>
                    <Button onClick={()=>triggerEmail()}>Resend verification link</Button>
                    <Container style={{"width":"90%"}}>
                        <hr></hr>
                        <Row>
                            <p className='text-center text-muted'>Didn't receive email?</p>
                            <p className='text-center text-muted'><SlPaperPlane/>&nbsp;Generate verification code for your email and confirm below</p>
                        </Row>
                        <Row>
                            <Form.Group as={Row} controlId="formGridPassword">
                                <Form.Control type="input" name="verificationCode" onChange={handleOnChange} value={verificationCode} required placeholder="Enter verification code" />
                            </Form.Group>
                        </Row>
                        <Row>{ (linkResponse != "")?
                            <div>
                                <br></br>
                                <p className="text-center">{linkResponse}</p>
                            </div>
                            :""
                            }
                        </Row>
                        <br></br>
                        <Button as={Col} variant="primary" onClick={()=>generateCode()}>
                                Generate Code
                        </Button>
                            &nbsp;&nbsp;
                        <Button as={Col} variant="primary" onClick={()=>verifyCode()}>
                            Verify
                        </Button>
                    </Container>
                </Container>
            </Modal.Body>
        </Modal>

);}
export default LoginVerificationModal;