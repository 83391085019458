import React, { useState, useEffect } from 'react';
import { Row, Container } from 'react-bootstrap';
import * as apiUtility from '../../config/APIUtils';
import SuccessPopUp from '../Utility/SuccessPopUp';
import ErrorPopUp from '../Utility/ErrorPopUp';
import SpinnerBox from '../Utility/SpinnerBox';
import { Typography } from '@mui/material';
import 'react-phone-number-input/style.css';
import MessageBox from '../Utility/MessageBox';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';


function TrainingProviderDetails(props) {
    var homeTypes = JSON.parse(sessionStorage.getItem("home_types"));

    const URL_TP_COURSE_DETAILS = "course/fetch-provider-courses/";

    const [dataFlag, setDataFlag] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const [courses, setCourses] = useState([]);
    const [courseVersions, setCourseVersions] = useState({});


    var data = props.data;
    var mapOfHomeType = {};
    homeTypes?.forEach(element => {
        mapOfHomeType[element.id] = element.value;
    });

    useEffect(() => {
        //console.log("Data recived : ",data);
        if (data === undefined || data.tp_id === undefined) {
            setDataFlag(false);
        } else {
            var apiParams = apiUtility.apiInputRequest;
            apiParams.endPoint = URL_TP_COURSE_DETAILS + data.tp_id;
            apiUtility.fetchAPIDataGET(apiParams).then(res => {
                var parentCourse = res.data["course"];
                var courseVersions = res.data["courseVersions"];
                if (parentCourse === undefined || courseVersions === undefined) {
                    setErrorMsg("Internal server error. Error while fetching courses.");
                    setShowErrorMsg(true);
                }else{
                    setCourses(Object.values(parentCourse));
                    setCourseVersions(courseVersions);
                    setDataFlag(true);
                }
            })
                .catch(err => {
                    setErrorMsg("Internal server error. Please try again later.");
                    setShowErrorMsg(true);
                    console.error('Request failed:', err);
                })
        }
    }, [props.data]);

    const courseCodeUrlFormatter = (data, row) => {
        if (row.training_url !== undefined && row.training_url !== "") {
            return <Typography variant='body' style={{ width:'8rem', cursor: 'pointer',display:'flex' }} onClick={() => { window.open(row.training_url, "_blank") }}>
                    <OpenInNewOutlinedIcon color='primary' style={{ fontSize:'1.3pc' }} />&nbsp;{row.courseID}
                </Typography>;
        }else{
            return <Typography variant='body' style={{ width:'8rem' }}>
                        {row.courseID}
                </Typography>;
        }
    };

    const validityFormatter = (data, row) => {
        if (row.validity_duration.years !== 0 || row.validity_duration.months !== 0) {
            let str = (row.validity_duration.years > 0)? row.validity_duration.years+" years ":"";
            str += (row.validity_duration.months > 0)? row.validity_duration.months+" months":"";
            return <Typography variant='body' style={{ width:'8rem'}} >
                    {str}
                </Typography>;
        }else{
            return "";
        }
    };

    const industryFormatter = (data, row) => {
        return mapOfHomeType[row.type];
    };

    const issueOrgFormatter = (data, row) => {
        return props.data.provider_name;
    };

    const dateFormatter = (data, row) => {
        let date = new Date(row.last_modified_date); 
        return date.toLocaleDateString();
    };

    const registrationFormatter = (data, row) => {
        return (row.direct_registration)?"Yes":"No";
    };
    
    const validFormatter = (data, row) => {
        return (row.is_valid)?"Yes":"No";
    };

    const trainDurationFormatter = (data, row) => {
        let td = (row.training_duration.indexOf("-m") >= 0) ? row.training_duration.split("-")[0] + " mins" : row.training_duration.split("-")[0] + " hrs";
        return td;
    };

    const columns = [
        {
            dataField: "courseID",
            text: "Course Code",
            sort: true,
            filter: textFilter(),
            formatter: courseCodeUrlFormatter,
            style: {
                fontWeight: 'bold',
                height: '5%',
                width: "7rem"
            }

        },
        {
            dataField: "title",
            text: "Title",
            sort: true,
            filter: textFilter(),
            title: (cell, row, rowIndex, colIndex) => `${cell}`
        },
        {
            dataField: "description",
            text: "Description",
        },
        {
            dataField: "type",
            text: "Applicable Industry",
            sort: true,
            formatter: industryFormatter
        },
        {
            dataField: "last_modified_date",
            text: "Last Modified",
            sort: true,
            formatter: dateFormatter
        },
        
        {
            dataField: "validity_duration",
            text: "Expiry ",
            formatter: validityFormatter,
        }  
    ];

    const courseVersionColumns = [
        {
            dataField: "tp_course_code",
            text: "Version Code",
            sort: true,
            style: {
                fontStyle: 'italic',
                height: '3%'
            }

        },
        {
            dataField: "version_number",
            text: "Version Reference Number",
        },
        {
            dataField: "title",
            text: "Title",
        },
        {
            dataField: "training_duration",
            text: "Training Duration",
            formatter: trainDurationFormatter
        },
        {
            dataField: "is_valid",
            text: "Is Valid",
            formatter: validFormatter
        },
        {
            dataField: "last_modified_date",
            text: "Last Modified",
            formatter: dateFormatter
        },
        {
            dataField: "orgIssued",
            text: "Training Provider",
            formatter: issueOrgFormatter
        },
        {
            dataField: "direct_registration",
            text: "Direct Registration",
            formatter: registrationFormatter
        }
        

    ];


    const paginationOptions = {
        sizePerPage: 10,
        hideSizePerPage: false,
        hidePageListOnlyOnePage: false,
    };

    const expandRow = {
        renderer: row => (
            <div>
                <BootstrapTable columns={courseVersionColumns}
                keyField="course_version_id"
                            data={courseVersions[row.courseID]}
                            filter={filterFactory()}
                            striped
                            hover
                            condensed />
            </div>
        ),
        text: "View",
        showExpandColumn: true,
        onlyOneExpanding: true,
        expandByColumnOnly: true
    };


    return (
        <div style={{ marginBottom: "2pc" }}>
            <Container fluid>
                <Row>
                    {(!dataFlag) ?
                        <Row>
                            <MessageBox data="Select a training provider to view" />
                        </Row>
                        :
                        <BootstrapTable columns={columns}
                                keyField="courseID"
                                data={courses}
                                pagination={paginationFactory(paginationOptions)}
                                filter={filterFactory()}
                                expandRow={expandRow}
                                filterPosition="top"
                                striped
                                hover
                                condensed />
                    }
                </Row>
                <br></br>

            </Container>
            <ErrorPopUp show={showErrorMsg} data={errorMsg} onHide={() => { setShowErrorMsg(false) }} />
        </div>
    )
}

export default TrainingProviderDetails;



