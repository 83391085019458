import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import UserHomePage from "../user/UserHomePage";
import { Card, Col, Container, Row } from "react-bootstrap";
import { SlUserFollowing, SlUserUnfollow } from "react-icons/sl";
import MessageBox from "../Utility/MessageBox";
import * as apiUtility from "../../config/APIUtils";
import { Box } from "@mui/material";

function EnterIndividualForm() {
    const [userIdList, setUserIdList] = useState([]);
    const [userId, setUserId] = useState("");
    const accessToken = sessionStorage.getItem('authToken');
    const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

    useEffect(async () => {
        // user / getAll
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "user/getAllForAdminUserModule";
        //apiRequest.data = tempOrgObj;
        var res = await apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
           
             //console.log(res.data.data);
             var userIDListArr = [];
             for (let i = 0; i < res.data.data.length; i++) {
                 if (res.data.data[i]["firstName"] == undefined) {
                     res.data.data[i]["firstName"] = "";
                 }
                 if (res.data.data[i]["lastName"] == undefined) {
                     res.data.data[i]["lastName"] = "";
                 }
                 var body = {
                     label: res.data.data[i]["firstName"] + " " + res.data.data[i]["lastName"] + " (" + res.data.data[i]["user_id"] + ")",
                     id: res.data.data[i]["user_id"],verified: res.data.data[i]["verified"],active: res.data.data[i]["active"]
                 }
                 userIDListArr.push(body);
              
             }
             setUserIdList(userIDListArr)
          
        }).catch((error) => {
                console.error(error);
                // setshowSpinner(false);
              });
        // if(res){
        //      //console.log(res.data.data);
        //      var userIDListArr = [];
        //      for (let i = 0; i < res.data.data.length; i++) {
        //          if (res.data.data[i]["firstName"] == undefined) {
        //              res.data.data[i]["firstName"] = "";
        //          }
        //          if (res.data.data[i]["lastName"] == undefined) {
        //              res.data.data[i]["lastName"] = "";
        //          }
        //          var body = {
        //              label: res.data.data[i]["firstName"] + " " + res.data.data[i]["lastName"] + " (" + res.data.data[i]["user_id"] + ")",
        //              id: res.data.data[i]["user_id"]
        //          }
        //          userIDListArr.push(body);
        //      }
        //      setUserIdList(userIDListArr)
        // }
        // var gethomeDetailsUrl = BASE_API_URL + "user/getAllForAdminUserModule";
        // axios.get(gethomeDetailsUrl,{headers})
        //     .then(res => {

        //         //console.log(res.data.data);
        //         var userIDListArr = [];
        //         for (let i = 0; i < res.data.data.length; i++) {
        //             if (res.data.data[i]["firstName"] == undefined) {
        //                 res.data.data[i]["firstName"] = "";
        //             }
        //             if (res.data.data[i]["lastName"] == undefined) {
        //                 res.data.data[i]["lastName"] = "";
        //             }
        //             var body = {
        //                 label: res.data.data[i]["firstName"] + " " + res.data.data[i]["lastName"] + " (" + res.data.data[i]["user_id"] + ")",
        //                 id: res.data.data[i]["user_id"]
        //             }
        //             userIDListArr.push(body);
        //         }
        //         setUserIdList(userIDListArr)
        //         //console.log()
        //         // toggleshowSpinner()
        //     })
        //     .catch(err => {
        //         //console.log(err);
        //     })







    }, [])

    function getUserIdFromList(e,v){
        setUserId("");
        if(v !== undefined && v !== null && v.id !== undefined && v.id !== null){
            setUserId(v.id)
           // console.log(((v).split("(")[1]).split(")")[0]))
        }
    }

  //  userIDListArr.push(res.data.data[i]["firstName"] + " " + res.data.data[i]["lastName"] + " (" + res.data.data[i]["user_id"] + ")");


    return (
       <Container fluid style={{"width":"95%"}}>
        <Card style={{"border-radius":"0px"}}>
        <br></br>
                <Row className="text-center">
                    <Col xs={0} md={4}></Col>
                    <Autocomplete
                        disablePortal
                        as={Col}
                        style={{"margin":"0.5pc"}}
                        id="userIdAutoInputs"
                        options={userIdList}
                        sx={{ width: 300 }}
                        onChange={(e, v) => {
                            getUserIdFromList(e,v)
                        }}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} id={option.user_id} {...props}>

                                {
                                    (option.verified === true || option.verified === "true") ?
                                        <SlUserFollowing style={{ "fontSize": "1.5pc", "color": "#0f6fc5", "float": "left" }} /> :
                                        ((option.active === false || option.active === "false") && (option.verified === false || option.verified === "false")) ?
                                            <SlUserUnfollow style={{ "fontSize": "1.5pc", "color": "red", "float": "left" }} />
                                            : <SlUserUnfollow style={{ "fontSize": "1.5pc", "color": "#0f6fc5", "float": "left" }} />
                                } &nbsp; &nbsp;{option.label}
                            </Box>
                        )}
                        renderInput={(params) => <TextField {...params} label="Select User" />}
                    />
                    <Col xs={0} md={4}></Col>
                </Row>
                {/* <Card variant="outlined" flex style={{"margin":"2pc","width":"40%","border-radius":"0px"}} className='mx-auto'><h5 style={{"margin":"2pc"}} className="text-muted text-center"><SlInfo style={{"font-size":"2pc"}} className="text-muted text-center"/><br></br>Select an user to view profile</h5></Card> */}
                <Row>
                    {(userId === "") ? <MessageBox data="Select an user to view profile"/>
                     : <UserHomePage userId={userId} />}
                </Row>
            </Card>
            <br></br>
            </Container>
    )
}

export default EnterIndividualForm