export const BACKEND_URL = 'https://lcpt-prod-backend.herokuapp.com/';
export const FRONTEND_URL = 'https://www.carepassport.ca/';

export const BASE_API_URL = process.env.NODE_ENV === 'production' ? BACKEND_URL : "http://localhost:4000/";
export const BASE_URL_FRONTEND = process.env.NODE_ENV === 'production' ? FRONTEND_URL : 'http://localhost:3000/';

//only changes for production build in package.json
//"start": "node server.js",
//"start-react": "react-scripts start",
//For dev
////"start": "react-scripts start",

