import UserCourses from './UserCourses';
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { Form, Tabs, Tab, Row, Col, Container, } from 'react-bootstrap'
import HomeMainComponent from '../organisation_component/HomeMainComponent';
import UserProfile from './UserProfile';
import Organisation from '../organisation_component/organisation';
import * as apiUtility from '../../config/APIUtils';
import './tabs.css';
import MessageBox from '../Utility/MessageBox';
import SessionTimeoutContainer from '../Utility/SessionTimeoutContainer';
import { Badge } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import UserTrainingCart from './UserTrainingCart';
import { useDispatch } from 'react-redux';
//import { loadUserTrainingCart } from '../../config/UserSlice';

function UserHomePage(props) {

    const dispatch = useDispatch();

    const [homeIdForUser, setomeIdForUser] = useState("");
    const [orgIdForAdmin, setOrgIdForAdmin] = useState("");
    const [homeDetails, setHomeDetails] = useState([]);
    const [orgDet4DrpDwn, setOrgDet4DrpDwn] = useState([]);
    const [orgCount, setOrgCount] = useState('1');
    const [homeDetailList, setHomeDetailList] = useState('1');
    const [orgDetailList, setOrgDetailList] = useState([]);
    const [orgTabFlag, setOrgTabFlag] = useState(false);
    const [homeTabFlag, setHomeTabFlag] = useState(true);
    const [userId, setUserId] = useState('');
    const [hideOrgComponent, setHideOrgComponent] = useState(false);
    const [hideHomeComponent, setHideHomeComponent] = useState(false);
    const [cartCourseCount, setCartCourseCount] = useState(0);
    const [cartCourseList, setCartCourseList] = useState({});

    var paramId = useParams().id;
    useEffect(() => {
        var user = "";
        if (paramId !== undefined) {
            setUserId(paramId)
            user = paramId;
        } else if (props.userId !== undefined) {
            setUserId(props.userId)
            user = props.userId;
        }
        if (user != undefined && user != "") {
            temMethod(user);
            fetchTrainingCartCourses(user);
        }
    }, [props.userId])

    const temMethod = (user) => {
        setHomeDetails(JSON.parse(sessionStorage.getItem("allHomeArr")));
        setOrgDet4DrpDwn(JSON.parse(sessionStorage.getItem("OtherOrgId")));
        if (sessionStorage.getItem("userType") === 'admin')
            setHideOrgComponent(true);
        const getOrgListFromUserId = "orgnization/getOrgInfoForUserLogin/"
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = getOrgListFromUserId;
        apiRequest.data = {
            "user_id": user,
            "isAdmin": false,
            "homeList": (JSON.parse(sessionStorage.getItem("allHomeArr")))
        };
        apiUtility.sendAPIDataPOST(apiRequest)
            .then(res => {
                for (let data of res.data) {
                    if (data.orgListFromUserId != undefined && data.orgListFromUserId != "") {
                        setOrgDetailList(data.orgListFromUserId);
                        setOrgTabFlag(true);
                    } else {
                        setOrgTabFlag(false);
                        setOrgDetailList([])
                    }
                    if (data.homeListDetail != undefined && data.homeListDetail != "") {
                        setHomeTabFlag(true);
                        setHomeDetailList(data.homeListDetail)
                    } else {
                        setHomeTabFlag(false);
                        setHomeDetailList([])
                    }
                    setOrgCount(String(parseInt(data.getOrgCount) + 1))
                    setOrgDet4DrpDwn(JSON.parse(sessionStorage.getItem("OtherOrgId")))
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const fetchTrainingCartCourses = async (user) => {
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "user/fetchTrainingCartList/" + user;
        await apiUtility.fetchAPIDataGET(apiRequest)
            .then(res => {
                //console.log("Response: ", res.data);
                //dispatch(loadUserTrainingCart(res.data));
                setCartCourseList(res.data);
                setCartCourseCount(1);
                countLengthOfCartCourses(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    var createHomeIDSelectItems = () => {
        let items = [];
        for (var i = 0; i < homeDetailList.length; i++) {
            items.push(<option value={homeDetailList[i].home_id}> {homeDetailList[i].name} </option>);
        }
        return items;
    }

    function changeHomeId(e) {
        var homeID = (e.target.value);
        if (homeID != '') {
            setHideHomeComponent(false);
            setomeIdForUser(homeID);
            sessionStorage.setItem("homeId", homeID);
        } else {
            setHideHomeComponent(true);
        }
    }

    function changeOrganisation(event) {
        if (event.target.value != "") {
            setHideOrgComponent(false);
            sessionStorage.setItem("orgId", event.target.value)
            setOrgIdForAdmin(String(event.target.value))
        } else {
            setHideOrgComponent(true);
        }
    }

    const handleCourseCartCallback = (result, addedFromRejected, isRemovedFromPending, deletedFromRejected) => {
        let cart = cartCourseList;
        //console.log("Cart Course list : ",cartCourseList, " Result : ",result);
        if (deletedFromRejected) {
            //Just deleting from Pending list
            let filteredRejArray = cart["rejected"].filter(item => item.courseVersionId !== result.courseVersionId);
            cart["rejected"] = filteredRejArray;
            //console.log("filteredRejArray : ",filteredRejArray, " cart ", cart["rejected"]);
            setCartCourseList(cart);
            setCartCourseCount(1);
        } else {
            //Different scenario working on both pending rejected list
            if (isRemovedFromPending) {
                let filteredArray = cart["pending"].filter(item => item.courseVersionId !== result.courseVersionId);
                cart["pending"] = filteredArray;
                setCartCourseList(cart);
            } else {
                cart["pending"].push(result);
                //setCartCourseList(cart);
                //cart = {...cart, pending: [...cart.pending ,result]};
                //console.log("In pending adding - course: ", cart);
                setCartCourseList(cart);
            }
            if (addedFromRejected) {
                let filteredRejArray = cart["rejected"].filter(item => item.courseVersionId !== result.courseVersionId);
                cart["rejected"] = filteredRejArray;
                setCartCourseList(cart);
            }
        }

        countLengthOfCartCourses();
    }

    const updatedCartAfterSubmission = (result) => {
        let cart = cartCourseList;
        let olderApproved = cart["active-approved"];
        if (olderApproved != undefined && olderApproved.length > 0) {
            //let app = (cart["approved"] != undefined)?cart["approved"]:[];
            const merged = [...(olderApproved || []), ...(cart["approved"] || [])];
            cart["approved"] = merged;
        }
        cart["active-approved"] = [];
        let rejected = [];
        result.forEach(element => {
            if (element.status == true) {
                cart["active-approved"].push(element);
            } else {
                rejected.push(element);
            }
        });
        cart["rejected"] = rejected;
        cart["pending"] = [];
        setCartCourseList(cart);
        countLengthOfCartCourses();
    }

    const countLengthOfCartCourses = (response) => {
        let count = 0;
        //console.log("CartCourseList: ", cartCourseList, " Response :", response)
        if (response == undefined) {
            //console.log("Count from CartCourseList: ", cartCourseList["pending"], "Aprov -> ", cartCourseList["approved"], "Rejected -> ",cartCourseList["rejected"]);
            count += (cartCourseList["pending"] != undefined && cartCourseList["pending"].length > 0) ? cartCourseList["pending"].length : 0;
        } else {
            //console.log("Count from Response: ", response["pending"], "Aprov -> ", response["approved"], "Rejected -> ",response["rejected"]);
            count += (response["pending"] != undefined && response["pending"].length > 0) ? response["pending"].length : 0;
        }
        //console.log("Final Cournt : ",count);
        setCartCourseCount(count);
    }

    return (
        <Container fluid style={{ marginTop: "8vh" }}>
            <Row>
                <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3">
                    <Tab as={Col} eventKey="profile" title="My Profile">
                        <UserProfile userId={props.userId} homeId={props.homeID} />
                    </Tab>
                    <Tab as={Col} eventKey="employment" title="Manage My Training">
                        <UserCourses userId={props.userId} homeId={props.homeID} count={cartCourseCount} activeCartList={cartCourseList} handleCourseCartCallback={handleCourseCartCallback} />
                    </Tab>
                    <Tab as={Col} eventKey="trainingCart" title={
                        <React.Fragment>
                            Training Cart &nbsp;
                            <Badge color="primary" badgeContent={cartCourseCount}>
                                <ShoppingCartIcon />
                            </Badge>
                        </React.Fragment>
                    }>
                        <UserTrainingCart userId={props.userId} homeId={props.homeID} count={cartCourseCount} cartCourseList={cartCourseList} handleCourseCartCallback={handleCourseCartCallback} updatedCartAfterSubmission={updatedCartAfterSubmission} />
                    </Tab>
                    {/* <Tab eventKey="audit" title="Audit">
                    <AuditReportManagement userId={props.userId}/>
                </Tab> */}

                    {orgTabFlag ? (
                        <Tab as={Col} eventKey="organizationView" title="Manage Organizations" >
                            {(sessionStorage.getItem("userType") && sessionStorage.getItem("userType") == 'admin') ?
                                //<div style={{ display: "flex", marginLeft: "10%",marginBottom:"5%" }}>
                                <Row><Col xs={0} md={4}></Col>
                                    <Form.Group as={Col} controlId="formBasicEmail">

                                        <p className="text-muted"> Select Organization</p>
                                        <Form.Select size="lg" aria-label="Default select example" onChange={(e) => {
                                            changeOrganisation(e);
                                        }} >
                                            <option value="" className='text-muted'> -- Please select an Organization -- </option>
                                            {orgDetailList.map((item, _id) => {
                                                return <option key={_id} value={item.permission_of_id}>
                                                    {item.name}
                                                </option>
                                            })}
                                        </Form.Select>


                                    </Form.Group><Col xs={0} md={4}></Col>
                                </Row>
                                //</div>
                                // <Card flex style={{"margin":"2pc","width":"30%","border-radius":"1.5px"}} className='mx-auto'><h5 style={{"margin":"2pc"}} className="text-muted text-center"><SlInfo style={{"font-size":"2pc"}} className="text-muted text-center"/><br></br>Select an organization to view</h5></Card>
                                : <div></div>}
                            {!hideOrgComponent ?
                                <Organisation userId={userId} orgIdForAdmin={orgIdForAdmin} />
                                : <MessageBox data="Select an organization to view" />}


                        </Tab>
                    ) : (
                        <div></div>
                    )}
                    {homeTabFlag ? (

                        <Tab as={Col} eventKey="homeView" title="Manage Homes" >

                            {/* <div style={{ display: "flex", marginLeft: "10%" }}> */}
                            <Row><Col xs={0} md={4}></Col>
                                <Form.Group as={Col} controlId="formBasicHome">
                                    <p className="text-muted"> Select Home  </p>
                                    <Form.Select size="lg" id="homeDropDown" onChange={(e) => {
                                        changeHomeId(e)

                                    }}>
                                        <option value=""> -- Please select a Home -- </option>
                                        {createHomeIDSelectItems()}

                                    </Form.Select>
                                </Form.Group><Col xs={0} md={4}></Col>
                            </Row>
                            {/* </div> */}
                            {/* <Card flex style={{"margin":"2pc","width":"30%","border-radius":"1.5px"}} className='mx-auto'><h5 style={{"margin":"2pc"}} className="text-muted text-center"><SlInfo style={{"font-size":"2pc"}} className="text-muted text-center"/><br></br>Select a home to view</h5></Card> */}
                            {(homeIdForUser == "" || hideHomeComponent) ? <MessageBox data="Select a home to view" /> :
                                <HomeMainComponent userId={userId} homeIdFprAdmin={homeIdForUser} />

                            }

                        </Tab>
                    ) : (
                        <div></div>
                    )}
                </Tabs>
            </Row>
            <SessionTimeoutContainer />
        </Container>)
}

export default UserHomePage;