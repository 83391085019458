import React, { useEffect, useState } from 'react'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import BootstrapTable from 'react-bootstrap-table-next';
import Spinner from 'react-bootstrap/Spinner'
import { Col, Row } from 'react-bootstrap';
import * as apiUtility from "../../config/APIUtils";
import { IconButton, Paper, Tooltip } from "@mui/material";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import ErrorPopUp from '../Utility/ErrorPopUp';
import { RiFileExcel2Line } from "react-icons/ri";

function CourseExpiryReport(props) {
    const [expCrsData, setExpCrsData] = useState([]);
    const [showSpinner, setshowSpinner] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [dataForErrorPopup, setDataForErrorPopup] = useState('');

    useEffect(async () => {
        setshowSpinner(true)
        var requestBody = {...props};
        console.log(props.expDate.startDate,props.expDate.endDate)
        if(requestBody.expDate !== undefined && requestBody.expDate !== ""){
            let tempStartDate = requestBody.expDate.startDate + 'T' + new Date().toISOString().split('T')[1];
            let tempEndDate = requestBody.expDate.endDate + 'T' + new Date().toISOString().split('T')[1];
            requestBody.expDate.startDate = tempStartDate;
            requestBody.expDate.endDate = tempEndDate;
           // requestBody.expDate =  requestBody.expDate + 'T' + new Date().toISOString().split('T')[1];
        }
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "audit-report/user-crs-expiry/";
        apiRequest.data = requestBody;
        apiUtility.updateAsyncAPIDataPUT(apiRequest).then(res => {
            var courseExpData = res.data;
            setshowSpinner(false)
            setExpCrsData(courseExpData)
        }).catch((error) => console.error(error));


    }, [props.homeId]);

    const processedData = expCrsData.map(item => {
        return {
          ...item,
         
            formattedExpiryDate: new Date(item.expiry_date).toLocaleDateString(),

        };
      });
      
      var adminHomeCrsCols = [
        {
            dataField: 'user_id',
            sort: true,
            text: 'User ID'
        },
       
        {
            dataField: 'first_name',
            sort: true,
            text: 'First Name'
        },
        {
            dataField: 'last_name',
            sort: true,
            text: 'Last Name'
        },
         {
            dataField: 'home_id',
            sort: true,
            text: 'Home ID'
        },
        {
            dataField: 'role_name',
            sort: true,
            text: 'Role Name'
        },
        , {
            dataField: 'course_id',
            text: 'Course ID'
        }

        , {
            dataField: 'formattedExpiryDate',
            sort: true,
            text: 'Course Expiry Date'
        }
    ];

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: "xlsx",
            bookSST: false,
            type: "binary",
        };
        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([s2ab(wbout)], {
            type: "application/octet-stream",
        });
        return blob;
    };
    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; i++) {
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const exportInExcel = async () => {
        try {
            var rowsCount = 6;
            const title = [{A: 'Ontario Long Term Care Orientation Co-operative Webportal - Course Expiry Report for Users'}];
            let reportView = [{ A:'Name',B:'User ID',C:'Role',D:'Home ID',E:'Date'}];
            reportView.push({
                A: sessionStorage.getItem('fullName'),
                B: sessionStorage.getItem('userId'),
                C: sessionStorage.getItem('userType').toUpperCase(),
                D: props.homeName+'('+props.homeId+')',
                E: new Date().toLocaleString()
            });
            reportView.push("");
            reportView.push("");
            let reportHeaders = {A:'User ID',B:'First Name',C:'Last Name',D:'Home ID',E:'Role Name',F:'Course ID',G:'Course Expiry Date'};
            reportView.push(reportHeaders);
           // orgStaffTemplateData.forEach(element => {
            processedData.forEach(element => {
                    rowsCount += 1;
                    var tempObj = {
                        A: element.user_id,
                        B: element.first_name,
                        C: element.last_name,
                        D: element.home_id,
                        E: element.role_name,
                        F: element.course_id,
                        G: element.formattedExpiryDate,
                    };
                    reportView.push(tempObj);
                });
            const finalData = [...title, ...reportView];
            const worksheet = XLSX.utils.json_to_sheet(finalData, {
                skipHeader: true,
            });
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Home Course Role Report");
            const workbookblob = workbook2blob(workbook);
            var headerIndexes = [];
            finalData.forEach((data, index) =>
                data["A"] === "User ID" ? headerIndexes.push(index) : null
            );
            const dataInfo = {
                titleCell: "A2",
                titleRange: "A1:G1",
                tbodyRange: `A7:G${finalData.length}`,
                uHeadRange: `A2:G2`,
                uBodyRange: `A3:G3`,
                theadRange:
                  headerIndexes?.length >= 1
                    ? `A${headerIndexes[0] + 1}:G${headerIndexes[0] + 1}`
                    : null,
                theadRange1:
                  headerIndexes?.length >= 2
                    ? `A${headerIndexes[1] + 1}:I${headerIndexes[1] + 1}`
                    : null,
                tFirstColumnRange:
                  headerIndexes?.length >= 1
                    ? `A${headerIndexes[0] + 1}:A${rowsCount + headerIndexes[0] + 1}`
                    : null,
                tLastColumnRange:
                  headerIndexes?.length >= 1
                    ? `K${headerIndexes[0] + 1}:I${rowsCount + headerIndexes[0] + 1}`
                    : null,
          
                tFirstColumnRange1:
                  headerIndexes?.length >= 1
                    ? `A${headerIndexes[1] + 1}:A${rowsCount + headerIndexes[1] + 1}`
                    : null,
                tLastColumnRange1:
                  headerIndexes?.length >= 1
                    ? `H${headerIndexes[0] + 1}:I${rowsCount + headerIndexes[1] + 1}`
                    : null,
              };
            XlsxPopulate.fromDataAsync(workbookblob).then((workbook)=>{
                workbook.sheets().forEach((sheet)=>{
                    sheet.column("A").width(15);
                    sheet.column("B").width(30);
                    sheet.column("C").width(50);
                    sheet.column("D").width(30);
                    sheet.column("E").width(30);
                    sheet.column("F").width(30);
                    sheet.column("G").width(30);
                    sheet.range(dataInfo.titleRange).merged(true).style({
                        bold: true,
                        fontFamily:"Arial",
                        horizontalAlignment: "center",
                        verticalAlignment: "center",
                    });
                    sheet.range(dataInfo.uHeadRange).style({
                        fill: "A6A6A6",
                        bold: true,
                        horizontalAlignment: "center",
                    });
                    sheet.range(dataInfo.uBodyRange).style({
                        bold: true,
                        horizontalAlignment: "center",
                    });
                    if (dataInfo.tbodyRange) {
                        sheet.range(dataInfo.tbodyRange).style({
                          horizontalAlignment: "left",
                        });
                      }
                      sheet.range(dataInfo.theadRange).style({
                        fill: "A6A6A6",
                        bold: true,
                        horizontalAlignment: "center",
                      });
                      if (dataInfo.tFirstColumnRange) {
                        sheet.range(dataInfo.tFirstColumnRange).style({
                          bold: true,
                        });
                      }
                     
                });
    
                return workbook.outputAsync().then((workbookBlob) => { 
                        const downloadAnchorNode = document.createElement("a");
                        var dateVar = new Date();
                        var fileName = "LCPT_CourseExpiryReport-"+dateVar.getDate()+dateVar.getMonth()+dateVar.getFullYear()+"_"+dateVar.getHours()+dateVar.getMinutes()+".xlsx";
                        downloadAnchorNode.setAttribute("href", URL.createObjectURL(workbookBlob));
                        downloadAnchorNode.setAttribute("download", fileName);
                        downloadAnchorNode.click();
                        downloadAnchorNode.remove();
                        // setDataForSuccessPopup("Downloaded Successfully");
                        // setShowSuccessPopup(true);
                });  
            });
        } catch (error) {
             setDataForErrorPopup("Something went wrong while processing excel!");
             setShowErrorPopup(true);
        }
    }


    return (
        <div>
            {showSpinner ? <div style={{ paddingLeft: "50%", paddingTop: "10%", position: "absolute" }}>
                <Spinner show={showSpinner} animation="border" size="lg" variant='primary' />

            </div> : <div></div>}
            <Paper sx={{ p: 1, borderRadius: "1.5px" }}>
                <BootstrapTable id='organizationStaffSpecificTable' keyField='id' data={processedData} columns={adminHomeCrsCols} striped hover />
                <Row>
                    <Col xs={0} md={8}></Col>
                    <Col xs={12} md={4} className="text-center">
                    <Paper component="form" variant="outlined"
                        sx={{float:"right", display: 'flex', marginBottom:"4px",alignItems: 'center', width: 50 }}
                        >
                        <Tooltip variant="contained" title="Export to Excel" disableRipple>
                            <IconButton color="primary" onClick={exportInExcel} sx={{ p: '10px' }} aria-label="directions">
                                <RiFileExcel2Line/>
                            </IconButton>
                        </Tooltip>   
                        </Paper>
                    </Col>
                </Row>
            </Paper>
            <ErrorPopUp show={showErrorPopup} data={dataForErrorPopup} onHide={() => { setShowErrorPopup(false) }} />
        </div>
    )
}

export default CourseExpiryReport