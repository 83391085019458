import { BASE_API_URL } from "./Url-config";
import axios from 'axios';
import { BASE_URL_FRONTEND } from "./Url-config";

/***
   * This API Utility method is used to
   * fetch JWT access token from session storage 
   */
const getAuthHeaders = () => {
  const accessToken = sessionStorage.getItem('authToken');
  return {
    "Authorization": `Bearer ${accessToken}`,
  }
}

/**
 * Method is called while logging out
 * to clear details from session & local storage
 * 
 */
export async function logOut() {
  sessionStorage.clear();
  if (localStorage.getItem("activeTab")) {
    localStorage.removeItem("activeTab")
  }
}

/***
   * This API Utility variable is used to
   * send input paramaters for API
   */
export var apiInputRequest = {
  "endPoint": "",
  "data": "",
}

/***
   * This API Utility method is accepting GET request
   * with input parameter containing
   * endPoint (API Endpoint), params (string && formatted with /) 
   */
export function fetchAPIDataGET(input) {
  var response = axios.get(BASE_API_URL + input.endPoint, { headers: getAuthHeaders() })
  var axiosPromise = response;
  response.then(res => {
    if (res === "Access denied" || res === "Invalid token") {
      logOut();
      return window.location.href = BASE_URL_FRONTEND;
    }
  })
    .catch(err => {
      throw err;
    })
  return axiosPromise;
}

/***
   * This ASYNC API Utility method is accepting GET request
   * with input parameter containing
   * endPoint (API Endpoint), params (string && formatted with /) 
   */
export async function fetchAsyncAPIDataGET(input) {

  var response = axios.get(BASE_API_URL + input.endPoint, { headers: getAuthHeaders() })
  var axiosPromise = response;
  response.then(res => {
    if (res === "Access denied" || res === "Invalid token") {
      logOut();
      return window.location.href = BASE_URL_FRONTEND;
    }
  })
    .catch(err => {
      throw err;
    })
  return axiosPromise;
}


/***
   * This API Utility method is accepting POST request
   * with input parameter containing
   * endPoint (API Endpoint), params (string && formatted with /), data (request body)
   */
export function sendAPIDataPOST(input) {
  var response = axios.post(BASE_API_URL + input.endPoint, input.data, { headers: getAuthHeaders() })
  var axiosPromise = response;
  response.then(res => {
    if (res === "Access denied" || res === "Invalid token") {
      logOut();
      return window.location.href = BASE_URL_FRONTEND;
    }
  })
    .catch(err => {
      throw err;
    })
  return axiosPromise;
}

export async function sendAsyncAPIDataPOST(input) {
  var response = axios.post(BASE_API_URL + input.endPoint, input.data, { headers: getAuthHeaders() })
  var axiosPromise = response;
  response.then(res => {
    if (res === "Access denied" || res === "Invalid token") {
      logOut();
      return window.location.href = BASE_URL_FRONTEND;
    }
  })
    .catch(err => {
      throw err;
    })
  return axiosPromise;
}

export function updateAPIDataPUT(input) {
  var response = axios.put(BASE_API_URL + input.endPoint, input.data, { headers: getAuthHeaders() });
  var axiosPromise = response;
  response.then(res => {
    if (res === "Access denied" || res === "Invalid token") {
      logOut();
      return window.location.href = BASE_URL_FRONTEND;
    }
  })
    .catch(err => {
      throw err;
    })
  return axiosPromise;
}

export async function updateAsyncAPIDataPUT(input) {
  var response = axios.put(BASE_API_URL + input.endPoint, input.data, { headers: getAuthHeaders() });
  var axiosPromise = response;
  response.then(res => {
    if (res === "Access denied" || res === "Invalid token") {
      logOut();
      return window.location.href = BASE_URL_FRONTEND;
    }
  })
    .catch(err => {
      throw err;
    })
  return axiosPromise;
}

