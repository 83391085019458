import React from 'react';
import {
  DataGrid, GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector
} from '@mui/x-data-grid';
import { Alert, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { RiFileExcel2Line } from "react-icons/ri";

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  '.MuiDataGrid-columnHeadersInner, .MuiDataGrid-footerContainer, .css-rtrcn9-MuiTablePagination-root': {
    backgroundColor: '#1876d2',//'#007bff', // Header and cell background color\
    fontSize: '1pc',
    color: "white"
  },
}));


function JsonDataHistory({ rows, open, onClose }) {
  //var rows = props.history;
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [successAlert, setSuccessAlert] = React.useState(false);
  const [errorAlert, setErrorAlert] = React.useState(false);
  const [filterModel, setFilterModel] = React.useState({});

  React.useEffect(() => {
    setFilterModel({});
  }, [open]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <div style={{"paddingTop":2}}>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            </div>
          </Grid>
          {/* <GridToolbarExport /> */}
          <Grid item md={6} >
            <Tooltip variant="contained" sx={{float:"right"}} title="Export to Excel" disableRipple>
              <IconButton>
                <Button aria-label="export" sx={{ color: '#1876d2', padding: '0px', fontSize: "2pc" }} onClick={exportToExcel}><RiFileExcel2Line /></Button>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    );
  }


  const columns = [
    {
      field: "cnum",
      headerName: "C-Number",
      flex: 1,
      filterable: true
    },
    {
      field: "first",
      headerName: "First name",
      flex: 1,
      filterable: true,
      //     filterOperators: ['contains', 'equals', 'startsWith', 'endsWith'],
      // renderFilterCell: (params) => <TextField autoFocus fullWidth />,
      // sort: true,
      // filter: textFilter(),
      // title: (cell, row, rowIndex, colIndex) => `${cell}`
    },
    {
      field: "last",
      headerName: "Last name",
      flex: 1,
      filterable: true
    },
    {
      field: "code",
      headerName: "Course Code",
      flex: 1,
      filterable: true
    },
    {
      field: "course",
      headerName: "Course Name",
      flex: 1,
      filterable: true
    },
    {
      field: "date",
      headerName: "Completion Date",
      flex: 1,
      filterable: false,
      sortable: false
    },
    {
      field: "last_modified_date",
      headerName: "Last Processed",
      flex: 1,
      filterable: false,
      sortable: false,
    },
    {
      field: "reason",
      headerName: "Failure Reason",
      flex: 1,
      filterable: true,
    }
  ];

  //Excel
  const exportToExcel = async () => {
    try {
      var rowsCount = 6;
      const title = [{ A: 'Ontario Long Term Care Orientation Co-operative Webportal - Error History Report' }];
      let reportView = [{ A: 'Name', B: 'User ID', C: 'Role', D: 'Date' }];
      reportView.push({
        A: sessionStorage.getItem('fullName'),
        B: sessionStorage.getItem('userId'),
        C: sessionStorage.getItem('userType').toUpperCase(),
        D: new Date().toLocaleString()
      });
      reportView.push("");
      reportView.push("");
      let reportHeaders = { A: 'C-Number', B: 'First Name', C: 'Last Name', D: 'Course Code', E: 'Course Name', F: 'Completion Date', G: 'Last Processed', H: 'Failure Reason' };
      reportView.push(reportHeaders);
      for (let data of rows) {
        if (filterModel.items !== undefined && filterModel.items.length > 0 && filterModel.items[0].value !== undefined) {
          var dd = filterModel?.items.find((item) => {
            if (data[item.field].includes(item.value)) {
              return true;
            }
          });
          if (!dd)
            continue;
        }
        rowsCount += 1;
        var courseVersion = {};
        courseVersion = {
          A: data.cnum,
          B: data.first,
          C: data.last,
          D: data.code,
          E: data.course,
          F: data.date,
          G: data.last_modified_date,
          H: data.reason
        };
        reportView.push(courseVersion);
      }
      const finalData = [...title, ...reportView];
      const worksheet = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
      });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "JSON_ErrorReport");
      const workbookblob = workbook2blob(workbook);
      var headerIndexes = [];
      finalData.forEach((data, index) =>
        data["A"] === "C-Number" ? headerIndexes.push(index) : null
      );
      const dataInfo = {
        titleCell: "A2",
        titleRange: "A1:L1",
        tbodyRange: `A7:H${finalData.length}`,
        uHeadRange: `A2:D2`,
        uBodyRange: `A3:D3`,
        theadRange:
          headerIndexes?.length >= 1
            ? `A${headerIndexes[0] + 1}:H${headerIndexes[0] + 1}`
            : null,
        theadRange1:
          headerIndexes?.length >= 2
            ? `A${headerIndexes[1] + 1}:H${headerIndexes[1] + 1}`
            : null,
        tFirstColumnRange:
          headerIndexes?.length >= 1
            ? `A${headerIndexes[0] + 1}:A${rowsCount + headerIndexes[0] + 1}`
            : null,
        tLastColumnRange:
          headerIndexes?.length >= 1
            ? `K${headerIndexes[0] + 1}:L${rowsCount + headerIndexes[0] + 1}`
            : null,

        tFirstColumnRange1:
          headerIndexes?.length >= 1
            ? `A${headerIndexes[1] + 1}:A${rowsCount + headerIndexes[1] + 1}`
            : null,
        tLastColumnRange1:
          headerIndexes?.length >= 1
            ? `H${headerIndexes[0] + 1}:H${rowsCount + headerIndexes[1] + 1}`
            : null,
      };


      XlsxPopulate.fromDataAsync(workbookblob).then((workbook) => {
        workbook.sheets().forEach((sheet) => {
          sheet.column("A").width(15);
          sheet.column("B").width(30);
          sheet.column("C").width(30);
          sheet.column("D").width(20);
          sheet.column("E").width(40);
          sheet.column("F").width(20);
          sheet.column("G").width(20);
          sheet.column("H").width(60);


          sheet.range(dataInfo.titleRange).merged(true).style({
            bold: true,
            fontFamily: "Arial",
            horizontalAlignment: "center",
            verticalAlignment: "center",
          });
          sheet.range(dataInfo.uHeadRange).style({
            fill: "A6A6A6",
            bold: true,
            horizontalAlignment: "center",
          });
          sheet.range(dataInfo.uBodyRange).style({
            bold: true,
            horizontalAlignment: "center",
          });
          if (dataInfo.tbodyRange) {
            sheet.range(dataInfo.tbodyRange).style({
              horizontalAlignment: "left",
            });
          }
          sheet.range(dataInfo.theadRange).style({
            fill: "A6A6A6",
            bold: true,
            horizontalAlignment: "center",
          });
          if (dataInfo.tFirstColumnRange) {
            sheet.range(dataInfo.tFirstColumnRange).style({
              bold: true,
            });
          }

        });

        return workbook.outputAsync().then((workbookBlob) => {
          const downloadAnchorNode = document.createElement("a");
          var dateVar = new Date();
          var fileName = "LCPT_ErrorHistory-" + dateVar.getDate() + dateVar.getMonth() + dateVar.getFullYear() + "_" + dateVar.getHours() + dateVar.getMinutes() + ".xlsx";
          downloadAnchorNode.setAttribute("href", URL.createObjectURL(workbookBlob));
          downloadAnchorNode.setAttribute("download", fileName);
          downloadAnchorNode.click();
          downloadAnchorNode.remove();
          setSuccessAlert(true);
          setErrorAlert(false);
        });
      });
    } catch (error) {
      setSuccessAlert(false);
      setErrorAlert(true);
    }
  }

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };
    const wbout = XLSX.write(workbook, wopts);

    // The application/octet-stream MIME type is used for unknown binary files.
    // It preserves the file contents, but requires the receiver to determine file type,
    // for example, from the filename extension.
    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });
    return blob;
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; i++) {
      view[i] = s.charCodeAt(i);
    }
    return buf;
  }

  const handleFilterModelChange = (model) => {
    // Extract filtered rows based on the current filter model
    setFilterModel(model);
    // const filteredIds = model.items.map((item) => item.columnField);
    // const filteredData = rows.filter((row) =>
    //   filteredIds.every((id) => row["_id"].toString().includes(model.items.find((item) => item.columnField === id).value))
    // );

    // // Update state with filtered rows
    // setFilteredRows(filteredData);
  };

  return (

    <Dialog disableEnforceFocus fullWidth maxWidth="xl" open={open} onClose={onClose}>
      <DialogTitle>Error History
        {/* <Tooltip variant="contained" title="Export to Excel" disableRipple>
                        <IconButton>
                            <Button aria-label="export" sx={{ color: 'text.primary', padding: '0px',fontSize: 30 }} onClick={exportToExcel}><RiFileExcel2Line/></Button>
                        </IconButton>
                    </Tooltip>     */}
      </DialogTitle>
      <DialogContent>
        {(successAlert) ? <Alert severity="success" sx={{ marginBottom: "2px" }} onClose={() => { setSuccessAlert(false) }}>Download Completed!</Alert> : ""}
        {(errorAlert) ? <Alert severity="error" sx={{ marginBottom: "2px" }} onClose={() => { setErrorAlert(false) }}>Error while processing!</Alert> : ""}
        <div style={{ backgroundColor: "white" }}>
          <CustomDataGrid
            rows={rows}
            getRowId={(row) => row._id}
            columns={columns}
            size="small"
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },

            }}
            slots={{
              toolbar: CustomToolbar,
            }}
            //rows={filteredRows.length > 0 ? filteredRows : rows}
            //filterModel={{ items: [] }}
            onFilterModelChange={handleFilterModelChange}
            pageSizeOptions={[10, 50, 100, 500]}
            autoHeight
            sx={{ p: 0 }}


          />
        </div>
      </DialogContent>
    </Dialog>
  );
}
export default JsonDataHistory;