import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Badge, ListGroup, Card, Placeholder } from 'react-bootstrap';
import { SlClose, SlCheck } from "react-icons/sl";
import * as apiUtility from '../../config/APIUtils';
import JsonDataHistory from './JsonDataHistory';
import JsonReportHandler from './JsonReportHandler';
import { Alert } from '@mui/material';

function JsonDataUploader() {
  const [file, setFile] = useState(null);
  const [dataList, setDataList] = useState([]);
  const [errorList, setErrorList] = useState([]);
  const [uploadBar, setUploadBar] = useState(false);
  const [historyTable, setHistoryTable] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [reportTable, setReportTable] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [msgBoxData, setMsgBoxData] = useState("");
  
  const API_UPLOAD = "course/process-json";
  const API_HISTORY = "course/fetch-json-history";
  const API_REPORT = "course/fetch-json-report-history";
  const API_SYNC_FAILED = "course/sync-failed-records";

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };


  const handleUpload = () => {
    //console.log("Encrypt => "+CryptoJS.AES.encrypt("Mark Russell", "12345").toString());
    //var encrypted = CryptoJS.AES.encrypt("Mark Russell", "12345");
    //var bytes1  = CryptoJS.AES.decrypt("f2393536f6aa29919dd2b17c96ade25444b02cee2da1daa5f5066b07bb5105b0", '12345').toString(CryptoJS.enc.Utf8);
    //console.log("Decrypted: ",SHA256("f2393536f6aa29919dd2b17c96ade25444b02cee2da1daa5f5066b07bb5105b0", '12345'));
    //console.log("Decrypt => ",bytes1);

    //return;
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (event) => {
      const jsonData = JSON.parse(event.target.result);
      const dataList = jsonData.map((item) => ({
        cnum: item.cnum,
        first: item.first,
        last: item.last,
        code: item.code,
        course: item.course,
        date: item.date,
        upload: false
      }));
      setDataList(dataList);
      //sendToServer(dataList);
      //console.log("Event target : ", file);
      sendToServer(file, dataList);

    };
  };

  const sendToServer = (file, dataList) => {
    setUploadBar(true);
    setApiError(false);
    setApiSuccess(false);
    setMsgBoxData("");
    setDataList(dataList); 
    setErrorList([]);

      const formData = new FormData();
      formData.append('jsonFile', file);
      
      var apiRequest = apiUtility.apiInputRequest;
      apiRequest.endPoint = API_UPLOAD;
      apiRequest.data = formData;
      apiUtility.sendAPIDataPOST(apiRequest).then(response => {
          setTimeout(
            () => {setUploadBar(false); 
              setDataList(response.data.successData); 
              setErrorList(response.data.backupData);
              setMsgBoxData("Process completed with "+response.data.entries+" entries in "+response.data.time+"ms." );
              setApiSuccess(true);
            }, 
            2000
          );
        })
        .catch((error) => { setUploadBar(false); setApiError(true);});
    
  };

  const fetchHistory = () => {
    setApiError(false);
    var apiRequest = apiUtility.apiInputRequest;
    apiRequest.endPoint = API_HISTORY;
      apiUtility.fetchAPIDataGET(apiRequest).then(response => {
          setHistoryData(response.data);
          setHistoryTable(true);
        })
        .catch((error) => {setApiError(true);console.error(error)});
  };

  
  const fetchReport = () => {
    setApiError(false);
    var apiRequest = apiUtility.apiInputRequest;
    apiRequest.endPoint = API_REPORT;
      apiUtility.fetchAPIDataGET(apiRequest).then(response => {
          setReportData(response.data);
          setReportTable(true);
        })
        .catch((error) => {setApiError(true);console.error(error)});
  };

  const syncRecords = () => {
    setApiError(false);
    setApiSuccess(false);
    setMsgBoxData("");
    setUploadBar(true); 
    var apiRequest = apiUtility.apiInputRequest;
    apiRequest.endPoint = API_SYNC_FAILED;
      apiUtility.fetchAPIDataGET(apiRequest).then(response => {
        setTimeout(
          () => {
            setUploadBar(false); 
            var text = (response.data.sync)? "Data synced" : "Process interrupted, only synced";
            text = text + " with "+response.data.success+" records out of total "+response.data.totalRecords;
            setMsgBoxData(text);
            setApiSuccess(true);
          }, 
          2000
        );
        })
        .catch((error) => {
          setApiError(true);
          setUploadBar(false);
          console.error(error)
        });
  };

  return (
    <Container>
      <Row>
        <Col>
          <Card body style={{"border-radius":"1.5px"}}>
              <Card.Title><p className='text-center'>User Badge JSON Processor</p></Card.Title>
              <Row className="my-4">
                <Col xs={12} md={6}>
                  <Form.Group controlId="formFileLg" className="mb-3">
                    <Form.Label>Select JSON file</Form.Label>
                    <Form.Control type="file" size="md" onChange={handleFileChange} />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                    <Row style={{"marginTop":"1.7pc"}}>
                    <Col xs={12} md={3}>
                        <Button variant="primary" onClick={handleUpload}>
                          Upload
                        </Button>
                    </Col>
                    <Col xs={12} md={3}>
                        <Button  variant="primary" onClick={fetchReport}>
                          User Report 
                        </Button>
                    </Col>
                    <Col xs={12} md={3}>
                        <Button variant="primary" onClick={fetchHistory}>
                          Error History
                        </Button>
                    </Col>
                    <Col xs={12} md={3}>
                        <Button variant="primary" onClick={syncRecords}>
                          Sync Record 
                        </Button>
                    </Col>
                    </Row>
                </Col>
              </Row>
              {(uploadBar)?
                <Row> 
                  <p className='text-center text-muted'>Process started, please wait...</p>
                  <Placeholder as="p" animation="glow">
                    <Placeholder xs={12} />
                  </Placeholder>
                </Row>
              :""}
              {(apiError)?
                <Alert severity="error" className='text-center'>ERROR 500 - Internal server error</Alert>
                :""}
              {(apiSuccess)?
                <Alert severity="success">{msgBoxData}</Alert>
                :""}
              
              
          </Card>
        </Col>
      </Row>
      <Row className="my-4">
        <Col xs={12} md={6}>
          <Card body style={{"border-radius":"1.5px"}}>
          <Card.Title><p className='text-center'>{(uploadBar)?"JSON Data":"Uploaded Data"}</p></Card.Title>
          <ListGroup as="ol" style={{"maxHeight":"30pc","overflow-y": "scroll"}}>
            {dataList.map((item) => (
              <ListGroup.Item as="li" action key={item.cnum+item.code} className="d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                  {item.first}, {item.last} - {item.code}
                </div>
                {(!item.upload)?
                   <Badge bg="danger" pill>
                      <SlClose/>
                   </Badge>
                 : <Badge bg="success" pill>
                    <SlCheck/>
                 </Badge>}
                
              </ListGroup.Item>
            ))}
            </ListGroup>
            </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card body style={{"border-radius":"1.5px"}}>
          <Card.Title><p className='text-center'>Error List</p></Card.Title>
          {(uploadBar)?
              <div>
                  <Row> 
                    <Placeholder as="p" animation="glow">
                      <Placeholder xs={10} />
                    </Placeholder>
                  </Row>
                  <Row> 
                    <Placeholder as="p" animation="glow">
                      <Placeholder xs={7} />
                    </Placeholder>
                  </Row>
              </div>
              :""}
          <ListGroup as="ol" style={{"maxHeight":"30pc","overflow-y": "scroll"}}>
                    {Object.keys(errorList).map((key, index) => {
                      var arr = errorList[key];
                      return (

                        <ListGroup.Item as="li" action key={index} className="d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                {key} {arr.map((key,item) => ( <p style={{"font-size":"12px","margin":"0px"}} className='text-muted'>{key}</p>))}
                            </div>
                            
                            <Badge bg="danger" pill>
                                  <SlClose/>
                              </Badge>
                        </ListGroup.Item>
                      );
                    })}
              {/* {errorList.map((key,item) => (
                  <ListGroup.Item as="li" action key={key} className="d-flex justify-content-between align-items-start">
                    <div className="ms-2 me-auto">
                      {item.first}, {item.last} <p className='text-muted'>{item.error}</p>
                    </div>
                    
                    <Badge bg="danger" pill>
                          <SlClose/>
                      </Badge>
                  </ListGroup.Item>
                ))} */}
            </ListGroup>
          </Card>
          
        </Col>
      </Row>
      <JsonDataHistory rows={historyData} open={historyTable} onClose={() => { setHistoryTable(false)}}/> 
      <JsonReportHandler show={reportTable} report={reportData} onHide={() => { setReportTable(false)}}/> 
    </Container>
  );
}

export default JsonDataUploader;
