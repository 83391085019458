import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import Slide from '@mui/material/Slide';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

function UserConsentDialog(props) {
    
    const onButtonClick = (response) => {
        props.handleUpdateCallback(response);
    }

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    return (
        
        <Modal centered  {...props} onHide={props.onHide} style={{ "borderRadius": "0px", "width": "50pc", "top": "0%", "left": "30%" }} closeButton>
            <Modal.Body className='text-center'>
                <DialogTitle>{"Use of personal information"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Typography variant='body1' gutterBottom>As a part Lambton College’s commitment to Equity, Diversity, and Inclusion (EDI), we collect certain data from students at the point of application to the college. This data helps us to better understand the diverse backgrounds of students and to ensure equitable policies, programs, and services.
                        </Typography>
                        
                        <Typography variant='body1' gutterBottom>The data we collect includes demographic information such as ethnicity, religion and/or spiritual self-identity, sexual orientation, disability, and military-connected status. We understand that this information is personal and sensitive, and we take the responsibility of collecting and using it very seriously. All information collected is kept confidential and will be used solely for the purposes of improving our programs and services and furthering our commitment to EDI within our institution.
                        </Typography>
                        <Typography variant='body1' gutterBottom>Personal information provided will be collected, protected, used, disclosed, and retained in compliance with Ontario’s Freedom of Information and Protection of Privacy Act and Lambton College’s Confidentiality and Privacy of Information and Records Policy and Data Assurance Policy.
                            <b> If you have any concerns or questions about the collection and use of this data, please do not hesitate to contact Patrick Bennett, Director, Institutional Intelligence & College Registrar at Patrick.bennett@lambtoncollege.ca</b>
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {onButtonClick(true)}}>Agree</Button>
                    <Button onClick={() => {onButtonClick(false)}}>Disagree</Button>
                </DialogActions>
            </Modal.Body>
           
        </Modal>
    );
}
export default UserConsentDialog;