import './organisation.css';
import React, { useState, useEffect } from "react";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import { BASE_API_URL } from '../../config/Url-config';
import StaffRoleCourseChecklistComponent from './StaffRoleCourseChecklistComponent';
import SuccessPopUp from '../Utility/SuccessPopUp';
import { MdOutlineMailOutline } from "react-icons/md";
import * as apiUtility from "../../config/APIUtils";
import ErrorPopUp from '../Utility/ErrorPopUp';
import { Col, Row } from 'react-bootstrap';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

export default function HomePolicySettings(props) {

    const [showCoverageWarning, setCoverageWarningShow] = useState(false);
    const handleCloseCoverageWarning = () => setCoverageWarningShow(false);
    const handleshowCoverageWarning = () => setCoverageWarningShow(true);
    const [homeDetails, setHomeDetails] = useState({});
    const [policyDetails, setPolicyDetails] = useState({});

    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [dataForSuccessPopup, setDataForSuccessPopup] = useState('');
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [dataForErrorPopup, setDataForErrorPopup] = useState('');
    const [hideRoles, setHideRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 25,
    });
    const [sortModel, setSortModel] = React.useState([{
        field: "",
        sort: "asc",
    }]);
    //const [queryOptions, setQueryOptions] = React.useState({});
    const [totalRows, setTotalRows] = useState(0);
    const [searchText, setSearchText] = useState("");
    //const params = useParams().id;
   
    useEffect(() => {
    //  fetchHomePolicySettings();
    var apiRequest = apiUtility.apiInputRequest;
    apiRequest.endPoint = "orgnization/getHomeDetails/"+props.data.homeID;
    apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
        if (res != 'Something went wrong!' || res != 'No Role Found!'){
           // alert('done');
            setHomeDetails(res.data[0]);
            var policyObj = {
                'home_id':res.data[0].home_id,
                'default_to_pay':res.data[0].default_to_pay,
                'home_max_registration':res.data[0].home_max_registration,
                'home_preperiod':res.data[0].home_preperiod
            };
            setPolicyDetails(policyObj);
        }
            
    }).catch((error) => {
        console.error(error);
    });

    }, []);

    function fetchHomePolicySettings(){
        //setCoverageObj({})
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/getHomeDetails/"+props.data.homeID;
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
            if (res != 'Something went wrong!' || res != 'No Role Found!'){
               // alert('done');
                setHomeDetails(res.data[0]);
                var policyObj = {
                    'home_id':res.data[0].home_id,
                    'default_to_pay':res.data[0].default_to_pay,
                    'home_max_registration':res.data[0].home_max_registration,
                    'home_preperiod':res.data[0].home_preperiod
                };
                setPolicyDetails(policyObj);
            }
                
        }).catch((error) => {
            console.error(error);
        });
    }

    function changePolicySettings(event,detail){
        console.log("Hi")
       
        var policyObj = {...policyDetails}
        if(detail==='det1'){
            console.log('det1')
            policyObj.default_to_pay = event.target.checked;
        }
        else if(detail==='det2'){
            console.log('det2')
            policyObj.home_max_registration = event.target.value;
        }
        else{
            policyObj.home_preperiod = event.target.value;
        }
        setPolicyDetails(policyObj);
        console.log(policyDetails,policyObj)
    }

  
   
    function savePolicySettings(){
        
        console.log(policyDetails)

        if(policyDetails.home_max_registration!="" && policyDetails.home_preperiod!=""){
            if(isNaN(policyDetails.home_max_registration) || isNaN(policyDetails.home_preperiod)){
                alert("Should be a number")
            }
            else{
                var apiRequest = apiUtility.apiInputRequest;
                apiRequest.endPoint = "orgnization/updateHomePolicySettings";
                apiRequest.data = policyDetails;
                apiUtility.updateAPIDataPUT(apiRequest).then(res => {
                    if (res != 'Something went wrong!' || res != 'No Role Found!'){
                        sessionStorage.setItem('defaultPay',JSON.stringify(policyDetails.default_to_pay));
                        alert('Policy Updated!');
                        fetchHomePolicySettings();
                        // handleCloseCoverageWarning();
                    }
                        
                }).catch((error) => {
                    console.error(error);
                });
            }
           
        }
        else{
            alert('All fields are mandatory!')
        }
       
       
    }
    
    
    return (
        <div className='org-container'>

            <Row>
                <Col>
                    <h5>Home Policy Settings : </h5>
                </Col>
            
            </Row>
            <div md={6} className="mb-2">
            <Table striped bordered hover>
            <thead>
                    <tr>
                        <th style={{textAlign:"center"}}>Home Policy</th>
                        <th></th>
                                               
                    </tr>
                </thead>
                <tbody>
                <tr>
                <td>Default to Pay for Staff Training?</td>
                <td><input style={{transform:"scale(1.8)",marginLeft:"2%"}} type='checkbox' 
                 onChange={(e) => {
                    changePolicySettings(e, 'det1');
                }} checked={policyDetails.default_to_pay} ></input></td>
                </tr>
                        <tr >
                <td>Max number of registrations per microcred covered in a training cycle</td>
                
                <td>
                <Form.Group className="mb-3 col-xs-6" controlId="homeEditConFName">
                        {/* <Form.Label>Contact First-Name</Form.Label> */}
                        <Form.Control type="input" defaultValue={homeDetails.home_max_registration}
                            onChange={(e) => {
                                changePolicySettings(e, 'det2');
                            }}
                        />
                    </Form.Group>
                </td>
                </tr>
                <tr >
                <td>Default number of days before expiry of a microcred</td>
                {/* <td><input type='input'></input></td> */}
                <td> <Form.Group className="mb-3 col-xs-6" controlId="homeEditConFName">
                        {/* <Form.Label>Contact First-Name</Form.Label> */}
                        <Form.Control type="input" defaultValue={homeDetails.home_preperiod}
                            onChange={(e) => {
                                changePolicySettings(e, 'det3');
                            }}
                        />
                    </Form.Group></td>
                </tr>
                
                </tbody>
                </Table>
                <Button variant="warning"  style={{float:"right",marginBottom:"2%"}} onClick={savePolicySettings}>
                                            Save Changes
                                        </Button>
               
            </div>
            
            
            {/* <Button style={{ float: "right", marginRight: "1%", marginBottom: "1%" }} variant="warning" onClick={handleNewFields}>Add New Fields</Button> */}
            {/* <Modal style={{ width: "50%", left: "25%", top: "20%" }} show={showCoverageWarning} onHide={handleCloseCoverageWarning}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Warning</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <h5>Are you sure you want to change the Coverage Status for this role?</h5>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleCloseCoverageWarning}>
                                            Cancel
                                        </Button>
                                        <Button variant="primary" onClick={saveCoverageStatus}>
                                            Yes
                                        </Button>
                                    </Modal.Footer>
                                </Modal> */}
            {/* <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>S.N.</th>
                        <th>User ID</th>
                        <th style={{cursor:"pointer"}}>First Name </th>
                        <th style={{cursor:"pointer"}}>Last Name </th>
                        <th>Role</th>
                        <th>Coverage Activation</th>
                        <th>Coverage Status</th>
                       
                    </tr>
                </thead>

                {staffList.map((data, id) => {
                    return <tbody key={id}>
                        <tr >
                            <td>{id + 1}</td>
                            <td>{data.user_id}</td>
                            <td>{data.firstName}</td>
                            <td>{data.lastName}</td>
                           
                            <td>{data.role_name}</td>
                           
                          
                            <td>{data.coverage_activation_date}</td>
                            
                            
                            <td>
                            
                             <ButtonGroup>
                <ToggleButton
                    id={`radio1-${id}`}
                    type="radio"
                    variant={data.coverage_status === 'Inactive' ? 'danger' : 'outline-danger'}
                    name={`radio-${id}`}
                    value="Inactive"
                    size="sm"
                    defaultChecked={data.coverage_status === 'Inactive'}
                    onChange={(e) => change_CoverageStatus(e, data.role_id, data.user_id,data)}
                >
                    Inactive
                </ToggleButton>
                <ToggleButton
                    id={`radio2-${id}`}
                    type="radio"
                    variant={data.coverage_status === 'Active' ? 'success' : 'outline-success'}
                    name={`radio-${id}`}
                    value="Active"
                    size="sm"
                    defaultChecked={data.coverage_status === 'Active'}
                    onChange={(e) => change_CoverageStatus(e, data.role_id, data.user_id,data)}
                >
                    Active
                </ToggleButton>
                <ToggleButton
                    id={`radio3-${id}`}
                    type="radio"
                    variant={data.coverage_status === 'Pending' ? 'warning' : 'outline-warning'}
                    name={`radio-${id}`}
                    value="Pending"
                    size="sm"
                    defaultChecked={data.coverage_status === 'Pending'}
                    onChange={(e) => change_CoverageStatus(e, data.role_id, data.user_id,data)}
                >
                    Pending
                </ToggleButton>
            </ButtonGroup>
                            </td>
                            </tr>
                            </tbody>
                }
            )}



            </Table> */}


        </div>);
}
