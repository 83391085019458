import React, {useState} from 'react';
import { Form, Row, Button, Modal, Container } from 'react-bootstrap';
import * as apiUtility from '../../config/APIUtils';
import { SlPaperPlane } from "react-icons/sl";
import { Typography } from '@mui/material';

function ForgotUsername(props) {

    const [emailID, setEmailID] = useState("");
    const [emailErrorDesc, setEmailErrorDesc] = useState("");
    const [isDisabled, setIsDisabled] = useState(false);
    
    const handleOnChange = e => {
        const{name, value} = e.target
        
        if(name === 'email'){
            setEmailID(value);
            setEmailErrorDesc("");
            if(value.length <= 0){
                setEmailErrorDesc("Email cannot be left empty.");
            }else{
                if(!validateEmail(value)){
                    setEmailErrorDesc("Invalid email");
                }
            }
        }
    };

    const validateEmail = (email) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
                return (true);
        }
            return (false)
      };

    const triggerEmail = async () => {
        setIsDisabled(false);
        if(emailID === "" || emailErrorDesc !== ""){
            setEmailErrorDesc("Please enter your email.");
        }else{
            //const json = JSON.stringify({ login_id: username });
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "login/mail-username";
            apiRequest.data = { "emailId": emailID };
            await apiUtility.sendAsyncAPIDataPOST(apiRequest)
            .then(res => {
                if(res.data.error !== "" && res.data.error === false){
                    setEmailErrorDesc("Username sent : "+res.data.emailId);
                    setIsDisabled(true);
                }else{
                    setEmailErrorDesc(res.data.error);
                }
            }).catch(err=>{
                setEmailErrorDesc("Something went wrong!");
                console.log("Error: ", err);
            });
        }
    }

return (
    
        <Modal {...props} centered backdrop="static" style={{"width":"40%","top":"0%","left":"30%"}} keyboard={false}>
            <Modal.Body>
                <Form>
                    <Container style={{"width":"100%"}}>
                    <Row className="text-left" style={{"color":"#0f6fc5"}} >
                        <h5 className="text-left">Forgot Username!</h5>
                        <hr></hr>
                    </Row>
                    <Container style={{"width":"90%"}}>
                        <Row>
                            <Form.Group as={Row}  className="m-3" controlId="formGridPassword">
                                <Form.Label className="text-left"> Email ID </Form.Label>
                                <Form.Control type="input" name="email" disabled={isDisabled} onChange={handleOnChange} value={emailID} required placeholder="Enter your registered email" />
                            </Form.Group>
                        </Row>
                        <Row className="text-center">
                            <Typography variant={"caption"} sx={{color: 'text.secondary'}} class="pe-auto">{emailErrorDesc}</Typography>
                        </Row>
                   <br></br>
                        <Row className="text-center">
                            
                            {/* <Button onClick={()=>triggerEmail()}>Generate password</Button> */}
                            {/* <a enable-button-pointers={true} style={{"color":"#0f6fc5","cursor":"pointer"}} class="pe-auto" onClick={()=>triggerEmail()}><SlPaperPlane/>&nbsp;Email me a temporary password</a> */}
                            <Typography style={{"color":"#0f6fc5","cursor":"pointer"}} disabled={isDisabled} class="pe-auto" onClick={()=>triggerEmail()}><SlPaperPlane/>&nbsp;Click to send username through email</Typography>
                            
                        </Row>
                        
                        
                    </Container>
                   
                    
                    </Container>
                </Form>
                
            </Modal.Body>
            <Modal.Footer>
                <Button className='btn btn-secondary mr-auto' onClick={props.onHide}>
                        Close
                    </Button>
            </Modal.Footer>
        </Modal>

);}
export default ForgotUsername;