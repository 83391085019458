import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Button, Tabs, Tab, FloatingLabel, ListGroup } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import EnterIndividualForm from './EnterIndividualForm';
import MicroCredsDashboard from './MicroCredsDashboard';
import Organisation from '../organisation_component/organisation';
import HomeMainComponent from '../organisation_component/HomeMainComponent';
import UserProfile from '../user/UserProfile';
import Spinner from 'react-bootstrap/Spinner'
import AdminUserEditor from './AdminUserEditor';
import { useParams } from "react-router-dom";
import { BASE_API_URL } from '../../config/Url-config';
import { BASE_URL_FRONTEND } from '../../config/Url-config';
import JsonDataUploader from '../JsonDataProcessor_Component/JsonProcessor';
import './../organisation_component/organisation.css';
import MessageBox from '../Utility/MessageBox';
import SessionTimeoutContainer from '../Utility/SessionTimeoutContainer';
import interceptor from './../interceptor';
import * as apiUtility from "../../config/APIUtils";
import SystemAdminPanel from './SystemAdminPanel';
import TrainingProvider from './TrainingProvider';


function Admin_Home() {

    const params = useParams().id;
    const [orgIdForAdmin, setOrgIdForAdmin] = useState();
    const [orgIdForHomeAdmin, setOrgIdForHomeAdmin] = useState();
    const [homeIdForAdmin, setomeIdForAdmin] = useState();
    const [homeDetails, setHomeDetails] = useState([]);
    const [orgDet4DrpDwn, setOrgDet4DrpDwn] = useState([]);
    const [OrgDetails, setOrgDetails] = useState({});
    const [orgCount, setOrgCount] = useState('1');
    const [showSpinner, setshowSpinner] = useState(false);
    const toggleshowSpinner = () => setshowSpinner(!showSpinner);
    const [showOrg, setOrgShow] = useState(false);
    const handleCloseOrg = () => setOrgShow(false);
    const handleShowOrg = () => setOrgShow(true);
    const [homes_list, setHomesList] = useState([]);

    const [showManageOrg, setManageOrgShow] = useState(false);
    const handleCloseManageOrg = () => setManageOrgShow(false);
    const handleShowManageOrg = () => setManageOrgShow(true);
    const [errors, setErrors] = useState(false);
    const [showOrgDetails, setShowOrgDetails] = useState(false);
    const [key, setKey] = useState("profile");
    const [showManageHome, setManageHomeShow] = useState(false);
    const handleCloseManageHome = () => setManageHomeShow(false);
    const handleShowManageHome = () => setManageHomeShow(true);


    async function getOrgListDetails() {
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/getOrganizationList";
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
            setshowSpinner(false)
            setOrgDet4DrpDwn(res.data)
        }).catch((error) => console.error(error));
    }

    useEffect(async () => {
        setErrors(false)
        if (sessionStorage.getItem("userType") != 'admin') {
            alert("Sorry.Access Not Permitted")
            return window.location.href = BASE_URL_FRONTEND;
        }
        else {
            setshowSpinner(true)
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "orgnization/getHomesList/" + orgIdForHomeAdmin;
            apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
                //console.log(res)
                setshowSpinner(false)
                setHomeDetails(res.data)
            }).catch((error) => console.error(error));

            getOrgListDetails();
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "orgnization/getOrgCount";
            apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
                setOrgCount(String(res.data + 1))
                setshowSpinner(false)
            }).catch((error) => {
                console.error(error);
                setshowSpinner(false);
            });
        }
        const getActiveTab = JSON.parse(localStorage.getItem("activeTab"));
        if (getActiveTab) {
            setKey(getActiveTab);
        }
    }, [orgIdForHomeAdmin])

    useEffect(() => {
        localStorage.setItem("activeTab", JSON.stringify(key));
    }, [key]);

    var createOrganizationIdList = () => {
        let items = [];
        for (var i in orgDet4DrpDwn) {
            if (orgDet4DrpDwn[i].orgActiveStatus) {
                items.push(<option value={orgDet4DrpDwn[i].org_id}> {orgDet4DrpDwn[i].org_name} </option>);
            }

        }
        return items;
    }
    var createHomeIDSelectItems = () => {
        if (orgIdForHomeAdmin == '') {
            return [];
        }
        let items = [];
        for (var i in homeDetails) {
            if (homeDetails[i].homeActiveStatus) {
                items.push(<option value={homeDetails[i].home_id}> {homeDetails[i].name} </option>);
            }
        }
        return items;
    }
    function addOrgText(event, id) {
        let newOrgDetailObj = {};
        newOrgDetailObj = { ...OrgDetails };
        if (id === 'org1')
            newOrgDetailObj.org_name = event.target.value;
        else if (id === 'org2')
            newOrgDetailObj.contact_firstName = event.target.value;
        else if (id === 'org3')
            newOrgDetailObj.contact_lastName = event.target.value;
        else if (id === 'org4')
            newOrgDetailObj.phone_no = event.target.value;
        else if (id === 'org5')
            newOrgDetailObj.email_id = event.target.value;
        newOrgDetailObj.org_id = orgCount;
        newOrgDetailObj.train_standards = [];
        newOrgDetailObj.orgActiveStatus = true
        setOrgDetails(OrgDetails => ({
            ...OrgDetails, ...newOrgDetailObj
        }));
    }

    function changeHomeId(event) {
        setOrgIdForHomeAdmin(event.target.value)

    }

    async function selectNewHomeId(e) {
        setomeIdForAdmin(e.target.value);
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/getHomesList/" + orgIdForHomeAdmin;
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
            setHomesList(res.data)
        }).catch((error) => console.error(error));
    }

    async function saveNewOrgText() {
        if (OrgDetails.org_name && OrgDetails.contact_firstName && OrgDetails.contact_lastName && OrgDetails.phone_no && OrgDetails.email_id) {
            setErrors(false)
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "orgnization/addNewOrg";
            apiRequest.data = OrgDetails;
            apiUtility.sendAPIDataPOST(apiRequest).then(res => {
                handleCloseOrg()
                alert("New Organization added!")
            }).catch((error) => console.error(error));
        }
        else {
            setErrors(true)

        }

    }
    function changeOrgId(e) {
        setShowOrgDetails(false);
        if (e.target.value != '') {
            setOrgIdForAdmin(e.target.value)
            sessionStorage.setItem("orgId", e.target.value)
            setShowOrgDetails(true);
        }
        else {

        }
    }

    async function showOrgRemoveOption(e, item) {
        var tempOrgObj = {}

        console.log(e.target.checked, item)
        tempOrgObj.org_id = item.org_id;
        tempOrgObj.orgActiveStatus = e.target.checked
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/changeOrgActiveStatus";
        apiRequest.data = tempOrgObj;
        apiUtility.updateAPIDataPUT(apiRequest).then(res => {
            getOrgListDetails()
            alert("Organization Status Updated!");
            handleCloseManageOrg();
        }).catch((error) => console.error(error));
    }
    function showHomeRemoveOption(e, item) {

        var tempHomeObj = {}

        console.log(e.target.checked, item)
        tempHomeObj.home_id = item.home_id;
        tempHomeObj.homeActiveStatus = e.target.checked
        const changeHomeActiveStatusUrl = BASE_API_URL + "orgnization/changeHomeActiveStatus"
        interceptor.put(changeHomeActiveStatusUrl, tempHomeObj)
            .then(res => {
                handleCloseManageHome()
                alert("Home Status Changed!")
            })
            .catch(err => {
            })
    }


    return (
        <div style={{ marginTop: "8vh" }}>
            {showSpinner ? <div style={{ paddingLeft: "50%", paddingTop: "10%", position: "absolute" }}>
                <Spinner show={showSpinner} animation="border" size="lg" variant='primary' />

            </div> : <div></div>}
            <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3" fill>
                <Tab eventKey="profile" title="Profile">
                    <UserProfile userId={sessionStorage.getItem("userId")} />
                </Tab>
                <Tab eventKey="microCardDash" title="Micro-cred Dashboard" >
                    <MicroCredsDashboard />
                </Tab>
                <Tab eventKey="trainingProviders" title="Training Providers" >
                    <TrainingProvider />
                </Tab>
                <Tab eventKey="verifyCredentials" title="Verify Credentials" >
                    {key === "verifyCredentials" &&
                        <AdminUserEditor />
                    }
                </Tab>
                <Tab eventKey="organizationView" title="Organizations" >
                    <Row className="shadow p-3 mb-5 bg-white rounded">
                        <Col xs={0} md={2}>
                        </Col>
                        <Col xs={12} md={4}>
                            <FloatingLabel
                                controlId="floatingOrgTab"
                                label="Select Organization"
                            >
                                <Form.Select aria-label="Floating label select example" onChange={(e) => { changeOrgId(e) }}>
                                    <option value=""> -- Please select organization --  </option>
                                    {createOrganizationIdList()}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                        <Col xs={12} md={4}>
                            <Button style={{ float: "right", marginRight: "1%", marginBottom: "1%" }} variant="warning" onClick={handleShowOrg} >Add New Organization</Button>
                            <Modal show={showOrg} onHide={handleCloseOrg} id="addHomeInOrg">
                                <Modal.Header closeButton>
                                    <Modal.Title>Add New Organization</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Form.Group className="mb-2 col-xs-6" controlId="formBasicEmail">
                                            <Form.Label>Enter the Organization Name</Form.Label>
                                            <Form.Control type="input"
                                                onChange={(e) => {
                                                    addOrgText(e, 'org1');
                                                }}
                                            />

                                            <Form.Label>Enter the Contact First Name</Form.Label>
                                            <Form.Control type="input"
                                                onChange={(e) => {
                                                    addOrgText(e, 'org2');
                                                }}
                                            />
                                            <Form.Label>Enter the Contact Last Name</Form.Label>
                                            <Form.Control type="input"
                                                onChange={(e) => {
                                                    addOrgText(e, 'org3');
                                                }}
                                            />
                                            <Form.Label>Enter the Phone Number</Form.Label>

                                            <Form.Control type="input"
                                                onChange={(e) => {
                                                    addOrgText(e, 'org4');
                                                }}
                                            />
                                            <Form.Label>Enter the Email ID</Form.Label>

                                            <Form.Control type="input"
                                                onChange={(e) => {
                                                    addOrgText(e, 'org5');
                                                }}
                                            />
                                        </Form.Group>

                                    </Form>
                                    <div>
                                        {errors ? (
                                            <p className="text-danger">*All fields are mandatory</p>

                                        ) : (
                                            <p className="text-danger"></p>

                                        )}
                                    </div>
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseOrg}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={saveNewOrgText} >
                                        Save Changes
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Button style={{ float: "right", marginRight: "1%", marginBottom: "1%" }} variant="warning" onClick={handleShowManageOrg} >Manage Organizations</Button>
                            <Modal show={showManageOrg} onHide={handleCloseManageOrg} className="custom-map-modal">
                                <Modal.Header closeButton>
                                    <Modal.Title>Enable/Disable Organization</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div style={{ "marginBottom": "8pc" }}>

                                        <Row className="justify-content-md-center">

                                            <Form style={{ width: "50%" }}>
                                                <br></br>
                                                <br></br>
                                                <h3 className="text-center">All Organization List:</h3>
                                                {orgDet4DrpDwn.length > 0 ?
                                                    <ListGroup as="ol" numbered>
                                                        {['checkbox'].map((type) => (
                                                            <div key={`reverse-${type}`} className="mb-3">
                                                                {orgDet4DrpDwn.map((item, _id) => {
                                                                    return <ListGroup.Item as="li"> <Form.Check label={item.org_name} name="group1" type={type} defaultChecked={item.orgActiveStatus} id={`reverse-${type}-1`} onChange={(e) => {
                                                                        showOrgRemoveOption(e, item)
                                                                    }} /></ListGroup.Item>
                                                                })}

                                                            </div>
                                                        ))}
                                                    </ListGroup> : <div>No Organization Found!</div>

                                                }

                                            </Form>

                                        </Row>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseManageOrg}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Col>

                    </Row>
                    <h1>{showOrgDetails}</h1>
                    {(sessionStorage.getItem("orgId") && sessionStorage.getItem("orgId") != "undefined") ?
                        (showOrgDetails) ? <Organisation orgIdForAdmin={orgIdForAdmin} /> :
                            <MessageBox data="Select an organization to view" />
                        : <div></div>
                    }
                </Tab>
                <Tab eventKey="homeView" title="Homes" >
                    {/* <EnterHomeForm /> */}
                    <Row className="w-100 shadow p-3 mb-5 bg-white rounded">
                        <Col xs={0} md={1}></Col>
                        <Col xs={12} md={4}>
                            <FloatingLabel controlId="floatingOrg" label="Organisation">
                                <Form.Select aria-label="Floating label select example" onChange={(e) => {
                                    console.log(e)
                                    setOrgIdForHomeAdmin(e.target.value);
                                    setomeIdForAdmin("");
                                    document.getElementById("homeDropDown").value = "";
                                    changeHomeId(e);
                                }}>
                                    <option value=''>Select an Organization</option>
                                    {createOrganizationIdList()}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                        <Col xs={12} md={4}>
                            <FloatingLabel controlId="floatingHome" label="Home">
                                <Form.Select aria-label="Floating label select example" id="homeDropDown" onChange={(e) => { selectNewHomeId(e) }}>
                                    <option value=""> -- Please select a Home -- </option>
                                    {createHomeIDSelectItems()}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                        <Col xs={12} md={3}>{(orgIdForHomeAdmin && homeIdForAdmin) ? <div><Button style={{ float: "right", marginRight: "1%", marginBottom: "1%" }} variant="warning" onClick={handleShowManageHome} >Manage Homes</Button></div> : <div></div>}</Col>

                        <Col xs={12} md={5}>
                            <Modal show={showManageHome} onHide={handleCloseManageHome} className="custom-map-modal">
                                <Modal.Header closeButton>
                                    <Modal.Title>Enable/Disable Home</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div style={{ "marginBottom": "8pc" }}>

                                        <Row className="justify-content-md-center">

                                            <Form style={{ width: "50%" }}>
                                                <br></br>
                                                <br></br>
                                                <h3 className="text-center">All Homes List:</h3>
                                                {homes_list.length > 0 ?
                                                    <ListGroup as="ol" numbered>
                                                        {['checkbox'].map((type) => (
                                                            <div key={`reverse-${type}`} className="mb-3">
                                                                {homes_list.map((item, _id) => {
                                                                    return <ListGroup.Item as="li"> <Form.Check label={item.name} name="group1" type={type} defaultChecked={item.homeActiveStatus} id={`reverse-${type}-1`} onChange={(e) => {
                                                                        showHomeRemoveOption(e, item)
                                                                    }} /></ListGroup.Item>
                                                                })}

                                                            </div>
                                                        ))}
                                                    </ListGroup> : <div>No Home Found!</div>

                                                }

                                            </Form>

                                        </Row>

                                        {/* </Form> */}


                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseManageHome}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal></Col>
                        <Col xs={0} md={1}></Col>
                    </Row>
                    {!(homeIdForAdmin) ? <MessageBox data="Select a home to view" /> :
                        <HomeMainComponent homeIdFprAdmin={homeIdForAdmin} orgId={orgIdForHomeAdmin} />

                    }
                </Tab>
                <Tab eventKey="individualView" title="Users" >
                    <EnterIndividualForm />
                </Tab>
                <Tab eventKey="dataProcessor" title="Data Processor" >
                    <JsonDataUploader />
                </Tab>
                <Tab eventKey="addPermissions" title="Manage Admin" >
                    <SystemAdminPanel />
                </Tab>
            </Tabs>
            <SessionTimeoutContainer />
        </div>)
}

export default Admin_Home;