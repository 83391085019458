import React from 'react';
import { Modal } from 'react-bootstrap';
import { SlCheck } from "react-icons/sl";

function SuccessPopUp(props) {

return (
             <Modal centered  {...props} onHide={props.onHide} style={{"borderRadius":"0px","width":"20pc","top":"0%","left":"40%"}} closeButton>
                <Modal.Body className='text-center'>
                    <h1><SlCheck style={{"fontSize":"5pc","color":"#0f6fc5"}}/></h1> <br></br>{props.data}
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button className='btn btn-primary mr-auto' onClick={ () => setShowUserMsg(false)}>
                        OK
                    </Button>
                </Modal.Footer> */}
            </Modal>
);}
export default SuccessPopUp;