import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, Modal, Container } from 'react-bootstrap'
import { Switch } from '@material-ui/core'
import * as apiUtility from "../../config/APIUtils";
import ErrorPopUp from "../Utility/ErrorPopUp";

function EditCourseVersion(props) {
    var trainingProvidersMap = JSON.parse(sessionStorage.getItem("training_providers"));
    const [trainingProvider, setTrainingProvider] = useState(0);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [dataForErrorPopup, setDataForErrorPopup] = useState('');
    const [trainingUrl, setTrainingUrl] = useState("");
    const [equivalentFieldVisible, setEquivalentFieldVisibile] = useState(false);

    useEffect(() => {
        if (props.crsVerID != "") {
            fillAllFields();
        }
    }, [props.crsVerID])

    var handleDisableFieldsFunction = (e) => {
        if (e.target.checked) {
            document.getElementById("version_number_edit").disabled = false;
            document.getElementById("is_valid_edit").disabled = false;
            document.getElementById("direct_registration_edit").disabled = false;
            document.getElementById("crsVerDescription_edit").disabled = false;
            document.getElementById("training_duration_crs_ver_edit").disabled = false;
            document.getElementById("training_provider_id_edit").disabled = false;
            document.getElementById("crsVerTitle_edit").disabled = false;
            document.getElementById("isMajorChange").disabled = false;
            document.getElementById("version_code_edit").disabled = false;
            document.getElementById("training-URL").disabled = false;
        } else {
            document.getElementById("version_number_edit").disabled = true;
            document.getElementById("is_valid_edit").disabled = true;
            document.getElementById("direct_registration_edit").disabled = false;
            document.getElementById("crsVerDescription_edit").disabled = true;
            document.getElementById("training_duration_crs_ver_edit").disabled = true;
            document.getElementById("training_provider_id_edit").disabled = true;
            document.getElementById("crsVerTitle_edit").disabled = true;
            document.getElementById("isMajorChange").disabled = true;
            document.getElementById("version_code_edit").disabled = true;
            document.getElementById("training-URL").disabled = true;
        }
    }


    var editNewCourseVersion = async (e) => {
        e.preventDefault();
        var version_number = document.getElementById("version_number_edit").value;
        var version_code = document.getElementById("version_code_edit").value;
        var is_valid = document.getElementById("is_valid_edit").checked;
        var direct_registration = document.getElementById("direct_registration_edit").checked;
        var crsVerDescription = document.getElementById("crsVerDescription_edit").value;
        var training_duration_crs_ver = document.getElementById("training_duration_crs_ver_edit").value;
        var equivalent_course_id = version_code;//document.getElementById("equivalent_course_id_edit").value == "" ? null : document.getElementById("equivalent_course_id_edit").value;
        var crsVerTitle = document.getElementById("crsVerTitle_edit").value;
        //var issuingOrgName = document.getElementById("issuing_org_edit").value;
        var trainingDurHrOrMin = document.getElementById("addCrsVerTrainDurInEdit").checked == true ? "m" : "h";

        var crsVerId = props.crsVerID;
        var courseID = props.parentCrs;

        if (courseID == "" || crsVerId == "" || version_code == "" || version_number == "" || crsVerDescription == "" || training_duration_crs_ver == "" || crsVerTitle == "") {
            setShowErrorPopup(true);
            setDataForErrorPopup("Course version details cannot be empty or left blank");
            return;
        } else if(trainingProvider == "0"){
            setShowErrorPopup(true);
            setDataForErrorPopup("Please select a Trainiing Provider!");
            return;
        } else if (document.getElementById("editDetailsSwitch").checked == false) {
            setShowErrorPopup(true);
            setDataForErrorPopup("To update please select 'Edit Details' Swtich");
            return;
        } else if (isNaN(training_duration_crs_ver)) {
            setShowErrorPopup(true);
            setDataForErrorPopup("Training duration has to be a number");
            return;
        } else {
            var isMajorChange = document.getElementById("isMajorChange");
            if (isMajorChange.checked) {
                var apiRequest = apiUtility.apiInputRequest;
                apiRequest.endPoint = "course/create-version";
                apiRequest.data = {
                    "course_id": [courseID],
                    "course_version_id": "",
                    "version_number": version_number,
                    "is_valid": is_valid,
                    "last_modified_date": new Date(),//today
                    "training_provider_id": trainingProvider,
                    "direct_registration": direct_registration,
                    "description": crsVerDescription,
                    "training_duration": training_duration_crs_ver + "-" + trainingDurHrOrMin,
                    "equivalent_course_id": equivalent_course_id,
                    "title": crsVerTitle,
                    "training_url":trainingUrl,
                    "tp_course_code":version_code
                };
                await apiUtility.sendAPIDataPOST(apiRequest)
                    .then(res => {
                        var rspMsg = [1, res.data.msg.toString()];
                        props.handleCallBack(rspMsg)

                    })
                    .catch(err => {
                        var rspMsg = [-1, "Error while updating data!"];
                        props.handleCallBack(rspMsg)
                    })
            } else {
                var apiRequest = apiUtility.apiInputRequest;
                apiRequest.endPoint = "course/update-version";
                apiRequest.data = {
                    "course_id": courseID,
                    "course_version_id": crsVerId,
                    "version_number": version_number,
                    "is_valid": is_valid,
                    "last_modified_date": new Date(),
                    "description": crsVerDescription,
                    "training_duration": training_duration_crs_ver + "-" + trainingDurHrOrMin,
                    "equivalent_course_id": equivalent_course_id,
                    "title": crsVerTitle,
                    "training_provider_id": trainingProvider,
                    "direct_registration": direct_registration,
                    "training_url":trainingUrl,
                    "tp_course_code":version_code

                };
                await apiUtility.sendAPIDataPOST(apiRequest).then(res => {
                    var rspMsg = [1, res.data.msg.toString()];
                    props.handleCallBack(rspMsg)
                })
                    .catch(err => {
                        var rspMsg = [-1, "Error while updating data!"];
                        props.handleCallBack(rspMsg)
                    })
            }
        }
    }

    var fillAllFields = async () => {
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "course/get-one-course-version/" + props.crsVerID + "/" + props.parentCrs;
        await apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
            var crsDetails = res.data[0];
            document.getElementById("crs_ver_edit_prt_crs").value = props.parentCrs;
            document.getElementById("crs_ver_edit_crs_ver_id").value = props.crsVerID;
            if (crsDetails.training_duration.split('-')[1] == "m") {
                document.getElementById("addCrsVerTrainDurInEdit").checked = true;
                document.getElementById("trDurHrMinDiv").children[2].children[0].classList.add("PrivateSwitchBase-checked-18");
                document.getElementById("trDurHrMinDiv").children[2].children[0].classList.add("Mui-checked");
            }
            document.getElementById("version_number_edit").value = crsDetails.version_number;
            document.getElementById("is_valid_edit").checked = crsDetails.is_valid;
            document.getElementById("direct_registration_edit").checked = crsDetails.direct_registration;
            document.getElementById("crsVerDescription_edit").value = crsDetails.description;
            document.getElementById("training_duration_crs_ver_edit").value = crsDetails.training_duration.split('-')[0];
            //document.getElementById("issuing_org_edit").value = crsDetails.issuing_org_name;
            setTrainingProvider(crsDetails.training_provider_id);
            document.getElementById("crsVerTitle_edit").value = crsDetails.title == undefined ? "" : crsDetails.title;
            //document.getElementById("equivalent_course_id_edit").value = (crsDetails.equivalent_course_id == null || crsDetails.equivalent_course_id == undefined) ? "" : crsDetails.equivalent_course_id;
            document.getElementById("version_code_edit").value = (crsDetails.tp_course_code == null || crsDetails.tp_course_code == undefined) ? "" : crsDetails.tp_course_code;
            setTrainingUrl((crsDetails.training_url == null || crsDetails.training_url == undefined) ? "" : crsDetails.training_url)
        })
            .catch(err => {
                console.log(err);
            })
    }

    const handleTrainingUrl = (event) => {
        const { value } = event.target;
        setTrainingUrl(value);
    };


    return (
        <Container>
            <Row>
                <Modal
                    {...props}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Course version</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Col>
                                    <Form.Group controlId="crs_ver_edit_prt_crs">
                                        <Form.Label >Parent Course</Form.Label>
                                        <Form.Control disabled />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="crs_ver_edit_crs_ver_id">
                                        <Form.Label>Course Version ID</Form.Label>
                                        <Form.Control disabled />
                                    </Form.Group>
                                </Col>
                                <Form.Group as={Col} controlId="version_code_edit">
                                    <Form.Label>Course Code</Form.Label>
                                    <Form.Control disabled placeholder="Enter Training provider course Code" />
                                </Form.Group>

                            </Row>
                            <br></br>
                            <Row>
                                <Form.Group as={Col} controlId="is_valid_edit">
                                    <Form.Label defaultChecked>is valid</Form.Label>
                                    <Form.Check disabled placeholder="Enter Course Version Number" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="direct_registration_edit">
                                    <Form.Label defaultChecked>Direct Registraion</Form.Label>
                                    <Form.Check disabled placeholder="Enter Course Version Number" />
                                </Form.Group>

                                <Form.Group as={Col} controlId="training_provider_id_edit">
                                    <Form.Label>Training Provider</Form.Label>
                                    {/* <Form.Control disabled placeholder="Enter Issuing Organization" /> */}
                                    <Form.Select disabled name="trainingProvider" value={trainingProvider} onChange={(e) => setTrainingProvider(e.target.value)} >
                                        <option value="0"> -- Please select Training Provider --  </option>
                                        {trainingProvidersMap?.map((data, id) => (
                                            <option value={data.tp_id} key={data.tp_id}>{data.provider_name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group as={Col} controlId="version_number_edit">
                                    <Form.Label>Version Number</Form.Label>
                                    <Form.Control disabled placeholder="Enter Course Version Number" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="crsVerTitle_edit">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control disabled placeholder="Enter Title" />
                                </Form.Group>
                            </Row>
                            <br></br>
                            <Row>
                                <Form.Group style={{ height: "20%" }} as={Col} controlId="crsVerDescription_edit">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control disabled placeholder="Enter Description" />
                                </Form.Group>

                            </Row>
                            <br></br>
                            <Row>
                                <Form.Group as={Col} controlId="training_duration_crs_ver_edit">
                                    <Form.Label>Traning Duration</Form.Label>
                                    <Form.Control disabled placeholder="Enter Total Training in Days" />
                                </Form.Group>
                                <Form.Group as={Col} id="trDurHrMinDiv" >
                                    <Form.Label as={Col}>Traning Duration is in: </Form.Label>

                                    <Form.Label >Hours</Form.Label>
                                    <Switch id="addCrsVerTrainDurInEdit" color="primary" />
                                    <Form.Label >Minutes</Form.Label>
                                </Form.Group>
                                {(equivalentFieldVisible) && <Form.Group as={Col} controlId="equivalent_course_id_edit">
                                    <Form.Label>Equivalent Course</Form.Label>
                                    <Form.Control disabled placeholder="Leave Empty if not an equivalent course" />
                                </Form.Group>}
                                

                            </Row>
                            <br></br>
                            <Row>
                            <Form.Group as={Col} controlId="training-URL">
                                        <Form.Label>Training URL</Form.Label>
                                        <Form.Control type="text" value={trainingUrl} disabled onChange={handleTrainingUrl} placeholder="Enter the training URL for this course.." />
                                </Form.Group>
                            </Row>
                            <br></br>
                            <Row style={{ "marginLeft": "auto", "marginRight": "auto", "marginTop": "2%", "width": "50%" }}>
                                <Form.Group as={Col} controlId="isMajorChange">
                                    <Form.Label defaultChecked>is major change?</Form.Label>
                                    <Form.Check disabled placeholder="Is a major change?" />
                                </Form.Group>

                                <Form.Group as={Col} >
                                    <Form.Label defaultChecked>Edit Details</Form.Label>
                                    <Switch id="editDetailsSwitch" onChange={handleDisableFieldsFunction} color="primary" />
                                </Form.Group>

                            </Row>
                        </Form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit" onClick={editNewCourseVersion} >
                            Update
                        </Button>
                        <Button variant="primary" onClick={props.onHide}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Row>
            <ErrorPopUp show={showErrorPopup} data={dataForErrorPopup} onHide={() => { setShowErrorPopup(false) }} />
        </Container>
    )
}

export default EditCourseVersion