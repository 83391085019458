import './organisation.css';
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner'
import { BASE_API_URL } from '../../config/Url-config';
import { BASE_URL_FRONTEND } from '../../config/Url-config';
import { Tabs, Tab, Col, Row } from 'react-bootstrap'
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import * as apiUtility from "../../config/APIUtils";
import BootstrapTable from 'react-bootstrap-table-next';
import { Alert, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { RiFileExcel2Line, RiFilePdfLine } from 'react-icons/ri';
import jsPDF from "jspdf";
import "jspdf-autotable";
export default function StaffRoleCourseChecklistComponent(props) {


    const [showSpinner, setshowSpinner] = useState(false);
    var { userId, homeId } = useParams();
    const [userName, setUserName] = useState('');
    const [showArchiveWarning, setArchiveWarningShow] = useState(false);
    const handleCloseArchiveWarning = () => setArchiveWarningShow(false);
    const handleshowArchiveWarning = () => setArchiveWarningShow(true);
    const [staffRoleDetails, setStaffRoleDetails] = useState([]);
    const [showTemplateDetails, setShowTemplateDetails] = useState(false)

    const [staffFullName, setStaffFullName] = useState('');
    const [archivedObj, setArchivedObj] = useState({});

    var fullName = '';
    if (props.userId && props.homeId) {
        userId = props.userId;
        homeId = props.homeId;

    }
    //console.log("staffFullName",props.fullName,staffFullName)
    useEffect(() => {
        if (props.fullName) {
            setStaffFullName(props.fullName)
            fullName = props.fullName
        }
        if (!sessionStorage.getItem("homeId") && sessionStorage.getItem("userType") != 'admin') {
            alert("Sorry.Access Not Permitted")
            return window.location.href = BASE_URL_FRONTEND;

        }
        if (sessionStorage.getItem("allHomeArr") && sessionStorage.getItem("homeId") && sessionStorage.getItem("userType") != 'admin') {
            var flag = false;
            for (var i = 0; i < JSON.parse(sessionStorage.getItem("allHomeArr")).length; i++) {
                if (sessionStorage.getItem("homeId") == JSON.parse(sessionStorage.getItem("allHomeArr"))[i]) {
                    sessionStorage.setItem("homeId", String(homeId))
                    flag = true;
                    break;
                }
            }
            if (!flag) {
                alert("Sorry.Access Not Permitted")
                return window.location.href = BASE_URL_FRONTEND;
            }
        }
        getStaffRoleData();
        // getCourseListByUser();
    }, [props])

    async function getStaffRoleData() {
        setshowSpinner(true)
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/getUserByName/" + userId;
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
            if (res != 'Something went wrong!' && res != 'No User Found!')
                setUserName(res.data)
        }).catch((error) => {
            console.error(error);
            // setshowSpinner(false);
        });

        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/getStaffCourseRoleCheckList/" + userId + "/" + homeId;
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
            // console.log(res.data)
            setStaffRoleDetails(res.data)
            setshowSpinner(false)
            if (staffRoleDetails)
                setshowSpinner(false)
        }).catch((error) => {
            console.error(error);
            setshowSpinner(false);
        });

        if (staffRoleDetails != []) {
            setTimeout(setShowTemplateDetails(true), 1000)


        }
    }

    const exportInExcel = async (roleId, roleName, data, id) => {
        try {
            var rowsCount = 6;
            //const title = [{A: 'Ontario Long Term Care Orientation Co-operative Webportal - Error History Report'}];
            const title = [{ A: 'Ontario Long Term Care Orientation Co-operative Webportal - Status of Training Requirements for the Role of ' + String(roleName) }];

            let reportView = [{ A: 'Name', B: 'User ID', C: 'Role', D: 'Date' }];
            reportView.push({
                A: sessionStorage.getItem('fullName'),
                B: sessionStorage.getItem('userId'),
                C: (sessionStorage.getItem('userType') !== null)?sessionStorage.getItem('userType').toUpperCase():"User",
                D: (new Date().toLocaleString()).split(',')[0]
            });
            reportView.push("");
            reportView.push("");
            let reportHeaders = { A: 'Course ID', B: 'Course Title', C: 'Course Expiry', D: 'Completed' };

            reportView.push(reportHeaders);
            for (var i = 0; i < data.course_details.length; i++) {
                rowsCount += 1;
                reportView.push({
                    A: data.course_details[i].id,
                    B: data.course_details[i].details,
                    C: data.course_details[i].isComp ? (data.course_details[i].crsExp ? data.course_details[i].crsExp.split('T')[0] : 'N/A') : '',
                    D: data.course_details[i].isComp ? 'Y' : 'N',
                });
            }
            const finalData = [...title, ...reportView];
            const worksheet = XLSX.utils.json_to_sheet(finalData, {
                skipHeader: true,
            });
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "JSON_ErrorReport");
            const workbookblob = workbook2blob(workbook);
            var headerIndexes = [];
            finalData.forEach((data, index) =>
                data["A"] === "Course ID" ? headerIndexes.push(index) : null
            );
            const dataInfo = {
                titleCell: "A2",
                titleRange: "A1:L1",
                tbodyRange: `A7:D${finalData.length}`,
                uHeadRange: `A2:D2`,
                uBodyRange: `A3:D3`,
                theadRange:
                    headerIndexes?.length >= 1
                        ? `A${headerIndexes[0] + 1}:H${headerIndexes[0] + 1}`
                        : null,
                theadRange1:
                    headerIndexes?.length >= 2
                        ? `A${headerIndexes[1] + 1}:H${headerIndexes[1] + 1}`
                        : null,
                tFirstColumnRange:
                    headerIndexes?.length >= 1
                        ? `A${headerIndexes[0] + 1}:A${rowsCount + headerIndexes[0] + 1}`
                        : null,
                tLastColumnRange:
                    headerIndexes?.length >= 1
                        ? `K${headerIndexes[0] + 1}:L${rowsCount + headerIndexes[0] + 1}`
                        : null,

                tFirstColumnRange1:
                    headerIndexes?.length >= 1
                        ? `A${headerIndexes[1] + 1}:A${rowsCount + headerIndexes[1] + 1}`
                        : null,
                tLastColumnRange1:
                    headerIndexes?.length >= 1
                        ? `H${headerIndexes[0] + 1}:H${rowsCount + headerIndexes[1] + 1}`
                        : null,
            };


            XlsxPopulate.fromDataAsync(workbookblob).then((workbook) => {
                workbook.sheets().forEach((sheet) => {
                    sheet.column("A").width(25);
                    sheet.column("B").width(80);
                    sheet.column("C").width(30);
                    sheet.column("D").width(20);

                    sheet.range(dataInfo.titleRange).merged(true).style({
                        bold: true,
                        fontFamily: "Arial",
                        horizontalAlignment: "center",
                        verticalAlignment: "center",
                    });
                    sheet.range(dataInfo.uHeadRange).style({
                        fill: "A6A6A6",
                        bold: true,
                        horizontalAlignment: "center",
                    });
                    sheet.range(dataInfo.uBodyRange).style({
                        bold: true,
                        horizontalAlignment: "center",
                    });
                    if (dataInfo.tbodyRange) {
                        sheet.range(dataInfo.tbodyRange).style({
                            horizontalAlignment: "left",
                        });
                    }
                    //   sheet.range(dataInfo.theadRange).style({
                    //     fill: "A6A6A6",
                    //     bold: true,
                    //     horizontalAlignment: "center",
                    //   });
                    if (dataInfo.tFirstColumnRange) {
                        sheet.range(dataInfo.tFirstColumnRange).style({
                            bold: true,
                        });
                    }
                });

                return workbook.outputAsync().then((workbookBlob) => {
                    const downloadAnchorNode = document.createElement("a");
                    var dateVar = new Date();
                    var fileName = "LCPT_StaffRoleCCourseDetails-" + dateVar.getDate() + dateVar.getMonth() + dateVar.getFullYear() + "_" + dateVar.getHours() + dateVar.getMinutes() + ".xlsx";
                    downloadAnchorNode.setAttribute("href", URL.createObjectURL(workbookBlob));
                    downloadAnchorNode.setAttribute("download", fileName);
                    downloadAnchorNode.click();
                    downloadAnchorNode.remove();

                });
            });
        } catch (error) {
            console.log(error)
        }
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: "xlsx",
            bookSST: false,
            type: "binary",
        };
        const wbout = XLSX.write(workbook, wopts);

        // The application/octet-stream MIME type is used for unknown binary files.
        // It preserves the file contents, but requires the receiver to determine file type,
        // for example, from the filename extension.
        const blob = new Blob([s2ab(wbout)], {
            type: "application/octet-stream",
        });
        return blob;
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; i++) {
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }







    function setArhiveStatus(role_id, role_name, id, archiveStatus) {
        var tempArchiveObj = {};
        tempArchiveObj.role_id = String(role_id);
        tempArchiveObj.role_name = String(role_name);
        tempArchiveObj.home_id = String(homeId);
        tempArchiveObj.user_id = String(userId);
        tempArchiveObj.staffArchiveStatus = String(archiveStatus);
        tempArchiveObj.staffFullName = String(staffFullName);
        setArchivedObj(tempArchiveObj)

        if (archiveStatus == 'Active') {
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "orgnization/checkArchiveStatusForStaff/";
            apiRequest.data = { home_id: homeId, user_id: userId, role_id: role_id, id: id };
            apiUtility.updateAPIDataPUT(apiRequest).then(res => {
                if (res.data == 'Archived') {
                    handleshowArchiveWarning();
                }
                else {
                    saveArchiveStatus(tempArchiveObj);
                }
            }).catch((error) => {
                console.error(error);
                alert("Something Went Wrong!")
            });

        }
        else {
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "orgnization/setArchiveStatus/";
            apiRequest.data = { home_id: homeId, user_id: userId, role_id: role_id, id: id, staffArchiveStatus: archiveStatus, staffFullName: staffFullName, role_name: role_name };
            apiUtility.updateAPIDataPUT(apiRequest).then(res => {
                if (archiveStatus == 'Archive') {
                    alert("Staff Employment status changed!")
                }
                else {
                    alert("Email Sent to User!")

                }
            }).catch((error) => {
                console.error(error);
                alert("Something Went Wrong!")
            });

        }


    }

    function saveArchiveStatus(archivedObj) {
        handleCloseArchiveWarning()
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/setArchiveStatus/";
        apiRequest.data = archivedObj;
        apiUtility.updateAPIDataPUT(apiRequest).then(res => {
            if (archivedObj.staffArchiveStatus == 'Archive') {
                alert("Staff Employment status changed!")
            }
            else {
                alert("Email Sent to User!")

            }
        }).catch((error) => {
            console.error(error);
            alert("Something Went Wrong!")
        });

    }

    var cols = [{
        dataField: 'id',
        text: 'Course ID',
        sort: true,
        style: {
            fontWeight: 'bold',
            height: '5%'
        }
    },
    {
        dataField: 'details',
        text: 'Course Title',
        sort: true,
        headerStyle: { cursor: 'pointer' }
    },
    {
        dataField: 'crsExp',
        text: 'Course Validity',
        sort: true,
        headerStyle: { cursor: 'pointer' },
        formatter: (rowContent, item) => {
            return (
                <span style={{ cursor: "pointer" }} > <input
                    type="checkbox" style={{ transform: "scale(1.5)", marginLeft: "15%" }}
                    defaultChecked={item.isComp} disabled
                /> &nbsp;{item.isComp ? (
                    <span style={{ marginLeft: "8px" }}>
                        {/* {item.crsExp ? new Date(item.crsExp).toLocaleDateString() : 'N/A'} */}
                        {
                            item.crsExp
                                ? (new Date(item.crsExp) > new Date()
                                    ? new Date(item.crsExp).toLocaleDateString()
                                    : 'Expired')
                                : 'N/A'
                        }
                    </span>
                ) : ''} </span>
            )
        },

        sortFunc: (a, b, order, dataField, rowA, rowB) => {
            // Case 3: when rowA.isComp is false
            if (!rowA.isComp && rowB.isComp) {
                return order === 'asc' ? -1 : 1;
            }
            if (rowA.isComp && !rowB.isComp) {
                return order === 'asc' ? 1 : -1;
            }

            // Case 1: when rowA.isComp is true and rowA.crsExp is mentioned.
            if (rowA.isComp && rowA.crsExp) {
                if (!rowB.isComp || (rowB.isComp && !rowB.crsExp)) {
                    return order === 'asc' ? -1 : 1;
                }
                return new Date(rowA.crsExp) - new Date(rowB.crsExp);
            }

            // Case 2: when rowA.isComp is true and rowA.crsExp is missing/empty
            if (rowA.isComp && !rowA.crsExp) {
                if (!rowB.isComp || (rowB.isComp && !rowB.crsExp)) {
                    return order === 'asc' ? 1 : -1;
                }
                return order === 'asc' ? 1 : -1;
            }

            // Default case
            return 0;
        }
    }];

    var exportAuditPDF = async (roleId, roleName, data, id) => {

        const title = 'Ontario Long Term Care Orientation Co-operative Webportal\nStatus of Training Requirements for the Role of ' + String(roleName);

        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";
        const compress = true;
        const fontSize = 8;
        const lineHeight = 1;
        const autoSize = false;
        const printHeaders = true;
        const doc = new jsPDF(orientation, unit, size, compress, fontSize, lineHeight, autoSize, printHeaders);
        //const doc = new jsPDF();
        var rowsCount = 6;
        doc.setFontSize(15);

        var userBody = [{}];
        var userHeader = [{}];
        var tableData = [];
        userHeader = [{ 'first': 'Name : ' + staffFullName }];
        userBody = [{ 'first': 'UserId : ' + userId }, { 'first': 'Date : ' + new Date().toLocaleString() }];
        let headers = [['Course ID', 'Course Title', 'Course Expiry', 'Completed']];
        for (var i = 0; i < data.course_details.length; i++) {
            rowsCount += 1;
            tableData.push([
                data.course_details[i].id,
                data.course_details[i].details,
                data.course_details[i].isComp ? (data.course_details[i].crsExp ? data.course_details[i].crsExp.split('T')[0] : 'N/A') : '',
                data.course_details[i].isComp ? 'Y' : 'N',
            ]);
        }


        const lineColor = '#cccccc';
        doc.setDrawColor(lineColor);

        const pageWidth = doc.internal.pageSize.getWidth() - 20;

        doc.rect(40, 20, pageWidth - 60, 55);
        doc.rect(40, 20, pageWidth - 60, 150);
        doc.autoTable({
            head: userHeader,
            body: userBody,
            styles: { font: 'helvetica', cellWidth: 'wrap' },
            //columnStyles: {0: {halign: 'left', fontStyle: 'bold'}},
            startY: 80,
            theme: 'plain'
        });

        const headerBackgroundColor = '#cccccc'; // Set the desired background color
        doc.setFillColor(headerBackgroundColor);
        let content = {
            startY: 190,
            styles: { font: 'helvetica', cellWidth: 'auto', minCellWidth: 2 },
            columnStyles: { 0: { halign: 'center', fontStyle: 'bold' } },//, fillColor: [0, 255, 0]}}, // Cells in first column centered and green
            headStyles: {
                fillColor: [15, 111, 197], halign: 'center'
            },
            margin: { top: 30 },
            head: headers,
            body: tableData,
            theme: 'grid'

        };
        doc.setProperties({
            title: title,
            subject: 'This is the subject',
            author: 'LCPT',
            keywords: 'generated, javascript, web 2.0, ajax',
            creator: 'LCPT',
            bodyStyles: {
                color: '#cccccc'
            }
        });
        doc.setFontSize(15);
        doc.text(title, 300, 40, {
            align: 'center'
        });
        doc.autoTable(content);
        doc.save("LCPT_UserAuditReport-" + ".pdf");

    }

    return (
        <div className='org-container'>
            {showSpinner ? <div style={{ paddingLeft: "50%", paddingTop: "10%", position: "absolute" }}>
                <Spinner show={showSpinner} animation="border" size="lg" variant='primary' />

            </div> : <div></div>}
            {!showTemplateDetails ? (
                <div></div>
            ) : (
                <div style={{ width: "100%" }}>
                    {staffFullName ? (<h5>Status of Training Requirements by Role for
                        : {staffFullName}
                    </h5>) : <div><h5>Status of Training Requirements by Role for
                        : {userName}
                    </h5></div>}
                    <br></br>
                    {(staffRoleDetails.length == 0) ? <Alert variant="outlined" severity="error">No Active Roles Found!</Alert> : <div></div>}
                    <div>
                        <Tabs id="uncontrolled-tab-example" className="mb-3" fill>

                            {staffRoleDetails.map((data, id) => {
                                return (

                                    <Tab eventKey={data.role_id} title={data.role_name} key={id}>
                                        <Modal style={{ width: "50%", left: "25%", top: "20%" }} show={showArchiveWarning} onHide={handleCloseArchiveWarning}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Warning</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <h5>Are you sure that you want to activate this staff in this role?  The role template is currently archived and if you activate the staff, the role template status will be changed to active.</h5>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={handleCloseArchiveWarning}>
                                                    Cancel
                                                </Button>
                                                <Button variant="primary" onClick={() => saveArchiveStatus(archivedObj)}>
                                                    Yes
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                        <Table id={id}>
                                            {/* <h2>Role Status: {data.emp_status}</h2> */}
                                            {

                                                <div>
                                                    {data.staffArchiveStatus.find((role) => role.role_id === data.role_id && role.roleStatus !== "Active") ? (
                                                        <div>
                                                            <br></br>
                                                            {data.staffArchiveStatus.find((role) => role.role_id === data.role_id && role.roleStatus === "Hold") ? (
                                                                <Alert variant="outlined" severity="warning">Role is pending for confirmation through email</Alert>
                                                            ) : (
                                                                <Alert variant="outlined" severity="warning">Please activate user role to view course completion details</Alert>
                                                            )}
                                                            <br></br>
                                                        </div>
                                                    ) :
                                                        <div>
                                                            {data.course_details.length < 1 ? <div></div> :
                                                                <Typography variant="subtitle1" align="center">Courses completion Status for {data.role_name} : {data.course_status}</Typography>}
                                                                <BootstrapTable striped hover keyField='courseID' data={data.course_details} columns={cols} />
                                                        </div>
                                                    }
                                                </div>}
                                        </Table>
                                        <Row>
                                            <Col xs={12} md={3}>

                                                <Paper component="form" variant="outlined"
                                                    sx={{ float: "left", marginBottom: "4px", alignItems: 'center', width: 50, marginRight: "8%" }}
                                                >
                                                    <Tooltip variant="contained" title="Export to Excel" disableRipple>
                                                        <IconButton color="primary" onClick={(e) => {
                                                            { exportInExcel(data.role_id, data.role_name, data, id) }
                                                        }} sx={{ p: '8px' }} aria-label="directions">
                                                            <RiFileExcel2Line />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Paper>
                                                <Paper component="form" variant="outlined"
                                                    sx={{ float: "left", marginBottom: "4px", alignItems: 'center', width: 50, marginRight: "8%" }}
                                                >
                                                    <Tooltip variant="contained" title="Export to Pdf" disableRipple>
                                                        <IconButton color="primary" onClick={(e) => {
                                                            { exportAuditPDF(data.role_id, data.role_name, data, id) }
                                                        }} sx={{ p: '8px' }} aria-label="directions">
                                                            <RiFilePdfLine />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Paper>
                                                {/* </Col>
                                            <Col xs={12} md={2} className="text-center"> */}
                                                {/* {(data.staffArchiveStatus.find((role) => console.log(role,data) (role.role_id === data.role_id && role.status === "Archive")))} */}
                                                {data.staffArchiveStatus.find((role) => (role.role_id === data.role_id && role.roleStatus != "Archive")) ?
                                                    <Button style={{ float: "left" }} variant='warning' onClick={(e) => {
                                                        { setArhiveStatus(data.role_id, data.role_name, id, 'Archive') }
                                                    }}
                                                    >Archive Role</Button> :
                                                    <Button style={{ float: "left" }} variant='warning' onClick={(e) => {
                                                        { setArhiveStatus(data.role_id, data.role_name, id, 'Active') }
                                                    }}
                                                    >Activate Role</Button>
                                                }

                                            </Col>
                                        </Row>
                                    </Tab>
                                )
                            })
                            }
                        </Tabs>
                    </div>
                </div>
            )
            }
        </div>);
}

