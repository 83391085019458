

import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import { BASE_API_URL } from '../../config/Url-config';
import Spinner from 'react-bootstrap/Spinner'
import { Col, Row } from 'react-bootstrap';
import { FiRefreshCw } from "react-icons/fi";
import ToggleButton from 'react-bootstrap/ToggleButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import * as apiUtility from "../../config/APIUtils";
import ErrorPopUp from '../Utility/ErrorPopUp';
import SuccessPopUp from '../Utility/SuccessPopUp';
import RoleTemplateOrg from './RoleTemplateOrg';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import KeyboardDoubleArrowUpOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowUpOutlined';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';

import { Divider, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Tooltip, Typography } from '@mui/material';

export default function TrainStandardComponent(props) {
    const ref = useRef(null);

    const [copyRoleDetails, setCopyRoleDetails] = useState({});
    const [positionDetail, setPositionDetail] = useState({});
    const [showTemplateDetails, setShowTemplateDetails] = useState(false)
    const [templateDetails, setTemplateDetails] = useState({});

    const [courseList, setCourseList] = useState([]);
    const [trainStandards, setTrainStandards] = useState([]);
    const [show, setShow] = useState(false);
    const [newStandard, setNewStandard] = useState('');
    const [errors, setErrors] = useState(false)
    const [roleDetails, setRoleDetails] = useState([]);
    const [roleDetailOverWrite, setroleDetailOverWrite] = useState({});

    const [MicroCredShow, setMicroCredShow] = useState(false);
    const [dispRoleDetail, setDispRoleDetail] = useState({});
    const [showDetailForm, setShowDetailForm] = useState(false);
    const [showOverwriteWarning, setOverwriteWarning] = useState(false);
    const handleCloseOverwriteWarning = () => setOverwriteWarning(false);
    const handleShowOverWriteWarning = () => {
        setOverwriteWarning(true);
    }

    const [orgList, setOrgList] = useState([]);
    const [homeList, setHomeList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [roleDetailOptions, setRoleDetailOptions] = useState({});
    const [editRoleDetail, setEditRoleDetail] = useState({});
    const [checkListRoleId, setCheckListRoleId] = useState('');
    const [checkListHomeId, setCheckListHomeId] = useState('');
    const handleShowDetailForm = () => {
        setShowDetailForm(!showDetailForm);
        setRoleDetailOptions({});
    }

    const handleCloseMicroCred = () => setMicroCredShow(false);
    const handleshowMicroCred = () => setMicroCredShow(true);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setPositionDetail({})
        setRoleDetailOptions({});
        setShowDetailForm(false);
        setShow(true); setNewStandard('');
        setErrors(false); setTrainStdErrorMsg('')
    };
    const [roleDetailshow, setRoleDetailShow] = useState(false);
    const handleroleDetailClose = () => setRoleDetailShow(false);
    const handleroleDetailShow = () => setRoleDetailShow(true);
    const [dispMicroCred, setDispMicroCred] = useState('');
    const [showSpinner, setshowSpinner] = useState(false);
    const toggleshowSpinner = () => setshowSpinner(!showSpinner);
    const [editTableWidth, setEditTableWidth] = useState('');
    const [editRowWidth, setEditTableRowWidth] = useState('');

    const [trainStdErrorMsg, setTrainStdErrorMsg] = useState('');
    const [showTrainStandardButton, setShowTrainStandardButton] = useState(false);
    const [archivedObj, setArchivedObj] = useState({});
    const [archivedStates, setArchivedStates] = useState([]);
    const [showArchiveWarning, setArchiveWarningShow] = useState(false);
    const handleCloseArchiveWarning = () => setArchiveWarningShow(false);
    const handleshowArchiveWarning = () => setArchiveWarningShow(true);
    const [homesType, setHomesType] = useState(JSON.parse(sessionStorage.getItem('home_types')));
    const [selectedHomeType, setSelectedHomeType] = useState(homesType[0]);
    const [roleDetail, setRoleDetail] = useState();
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [successMsg, setsuccessMsg] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [checkListRoleDetails, setCheckListRoleDetailsModal] = useState(false);
    const handleCloseCheckListRoleDetails = () => {
        setCheckListRoleDetailsModal(false);
        setShowOverWriteOption(false);
        setroleDetailOverWrite({})
    }
    const [showOverWriteOption, setShowOverWriteOption] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [selectedArchiveType, setSelectedArchiveType] = useState({ "name": "Active", "value": "2" });

    const archiveType = [{ 'name': "All", 'value': "1" }, { 'name': "Active", 'value': "2" }, { 'name': "Archived", 'value': "3" }]

    const accessToken = sessionStorage.getItem('authToken');
    const headers = {
        Authorization: `Bearer ${accessToken}`,
    };
    const tableRef = useRef();

    const user_id = useParams().id;
    ////console.log(props)
    if (sessionStorage.getItem("userType") == 'admin') {
        var params = props.id

    }
    else {
        if (props.orgId)
            var params = props.orgId
        else
            var params = sessionStorage.getItem("orgId")

    }



    function handleArchiveTypeChange(archiveType) {
        setSelectedArchiveType(archiveType);

    }

    function handleHomeTypeChange(homeType) {
        setSelectedHomeType(homeType)

    }
    function setTableWidthFunc(resLength) {
        if (resLength >= 0) {
            switch (resLength) {
                case 0: setEditTableWidth('68vh');
                    setEditTableRowWidth('100%');
                    //alert('No Role Found!')
                    break;
                case 1: setEditTableWidth('130vh');
                    setEditTableRowWidth('52.5%');
                    break;
                case 2: setEditTableWidth('170vh');
                    setEditTableRowWidth('40%');
                    break;
                case 3: setEditTableWidth('200vh');
                    setEditTableRowWidth('34%');
                    break;
                case 4: setEditTableWidth('230vh');
                    setEditTableRowWidth('29.6%');
                    break;
                case 5: setEditTableWidth('270vh');
                    setEditTableRowWidth('25.2%');
                    break;
                case 6: setEditTableWidth('310vh');
                    setEditTableRowWidth('22%');
                    break;
                case 7: setEditTableWidth('360vh');
                    setEditTableRowWidth('19%');
                    break;
                case 8: setEditTableWidth('405vh');
                    setEditTableRowWidth('16.8%');
                    break;
                case 9: setEditTableWidth('450vh');
                    setEditTableRowWidth('15.2%');
                    break;
                // 10 
                case 10: setEditTableWidth('500vh');
                    setEditTableRowWidth('13.7%');

                    break;
                case 11: setEditTableWidth('545vh');
                    setEditTableRowWidth('12.5%');

                    break;
                case 12: setEditTableWidth('590vh');
                    setEditTableRowWidth('11.58%');
                    break;
                case 13: setEditTableWidth('640vh');
                    setEditTableRowWidth('10.68%');
                    break;
                case 14: setEditTableWidth('685vh');
                    setEditTableRowWidth('10%');
                    break;
                case 15: setEditTableWidth('735vh');
                    setEditTableRowWidth('9.3%');
                    break;
                case 16: setEditTableWidth('780vh');
                    setEditTableRowWidth('8.7%');
                    break;
                case 17: setEditTableWidth('825vh');
                    setEditTableRowWidth('8.25%');

                    break;
                case 18: setEditTableWidth('870vh');
                    setEditTableRowWidth('7.8%');

                    break;
                case 19: setEditTableWidth('930vh');
                    setEditTableRowWidth('7.34%');

                    break;
                case 20: setEditTableWidth('985vh');
                    setEditTableRowWidth('6.9%');

                    break;
                case 21: setEditTableWidth('1030vh');
                    break;
                case 22: setEditTableWidth('1075vh');
                    break;
                case 23: setEditTableWidth('1400vh');
                    break;
                case 24: setEditTableWidth('1120vh');
                    break;
                case 25: setEditTableWidth('1170vh');
                    break;
                case 26: setEditTableWidth('1220vh');
                    break;
                case 27: setEditTableWidth('1270vh');
                    break;
                case 28: setEditTableWidth('1325vh');
                    break;
                case 29: setEditTableWidth('1370vh');
                    break;
                case 30: setEditTableWidth('1420vh');
                    break;
                case 31: setEditTableWidth('1480vh');
                    break;
                case 32: setEditTableWidth('1560vh');
                    break;
                case 33: setEditTableWidth('1620vh');
                    break;
                case 34: setEditTableWidth('1680vh');
                    break;
                case 35: setEditTableWidth('1740vh');
                    break;
                case 36: setEditTableWidth('1800vh');
                    break;
                case 37: setEditTableWidth('1860vh');
                    break;
                case 38: setEditTableWidth('1920vh');
                    break;
                case 39: setEditTableWidth('1980vh');
                    break;
                case 40: setEditTableWidth('2040vh');
                    break;
                case 41: setEditTableWidth('2300vh');
                    break;
                case 42: setEditTableWidth('2360vh');
                    break;
                case 43: setEditTableWidth('2420vh');
                    break;
                case 44: setEditTableWidth('2480vh');
                    break;
                case 45: setEditTableWidth('2560vh');
                    break;
                case 46: setEditTableWidth('2620vh');
                    break;
                case 47: setEditTableWidth('2680vh');
                    break;
                case 48: setEditTableWidth('2760vh');
                    break;
                case 49: setEditTableWidth('2820vh');
                    break;
                case 50: setEditTableWidth('2880vh');
                    break;
                case 51: setEditTableWidth('2940vh');
                    break;
                case 52: setEditTableWidth('3000vh');
                    break;
                case 53: setEditTableWidth('3070vh');
                    break;
                case 54: setEditTableWidth('3140vh');
                    break;
                case 55: setEditTableWidth('3210vh');
                    break;
                case 56: setEditTableWidth('3280vh');
                    break;
                case 57: setEditTableWidth('3350vh');
                    break;
                case 58: setEditTableWidth('3240vh');
                    break;
                case 59: setEditTableWidth('3310vh');
                    break;
                case 60: setEditTableWidth('3380vh');
                    break;
                default: setEditTableWidth('3450vh');
            }
            // var tableWidth = document.getElementById("tableWidth");
            // tableWidth.style.width = editTableWidth;
        }
    }

    async function getTrainingData() {

        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/getOrganisationDetails/" + params;
        //apiRequest.data = tempHomeObj;
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {

            if (res != 'Something went wrong!' || res != 'No Standards Found!') {
                var tempRoleArray;
                if (selectedArchiveType.value === '1') {
                    tempRoleArray = res.data[0].train_standards;
                }
                else if (selectedArchiveType.value === '2') {
                    tempRoleArray = res.data[0].train_standards.filter(item => ((item.archived === false) || (item.archived === 'False') || (item.archived === 'false')));
                }
                else {
                    tempRoleArray = res.data[0].train_standards.filter(item => ((item.archived === true) || (item.archived === 'True') || (item.archived === 'true')));
                }
                setTrainStandards(tempRoleArray)
                //setshowSpinner(false)roleDetails
                setRoleDetails(tempRoleArray);
                let resLength = tempRoleArray.length;
                var arr = {}; // Initialize the 'arr' object
                res.data[0].train_standards.forEach(data => {
                    arr[data.role_id] = data.archived;
                });
                setArchivedStates(prevStates => ({ ...prevStates, ...arr }));
                setTableWidthFunc(resLength);
            }


        }).catch((error) => {
            console.error(error);
            // setshowSpinner(false);
        });


        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/getCourseList/";
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {

            if (res.data != 'Something went wrong!' && res.data != 'No Course Found!') {
                if (selectedHomeType.id == '100' || selectedHomeType.id == '101') {
                    setCourseList(res.data);
                }
                else {
                    var tempCourseList = [];
                    for (var i = 0; i < res.data.length; i++) {
                        if (res.data[i].type === selectedHomeType.id) {
                            tempCourseList.push(res.data[i]);
                        }
                    }
                    setCourseList(tempCourseList)
                }
            } else {
                setCourseList([]);
            }

        }).catch((error) => {
            console.error(error);
            setCourseList([]);
        });


        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/getOrgRoleList/" + params;
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {

            if (res != 'Something went wrong!' || res != 'No Role Found!') {
                setRoleDetails(res.data);
            }

        }).catch((error) => {
            console.error(error);
            // setshowSpinner(false);
        });





        // setshowSpinner(false)

    }
    function getMicrodetails(event, val) {
        // //console.log(roleDetails[id].course_details[_id])
        if (val.description) {
            var obj = {}
            obj['description'] = val.description
            obj['title'] = val.title
            obj['training_duration'] = val.training_duration
            obj['validity_duration'] = val.validity_duration.years + " years " + val.validity_duration.months + " months"
            setDispMicroCred(obj);
        }

        else
            setDispMicroCred(val.title)
        handleshowMicroCred()

    }

    function addTrainingText(event, id) {
        let newPositionDetailObj = {};
        newPositionDetailObj = { ...positionDetail };
        if (id == 'tran1') {
            newPositionDetailObj.role_name = event.target.value

        }
        else {
            newPositionDetailObj.role_details = event.target.value
        }
        setPositionDetail(newPositionDetailObj);


    }
    const scroll = (scrollOffset, direction) => {
        if (direction === 'horizontal') {
            ref.current.scrollLeft += scrollOffset;
        } else if (direction === 'vertical') {
            ref.current.scrollTop += scrollOffset;
        }
    };

    function addPositionText(event, id) {
        let newPositionDetailObj = {};
        newPositionDetailObj = { ...positionDetail };
        if (id === 'pos1')
            newPositionDetailObj.role_name = event.target.value;
        newPositionDetailObj.role_id = String(roleDetails.length + 1);

        newPositionDetailObj.home_id = String(params);
        newPositionDetailObj.archived = false;
        newPositionDetailObj.org_id = sessionStorage.getItem("orgId");
        setPositionDetail(newPositionDetailObj);
        //console.log("position details:", positionDetail)


    }
    function changeArchiveStatus(e, role_id) {

        const newArchivedStates = { ...archivedStates };
        newArchivedStates[role_id] = e.target.value;
        var tempArchiveObj = {};
        tempArchiveObj.role_id = String(role_id);
        tempArchiveObj.archived = (e.target.value);
        if (e.target.value === "True" || e.target.value === "true" || e.target.value === true) {
            tempArchiveObj.archived = true;
        }
        else {
            tempArchiveObj.archived = false;
        }
        tempArchiveObj.home_id = String(params);
        tempArchiveObj.archivedStates = newArchivedStates
        setArchivedObj(tempArchiveObj);
        handleshowArchiveWarning();


    }
    function saveArchiveStatus() {
        handleCloseArchiveWarning();
        setArchivedStates(archivedObj.archivedStates);
        var courses = trainStandards.find(item => item.role_id == archivedObj.role_id)
        const archiveStatusUrl = BASE_API_URL + "orgnization/editRoleArchiveStatus"
        axios.put(archiveStatusUrl, { "role_id": archivedObj.role_id, "archived": archivedObj.archived, "org_id": String(params), "isTrainStandard": true, "courses": courses.course_details }, { headers })
            .then(res => {
                setsuccessMsg("Archive Status Changed!")
                setShowSuccessPopup(true);
                getTrainingData();

            })
            .catch(err => {
                setErrorMsg("Something Went Wrong!")
                setShowErrorPopup(true);
                //console.log(err);
            })
    }

    useEffect(() => {

        if (params) {
            getTrainingData();
        }

        // setSelectedArchiveType({ "name": "Active", "value": "2" });

    }, [params, selectedHomeType, selectedArchiveType]);

    // useEffect(() => {

    //     getTrainingData();
    //     setSelectedArchiveType({ "name": "Active", "value": "2" });
    // }, [selectedHomeType])


    async function saveNewStandard() {
        console.log(roleDetail, roleDetailOptions, positionDetail)
        var tempStandard = {};

        if (positionDetail.role_name && positionDetail.role_details) {
            tempStandard.role_name = positionDetail.role_name
            tempStandard.role_details = positionDetail.role_details

            tempStandard.archived = false;
            tempStandard.role_id = "1";
            // setNewStandard(...newStandard, tempStandard);
            if (tempStandard.role_name && tempStandard.role_details && tempStandard.role_id) {
                let newtrainStandards = [...trainStandards];

                if (roleDetailOptions.course_details && roleDetailOptions.course_details.length > 0) {
                    tempStandard['course_details'] = roleDetailOptions.course_details;
                }
                else {
                    tempStandard['course_details'] = [];

                }
                newtrainStandards.push(tempStandard);
                setTrainStandards(newtrainStandards);

                let trainStandardsUrl = BASE_API_URL + "orgnization/addNewStandard/";

                var apiRequest = apiUtility.apiInputRequest;
                apiRequest.endPoint = "orgnization/addNewStandard/";
                apiRequest.data = { id: params, trainStandards: newtrainStandards, newStandard: tempStandard };
                console.log(newtrainStandards, tempStandard)
                apiUtility.sendAsyncAPIDataPOST(apiRequest).then(newRes => {
                    setRoleDetailOptions({});
                    setShowDetailForm(false);
                    handleClose();
                    clearAllSetting();
                    getTrainingData();
                }).catch((error) => {
                    setShowDetailForm(false)
                    console.error(error);
                    clearAllSetting();
                    document.getElementById("tran1").value = ""
                    document.getElementById("tran2").value = ""
                    setshowSpinner(false)
                });



            }
            setPositionDetail({});
        }
        else {
            console.log(positionDetail)
            setshowSpinner(false);
            setErrors(true);
        }


    }

    async function handleCheckOnChange(event, courseDetail, roleDetail, trainData, id) {
        setshowSpinner(true)
        console.log("Course Detail : ", courseDetail, "RoleDatils : ", roleDetail, "trainData:", trainData);
        if (roleDetail.archived === "True" || roleDetail.archived === true) {
            setErrorMsg("Request not processed! Cannot add/remove course when role is archived.")
            setShowErrorPopup(true);
            setshowSpinner(false);
            return;
        }
        console.log(event.target.checked, courseDetail, roleDetail, id)
        var tempArray = roleDetail;
        roleDetail.org_id = sessionStorage.getItem("orgId")
        var isRemoved = false;
        if (!tempArray.course_details) {
            // If course_details doesn't exist, create a new array
            tempArray.course_details = [];
        }
        if (event.target.checked) {
            tempArray.course_details.push({ "id": courseDetail.courseID, "details": courseDetail.title })
        } else {
            isRemoved = true;
            tempArray.course_details = tempArray.course_details.filter(function (el) { return el.id != courseDetail.courseID; });
        }
        setRoleDetails(tempArray)
        //console.log(tempArray)
        var body = {
            "forOrg": tempArray,
            "forHome": roleDetail,
            "isRemoved": isRemoved
        }

        const editOrgCourseDetails = BASE_API_URL + "orgnization/editOrgCourseDetails/"
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/editOrgCourseDetails/";
        apiRequest.data = body;
        apiUtility.updateAsyncAPIDataPUT(apiRequest).then(res => {
            setshowSpinner(false)
            getTrainingData()
        }).catch((error) => {
            console.error(error);
            // setshowSpinner(false);
        });



    }

    function openDispRoleDetail(event, data) {
        console.log(data)
        setDispRoleDetail(data)
        handleroleDetailShow()
    }

    function getOrgDetails() {
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/getOrganizationList";
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
            console.log(res)
            setOrgList(res.data);
        }).catch((error) => console.error(error));
    }

    function selectOrgForRole(event) {
        if (event.target.value) {
            var tempRoleDetails = {};
            tempRoleDetails.org_id = event.target.value;
            tempRoleDetails.home_id = '';
            tempRoleDetails.role_id = '';
            // setHomeList([]);
            // setRoleList([]);
            setRoleDetailOptions(tempRoleDetails)
            console.log("org", roleDetailOptions);
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "orgnization/getHomesList/" + event.target.value;
            apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
                console.log(res)
                if (res.length == 0 || !res.data || !res || res.data === 'No Home Found!') {
                    setHomeList([])
                }
                else {
                    setHomeList(res.data);
                    var apiRequest = apiUtility.apiInputRequest;
                    apiRequest.endPoint = "orgnization/getOrganisationDetails/" + event.target.value;
                    apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {

                        if (res != 'Something went wrong!' && res != 'No Standards Found!' && res.data) {
                            setRoleList(res.data[0].train_standards)

                            console.log(roleList)

                        }
                        else {
                            setRoleList([]);

                        }

                    }).catch((error) => console.error(error))
                }
            }).catch((error) => console.error(error))

        }
    }

    function selectHomeForRole(event) {
        //setRoleList([]);
        if (event.target.value) {
            console.log("home", event.target.value);
            var tempRoleDetails = { ...roleDetailOptions };
            tempRoleDetails.home_id = event.target.value;
            tempRoleDetails.role_id = '';

            setRoleDetailOptions(tempRoleDetails)

            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "orgnization/showHomeCheckList/" + event.target.value;

            apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
                setRoleDetailOptions(tempRoleDetails);
                if (res.data === 'No Role Found!') {
                    setRoleList([]);

                }
                else {
                    setRoleList(res.data);
                }

            }).catch((error) => console.error(error))
        }
    }

    function selectRoleForCopy(event) {
        console.log(roleDetailOptions)
        if (event.target.value) {
            var tempRoleDetails = { ...roleDetailOptions };
            tempRoleDetails.role_id = event.target.value;
            var copyRoleDetails = roleList.find(item => item.role_id === event.target.value)
            console.log(copyRoleDetails)
            setRoleDetailOptions(copyRoleDetails);
            setRoleDetail(copyRoleDetails);
            console.log("roleDetails:", roleDetail);
            console.log("positionDetail:", positionDetail);
        }


    }
    function selectOrgForEdit(event) {
        if (event.target.value) {
            var tempRoleDetails = {};
            tempRoleDetails.org_id = event.target.value;
            tempRoleDetails.home_id = '';
            tempRoleDetails.role_id = '';
            // setHomeList([]);
            // setRoleList([]);
            setEditRoleDetail(tempRoleDetails)
            console.log("org", editRoleDetail);
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "orgnization/getHomesList/" + event.target.value;
            apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
                console.log(res)
                if (res.length == 0 || !res.data || !res || res.data === 'No Home Found!') {
                    setHomeList([])
                }
                else {
                    setHomeList(res.data);
                    var apiRequest = apiUtility.apiInputRequest;
                    apiRequest.endPoint = "orgnization/getOrganisationDetails/" + event.target.value;
                    apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {

                        if (res != 'Something went wrong!' && res != 'No Standards Found!' && res.data) {
                            setRoleList(res.data[0].train_standards)

                            console.log(roleList)

                        }
                        else {
                            setRoleList([]);

                        }

                    }).catch((error) => console.error(error))
                }
            }).catch((error) => console.error(error))


        }
    }
    function selectHomeForEdit(event) {
        //setRoleList([]);
        if (event.target.value) {
            console.log("home", event.target.value);
            var tempRoleDetails = { ...editRoleDetail };
            tempRoleDetails.home_id = event.target.value;
            tempRoleDetails.role_id = '';

            setEditRoleDetail(tempRoleDetails)

            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "orgnization/showHomeCheckList/" + event.target.value;

            apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
                setRoleDetailOptions(tempRoleDetails);
                if (res.data === 'No Role Found!') {
                    setRoleList([]);

                }
                else {
                    setRoleList(res.data);
                }

            }).catch((error) => console.error(error))
        }

    }
    function selectRoleForEdit(event) {
        if (event.target.value) {
            var tempRoleDetails = { ...editRoleDetail };
            tempRoleDetails.role_id = event.target.value;
            setEditRoleDetail(tempRoleDetails);
            console.log(editRoleDetail, tempRoleDetails);

            var copyRoleDetails = roleList.find(item => item.role_id === event.target.value);
            setroleDetailOverWrite(copyRoleDetails);
        }


    }

    function clearAllSetting() {
        setRoleList([]);
        setOrgList([]);
        setHomeList([]);
    }
    // function handlePasteRole() {
    //     var apiRequest = apiUtility.apiInputRequest;
    //     apiRequest.endPoint = "orgnization/getRoleLength/";
    //     //apiRequest.data = tempHomeObj;
    //     apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {


    //         console.log(JSON.parse(sessionStorage.getItem('copyRoleObj')));
    //         var tempStandard = {};
    //         tempStandard.course_details = JSON.parse(sessionStorage.getItem('copyRoleObj')).course_details;
    //         tempStandard.org_id = params;
    //         tempStandard.role_name = JSON.parse(sessionStorage.getItem('copyRoleObj')).role_name;
    //         tempStandard.role_details = JSON.parse(sessionStorage.getItem('copyRoleObj')).role_details;
    //         tempStandard.role_id = String(res.data + 1);
    //         //tempStandard.archived = "False";
    //         var tempTrainStandard = trainStandards;


    //         tempTrainStandard.push(tempStandard)
    //         console.log(tempTrainStandard, tempStandard)
    //         var apiRequest = apiUtility.apiInputRequest;
    //         apiRequest.endPoint = "orgnization/addNewStandard/";
    //         apiRequest.data = { id: params, trainStandards: tempTrainStandard, newStandard: tempStandard };
    //         apiUtility.sendAsyncAPIDataPOST(apiRequest).then(newRes => {
    //             console.log(newRes)
    //             getTrainingData();
    //             handleClose();
    //         }).catch((error) => {
    //             console.error(error);
    //             setshowSpinner(false)
    //         });

    //         sessionStorage.removeItem('copyRoleObj')
    //     })


    // }
    // const handleShowTooltip = () => {
    //     setShowTooltip(true);
    //     setTimeout(() => {
    //         setShowTooltip(false);
    //     }, 2000); // 2000 milliseconds = 2 seconds
    // };

    function handleOverWriteRole(role_id) {


        console.log(role_id)
        // var tempTrainStandard = trainStandards;
        console.log("copied-------->", JSON.parse(sessionStorage.getItem('copyRoleObj')));
        console.log("overwrite------------->", role_id, roleDetailOverWrite, roleDetail, roleDetails)
        // var tempCopyRole;
        // tempCopyRole.course_details = JSON.parse(sessionStorage.getItem('copyRoleObj')).course_details;
        //     console.log(tempCopyRole)

        setshowSpinner(true)
        //tempCopyRole.org_id = sessionStorage.getItem("orgId");
        var tempOrgCopyRole = {};
        var tempHomeCopyRole = {};
        tempOrgCopyRole.role_id = role_id
        tempHomeCopyRole.role_id = role_id
        tempOrgCopyRole.org_id = sessionStorage.getItem("orgId");
        tempHomeCopyRole.org_id = sessionStorage.getItem("orgId");
        tempOrgCopyRole.course_details = roleDetailOverWrite.course_details;
        tempHomeCopyRole.course_details = roleDetailOverWrite.course_details;
        var body = {
            "forOrg": tempOrgCopyRole,
            "forHome": tempHomeCopyRole,
            "isRemoved": false
        }
        console.log(body)

        const editOrgCourseDetails = BASE_API_URL + "orgnization/editOrgCourseDetails/"
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/editOrgCourseDetails/";
        apiRequest.data = body;
        apiUtility.updateAsyncAPIDataPUT(apiRequest).then(res => {
            setRoleDetailOptions({});
            setOverwriteWarning(false);
            handleCloseOverwriteWarning();
            handleCloseCheckListRoleDetails();
            console.log(res)
            setshowSpinner(false);
            getTrainingData()
        }).catch((error) => {
            alert('something went wrong!')
            console.error(error);
            setshowSpinner(false);
        });


    }


    function handleShowCheckListRoleDetails(e, homeId, roleId) {
        getOrgDetails()
        //var apiRequest = apiUtility.apiInputRequest;
        // const params = params + "/" + roleId

        // const getRoleTemplateUrl = BASE_API_URL + "orgnization/getRoleTemplateDetailsForOrg/" + params
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/getRoleTemplateDetailsForOrg/" + params + "/" + roleId;
        //apiRequest.data = tempHomeObj;
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
            if (res == 'Something went wrong!' || res == 'No Role Found!')
                console.log("No data")
            else {
                console.log(res);
                setTemplateDetails(res.data)
                if (templateDetails != {})
                    setShowTemplateDetails(true)
            }
        }).catch((error) => {
            setShowTemplateDetails(false)

            console.error(error);
            // setshowSpinner(false);
        });
        setCheckListHomeId(homeId)
        setCheckListRoleId(roleId)
        setCheckListRoleDetailsModal(true);

    }
    // const handleShowOverWriteOption = () => setShowOverWriteOption(!showOverWriteOption);
    function handleShowOverWriteOption() {
        if (showOverWriteOption) {
            // roleDetailOverWrite
            setroleDetailOverWrite({});
            setShowOverWriteOption(false)
        }
        else {
            setShowOverWriteOption(true);
        }
    }

    return (
        <div>
            <Modal show={checkListRoleDetails} onHide={handleCloseCheckListRoleDetails} className=" custom-map-modal">
                <Modal.Header closeButton>
                    <Modal.Title> Role Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RoleTemplateOrg roleId={checkListRoleId} orgId={sessionStorage.getItem('orgId')} />
                    <Button variant="primary" onClick={handleShowOverWriteOption}>Overwrite from existing</Button>
                    {showOverWriteOption ?
                        <div>
                            <Row >
                                <Col>
                                    <Form.Label>Organisation</Form.Label>
                                    <Form.Select className="form-select-sm" aria-label="Default select example" onChange={selectOrgForEdit}>
                                        <option value=''>Select an Organization</option>
                                        {orgList?.map((data) => {
                                            return <option value={data.org_id}>{data.org_name}</option>;
                                        })}
                                    </Form.Select>
                                </Col>

                                <Col>
                                    <Form.Label>Home</Form.Label>
                                    <Form.Select className="form-select-sm" aria-label="Default select example" onChange={selectHomeForEdit}>
                                        <option value=''>Select a Home</option>

                                        {homeList?.map((data) => {
                                            if (data.homeActiveStatus === true) {
                                                return <option value={data.home_id}>{data.name}</option>;
                                            } else {
                                                return null;
                                            }
                                            // return <option value={data.home_id}>{data.name}</option>;
                                        })}
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row >
                                <Col>
                                    <Form.Label>Role</Form.Label>
                                    <Form.Select className="form-select-sm" aria-label="Default select example" onChange={selectRoleForEdit}>
                                        <option value=''>Select a Role</option>
                                        {roleList?.map((data) => {
                                            if (data.archived === "False" || data.archived === false) {
                                                return <option value={data.role_id}>{data.role_name}</option>;
                                            } else {
                                                return null;
                                            }
                                            // return <option value={data.role_id}>{data.role_name}</option>;
                                        })}
                                    </Form.Select>
                                </Col>
                                <Col></Col>

                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3 col-xs-6" controlId="formBasicEmail">
                                        <Form.Label style={{ display: "block" }}><b>Courses to overwrite this Role : </b></Form.Label>
                                        {roleDetailOverWrite && roleDetailOverWrite.course_details ? (
                                            roleDetailOverWrite.course_details.map((data, id) => {
                                                return <ul key={id}>
                                                    <li><b>{data.id}</b> - {data.details}</li>
                                                </ul>
                                            })
                                        ) : (

                                            <p>-</p>)
                                        }
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3 col-xs-6" controlId="formBasicEmail">
                                        <Form.Label style={{ display: "block" }}><b>Present Courses for this Role : </b></Form.Label>
                                        {showTemplateDetails && templateDetails.course_details ? (
                                            templateDetails.course_details.map((data, id) => {
                                                return <ul key={id}>
                                                    <li><b>{data.id}</b> - {data.details}</li>
                                                </ul>
                                            })
                                        ) : (

                                            <p>...Data Loading.....</p>)
                                        }
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Button variant="warning" onClick={handleShowOverWriteWarning}  >Save OverWrite Changes</Button>
                        </div> :
                        <div></div>}

                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={handleCloseCheckListRoleDetails} >
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
            <Modal style={{ marginLeft: "25%", marginTop: "10%", width: "50%" }} show={showOverwriteWarning} onHide={handleCloseOverwriteWarning} className=" custom-map-modal">
                <Modal.Header closeButton>
                    <Modal.Title> Confirm Overwrite</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    You will be overwriting the training template with the new selection - please confirm this action.

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleOverWriteRole(checkListRoleId)}>
                        Confirm
                    </Button>
                    <Button variant="primary" onClick={handleCloseOverwriteWarning}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <Row>
                <Col xs={12} md={12}>
                    <Paper component="form" variant="outlined"
                        sx={{ float: "right", display: 'flex', marginBottom: "4px", alignItems: 'center', width: 100 }}>
                        <Tooltip variant="contained" title="Add New Standard" disableRipple>
                            <IconButton color="primary" onClick={() => handleShow()} sx={{ p: '10px' }} aria-label="directions">
                                <CreateNewFolderIcon />
                            </IconButton>
                        </Tooltip>
                        <Divider sx={{ height: 28, m: 0.5 }} color='primary' orientation="vertical" />
                        <Tooltip variant="contained" title="Refresh Data" disableRipple>
                            <IconButton color="primary" onClick={() => getTrainingData()} sx={{ p: '10px' }} aria-label="directions">
                                <FiRefreshCw />
                            </IconButton>
                        </Tooltip>
                        {/* <Divider sx={{ height: 28, m: 0.5 }} color='primary' orientation="vertical" />
                            <Tooltip variant="contained" title="Add New Standard" disableRipple>
                                <IconButton color="primary" onClick={() => handleShow} sx={{ p: '10px' }} aria-label="directions">
                                    <RiFileExcel2Line />
                                </IconButton>
                            </Tooltip> */}
                    </Paper>
                </Col>

                {/* <Col>
                    <Col>
                        <OverlayTrigger
                            placement="left"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip1}
                        >
                            <Button variant="warning" className="btn-md" onClick={() => getTrainingData()} style={{ float: "right", fontWeight: "1pc" }}><FiRefreshCw /></Button>
                        </OverlayTrigger>
                    </Col>
                    <Col>
                        {(sessionStorage.getItem("orgId") != "undefined") ? <Button style={{ float: "right", marginRight: "1%", marginBottom: "1%" }} variant="warning" onClick={handleShow}>Add New Standard</Button>
                            : (<div></div>)
                        }
                    </Col>
                </Col> */}
            </Row>
            <Modal show={show} onHide={handleClose} id="addHomeInOrg">
                <Modal.Header closeButton>
                    <Modal.Title>Add New HR Training Standard</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-2 col-xs-6" controlId="formBasicEmail">
                            <Form.Label>Enter the Role Name</Form.Label>
                            <Form.Control type="input" controlId="tra1"
                                defaultValue={newStandard} onChange={(e) => {
                                    addTrainingText(e, 'tran1');
                                }}
                            />
                            <Form.Label>Enter the Role Details</Form.Label>
                            <Form.Control type="input" controlId="tra2"
                                defaultValue={newStandard} onChange={(e) => {
                                    addTrainingText(e, 'tran2');
                                }}
                            />




                            {!showDetailForm ? <Button style={{ margin: "1%" }} onClick={() => { handleShowDetailForm(); getOrgDetails() }}>Copy from existing</Button> :
                                <div>                            <Button style={{ margin: "1%" }} onClick={() => { handleShowDetailForm(); clearAllSetting() }}>Hide Details</Button>
                                    <Row >
                                        <Col>
                                            <Form.Label>Organisation</Form.Label>
                                            <Form.Select className="form-select-sm" aria-label="Default select example" onChange={selectOrgForRole}>
                                                <option value=''>Select an Organization</option>
                                                {orgList?.map((data) => {
                                                    return <option value={data.org_id}>{data.org_name}</option>;
                                                })}
                                            </Form.Select>
                                        </Col>

                                        <Col>
                                            <Form.Label>Home</Form.Label>
                                            <Form.Select className="form-select-sm" aria-label="Default select example" onChange={selectHomeForRole}>
                                                <option value=''>Select a Home</option>

                                                {homeList?.map((data) => {
                                                    if (data.homeActiveStatus === true) {
                                                        return <option value={data.home_id}>{data.name}</option>;
                                                    } else {
                                                        return null;
                                                    }
                                                    // return <option value={data.home_id}>{data.name}</option>;
                                                })}
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col>
                                            <Form.Label>Role</Form.Label>
                                            <Form.Select className="form-select-sm" aria-label="Default select example" onChange={selectRoleForCopy}>
                                                <option value=''>Select a Role</option>
                                                {roleList?.map((data) => {
                                                    if (data.archived === "False" || data.archived === false) {
                                                        return <option value={data.role_id}>{data.role_name}</option>
                                                    } else {
                                                        return null;
                                                    }
                                                    // return <option value={data.role_id}>{data.role_name}</option>;
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col></Col>

                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3 col-xs-6" controlId="formBasicEmail">
                                                <Form.Label style={{ display: "block" }}><b>Courses to Copy From this Role : </b></Form.Label>
                                                {roleDetailOptions && roleDetailOptions.course_details ? (
                                                    roleDetailOptions.course_details.map((data, id) => {
                                                        return <ul key={id}>
                                                            <li><b>{data.id}</b> - {data.details}</li>
                                                        </ul>
                                                    })
                                                ) : (

                                                    <p>-</p>)
                                                }
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3 col-xs-6" controlId="formBasicEmail">
                                                <Form.Label style={{ display: "block" }}><b>Present Courses for this Role : </b></Form.Label>
                                                -
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {/* <Button variant="warning"  onClick={() => handleOverWriteRole(checkListRoleId)}>Save Changes</Button> */}
                                </div>}

                        </Form.Group>
                        <div>
                            {errors ? (
                                <p className="text-danger">*All fields are mandatory</p>

                            ) : (
                                <p className="text-danger"></p>

                            )}
                            {trainStdErrorMsg !== '' ?
                                (<p className="text-danger text-right">**{trainStdErrorMsg}</p>)
                                : ""}
                        </div>
                    </Form>

                </Modal.Body>
                <Modal.Footer>


                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={saveNewStandard}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            {showSpinner ? <div style={{ paddingLeft: "40%", top: "50%", position: "fixed" }}>
                <Spinner show={showSpinner} animation="border" size="lg" variant='primary' />
            </div> : <div></div>}
            <div ref={ref} style={{ overflowY: "scroll", height: "110vh", overflowX: "auto" }}>
                <Table striped bordered hover style={{ width: `${editTableWidth}` }}>
                    <thead style={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: "98" }}>
                        <th style={{ width: `${editRowWidth}`, textAlign: "center", left: "0px", backgroundColor: "rgb(255 255 255)", zIndex: "99", position: "sticky" }}>
                            <Row style={{ justifyContent: "center" }}>
                                <Col>
                                    <FormControl sx={{ width: "100%" }} size="small">
                                        <InputLabel id="demo-select-small-label">Courses</InputLabel>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            value={selectedHomeType.value}
                                            label="Courses">
                                            {homesType?.map((item) => {
                                                return <MenuItem value={item.value} onClick={() => handleHomeTypeChange(item)}>{item.value}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Col>


                                <Col>
                                    <FormControl sx={{ width: "100%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">Roles</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedArchiveType.name}
                                            label="Roles">
                                            {archiveType?.map((item) => {
                                                return <MenuItem value={item.name} onClick={() => handleArchiveTypeChange(item)}>{item.name}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Paper component="form" variant="outlined"
                                        sx={{ display: 'flex', marginBottom: "2px", alignItems: 'center' }}>


                                        <Col >
                                            <Tooltip variant="contained" title="Scroll Up">
                                                <IconButton color="primary" onClick={() => scroll(-100, 'vertical')} aria-label="directions-up">
                                                    <KeyboardDoubleArrowUpOutlinedIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Col>
                                        <Divider sx={{ height: 28, m: 0.5 }} color='primary' orientation="vertical" />
                                        <Col >
                                            <Tooltip variant="contained" title="Scroll Right">
                                                <IconButton color="primary" onClick={() => scroll(100, 'horizontal')} aria-label="directions-right">
                                                    <KeyboardDoubleArrowRightOutlinedIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Col>
                                        <Divider sx={{ height: 28, m: 0.5 }} color='primary' orientation="vertical" />
                                        <Col >
                                            <Tooltip variant="contained" title="Scroll Down">
                                                <IconButton color="primary" onClick={() => scroll(100, 'vertical')} aria-label="directions-down">
                                                    <KeyboardDoubleArrowDownOutlinedIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Col>
                                        <Divider sx={{ height: 28, m: 0.5 }} color='primary' orientation="vertical" />
                                        <Col>
                                            <Tooltip variant="contained" title="Scroll Left">
                                                <IconButton color="primary" onClick={() => scroll(-100, 'horizontal')} aria-label="directions-left">
                                                    <KeyboardDoubleArrowLeftOutlinedIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Col>
                                    </Paper>
                                </Col>
                            </Row>
                            <Typography variant='subtitle1' gutterBottom> Course Title</Typography>
                        </th>

                        {trainStandards.map((data, id) => {
                            return (<th key={id} style={{ width: "5vh" }}>
                                <p>
                                    <Form className='text-center' style={{ justifyContent: "center" }}>
                                        <Form.Group className="mb-2 col-xs-6" controlId="formBasicEmail">
                                            <Form.Label><Typography variant='subtitle1'>Role Status</Typography></Form.Label>
                                            <br></br>
                                            <ButtonGroup>
                                                <ToggleButton
                                                    id={`radio11-${id}`}
                                                    type="radio"
                                                    variant={archivedStates[data.role_id] == true ? 'danger' : 'outline-danger'}
                                                    name={`radio-${id}`}
                                                    value='True'
                                                    size="sm"
                                                    // defaultChecked={data.archived}

                                                    checked={archivedStates[archivedStates[data.role_id]]}
                                                    onChange={(e) => { changeArchiveStatus(e, data.role_id) }}
                                                >
                                                    Archived
                                                </ToggleButton>
                                                <ToggleButton
                                                    id={`radio21-${id}`}
                                                    type="radio"
                                                    variant={archivedStates[data.role_id] == true ? 'outline-success' : 'success'}
                                                    name={`radio-${id}`}
                                                    value='False'
                                                    size="sm"
                                                    // defaultChecked={archivedStates[data.role_id]}
                                                    checked={archivedStates[archivedStates[data.role_id]]}
                                                    onChange={(e) => { changeArchiveStatus(e, data.role_id) }}


                                                >
                                                    Active
                                                </ToggleButton>
                                            </ButtonGroup>





                                        </Form.Group>
                                    </Form>
                                </p>

                                <Modal style={{ width: "50%", left: "25%", top: "20%" }} show={showArchiveWarning} onHide={handleCloseArchiveWarning}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Warning</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <h5>Are you sure you want to change the Archive Status for this role?</h5>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleCloseArchiveWarning}>
                                            Cancel
                                        </Button>
                                        <Button variant="primary" onClick={saveArchiveStatus}>
                                            Yes
                                        </Button>
                                    </Modal.Footer>
                                </Modal>


                                {/* <p style={{ textAlign: "center", cursor: "pointer" }}><b onClick={(e) => { openDispRoleDetail(e, data); }}>{data.role_name}</b></p> */}
                                <Typography variant="subtitle1" style={{ textAlign: "center", cursor: "pointer" }} onClick={(e) => {
                                    handleShowCheckListRoleDetails(e, data.home_id, data.role_id);
                                }} gutterBottom>{data.role_name}</Typography>

                                <p>
                                    <Modal show={roleDetailshow} onHide={handleroleDetailClose} style={{ width: "50%", left: "20%" }}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Role Details</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {dispRoleDetail ?
                                                <div>{dispRoleDetail.role_details}</div> : <div></div>

                                            }


                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleroleDetailClose} >
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal></p>


                            </th>)
                        })
                        }


                    </thead>
                    <tbody>

                        {courseList.map((courses, id) => {
                            return (<tr key={id} >
                                <td style={{ width: "10vh", position: "sticky", left: "0px", backgroundColor: "rgb(250 246 246)", zIndex: "9" }}><b style={{ cursor: "pointer", width: "10vh" }} onClick={(e) => {
                                    getMicrodetails(e, courses);
                                }}>{courses.title}({courses.courseID})</b><div>
                                        <Modal show={MicroCredShow} onHide={handleCloseMicroCred}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>View MicroCred Details</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {/* This will contain MicroCred Details for */}
                                                <h5><b>Title</b> : {dispMicroCred.title}</h5>
                                                <h5><b>Description</b> : {dispMicroCred.description}</h5>
                                                <h5><b>Validity Duration</b> : {dispMicroCred.validity_duration}</h5>

                                            </Modal.Body>
                                            <Modal.Footer>
                                                {/* <Button variant="secondary" >
                                            Close
                                        </Button>
                                        <Button variant="primary" >
                                            Save Changes
                                        </Button> */}
                                            </Modal.Footer>
                                        </Modal>
                                    </div></td>
                                {trainStandards.map((data, _id) => {
                                    return (<td key={_id} style={{ borderRight: "1px solid #000", borderLeft: "1px solid #000", width: "5vh" }}>
                                        <input
                                            type="checkbox" style={{ transform: "scale(2)", marginLeft: "50%" }}

                                            checked={(trainStandards[_id].course_details?.find(item =>
                                                item.id == courseList[id].courseID
                                            ) != undefined)}
                                            onChange={(e) => {
                                                handleCheckOnChange(e, courses, data, trainStandards[_id], _id);
                                            }}

                                        />

                                    </td>)
                                })
                                }
                            </tr>)
                        })}
                    </tbody>
                </Table>
            </div>
            <div>
                {errors ? (
                    <p className="text-danger">*Field Cannot be empty</p>

                ) : (
                    <p className="text-danger"></p>

                )}
            </div>
            <ErrorPopUp show={showErrorPopup} data={errorMsg} onHide={() => { setShowErrorPopup(false) }} />
            <SuccessPopUp show={showSuccessPopup} data={successMsg} onHide={() => { setShowSuccessPopup(false) }} />
        </div>);
}