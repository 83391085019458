import React, { useState, useEffect } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import jsPDF from "jspdf";
import "jspdf-autotable";
import Spinner from 'react-bootstrap/Spinner'
import { Col, Row } from "react-bootstrap";
import * as apiUtility from "../../config/APIUtils";
import { Divider, Icon, IconButton, Paper, Tooltip } from "@mui/material";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import ErrorPopUp from '../Utility/ErrorPopUp';
import { RiFileExcel2Line } from "react-icons/ri";
import { BsFiletypePdf } from "react-icons/bs";
import { RxInfoCircled } from "react-icons/rx";

function OrganizationSummaryComponent(props) {
    const [showSpinner, setshowSpinner] = useState(false);
    const org_id = props.org_id;
    const homeFlag = props.homeFlag;
    const staffFlag = props.staffFlag;
    const [orgSummFinal, setOrgSummFinal] = useState([]);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [dataForErrorPopup, setDataForErrorPopup] = useState('');


    useEffect(async () => {
        setshowSpinner(true);
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "audit-report/org-summary/" + org_id + "/" + homeFlag + "/" + staffFlag;
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
            setOrgSummFinal(res.data);
            setshowSpinner(false)
        }).catch((error) => {
            console.error(error);
            setshowSpinner(false);
        });


    }, [org_id])

    const holdUsersFormatter = (data, row) => {
        return row.hold_users;
    };

    var columns = [{
        dataField: 'homeId',
        sort: true,
        text: 'Home ID'
    }, {
        dataField: 'name',
        sort: true,
        text: 'Home Name'
    }, {
        dataField: 'status',
        sort: true,
        text: 'Status'
    }, {
        dataField: 'is_home_compliant',
        sort: true,
        text: 'Is Compliant?'
    }, {
        dataField: 'totalStaff',
        text: 'Total Staff'
    }, {
        dataField: 'compliant',
        text: 'Compliant'
    }, {
        dataField: 'non_compliant',
        text: 'Non-Compliant'
    }, {
        dataField: 'hold_users',
        text: 'Unknown',
        headerFormatter: (cell, row) => (
            <span>
                {"Unknown"} &nbsp;
                <Tooltip variant="contained" title="Users that have been added to the home but do not consent to share their records" disableRipple>
                    <Icon color='primary' style={{ "fontSize": "15px" }}>
                        <RxInfoCircled style={{ "marginBottom": "9px" }} />
                    </Icon>
                </Tooltip>
            </span>
        ),
        formatter: holdUsersFormatter
    }
    ];

    if (orgSummFinal == []) {
        return (
            <div>
                {/* Loading */}
            </div>
        )
    }

    var exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 10;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Organization Summary Report";

        var headers = [["homeId", "home_name", "is_complaint", "num_complaint", "num_non_complaint"]]

        //console.log(orgSummDetails);


        let content = {
            startY: 50,
            head: headers,
            body: orgSummFinal
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("report.pdf")
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: "xlsx",
            bookSST: false,
            type: "binary",
        };
        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([s2ab(wbout)], {
            type: "application/octet-stream",
        });
        return blob;
    };
    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; i++) {
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    //Excel
    const exportInExcel = async () => {
        try {
            var rowsCount = 6;
            const title = [{ A: 'Ontario Long Term Care Orientation Co-operative Webportal - Summary Report for Organization ID : ' + String(org_id) }];
            let reportView = [{ A: 'Name', B: 'User ID', C: 'Role', D: 'Date' }];
            reportView.push({
                A: sessionStorage.getItem('fullName'),
                B: sessionStorage.getItem('userId'),
                C: (sessionStorage.getItem('userType') !== null)?sessionStorage.getItem('userType').toUpperCase():"User",
                D: new Date().toLocaleString()
            });
            reportView.push("");
            reportView.push("");
            let reportHeaders = { A: 'Home ID', B: 'Home Name', C: 'Status', D: 'Is Compliant?', E: 'Total Staff', F: 'Compliant', G: 'Non-Compliant', H: 'Unknown' };
            reportView.push(reportHeaders);
            // orgStaffTemplateData.forEach(element => {
            orgSummFinal.forEach(element => {
                rowsCount += 1;
                var tempObj = {
                    A: element.homeId,
                    B: element.name,
                    C: element.status,
                    D: element.is_home_compliant,
                    E: element.totalStaff,
                    F: element.compliant,
                    G: element.non_compliant,
                    H: element.hold_users,
                };
                reportView.push(tempObj);
            });
            const finalData = [...title, ...reportView];
            const worksheet = XLSX.utils.json_to_sheet(finalData, {
                skipHeader: true,
            });
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Organization Summary");
            const workbookblob = workbook2blob(workbook);
            var headerIndexes = [];
            finalData.forEach((data, index) =>
                data["A"] === "Home ID" ? headerIndexes.push(index) : null
            );
            const dataInfo = {
                titleCell: "A2",
                titleRange: "A1:H1",
                tbodyRange: `A7:H${finalData.length}`,
                uHeadRange: `A2:D2`,
                uBodyRange: `A3:D3`,
                theadRange:
                    headerIndexes?.length >= 1
                        ? `A${headerIndexes[0] + 1}:H${headerIndexes[0] + 1}`
                        : null,
                theadRange1:
                    headerIndexes?.length >= 2
                        ? `A${headerIndexes[1] + 1}:I${headerIndexes[1] + 1}`
                        : null,
                tFirstColumnRange:
                    headerIndexes?.length >= 1
                        ? `A${headerIndexes[0] + 1}:A${rowsCount + headerIndexes[0] + 1}`
                        : null,
                tLastColumnRange:
                    headerIndexes?.length >= 1
                        ? `K${headerIndexes[0] + 1}:I${rowsCount + headerIndexes[0] + 1}`
                        : null,

                tFirstColumnRange1:
                    headerIndexes?.length >= 1
                        ? `A${headerIndexes[1] + 1}:A${rowsCount + headerIndexes[1] + 1}`
                        : null,
                tLastColumnRange1:
                    headerIndexes?.length >= 1
                        ? `H${headerIndexes[0] + 1}:I${rowsCount + headerIndexes[1] + 1}`
                        : null,
            };
            XlsxPopulate.fromDataAsync(workbookblob).then((workbook) => {
                workbook.sheets().forEach((sheet) => {
                    sheet.column("A").width(15);
                    sheet.column("B").width(40);
                    sheet.column("C").width(20);
                    sheet.column("D").width(20);
                    sheet.column("E").width(20);
                    sheet.column("F").width(20);
                    sheet.column("G").width(20);
                    sheet.column("H").width(20);
                    sheet.range(dataInfo.titleRange).merged(true).style({
                        bold: true,
                        fontFamily: "Arial",
                        horizontalAlignment: "center",
                        verticalAlignment: "center",
                    });
                    sheet.range(dataInfo.uHeadRange).style({
                        fill: "A6A6A6",
                        bold: true,
                        horizontalAlignment: "center",
                    });
                    sheet.range(dataInfo.uBodyRange).style({
                        bold: true,
                        horizontalAlignment: "center",
                    });
                    if (dataInfo.tbodyRange) {
                        sheet.range(dataInfo.tbodyRange).style({
                            horizontalAlignment: "left",
                        });
                    }
                    sheet.range(dataInfo.theadRange).style({
                        fill: "A6A6A6",
                        bold: true,
                        horizontalAlignment: "center",
                    });
                    if (dataInfo.tFirstColumnRange) {
                        sheet.range(dataInfo.tFirstColumnRange).style({
                            bold: true,
                        });
                    }

                });

                return workbook.outputAsync().then((workbookBlob) => {
                    const downloadAnchorNode = document.createElement("a");
                    var dateVar = new Date();
                    var fileName = "LCPT_OrgSummaryReport-" + dateVar.getDate() + dateVar.getMonth() + dateVar.getFullYear() + "_" + dateVar.getHours() + dateVar.getMinutes() + ".xlsx";
                    downloadAnchorNode.setAttribute("href", URL.createObjectURL(workbookBlob));
                    downloadAnchorNode.setAttribute("download", fileName);
                    downloadAnchorNode.click();
                    downloadAnchorNode.remove();
                    // setDataForSuccessPopup("Downloaded Successfully");
                    // setShowSuccessPopup(true);
                });
            });
        } catch (error) {
            setDataForErrorPopup("Something went wrong while processing excel!");
            setShowErrorPopup(true);
        }
    }

    return (
        <div>
            {showSpinner ? <div style={{ paddingLeft: "50%", paddingTop: "10%", position: "absolute" }}>
                <Spinner show={showSpinner} animation="border" size="lg" variant='primary' />

            </div> : <div></div>}


            {/* <OrganizationSummaryComponent /> */}
            <Paper sx={{ p: 1, borderRadius: "1.5px" }}>
                <BootstrapTable id='organizationSummaryTable' keyField='home_id' data={orgSummFinal} columns={columns} striped hover />
                <Row>

                    <Col xs={12} md={6} className="text-center"></Col>
                    <Col xs={12} md={6} className="text-center">
                        <Paper component="form" variant="outlined"
                            sx={{ float: "right", display: 'flex', marginBottom: "4px", alignItems: 'center', width: 100 }}
                        >
                            <Tooltip variant="contained" title="Export to PDF" disableRipple>
                                <IconButton color="primary" onClick={exportPDF} sx={{ p: '10px' }} aria-label="directions">
                                    <BsFiletypePdf />
                                </IconButton>
                            </Tooltip>
                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                            <Tooltip variant="contained" title="Export to Excel" disableRipple>
                                <IconButton color="primary" onClick={exportInExcel} sx={{ p: '10px' }} aria-label="directions">
                                    <RiFileExcel2Line />
                                </IconButton>
                            </Tooltip>
                        </Paper>

                    </Col>
                </Row>
            </Paper>
            <ErrorPopUp show={showErrorPopup} data={dataForErrorPopup} onHide={() => { setShowErrorPopup(false) }} />


        </div>
    )
}

export default OrganizationSummaryComponent